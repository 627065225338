<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss(null)" [loading]="loading" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">
  <div [class]="wrapperClass" fxLayout="column">
    <div [class]="innerClass" fxLayout="column">

      <safe-html-renderer *ngIf="heading" [imageWrapper]="true" [unsafeHtmlContent]="heading"
        innerClass="bold description-frame info-text-lg text-color-primary text-center wrap-img-container-wrapper theme-border-bottom margin-bottom-s"></safe-html-renderer>

      <safe-html-renderer *ngIf="descriptionHeader" [imageWrapper]="true" [unsafeHtmlContent]="descriptionHeader"
        innerClass="description-frame info-text-m bold text-color-primary text-center wrap-img-container-wrapper"></safe-html-renderer>

      <audio-guide *ngIf="descriptionPlain && withAudioGuide" label="" [contentHTML]="descriptionPlain"
        [autoplay]="audioGuideAutoplay"></audio-guide>

      <safe-html-renderer *ngIf="description" [imageWrapper]="true" [unsafeHtmlContent]="description"
        innerClass="info-text-m description-frame text-color-primary wrap-img-container-wrapper"></safe-html-renderer>

      <div *ngIf="photoUrl" class="padding-s ">
        <responsive-img [photoUrl]="photoUrl" class="border-radius"></responsive-img>
      </div>
      <div *ngIf="videoUrl" class="margin-top-xs margin-bottom-xs">
        <youtube-view [videoUrl]="videoUrl" customClass="height-40"></youtube-view>
      </div>

      <safe-html-renderer *ngIf="descriptionFooter" [imageWrapper]="true" [unsafeHtmlContent]="descriptionFooter"
        innerClass="info-text-m description-frame bold text-color-primary wrap-img-container-wrapper"></safe-html-renderer>
    </div>

    <div fxFlex>
    </div>

    <div class="stick-bottom" [ngClass]="buttonBar">
      <ion-grid class="full-w">
        <ion-row *ngIf="mode===modes.withOk">
          <ion-col size="12">
            <cooldown-button *ngIf="autoClose" [active]="true" [triggerCooldown]="true" [cooldown]="cooldown" name="Ok"
              theme="primary" [autoTriggerMode]="true" (select)="ok()">
            </cooldown-button>
            <ion-button *ngIf="!autoClose" class="action-button-fill button-color-transparent" (click)="ok()">
              <span class="button-font-size-m">
                Ok
              </span>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="mode===modes.withOkDismiss">
          <ion-col size="6">
            <ion-button class="action-button-fill button-color-warn" (click)="cancel()">
              <icon-wrapper slot="icon-only" [icon]="appIcons.cancel" [custom]="true"></icon-wrapper>
            </ion-button>
          </ion-col>
          <ion-col size="6">
            <ion-button *ngIf="!autoClose" class="action-button-fill button-color-accent" (click)="ok()">
              <icon-wrapper slot="icon-only" [icon]="appIcons.check" [custom]="true"></icon-wrapper>
            </ion-button>
            <cooldown-button *ngIf="autoClose" [active]="true" [triggerCooldown]="true" [cooldown]="cooldown"
              [icon]="appIcons.check" [customIcon]="true" name="Ok" theme="primary" [autoTriggerMode]="true"
              (select)="ok()">
            </cooldown-button>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="mode===modes.withCustomButton">
          <ion-col size="12">
            <ion-button *ngIf="!customButton.name" class="action-button-fill button-color-alternate" (click)="ok()">
              <icon-wrapper slot="icon-only" [icon]="customButton.icon" [custom]="customButton.customIcon">
              </icon-wrapper>
            </ion-button>
            <ion-button *ngIf="customButton.name" class="action-button-fill button-color-alternate"
              [disabled]="customButton.disabled" (click)="ok()">
              <span class="button-font-size-s">{{customButton.name}}</span>
              <icon-wrapper class="slot-icon-padding" slot="start" [icon]="customButton.icon"
                [custom]="customButton.customIcon"></icon-wrapper>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="mode===modes.withCustomButtonArray && buttons">
          <!-- dynamic button grid -->
          <ion-col *ngFor="let b of buttons" [dynamicCol]="b.size">
            <ion-button *ngIf="b.enabled" [disabled]='b.disabled' [ngClass]="b.class" (click)="customButtonTap(b)">
              <span *ngIf="b.name" class="button-font-size-s">{{b.name}}</span>
              <icon-wrapper class="slot-icon-padding" [slot]="b.name ? 'icon-only' : 'start'" [icon]="b.icon"
                [custom]="b.customIcon">
              </icon-wrapper>
              <!-- <icon-wrapper [custom]="b.customIcon" slot="icon-only" [icon]="b.icon"></icon-wrapper> -->
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</modal-container>