<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="true" title="Login" [loading]="loading"></leplace-toolbar>
</ion-header>

<!-- <ion-content class="theme-bg"> -->
<ion-content [ngClass]="theme">
  <!-- <div ></div> -->
  <!-- <div class="logo ">
      <img src="assets/img/appicon.svg " alt="Ionic logo ">
    </div> -->
  <div class="view-frame-small theme-background">
    <div class="padding-s" fxLayout="column">

      <span class="logo no-padding text-color-alternate-high">
        Leplace World
      </span>
      <!-- <span class="sub-logo sub-logo-padding-left no-padding center-text text-color-alternate-high">
        by Leplace
      </span> -->

      <form #loginForm="ngForm" novalidate class="scrollbar-y">
        <ion-list no-lines>
          <ion-item class="transparent">
            <ion-label position="stacked" class="text-color-primary info-text-s">Email</ion-label>
            <ion-input [(ngModel)]="login.email" ngDefaultControl name="email" type="email" #email="ngModel"
              spellcheck="false" autocapitalize="off" required
              pattern="[A-Za-z0-9._%+-]{2,}@[a-zA-Z._-]{2,}[.]{1}[a-zA-Z]{2,}" placeholder="email"
              class="text-color-primary info-text-m">
              <!-- (keyup.enter)="setFocus($event, password1)" -->
              <!-- pattern="[^@\s]+@[^@\s]+\.[^@\s]+" -->
              <!-- https://stackoverflow.com/questions/5601647/html5-email-input-pattern-attribute -->
              <!-- pattern=”^[a-zA-Z0–9_.+-]+@[a-zA-Z0–9-]+.[a-zA-Z0–9-.]+$” -->
              <!-- abc@example.com # Minimum three characters
                        ABC.xyz@example.com # Accepts Caps as well.
                        abce.xyz@example.co.in # Accepts . before @ -->
            </ion-input>
          </ion-item>
          <span class="info-text-xs text-color-warn-contrast" [hidden]="email.valid || (email.pristine && !submitted)"
            color="danger" padding-left>
            Email is required
          </span>
          <ion-item class="transparent">
            <ion-label position="stacked" class="text-color-primary info-text-s">Password</ion-label>
            <ion-input [(ngModel)]="login.password" ngDefaultControl name="password1" #password1="ngModel"
              type="password" required placeholder="password" class="text-color-primary info-text-m" enterKeyPress
              (enterKeyPress)="onLogin(loginForm)">
            </ion-input>
          </ion-item>
          <span class="info-text-xs text-color-warn-contrast"
            [hidden]="password1.valid || (password1.pristine && !submitted)" color="danger" padding-left>
            Password is required
          </span>
        </ion-list>

        <!-- (click)="onLogin(loginForm)" -->
        <ion-button id="login-btn" class="action-button-fill button-color-primary-70" block
          (click)="onLogin(loginForm)">
          <span class="button-font-size-s">
            Log in with Email
          </span>
        </ion-button>

        <ion-button *ngIf="!useWebSignInGoogleAPI" block class="action-button-fill button-color-custom-google"
          [disabled]="!apiKeysLoaded" (click)="loginWithGoogle()">
          <mat-progress-spinner *ngIf="!apiKeysLoaded" [diameter]="20" color="primary" mode="indeterminate">
          </mat-progress-spinner>
          <span class="button-font-size-s">Sign in with Google</span>
          <icon-wrapper *ngIf="apiKeysLoaded" class="slot-icon-padding" slot="start" icon="logo-google"></icon-wrapper>
        </ion-button>

        <div class="action-button-fill button-color-custom-google google-button-container"
          *ngIf="useWebSignInGoogleAPI">
          <mat-progress-spinner *ngIf="!apiKeysLoaded" [diameter]="20" color="primary" mode="indeterminate">
          </mat-progress-spinner>
          <div *ngIf="apiKeysLoaded" id="buttonDiv"></div>
          <span class="button-font-size-s padding-left-s uppercase bold">Sign in with Google</span>
        </div>

        <ion-button *ngIf="appleLogin" block class="action-button-fill button-color-custom-apple"
          (click)="signInWithApple()">
          <span class="button-font-size-s">Sign in with Apple</span>
          <icon-wrapper class="slot-icon-padding" slot="start" icon="logo-apple"></icon-wrapper>
        </ion-button>

        <!-- <ion-button  block class="action-button-fill button-color-custom-facebook"
            (click)="loginWithFacebook()">
            <span>Facebook Login</span>
            <icon-wrapper icon="logo-facebook"></icon-wrapper>
          </ion-button> -->

        <ion-button class="action-button-fill button-color-transparent margin-top-s" (click)="onSignup()" block outline>
          <span class="text-color-primary button-font-size-s">
            Sign up with Email
          </span>
        </ion-button>


        <div tappable class="margin-top-s">
          <span class="as-text element-selector button-font-size-s text-color-primary"
            (click)="forgotMyPassword(loginForm)">
            Forgot my password
          </span>
        </div>
        <div tappable class="margin-top-s">
          <span class="as-text element-selector button-font-size-s text-color-primary" (click)="showSignupTerms()">
            Terms of service
          </span>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="!apiKeysLoaded" class="text-color-primary info-text-s text-center">Connecting to server..</div>
</ion-content>