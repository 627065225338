import { Component, OnDestroy, ViewChild, ElementRef, OnInit, ViewEncapsulation, AfterViewInit, NgZone } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { EClass, EFooterClass, EHeaderClass, EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { EPhotos, EMarkerIcons, EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { IPlaceMarkerContent, IUserLocationData, ILocationData, IGeolocationResult, IWaypoint, IMarkerDetailsOpenContext, IPathContent } from 'src/app/classes/def/map/map-data';
import { IHudElement, HudUtils, IHudConfig, EHudContext, EMapHudCodes } from 'src/app/classes/def/app/hud';
import { ILeplaceTreasure, ITreasureSpec, TreasureUtils, ILeplaceWrapper, ILeplaceTreasureAction, ETreasureMode } from 'src/app/classes/def/places/leplace';
import { ILocationItemsDef, ILocationContainer, IBackendLocation } from 'src/app/classes/def/places/backend-location';
import { IStory, IStoryResponse, IMasterLockResponse, EStoryMode, ECheckpointMarkerStatus } from 'src/app/classes/def/core/story';
import { IActionLayers, ILeplaceObjectGenerator, ILeplaceObjectContainer, ECRUD } from 'src/app/classes/def/core/objects';
import { GoogleMap } from 'capacitor-plugin-google-maps';
import { EARModes, EARSpecialActivity, EViewLinkCodes } from 'src/app/classes/def/ar/core';
import { ISettings } from 'src/app/classes/def/app/settings';
import { EMapStyles } from 'src/app/classes/def/map/map-styles';
import { IGmapButtonsFlag, GmapUtils, EGmapShowOptions, IFindActivityResult, IGapModeSelector, IGmapCountdown, INearbyContentMagnet, ENearbyContentType, INearbyContentMagnetElem, INearbyContentMagnetContext } from 'src/app/classes/def/map/gmap-utils';
import { IGameItem, EItemCategoryCodes, EItemActions, EItemCodes } from 'src/app/classes/def/items/game-item';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { EStoryLocationDoneFlag, EStoryLocationStatusFlag, IStoryListNavParams } from 'src/app/classes/def/nav-params/story';
import { EGmapMode, IGmapEntryNavParams, IGmapDetailReturnParams, EGmapDetailReturnCode, IGmapDetailInteractions, IGmapActivityStartOptions, IGmapActivityPreviewOptions } from 'src/app/classes/def/nav-params/gmap';
import { Platform } from '@ionic/angular';
import { INavParams, IViewSpecs } from 'src/app/classes/def/nav-params/general';
import { EMarkerLayers } from 'src/app/classes/def/map/marker-layers';
import { MapSettings } from 'src/app/classes/utils/map-settings';
import { ThemeColors, EChartTheme } from 'src/app/classes/def/app/theme';
import { IMessageTimelineEntry, EMessageTimelineCodes } from 'src/app/classes/def/newsfeed/message-timeline';
import { EQueueMessageCode, IMessageQueueEvent, IQueueMessage } from 'src/app/classes/utils/queue';
import { IEventDetails, IEventDetailsResponse } from 'src/app/classes/def/events/events';
import { Messages } from 'src/app/classes/def/app/messages';
import { ETreasureType } from 'src/app/classes/def/items/treasures';
import { IWorldMapRefreshOptions, EPrivateScannerMode } from 'src/app/classes/def/items/scanner';
import { AppSettings } from 'src/app/services/utils/app-settings';
import { EGameContext } from 'src/app/classes/def/core/game';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { GeneralUtils, EKeyCodes } from 'src/app/classes/utils/general';
import { EMessageTrim, MessageUtils, IShareActivityProgress, IShareMsgParams } from 'src/app/classes/utils/message-utils';
import { LocationUtils } from 'src/app/services/location/location-utils';
import { StoryUtils } from 'src/app/classes/utils/story-utils';
import { BehaviorSubject, timer } from 'rxjs';
import { AppConstants } from 'src/app/classes/app/constants';
import { IFormatDisp, MathUtils } from 'src/app/classes/general/math';
import { IAppLocation, ELocationFlag } from 'src/app/classes/def/places/app-location';
import { DeepCopy } from 'src/app/classes/general/deep-copy';
import { IAppPlaceResult, ILeplaceRegMulti, ILeplaceReg } from 'src/app/classes/def/places/google';
import { ESmartZoomTransitions, ESmartZoomState } from 'src/app/classes/def/map/zoom';
import { IGetPhotoOptions, EGoogleMapsRequestStatus } from 'src/app/services/location/location-utils-def';
import { MarkerUtils } from 'src/app/services/map/marker-utils';
import { ActivityUtils } from 'src/app/classes/utils/activity-utils';
import { EMarkerScope } from 'src/app/classes/def/map/markers';
import { EStatCodes, IRegisterStoryFinished } from 'src/app/classes/def/user/stats';
import {
  IFindActivityDef, EActivityCodes, IActivity,
  IActivityResultCore, ECheckActivityResult, EFinishedActionParams, IActivityStatsContainer,
  IVisitActivityDef, IExploreActivityDef, EStandardActivityFailedCode, IARExploreActivityDef,
  IGenericMoveActivityDef, IPhotoActivityDef, IDanceActivityDef, IDecibelActivityDef, IQuestActivityDef, IMediaActivityDef
} from 'src/app/classes/def/core/activity';
import { GeometryUtils } from 'src/app/services/utils/geometry-utils';
import { EActivityDirectionsMode, ENavigateReturnCodes, INavigationStats } from 'src/app/classes/def/map/navigation';
import { GameUtils } from 'src/app/classes/utils/game-utils';
import { IPhotoActivityParams, IPhotoActivityStatus } from 'src/app/classes/def/activity/photo';
import { ITimeoutMonitorData, ETimeoutStatus } from 'src/app/classes/general/timeout';
import { IItemFound, IActivityFailed, IArenaNavParams } from 'src/app/classes/def/nav-params/activity';
import { EGroupRole, EArenaEvent, IGroup, EGroupContext } from 'src/app/classes/def/mp/groups';
import { ITreasureFoundReturnData, IActivityFinishedModalOutput, IStoryFinishedModalOutput } from 'src/app/classes/def/nav-params/modal-finished-return';
import { ELeaderStates, EMPEventSource, EMPUserInputCodes, EMemberStates, EMPMessageCodes, EMPVirtualMemberCodes, MPEncoding } from 'src/app/classes/def/mp/protocol';
import { Util } from 'src/app/classes/general/util';
import { IARExploreStatus } from 'src/app/classes/def/activity/arexplore';
import { IExploreActivityInit, IExploreCoinGen, EExploreCoinAction, EExploreObjectDynamics, EExploreModes, ICoinSpecsMpSyncData, IFindActivityInit, IExploreFindActivityInit, IExploreActivityStatus, IExploreCollectibleParams } from 'src/app/classes/def/activity/explore';
import { IARViewNavParams } from 'src/app/classes/def/nav-params/ar-view';
import { ICustomParamForActivity, ECustomParamScope, IFixedCoin } from 'src/app/classes/def/core/custom-param';
import { ARViewEntryPage } from '../ar-view-entry/ar-view-entry.page';
import { ActivityFinishedViewComponent } from 'src/app/modals/app/modals/activity-finished/activity-finished.component';
import { StoryFinishedViewComponent } from 'src/app/modals/app/modals/story-finished/story-finished.component';
import { IRemovedMultipleItemsResponse, IRemoveItemResponse } from 'src/app/classes/def/requests/inventory';
import { EFeatureCode, IFeatureDef, EOS } from 'src/app/classes/def/app/app';
import { ActivityFailedViewComponent } from 'src/app/modals/app/modals/activity-failed/activity-failed.component';
import { EActivityExitState } from 'src/app/classes/def/core/activity-manager';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { IMoveMonitorParams, IMoveMonitorData, EMoveActivityStatus } from 'src/app/classes/def/core/move-monitor';
import { IPlacesPopup } from 'src/app/classes/def/nav-params/place-popup';
import { PlacesPopupViewComponent } from 'src/app/modals/app/modals/places-popup/places-popup.component';
import { IPopoverActions, ICheckboxFrameStatus } from 'src/app/classes/def/app/modal-interaction';
import { ETreasureSpecInput, ITreasureSpecInput } from 'src/app/modals/app/modals/treasure-spec-selector/treasure-spec-selector.component';
import { IInventoryReturnItem } from 'src/app/classes/def/nav-params/inventory';
import { ICheckCollectItem, ItemCollectorUtils } from 'src/app/services/app/modules/item-collector-utils';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { IArenaReturnToMapData, EArenaReturnCodes } from 'src/app/classes/def/nav-params/arena';
import { MPUtils, IMPAvailableItems } from 'src/app/services/app/mp/mp-utils';
import { IMPGameResults } from 'src/app/classes/def/mp/http';
import { IMPMessageDataChallenge } from 'src/app/classes/def/mp/message-data';
import { IMPMessageDB } from 'src/app/classes/def/mp/message';
import { IDescriptionFrameParams, EDescriptionViewStyle } from 'src/app/modals/generic/modals/description-frame/description-frame.component';
import { IGmapApp, IGmapUser, EFollowMode, EMapInteraction, EGmapStates } from 'src/app/classes/def/map/gmap';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { StoryDataService } from 'src/app/services/data/story';
import { UserStatsDataService } from 'src/app/services/data/user-stats';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { LocationManagerService } from 'src/app/services/map/location-manager';
import { LocationMonitorService } from 'src/app/services/map/location-monitor';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { ModularViewsService } from 'src/app/services/utils/modular-views';
import { ExploreActivityService } from 'src/app/services/app/modules/activities/explore';
import { ItemScannerService } from 'src/app/services/app/modules/item-scanner';
import { MessageQueueHandlerService } from 'src/app/services/general/message-queue-handler';
import { ARExploreActivityService } from 'src/app/services/app/modules/activities/arexplore';
import { ActivityService, EExploreMoveStat } from 'src/app/services/app/modules/activity';
import { FindActivityService } from 'src/app/services/app/modules/activities/find';
import { BenchmarkDataService } from 'src/app/services/data/benchmark';
import { IPopupSkipResult, PopupFeaturesService } from 'src/app/services/app/modules/minor/popup-features';
import { PremiumDataService } from 'src/app/services/data/premium';
import { EventsDataService } from 'src/app/services/data/events';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { ContentCreatorService } from 'src/app/services/app/modules/content-creator';
import { PlacesDataService } from 'src/app/services/data/places';
import { ResourceHandlerDataService } from 'src/app/services/data/resource-handler';
import { DirectionsService } from 'src/app/services/utils/directions';
import { ShareService } from 'src/app/services/general/apis/share';
import { LocationApiService } from 'src/app/services/location/location-api';
import { MapManagerService } from 'src/app/services/map/map-manager';
import { LocalNotificationsService } from 'src/app/services/general/apis/local-notifications';
import { UserDataService } from 'src/app/services/data/user';
import { SmartZoomService } from 'src/app/services/app/utils/smart-zoom';
import { MarkerHandlerService } from 'src/app/services/map/markers';
import { MarkerUtilsService } from 'src/app/services/map/marker-utils-provider';
import { GmapModalsService } from 'src/app/services/app/modules/minor/gmap-modals';
import { MPGameInterfaceService, EMPGameMode } from 'src/app/services/app/mp/mp-game-interface';
import { NavigationHandlerService, INavUpdateResult, ENavUpdateResult } from 'src/app/services/map/navigation';
import { GeoObjectsService } from 'src/app/services/app/modules/geo-objects';
import { PhotoValidatorService } from 'src/app/services/app/utils/photo-validator';
import { ChallengeEntryService } from 'src/app/services/app/modules/challenge-entry';
import { TimeoutService } from 'src/app/services/app/modules/timeout';
import { PhotoActivityService } from 'src/app/services/app/modules/activities/photo';
import { MoveActivityService } from 'src/app/services/app/modules/activities/move';
import { RewardModalsService } from 'src/app/services/app/modules/minor/reward-modals';
import { AchievementsDataService } from 'src/app/services/data/achievements';
import { InventoryDataService } from 'src/app/services/data/inventory';
import { MPManagerService } from 'src/app/services/app/mp/mp-manager';
import { GameStatsService } from 'src/app/services/app/utils/game-stats';
import { ActivityStatsService } from 'src/app/services/app/modules/activity-stats';
import { MPDataService } from 'src/app/services/data/multiplayer';
import { InventoryWizardService } from 'src/app/services/app/modules/inventory-wizard';
import { BackgroundModeService } from 'src/app/services/general/apis/background-mode';
import { UserContentDataService } from 'src/app/services/data/user-content';
import { ERouteDef } from 'src/app/app-utils';
import { MPHomePage } from '../mp/mp-home/mp-home.page';
import { StoryHomePage } from '../storyline/story-home/story-home.page';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { IDrawerOptions } from 'src/app/components/generic/components/content-drawer/content-drawer.component';
import { NavUtilsService } from 'src/app/services/general/ui/nav-utils';
import { IEventStoryGroupLinkData } from 'src/app/classes/def/core/links';
import { SyncService } from 'src/app/services/app/utils/sync';
import { SleepUtils } from 'src/app/services/utils/sleep-utils';
import { ItemCollectorCoreService } from 'src/app/services/app/modules/item-collector-core';
import { ELocationTimeoutEvent } from 'src/app/classes/def/map/geolocation';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { MPGroupsHomePage } from '../mp/mp-groups-home/mp-groups-home.page';
import { IMPEventContainer } from 'src/app/classes/def/mp/events';
import { TextToSpeechService } from 'src/app/services/general/apis/tts';
import { GenericQueueService } from 'src/app/services/general/generic-queue';
import { AppVersionService } from 'src/app/services/general/app-version';
import { EPlaceUnifiedSource } from 'src/app/classes/def/places/provider';
import { IPlaceExtContainer } from 'src/app/classes/def/places/container';
import { IFindSpecsMpSyncData, IFindMarkers } from 'src/app/classes/def/activity/find';
import { ExploreActivityUtilsService } from 'src/app/services/app/modules/activities/explore-utils';
import { IMPGameSession, IMPGenericGroupStat } from 'src/app/classes/def/mp/game';
import { INgxChartOptions } from 'src/app/classes/def/app/charts';
import { NgxChartParams } from 'src/app/classes/general/params';
import { NavGaugeService, ENavGaugeMode, ENavGaugeDict, INavGaugeStat, ENavGaugeTooltip } from 'src/app/services/app/utils/nav-gauge';
import { IMoveMapContext, IMoveMapOptions } from 'src/app/classes/def/map/interaction';
import { DroneSimulatorService, IDroneStatusUpdate, EDroneStatusUpdateCode, IDroneStatus } from 'src/app/services/map/drone-simulator';
import { IJoystickPosition, IJoystickStatusUpdate } from 'src/app/components/generic/components/joystick-ngx/joystick-ngx.component';
import { VirtualPositionService, IVirtualLocation, EVirtualLocationSource } from 'src/app/services/app/modules/virtual-position';
import { SupportModalsService } from 'src/app/services/app/modules/minor/support-modals';
import { SupportDataService } from 'src/app/services/data/support';
import { WalkthroughService } from 'src/app/services/app/modules/minor/walkthrough';
import { StorageFlagsService } from 'src/app/services/general/data/storage-flags';
import { SoundEffectsService } from 'src/app/services/general/apis/sound-effects';
import { SoundManagerService } from 'src/app/services/general/apis/sound-manager';
import { WaitUtils } from 'src/app/services/utils/wait-utils';
import { ActivitiesDataService, IActivityTutorialContainer, EDroneMode } from 'src/app/services/data/activities';
import { FallbackUtils } from 'src/app/services/utils/fallback-utils';
import { ETrackedEvents } from 'src/app/classes/app/analytics';
import { ELocalAppDataKeys, ILocalShowFlags, IAppFlagsGenContent } from 'src/app/classes/def/app/storage-flags';
import { AnalyticsExtrasService } from 'src/app/services/general/apis/analytics-extras';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PermissionsService } from 'src/app/services/general/permissions/permissions';
import { IQuestActivityStatus } from 'src/app/classes/def/activity/quest';
import { QuestActivityService } from 'src/app/services/app/modules/activities/quest';
import { IActivityQuestSpecs, IActivityParamsView, IActivityPhotoSpecs } from 'src/app/classes/def/nav-params/activity-details';
import { ICheckPendingCheckpoints, StoryManagerService } from 'src/app/services/map/story-manager';
import { IMapEngineFlags, MapEngineUtilsService } from 'src/app/services/map/engine-utils';
import { HeadingService, IHeadingStatus } from 'src/app/services/map/heading';
import { TimeoutQueueService, ETimeoutQueue } from 'src/app/services/general/timeout-queue';
import { ModularInteractionService } from 'src/app/services/utils/modular-interaction';
import { PromiseUtils } from 'src/app/services/utils/promise-utils';
import { PlaceSearchViewComponent, IPlaceSearch } from 'src/app/modals/app/modals/place-search/place-search.component';
import { LinksDataService } from 'src/app/services/data/links';
import { ArrayUtils } from 'src/app/services/utils/array-utils';
import { OtherUtils } from 'src/app/services/utils/other-utils';
import { IGenericSlideData } from 'src/app/classes/def/views/slides';
import { IPhotoResultResponse } from 'src/app/classes/def/media/processing';
import { ActivityStatsTrackerService } from 'src/app/services/app/modules/activity-stats-tracker';
import { NetworkMonitorService, ENetworkMonitorState } from 'src/app/services/general/apis/network-monitor';
import { AuthRequestService } from 'src/app/services/general/auth-request/auth-request';
import { KeyHandlerService } from 'src/app/services/general/ui/key-handler';
import { EModalEvents, Events } from 'src/app/services/utils/events';
import { SoundUtils } from 'src/app/services/general/apis/sound-utils';
import { YouTubeService } from 'src/app/services/general/apis/youtube';
import { ETutorialEntries } from 'src/app/classes/def/app/tutorials';
import { MapGeneralUtilsService } from 'src/app/services/map/general-utils';
import { LocationDispUtils } from 'src/app/services/location/location-disp-utils';
import { ItemCollectorService } from 'src/app/services/app/modules/item-collector';
import { GameStatsUtils } from 'src/app/services/app/utils/game-stats-utils';
import { BackgroundModeWatchControllerService } from 'src/app/services/general/apis/background-mode-watch-controller';
import { MQTTService } from 'src/app/services/telemetry/mqtt.service';
import { EMQTTStatusKeys, IGameContextStatus, IMQTTChatMessage } from 'src/app/services/telemetry/def';
import { MQTTManagerService } from 'src/app/services/telemetry/manager.service';
import { MQTTChatService } from 'src/app/services/telemetry/chat.service';
import { ScreenService } from 'src/app/services/general/apis/screen';
import { ILocationScanSpecs } from 'src/app/services/location/def';
import { IActivityScoreResponse } from 'src/app/classes/def/core/activity-stats';
import { ECheckpointCollectMode, ECheckpointNavMode } from 'src/app/classes/def/core/interactive-features';
import { MiscDataService } from 'src/app/services/data/misc';
import { ILatLng } from 'src/app/classes/def/map/coords';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';
import { CameraIdleCallbackData } from 'capacitor-plugin-google-maps';


@Component({
  selector: 'app-gmap',
  templateUrl: './gmap.page.html',
  styleUrls: ['./gmap.page.scss'],
  animations: [
    trigger('showState', [
      state('inactive', style({
        // transform: 'translateY(-100%)',
        opacity: 0
      })),
      state('active', style({
        // transform: 'translateY(100%)',
        opacity: 1
      })),
      transition('inactive => active', [animate("0.7s ease-in")]),
      transition('active => inactive', [animate("0.7s ease-out")]),
      // transition('void => *', [
      //   style({ transform: 'translateY(-100%)' }),
      //   animate('500ms ease-out')
      // ]),
      // transition('* => void', [
      //   animate('500ms ease-in', style({ transform: 'translateY(100%)' }))
      // ])
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class GmapPage implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('gmap', { read: ElementRef, static: false }) mapElement: ElementRef<HTMLElement>;
  @ViewChild('auxmap', { read: ElementRef, static: false }) auxMapElement: ElementRef;

  showState: string = "active";
  mapClass: string = EClass.MAP_VIEW;
  logoSrc: string = EPhotos.logo;
  // logoSrc: string = EPhotos.bike;
  backgroundSrc: string = EPhotos.exploreMap;
  backgroundLoaded: boolean = false;
  plateSub: string = "Loading";
  userMarker: IPlaceMarkerContent;
  hudMsgXP: string;

  // app
  hudMsg: IHudElement[] = HudUtils.getMapHudInitValues();

  app: IGmapApp = {
    storyLocations: [],
    storyLocationsSlides: [],
    storyLocationsActiveSlide: 0,
    prevOffsetCenters: [],
    auxPlaceMarkers: [],
    itemsGenerated: 0,
    rewardLpStoryTotal: 0,
    collectedItemsCurrentActivity: 0,
    collectedItemsValueCurrentActivity: 0,
    canCompleteActivity: false,
    canExitMap: false,
    rewardLpCurrentActivity: 0,
    displayMsg: "",
    hud: false,
    hudXP: false,
    firstStart: false,
    firstCountdownTick: true,
    msg: "",
    startTime: 0,
    stopTime: 0,
    state: EGmapStates.INIT,
    start: false,
    challengeInProgress: false,
    mapSyncRequested: false,
    entry: true,
    finishedStory: false,
    skipAnimation: false,
    skipNextAnimation: false,
    locationIndex: 0,
    locationIndexView: 0,
    offsetCenter: null,
    activeItems: 0
  };


  collectibles: INearbyContentMagnetContext = {
    treasures: {
      objectsNearby: false,
      selectedSpec: null,
      list: []
    },
    challenges: {
      objectsNearby: false,
      selectedSpec: null,
      list: []
    },
    coins: {
      objectsNearby: false,
      selectedSpec: null,
      list: []
    }
  }

  currentLocationItems: ILocationItemsDef = null;

  countdownAutostart: IGmapCountdown = {
    value: 300
  };

  user: IGmapUser = {
    canSkipAnimation: false,
    canScan: false,
    canTakePhoto: true,
    clickedScan: false,
    canSkip: true,
    canRequestDirections: false,
    canShowNext: false,
    arMode: 0,
    hasScanned: false,
    canZoom: true
  };

  drawerOptions: IDrawerOptions = {
    handleHeight: 50,
    thresholdFromBottom: 200,
    thresholdFromTop: 200,
    bounceBack: true
  };

  mapEnabled: boolean = true;
  startButtonText: string = "Start";
  story: IStory;
  loading: boolean = false;
  progress: number = null;
  excludeLocationIdList: string[] = [];
  mapInitialized: boolean = false;
  mapInitializedFirstStage: boolean = false;
  showPlate: boolean = false;

  layers: IActionLayers = {};
  layersBak: IActionLayers = {};

  // map
  map: GoogleMap;
  jsMap: google.maps.Map;
  placesService: google.maps.places.PlacesService;
  directionsService: google.maps.DirectionsService;
  geocodeService: google.maps.Geocoder;
  // location
  // currentLocation: ILatLng = null;
  currentLocation: IUserLocationData = {
    // nativeCoords: null,
    location: null,
    timestamp: null,
    heading: null,
    elapsed: null,
    speed: null,
    altitude: null
  };
  chartInitLocation: ILatLng;
  challengeReachedWithDroneLocation: ILatLng;
  locationData: ILocationData = {
    waypointIndex: 0,
    waypoints: [],
    distanceToWaypoint: 0,
    bounds: null
  };
  arModes = EARModes;
  test: boolean = false;
  // settings
  appSettings: ISettings = null;
  followModes = EFollowMode;

  findJoystickPosition: IJoystickPosition = { x: 0, y: 0, heading: 0, headingDeg: 0, headingComp: 0, distance: 0 };
  findJoystickPositionUpdate: boolean = false;

  flags = {
    follow: EFollowMode.NONE, // should be initialized to false/0!
    prevFollow: EFollowMode.NONE,
    disableFollowOnDrag: true,
    showTestMarkers: false,
    zoomToUserAfterFitBounds: true,
    mapDebugMode: false,
    mapDebugModeFab: false,
    showHud: false,
    showHudPrev: false,
    showCompassAdjustControl: false,
    setARDemoMode: false,
    useARWebkitCompass: true,
    contextHud: false,
    animationDuration: 500,
    sequenceDelay: 1000,
    droneMode: false,
    waitForAuxMarkers: false,
    treasuresInStoryline: true,
    itemsPerSlide: 5,
    directionPointer: false,
    showFindJoystick: false
  };
  internalFlags = {
    updateUserMarkerGPSPrev: true,
    updateUserMarkerGPS: true,
    positionMarkerLocked: false,
    /** allow map handling from gmap */
    mapAllowGPSHandling: true,
    mapDisableGPSHandling: false,
    mapAllowCollectFromAR: true,
    AROpen: false,
    promiseEnabled: true,
    alreadyDoneLocationsCount: 0,
    bufferDirection: null,
    treasureOpened: false,
    treasureNotified: false,
    initialPositionFollow: false,
    chatMessageSignaledTimestamp: 0,
    chatMessagePrevTimestamp: 0,
    tutorialShownWorldMap: false,
    tutorialShownStoryline: false,
    placeSkipped: false,
    canExitMap: false,
    prevScanEnergy: null,
    showScanEnergyLow: false,
    tick: false,
    worldEditDetected: false,
    isRecording: false,
    eagleView: false,
    zoomOut: false,
    enableAR: true,
    eventMapInitScan: true,
    deinitInProgress: false,
    eventTimeoutShown: false,
    moveMapSequenceEnabled: false,
    deinitTriggered: false,
    duplicateViewDetected: false,
    proceedToTheNextLocationTTS: true,
    reachedChallengeWithDrone: false,
    nearbyStoryLocationIndex: null,
    gameplayTutorialShown: false,
    droneUpgradeState: false,
    postponeDirectionsRequest: false,
    hasVideoBefore: false,
    manualChallengeStart: true,
    directionsEnabled: true,
    collectMode: null,
    audioGuide: null,
    manualChallengeStartRequested: false,
    isHeadingTrackEngaged: false,
    routeRecalculateRequested: false,
    returnToStoryline: false,
    storyProgressUpdated: false,
    showPreviewNavEnabled: true,
    showPreviewStartEnabled: true,
    usingFallbackNav: false,
    preselectIndex: null,
    chatMessageCounter: 0,
    chatMessageFabDisp: "",
    teamFabDisp: "",
    receivedMessageFromOperator: false,
    storyFinishedTeamNotify: false,
    isWeb: false,
    isPWA: false,
    checkContinue: false,
    levelUpPopups: false
  };

  useNativeMap: boolean = false;
  isCapacitorMag: boolean = true;
  mapEngineFlags: IMapEngineFlags = null;

  useJoystick: boolean = false;

  testFlags = {
    enableRetryFallbackTest: false,
    retryCounter: 0
  };
  // sensors
  deviceData = {
    mapHeading: 0,
    timer: 0
  };
  platformType: number;

  mapStyle: number = 0;
  theme: string = "theme-light theme-light-bg";

  buttonOptions: IGmapButtonsFlag = GmapUtils.getButtonsDefault(false);
  buttonsLoaded: boolean = false;

  // inventoryCache: IUserInventory;
  activeInventoryItems: IGameItem[];

  mapSubscription = {
    mapEvent1: null,
    mapEvent2: null,
    mapEvent3: null,
    mapEvent4: null,
  };

  // aux
  subscription = {
    state: null,
    navigate: null,
    heading: null,
    navigatePreloadStory: null,
    navigateToCollectible: null,
    myUnifiedGeolocation: null,
    nativeGeolocation: null,
    mapReady: null,
    mapReady1: null,
    targetReachedAR: null,
    coinGenerator: null,
    locationTimeout: null,
    itemGenerateWatch: null,
    itemScanEventWatch: null,
    itemScanCooldownWatch: null,
    initVirtualPosition: null,
    itemCollectWatch: null,
    itemAvailableWatch: null,
    watchMove: null,
    messageQueue: null,
    operatorChat: null,
    headingWarning: null,
    otherObjectsGen: null,
    activityStatusMonitor: null,
    displayTimeout: null,
    activityEntry: null,
    googleRequestStatus: null,
    scanEnergyWatch: null,
    globalDistanceDump: null,
    smartZoomEventTreasures: null,
    networkWatch: null,
    exploreCollectStatus: null,
    droneStatusUpdate: null,
    keyEvent: null
  };

  subscriptionMp = {
    statusWS: null,
    messageWS: null,
    arenaEvent: null,
    groupChat: null,
    stateMachine: null,
    eventMux: null
  };

  observables = {
    state: null,
    linkViewSend: null,
    linkViewReceive: null
  };

  watches = {
    nativeGeolocation: null
  };

  timers = [];
  dedicatedTimers = {
    test: null,
    dispDone: null,
    generalCheck: null,
    debug: null
  };

  generalTimers = {
    ping: null
  };

  coinCollectTimeouts = [];

  triggerableTimeouts = {
    autoSetNav: null
  };

  dedicatedTimeouts = {
    location: null,
    stateChange: null,
    other: null,
    filterLocation: null,
    showLocations: null,
    initMapCheck: null,
    delayMessage: null,
    clearMap: null,
    refreshTreasureLayersRetry: null,
    collectCoins: null,
    collectCoinsExpired: null,
    autostart: null,
    mapInitTimeout: null,
    mapExitLockTimeout: null,
    showWarning: null,
    mapMarkerInitTimeout: null,
    mapBeforeInitTimeout: null,
    closeRewardHud: null,
    mapInitAnimation: null,
    logoAnimation: null,
    mapMoveDelay: null,
    goToUser: null,
    openTreasureModal: null,
    refreshSession: null,
    startMpGame: null,
    storyLoading: null,
    locating: null,
    showWalkthrough: null,
    mapDisableGPSHandling: null
  };

  title: string = "World Map";
  storyId: number = null;
  eventId: number = null;
  event: IEventDetails = null;
  eventGroupLinkData: IEventStoryGroupLinkData;

  eventUseMp: boolean = false;
  promises: Promise<{}>[] = [];
  initialized: boolean = false;
  platform: IPlatformFlags = {} as IPlatformFlags;
  errorString: string;
  storyParams: IStoryListNavParams;
  mode: number = EGmapMode.worldMap;

  show: boolean = true;

  modeSelect: IGapModeSelector = {
    blank: false,
    worldMap: false,
    storyline: false,
    editor: false,
    eventChallenge: false,
    eventWm: false
  };

  activityStarted = {
    explore: false,
    find: false,
    quest: false,
    move: false,
    snapshot: false,
    photo: false,
    ANY: false,
    // check if the activity context is set (e.g. find before reaching search zone)
    ANYTEMP: false,
    screenshotAR: false,
    nav: false
  };

  storySelected: boolean = false;

  footerClass: string = EFooterClass.FOOTER_SMALL;
  headerClass: string = EHeaderClass.HEADER_SMALL;
  footerContentClass: string = EFooterClass.FOOTER_CONTENT;

  hudConfig: IHudConfig = {
    outerClass: EClass.HUD_BAN_SMALL,
    tintClass: EClass.HUD_INFO,
    innerClass: EClass.HUD_INNER_INFO
  };

  remoteLogEnabled: boolean = false;
  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;
  np: INavParams = null;
  isWorldMap: boolean = true;
  enableScanner: boolean = true;
  /** check stories with private treasures (e.g. preload stories) */
  treasuresInStory: boolean = true;
  /** preload = nonlinear = mp / use for logic */
  isPreloadStory: boolean = false;
  /** custom map = static locations / use for layers */
  isCustomMapStory: boolean = false;

  isDroneOnly: boolean = false;
  isDroneOnlyEngaged: boolean = false;
  isDroneAllowed: boolean = true;
  isDroneAllowedForActivity: boolean = false;
  isARUnlocked: boolean = true;
  isMpStory: boolean = false;
  vgOptions: INgxChartOptions = NgxChartParams.getVGaugeOptions();

  viewId: number = 0;
  vs: IViewSpecs;

  qparams: Params;
  storyNavParams: IStoryListNavParams;

  constructor(
    public settingsProvider: SettingsManagerService,
    public storyDataProvider: StoryDataService,
    public userStatsProvider: UserStatsDataService,
    public placesDataProvider: PlacesDataService,
    public resourcesProvider: ResourcesCoreDataService,
    public resourceHandler: ResourceHandlerDataService,
    public contentCreator: ContentCreatorService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public router: Router,
    public permissionsService: PermissionsService,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public analytics: AnalyticsService,
    public analyticsExtras: AnalyticsExtrasService,
    public screen: ScreenService,
    public locationManager: LocationManagerService,
    public locationMonitor: LocationMonitorService,
    public backButton: BackButtonService,
    public modularViews: ModularViewsService,
    public shareProvider: ShareService,
    public directionsHandler: DirectionsService,
    public exploreProvider: ExploreActivityService,
    public exploreUtils: ExploreActivityUtilsService,
    public localNotifications: LocalNotificationsService,
    public locationApi: LocationApiService,
    public itemScanner: ItemScannerService,
    public messageQueueHandler: MessageQueueHandlerService,
    public events: Events,
    public userDataProvider: UserDataService,
    public smartZoom: SmartZoomService,
    public markerHandler: MarkerHandlerService,
    public mapManager: MapManagerService,
    public navigationHandler: NavigationHandlerService,
    public geoObjects: GeoObjectsService,
    public inventory: InventoryDataService,
    public mpManager: MPManagerService,
    public photoValidator: PhotoValidatorService,
    public markerUtils: MarkerUtilsService,
    public challengeEntry: ChallengeEntryService,
    public timeoutMonitor: TimeoutService,
    public arexploreProvider: ARExploreActivityService,
    public questActivityProvider: QuestActivityService,
    public activityProvider: ActivityService,
    public photoActivityProvider: PhotoActivityService,
    public findActivityProvider: FindActivityService,
    public bench: BenchmarkDataService,
    public achievements: AchievementsDataService,
    public moveActivityProvider: MoveActivityService,
    public popupFeatures: PopupFeaturesService,
    public gmapModals: GmapModalsService,
    public rewardModals: RewardModalsService,
    public mpGameInterface: MPGameInterfaceService,
    public inventoryWizard: InventoryWizardService,
    public gameStatsProvider: GameStatsService,
    public backgroundModeProvider: BackgroundModeService,
    public backgroundModeControllerProvider: BackgroundModeWatchControllerService,
    public userContentProvider: UserContentDataService,
    public premiumProvider: PremiumDataService,
    public activityStatsProvider: ActivityStatsService,
    public activityStatsTracker: ActivityStatsTrackerService,
    public eventData: EventsDataService,
    public mpData: MPDataService,
    public tutorials: TutorialsService,
    public nps: NavParamsService,
    public supportModals: SupportModalsService,
    public navUtils: NavUtilsService,
    public syncProvider: SyncService,
    public itemCollectorCore: ItemCollectorCoreService,
    public itemCollector: ItemCollectorService,
    public networkMonitor: NetworkMonitorService,
    public tts: TextToSpeechService,
    public genericQueue: GenericQueueService,
    public appVersionService: AppVersionService,
    public navGauge: NavGaugeService,
    public droneSimulator: DroneSimulatorService,
    public virtualPositionService: VirtualPositionService,
    public ngZone: NgZone,
    public support: SupportDataService,
    public walkthrough: WalkthroughService,
    public storageFlags: StorageFlagsService,
    public soundEffects: SoundEffectsService,
    public soundManager: SoundManagerService,
    public activityDataService: ActivitiesDataService,
    public storyManagerService: StoryManagerService,
    public mapEngineUtils: MapEngineUtilsService,
    public headingService: HeadingService,
    public timeoutQueueService: TimeoutQueueService,
    public modularInteraction: ModularInteractionService,
    public links: LinksDataService,
    public requestService: AuthRequestService,
    public keyHandler: KeyHandlerService,
    public youtube: YouTubeService,
    public mapGeneralUtils: MapGeneralUtilsService,
    public mqttService: MQTTService,
    public mqttManagerService: MQTTManagerService,
    public mqttChatService: MQTTChatService,
    public miscProvider: MiscDataService,
    public route: ActivatedRoute
  ) {

  }

  ngAfterViewInit() {
    console.log("gmap after view init: ", new Date().getTime());
  }

  getWrapperClass() {
    let c: string = "margin-top-none scroll-content-no-scroll";
    if (!this.mapInitializedFirstStage) {
      c += " theme-background";
    } else {
      c += " theme-background-uniform-fade-out";
      if (!this.platform.WEB) {
        c += " bg-transp";
      }
    }
    return c;
  }

  ngOnInit() {
    console.log("gmap on init: ", new Date().getTime());
    this.viewId = this.mapManager.getViewId();
    this.uiext.disableSidemenu();
    this.navUtils.setTransparentBg(true);
    this.mapEngineUtils.initMapEngine();
    this.mapEngineFlags = this.mapEngineUtils.getMapEngineFlags();
    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.gmap, null, this.np);
      console.log("gmap view nav params info: ", npInfo);
      this.qparams = this.route.snapshot.queryParams;
      console.log("activated route: ", this.qparams);

      let npar: INavParams = npInfo.params;
      let hasParams: boolean = npInfo.hasParams;
      this.initButtons();
      this.setOverlayStyle(false);
      this.itemScanner.setAutoCollect(false);
      this.activityProvider.setAutoCollectExitARDefault();
      this.itemScanner.setEditorMode(false);
      this.initHudState();
      console.log(npar);

      if (hasParams) {
        let np: INavParams = npar;
        let params: IGmapEntryNavParams = np.params;
        this.vs = np.view;
        this.mode = params.mode;
        this.storyNavParams = params.storyNavParams;
      } else {
        this.user.canScan = true;
        this.buttonOptions.scan.blink = true;
        this.storyId = null;
        this.storyParams = {
          storyId: null,
          category: null,
          categoryCode: null,
          localStories: false,
          includeGlobal: true,
          selectedCityId: null,
          dynamic: false,
          reload: true,
          loadStory: true
        };
        this.modeSelect.worldMap = true;
      }

      if (this.qparams != null && this.qparams.storyId != null) {
        // handle url navigation context
        try {
          this.storyId = Number.parseInt(this.qparams.storyId);
          this.mode = Number.parseInt(this.qparams.mode);
        } catch (err) {
          console.error(err);
        }
        if (this.mode === EGmapMode.storyline) {
          // url navigation, should load story data
          this.storyParams = {
            storyId: this.storyId,
            category: null,
            categoryCode: null,
            localStories: false,
            includeGlobal: true,
            selectedCityId: null,
            dynamic: false,
            reload: true,
            loadStory: true
          };
          this.modeSelect.worldMap = false;
          this.modeSelect.storyline = true;
        }
      }

      switch (this.mode) {
        case EGmapMode.blank:
          this.modeSelect.blank = true;
        case EGmapMode.worldMap:
          this.modeSelect.worldMap = true;
          break;
        case EGmapMode.storyline:
          if (this.storyNavParams != null) {
            this.storyId = this.storyNavParams.storyId;
            this.storyParams = this.storyNavParams;
          }
          // may contain meeting place data from world map, used for virtual drone positioning in drone only story
          this.itemScanner.clearCache();
          this.links.setStoryGroupLinkDataWrapper(null, this.storyId, false);
          this.modeSelect.storyline = true;
          break;
        case EGmapMode.editor:
          this.modeSelect.editor = true;
          this.itemScanner.setEditorMode(true);
          this.itemScanner.clearCache();
          break;
        default:
          this.modeSelect.worldMap = true;
          break;
      }

      // check if world map mode
      this.isWorldMap = [EGmapMode.blank, EGmapMode.worldMap, EGmapMode.eventChallenge, EGmapMode.editor, EGmapMode.customMap].indexOf(this.mode) !== -1;


      console.log("enter gmap mode: ", this.mode);
      console.log("story params: ", this.storyParams);

      this.webView.ready().then(() => {
        this.backButton.replace(() => {
          this.goBackRequest(true);
        });

        this.analytics.trackView("gmap");
        this.analyticsExtras.sendTrackOneTimeEvent(ETrackedEvents.firstMapOpen, "open", "open", 1, false);
        this.internalFlags.isWeb = GeneralCache.isWeb;
        this.internalFlags.isPWA = GeneralCache.isPWA;

        this.settingsProvider.getSettingsLoaded(true).then(async (res: boolean) => {
          console.log("get settings loaded: ", res);
          // wait to load global settings
          this.requestService.setNetworkSyncMode(true);
          if (res) {
            await this.loadAppSettings();
            await this.setPlateVisible(true);
            this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
              if (loaded) {
                if (SettingsManagerService.settings.app.settings.backgroundMode.value) {
                  this.backgroundModeProvider.enable();
                }
                this.dedicatedTimeouts.mapBeforeInitTimeout = setTimeout(() => {
                  this.platformType = this.settingsProvider.getPlatform();
                  this.platform = SettingsManagerService.settings.platformFlags;
                  this.itemScanner.setPlatform(this.platform);
                  MapSettings.reloadConfig();
                  if (this.platform.WEB) {
                    MapSettings.useIntegerZoomLevels();
                  }
                  this.useNativeMap = !this.platform.WEB;
                  // this.useNativeMap = false;
                  this.mapManager.setNative(this.useNativeMap);
                  this.markerHandler.setNative(this.useNativeMap);

                  this.useJoystick = !this.platform.ANDROID;

                  // set zoom init
                  MapSettings.setZoomInLevel(MapSettings.zoomInLevelDefault, this.getCurrentZoomLevelDelta());
                  this.itemScanner.setZoomLevel(MapSettings.zoomInLevelCrt, false);
                  let hudMode: number = SettingsManagerService.settings.app.settings.hudMode.value;
                  this.setHudState([EHudContext.map, EHudContext.mapAndAr].indexOf(hudMode) !== -1, true);
                  this.flags.contextHud = hudMode === EHudContext.auto;
                  this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
                  this.screen.setKeepScreenOn(SettingsManagerService.settings.app.settings.keepScreenOn.value);
                  // init location
                  this.locationManager.setMasterLock(true);
                  this.locationManager.setGeolocationMode(SettingsManagerService.settings.app.settings.locationMode.value);
                  // this.startSession();
                  let ttsMessage: string = "";
                  if (this.storyId != null) {
                    ttsMessage = Messages.tts.welcomeToWorldMapStoryMode;
                  } else {
                    ttsMessage = Messages.tts.welcomeToWorldMap;
                  }
                  this.soundManager.ttsWrapper(ttsMessage, true, SoundUtils.soundBank.worldMapIntro.id);
                  this.mqttService.createConnection();
                  this.mqttService.updateStatus(EMQTTStatusKeys.inGame, { value: true });

                  this.locationManager.getCurrentLocationWrapper(true, true, false).then((location: ILatLng) => {
                    console.log("map get current location: ", location);
                    this.currentLocation.location = location;
                    this.moveActivityProvider.startGlobalDistanceWatch();
                    PromiseUtils.wrapNoAction(this.startSession(true, true, null), true);
                  }).catch((err: Error) => {
                    console.error(err);
                    this.analytics.dispatchError(err, "gmap");
                    this.supportModals.showLocationErrorModal(err);
                    this.moveActivityProvider.startGlobalDistanceWatch();
                    PromiseUtils.wrapNoAction(this.startSession(true, true, null), true);
                  });
                }, 100);
              }
            }, (err: Error) => {
              console.error(err);
            });
          }
        }).catch((err: Error) => {
          console.error(err);
        });
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * schedule timeline tutorials
   */
  scheduleTutorials(mode: number) {
    if (SettingsManagerService.settings.app.settings.enableTutorial.value) {
      this.resourcesProvider.getMessageTimeline(mode).then((entries: IMessageTimelineEntry[]) => {
        if (entries) {
          for (let i = 0; i < entries.length; i++) {
            this.messageQueueHandler.schedule(entries[i].message, entries[i].timedelta, EQueueMessageCode.info);
          }
        }
      }).catch((err: Error) => {
        console.error(err);

        this.analytics.dispatchError(err, "gmap");
      });
    }
  }

  /**
   * check preloaded story data (specs)
   * check custom map / treasures enabled
   * @param withStory 
   */
  checkPreloadData(withStoryOverride: boolean): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      let withStory: boolean = this.checkWithStory(withStoryOverride);
      console.log("check preload data: ", withStoryOverride, withStory);
      if (withStory) {
        let promiseStoryData: Promise<IStory> = new Promise((resolve) => {
          if (this.storyParams && this.storyParams.storyOverview) {
            resolve(this.storyParams.storyOverview);
          } else {
            this.loadStory(false).then(() => {
              resolve(this.story);
            }).catch((err) => {
              console.error(err);
              resolve(this.story)
            });
          }
        });
        promiseStoryData.then((storyData: IStory) => {
          console.log("check preload data resolved to: ", storyData);
          if (storyData != null) {
            this.isCustomMapStory = storyData.customMap === 1;
            this.treasuresInStory = storyData.enableTreasures === 1;
            if (storyData.enableTreasures == null) {
              this.treasuresInStory = true;
            }
          }
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  }

  checkWithStory(withStoryOverride: boolean) {
    let withStory: boolean = false;
    if (withStoryOverride != null) {
      withStory = withStoryOverride;
    } else {
      withStory = !this.isWorldMap;
    }
    return withStory;
  }

  /**
   * load data that is required for the game to work
   * initializes some basic params e.g. auto start
   */
  loadData(withStoryOverride: boolean): Promise<boolean> {
    return new Promise(async (resolve) => {
      let withStory: boolean = this.checkWithStory(withStoryOverride);
      await this.checkPreloadData(withStoryOverride);
      let promises: Promise<boolean>[] = [];
      if (withStory) {
        console.log("loading story: ", this.storyParams);
        let storyMode: number = EStoryMode.linear;
        if (this.storyParams && this.storyParams.storyOverview) {
          storyMode = this.storyParams.storyOverview.mode;
        }
        this.mpGameInterface.setMode(EMPGameMode.storyPreload);
        this.locationApi.clearCache();
        switch (storyMode) {
          case EStoryMode.preload:
            this.isPreloadStory = true;
            this.itemScanner.setLocationBasedFiltering(false);
            promises.push(new Promise((resolve, reject) => {
              this.setStoryModeLayers(this.isCustomMapStory);
              this.loadStory(false).then(() => {
                this.preloadStoryCore(true, true).then(async () => {
                  if (!this.platform.WEB) {
                    this.setFollowFlag(EFollowMode.MOVE_MAP);
                  }
                  await SleepUtils.sleep(500);
                  await this.goToUser(null, false);
                  await PromiseUtils.wrapResolve(this.itemScanner.treasureScan(), true);
                  await this.handleMeetingPlaceTransfer(true);
                  await this.handleShowWarningCheck();
                  resolve(true);
                }).catch((err) => {
                  console.error(err);
                  this.messageQueueHandler.prepare("Error preloading story", true, EQueueMessageCode.warn);
                  reject(err);
                });
              }).catch((err: Error) => {
                console.error(err);
                this.messageQueueHandler.prepare("Error loading story", true, EQueueMessageCode.warn);
                this.mapManager.setMapStyle(EMapStyles.leplace);
                this.analytics.dispatchError(err, "gmap");
                reject(err);
              });
            }));
            break;
          case EStoryMode.linear:
          default:
            promises.push(new Promise((resolve, reject) => {
              this.setStoryModeLayers(this.isCustomMapStory);
              this.loadStory(false).then(() => {
                this.navigatePreloadStory(true);
                console.log("story loaded");
                this.plateSub = "Loading";
                this.setPlateVisible(true).then(() => {
                  if (SettingsManagerService.settings.app.settings.autoStart.value) {
                    this.app.firstStart = true;
                  } else {
                    this.app.firstStart = false;
                  }
                  this.countdownAutostart.value = 300;
                  resolve(true);
                }).catch((err: Error) => {
                  console.error(err);
                  reject(err);
                });
              }).catch((err: Error) => {
                console.error(err);
                this.messageQueueHandler.prepare("Error loading story", true, EQueueMessageCode.warn);
                this.mapManager.setMapStyle(EMapStyles.leplace);
                this.analytics.dispatchError(err, "gmap");
                reject(err);
              });
            }));
            break;
        }
      } else {
        this.mapManager.setMapStyle(EMapStyles.leplace);
        this.mpGameInterface.setMode(EMPGameMode.worldMapChallenge);
      }

      if (this.modeSelect.eventChallenge) {
        console.error("deprecated");
      }

      if (this.modeSelect.eventWm) {
        this.loadEventDetails();
      }

      promises.push(new Promise((resolve, reject) => {
        this.loadInventory().then(() => {
          console.log("inventory loaded");
          resolve(true);
        }).catch((err: Error) => {
          console.error(err);
          this.messageQueueHandler.prepare("Error loading inventory", true, EQueueMessageCode.warn);
          this.analytics.dispatchError(err, "gmap");
          reject(err);
        });
      }));

      promises.push(new Promise((resolve, reject) => {
        this.loadResourceLinks().then(() => {
          console.log("resource links loaded");
          resolve(true);
        }).catch((err: Error) => {
          console.error(err);
          this.messageQueueHandler.prepare("Error loading resources", true, EQueueMessageCode.warn);
          this.analytics.dispatchError(err, "gmap");
          reject(err);
        });
      }));

      Promise.all(promises).then(() => {
        console.log("data loaded");
        resolve(true);
      }).catch(() => {
        this.soundManager.ttsWrapper(Messages.tts.errorLoadingResources, true);
        resolve(false);
      });
      this.gameStatsProvider.init();
    });
  }

  loadEventDetails() {
    this.eventData.getDetailsV2(this.eventId).then((event: IEventDetailsResponse) => {
      if (event) {
        console.log("load event world map");
        console.log(event);
        this.event = event ? event.event : null;
        if (event && event.event && event.event.endDate) {
          this.event.endTs = new Date(event.event.endDate).getTime();
          console.log(this.event.endTs);
        }
      }
    }).catch((err: Error) => {
      console.error(err);
      this.uiext.showAlertNoAction(Messages.msg.mpChallengeLoadError.after.msg, Messages.msg.mpChallengeLoadError.after.sub);
    });
  }


  /**
   * load resource links e.g. cloud links
   */
  loadResourceLinks() {
    let promise = new Promise((resolve, reject) => {

      this.exploreProvider.setMarkerCallback((data: IPlaceMarkerContent, context: IMarkerDetailsOpenContext) => {
        this.getMarkerCallback(data, context);
      });

      this.resourcesProvider.getTreasureSpecs([ETreasureType.exploreObject, ETreasureType.findTarget]).then((objects: ITreasureSpec[]) => {
        if (objects) {
          this.resourceHandler.dispatchTreasureSpecs(objects);
        }
        resolve(objects);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * switch game context and show the available treasures for each case
   * @param gameContextCode 
   */
  updateWorldMapRefreshContext(gameContextCode: number) {
    let opts: IWorldMapRefreshOptions = this.itemScanner.getWorldMapRefreshOptions();
    opts.gameContextCode = gameContextCode;
  }

  checkApplyDebugMode() {
    if (this.flags.mapDebugMode) {
      this.showHudMessage(EMapHudCodes.debug, "on", null);
    } else {
      this.clearHudMessage(EMapHudCodes.debug);
      this.flags.mapDebugModeFab = false;
    }
    HudUtils.showDebugHudElements(this.hudMsg, this.flags.mapDebugMode);
  }

  loadPreset() {
    this.flags.mapDebugMode = AppSettings.localSettings.mapDebugMode;
    this.flags.setARDemoMode = AppSettings.localSettings.ARDemoMode;
    this.flags.useARWebkitCompass = AppSettings.localSettings.useWebkitCompass;
  }

  applyPreset() {
    AppSettings.localSettings.mapDebugMode = this.flags.mapDebugMode;
    AppSettings.localSettings.ARDemoMode = this.flags.setARDemoMode;
    AppSettings.localSettings.useWebkitCompass = this.flags.useARWebkitCompass;
  }

  loadAppSettings() {
    return new Promise((resolve) => {
      this.appSettings = SettingsManagerService.settings;
      let appSettings = this.settingsProvider.getAppSettings();
      this.itemScanner.loadSettingsFlags();
      this.loadPreset();
      if (AppSettings.testerMode) {
        this.checkApplyDebugMode();
        this.remoteLogEnabled = SettingsManagerService.settings.app.settings.sensorStreamingAR.value;
        this.flags.treasuresInStoryline = appSettings.treasuresInStoryline.value;
      }

      this.locationManager.loadConfigSettings();
      this.droneSimulator.setSimulationRateMode(appSettings.droneSimulationRate.value);

      let opts: IWorldMapRefreshOptions = {
        hardReset: false,
        markerClustering: this.appSettings.app.settings.useMarkerClustering.value,
        gameContextCode: EGameContext.all,
        privateScannerMode: EPrivateScannerMode.disabled,
        privateScannerItemId: null,
        fixed: this.modeSelect.eventWm,
        groupRole: this.eventGroupLinkData ? this.eventGroupLinkData.eventGroupRole : null,
        timeBasedScanner: false,
        locationBasedFiltering: true
      };

      if (this.modeSelect.eventWm) {
        opts.privateScannerMode = EPrivateScannerMode.event;
        opts.privateScannerItemId = this.eventId;
      }

      if (this.modeSelect.storyline && this.storyParams && this.storyParams.storyOverview) {
        if (this.storyParams.storyOverview.customMap === 1) {
          opts.privateScannerMode = EPrivateScannerMode.story;
          opts.privateScannerItemId = this.storyId;
          opts.timeBasedScanner = true;
        }
      }

      this.itemScanner.setWorldMapRefreshOptions(opts);

      this.geoObjects.getShowLayersResolve(GeneralCache.storylineMapOpened).then((layers: IActionLayers) => {
        console.log("get show layers: ", layers);
        this.setShowLayersCore(layers);
        this.layers = layers;
        this.layersBak = DeepCopy.deepcopy(this.layers);
        GeneralCache.storylineMapOpened = false;
        if (!this.isWorldMap) {
          // prepare for next world map opened after storyline (restore show layers workaround)
          GeneralCache.storylineMapOpened = true;
        }
        resolve(true);
      }).catch((err: Error) => {
        // normally resolve only
        console.error(err);
        resolve(false);
      });
    });
  }

  /**
   * moves map to location and sets user marker
   * @param location 
   */
  moveMapToLocationInit(location: ILatLng, animate: boolean): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve, reject) => {
      if (location) {
        this.dedicatedTimeouts.mapMarkerInitTimeout = setTimeout(() => {
          this.mapManager.moveMapWrapper(location, {
            animateCamera: animate, // true
            animateMarker: false,
            zoom: MapSettings.zoomInLevelCrt,
            bearing: null,
            tilt: null,
            moveMap: true,
            force: false,
            // marker: false,
            userMarker: true,
            duration: animate ? this.flags.animationDuration : null
          }).then((result) => {
            if (result) {
              // set follow enabled 
              // e.g. the user swiped the map before the location is loaded
              // it should return to user location on init
              if (!this.internalFlags.initialPositionFollow) {
                this.internalFlags.initialPositionFollow = true;
                if (this.flags.follow === EFollowMode.NONE) {
                  this.setFollow(EMapInteraction.enter, false);
                }
              }

              // load tutorials that will be shown as headlines
              // only after the gps is initialized so that the tutorials do not overlap with previous messages
              if (!this.mapInitialized) {
                if (this.isWorldMap) {
                  if (!this.internalFlags.tutorialShownWorldMap) {
                    this.scheduleTutorials(EMessageTimelineCodes.worldMap);
                    this.internalFlags.tutorialShownWorldMap = true;
                  }
                } else {
                  if (!this.internalFlags.tutorialShownStoryline) {
                    this.scheduleTutorials(EMessageTimelineCodes.storylineMap);
                    this.internalFlags.tutorialShownStoryline = true;
                  }
                }
              }

              resolve(true);
            } else {
              reject(new Error("cannot move map"));
            }
          }).catch((err: Error) => {
            console.error(err);
            reject(err);
          });
        }, 200);
      } else {
        reject(new Error("undefined location"));
      }
    });
    return promise;
  }

  setMap3D(coordinates: ILatLng) {
    let promise = new Promise((resolve, reject) => {
      console.log("set map 3D");
      this.mapManager.moveMapWrapper(coordinates, {
        animateCamera: true,
        animateMarker: false,
        zoom: MapSettings.zoomInLevelCrt,
        bearing: null,
        tilt: MapSettings.navTilt,
        moveMap: true,
        force: false,
        userMarker: false,
        duration: 500
      }).then(() => {
        console.log("set map 3D resolved");
        resolve(true);
      }).catch((err: Error) => {
        console.warn("set map 3D error");
        reject(err);
      });
    });
    return promise;
  }

  setMap2D(coordinates: ILatLng) {
    let promise = new Promise((resolve, reject) => {
      console.log("set map 2D");
      this.mapManager.moveMapWrapper(coordinates, {
        animateCamera: true,
        animateMarker: false,
        zoom: null,
        bearing: null,
        tilt: 0,
        force: false,
        moveMap: true,
        userMarker: false,
        duration: 500
      }).then(() => {
        console.log("set map 2D resolved");
        resolve(true);
      }).catch((err: Error) => {
        console.warn("set map 2D error");
        reject(err);
      });
    });
    return promise;
  }

  /**
  * move map to current location
  * enable watch position and heading
  * @param setFollow set follow mode to MOVE_MAP
  * @param applyWeb also apply on web testing
  */
  enableFollow(setFollow: boolean, applyWeb: boolean, animate: boolean, userInput: boolean) {
    console.log("enable follow");
    if (setFollow) {
      if (userInput) {
        // release lock only if the method was called from the HTML
        this.locationMonitor.manualLocationRelease();
        // this.internalFlags.updatePositionMarker = true;
      }
      this.setFollowFlag(EFollowMode.MOVE_MAP);
      this.internalFlags.updateUserMarkerGPS = true;
      this.moveMapToLocationInit(this.currentLocation.location, animate).then(async () => {
        console.log("move map init done (1)");
        this.mapInitialized = true;
        this.dedicatedTimeouts.mapInitTimeout = ResourceManager.clearTimeout(this.dedicatedTimeouts.mapInitTimeout);
        this.dedicatedTimeouts.mapExitLockTimeout = ResourceManager.clearTimeout(this.dedicatedTimeouts.mapExitLockTimeout);
        this.internalFlags.canExitMap = true;
        this.dedicatedTimeouts.showWarning = ResourceManager.clearTimeout(this.dedicatedTimeouts.showWarning);
        if (!this.modeSelect.storyline) {
          await this.handleShowWarningCheck();
        }
        // console.log("set zoom level (1)");
        this.onSetZoomLevel(MapSettings.zoomInLevelCrt);
      }).catch((err: Error) => {
        console.error(err);
      });
    }

    if (applyWeb || !this.platform.WEB) {
      // start watch
      // also for initial positioning, even with drone only mode
      if (!this.isDroneOnlyEngaged) {
        this.watchPosition();
        this.internalFlags.isHeadingTrackEngaged = true;
        this.watchHeading();
      }
    }
  }

  disableFollow() {
    this.setFollowFlag(EFollowMode.NONE);
    this.internalFlags.updateUserMarkerGPS = true;
    // don't move map but keep updating the position marker
  }

  /**
   * wrapper for set follow core
   * apply on web also
   * userInput specifies if the method is called from HTML
   */
  setFollow(action: number, userInput: boolean) {
    this.smartZoom.resetState();
    if (userInput) {
      this.triggerableTimeouts.autoSetNav = ResourceManager.clearTriggerableTimeout(this.triggerableTimeouts.autoSetNav);
    }
    PromiseUtils.wrapNoAction(this.setFollowCore(action, true, false, userInput), true);
  }

  /**
  * wrapper for set follow core
  * apply on web also
  */
  setFollowNoResetZoom(action: number) {
    PromiseUtils.wrapNoAction(this.setFollowCore(action, true, false, false), true);
  }

  /**
   * enable follow and set flag to move map
   */
  enableFollowTrueAnimate() {
    this.disableCompassNavMode();
    this.enableFollow(true, true, true, false);
    let usePrev: boolean = false;
    switch (this.flags.prevFollow) {
      case EFollowMode.MOVE_MAP:
      case EFollowMode.MOVE_MAP_HEADING_2D:
      case EFollowMode.MOVE_MAP_HEADING_3D:
        usePrev = true;
        break;
      default:
        usePrev = false;
        break;
    }
    if (usePrev) {
      this.flags.follow = this.flags.prevFollow;
    } else {
      this.setFollowFlag(EFollowMode.MOVE_MAP);
    }
  }

  isFreeMove() {
    return this.flags.follow === EFollowMode.NONE;
  }

  setFollowFlag(flag: number) {
    this.flags.follow = flag;
    this.flags.prevFollow = flag;
    console.log("set follow flag: " + flag);
  }

  getCurrentZoomLevelDelta() {
    let storyZoomLevelDelta: number = (this.story != null && this.story.zoomLevelDelta != null) ? this.story.zoomLevelDelta : null;
    let checkpointZoomLevelDelta: number = (this.story != null && this.app.storyLocations != null && this.app.locationIndex != null) ? (this.app.storyLocations[this.app.locationIndex] != null) ? this.app.storyLocations[this.app.locationIndex].loc.merged.zoomLevelDelta : null : null;
    let customZoomLevelDelta: number = checkpointZoomLevelDelta != null ? checkpointZoomLevelDelta : storyZoomLevelDelta;
    if (customZoomLevelDelta != null) {
      customZoomLevelDelta /= 10;
      console.log("custom zoom level delta: ", customZoomLevelDelta);
    }
    return customZoomLevelDelta;
  }

  /**
   * compass nav mode
   */
  setFollowNav2D() {
    console.log("set follow nav 2D");
    let coordinates: ILatLng = this.currentLocation.location;
    let followCrt: number = this.flags.follow;
    return new Promise((resolve) => {
      if (!this.headingService.isCompassAvailable()) {
        resolve(false);
      } else {
        this.setFollowFlag(EFollowMode.NONE);
        MapSettings.setZoomInLevel(MapSettings.zoomInLevelNav, this.getCurrentZoomLevelDelta());
        this.setMap2D(coordinates).then(() => {
          this.enableCompassNavMode();
          this.setFollowFlag(EFollowMode.MOVE_MAP_HEADING_2D);
          resolve(true);
        }).catch((err: Error) => {
          console.error(err);
          if (!this.isNavModeCheck(followCrt)) {
            MapSettings.setZoomInLevel(MapSettings.zoomInLevelDefault, this.getCurrentZoomLevelDelta());
          }
          this.setFollowFlag(followCrt);
          resolve(true);
        });
      }
    });
  }

  /**
   * GPS composite nav mode
   */
  setFollowNav3D() {
    console.log("set follow nav 3D");
    let coordinates: ILatLng = this.currentLocation.location;
    let followCrt: number = this.flags.follow;
    return new Promise((resolve) => {
      this.setFollowFlag(EFollowMode.NONE);
      MapSettings.setZoomInLevel(MapSettings.zoomInLevelNav, this.getCurrentZoomLevelDelta());
      this.setMap3D(coordinates).then(() => {
        this.setFollowFlag(EFollowMode.MOVE_MAP_HEADING_3D);
        resolve(true);
      }).catch((err: Error) => {
        console.error(err);
        // EFollowMode.MOVE_MAP  
        if (!this.isNavModeCheck(followCrt)) {
          MapSettings.setZoomInLevel(MapSettings.zoomInLevelDefault, this.getCurrentZoomLevelDelta());
        }
        this.setFollowFlag(followCrt);
        resolve(true);
      });
    });
  }

  /**
   * follow mode
   */
  setFollowTracking2D() {
    console.log("set follow tracking 2D");
    let coordinates: ILatLng = this.currentLocation.location;
    let followCrt: number = this.flags.follow;
    return new Promise((resolve) => {
      this.setFollowFlag(EFollowMode.NONE);
      MapSettings.setZoomInLevel(MapSettings.zoomInLevelDefault, this.getCurrentZoomLevelDelta());
      this.setMap2D(coordinates).then(() => {
        this.disableCompassNavMode();
        this.setFollowFlag(EFollowMode.MOVE_MAP);
        resolve(true);
      }).catch((err: Error) => {
        console.error(err);
        if (this.isNavModeCheck(followCrt)) {
          MapSettings.setZoomInLevel(MapSettings.zoomInLevelNav, this.getCurrentZoomLevelDelta());
        }
        this.setFollowFlag(followCrt);
        resolve(true);
      });
    });
  }

  /**
   * init follow mode
   */
  setFollowTrackingSetup2D(applyWeb: boolean, animate: boolean, userInput: boolean) {
    console.log("set follow tracking setup 2D");
    let coordinates: ILatLng = this.currentLocation.location;
    let followCrt: number = this.flags.follow;
    return new Promise((resolve) => {
      this.setFollowFlag(EFollowMode.NONE);
      MapSettings.setZoomInLevel(MapSettings.zoomInLevelDefault, this.getCurrentZoomLevelDelta());
      this.setMap2D(coordinates).then(() => {
        this.disableCompassNavMode();
        this.enableFollow(true, applyWeb, animate, userInput);
        this.setFollowFlag(EFollowMode.MOVE_MAP);
        resolve(true);
      }).catch((err: Error) => {
        console.error(err);
        if (this.isNavModeCheck(followCrt)) {
          MapSettings.setZoomInLevel(MapSettings.zoomInLevelNav, this.getCurrentZoomLevelDelta());
        }
        this.setFollowFlag(followCrt);
        resolve(true);
      });
    });
  }


  isNavModeCheck(mode: number) {
    return [EFollowMode.MOVE_MAP_HEADING_2D, EFollowMode.MOVE_MAP_HEADING_3D].indexOf(mode) !== -1;
  }

  /**
   * preview mode
   */
  setFollowNone() {
    console.log("set follow none");
    return new Promise((resolve) => {
      this.setFollowFlag(EFollowMode.NONE);
      this.disableCompassNavMode();
      this.disableFollow();
      resolve(true);
    });
  }

  /**
   * set follow mode
   * the map view mode 2d/3d is set on the transition
   * userInput specifies if the method is called from HTML
   */
  setFollowCore(action: number, applyWeb: boolean, animate: boolean, userInput: boolean) {
    // click 0, move map 1
    // console.log("set follow: ", action);
    let promise = new Promise((resolve) => {
      if (action === EMapInteraction.none) {
        resolve(true);
        return;
      }
      switch (this.flags.follow) {
        case EFollowMode.NONE:
          switch (action) {
            case EMapInteraction.enter:
              this.setFollowTrackingSetup2D(applyWeb, animate, userInput).then((res: boolean) => {
                resolve(res);
              });
              break;
            case EMapInteraction.switch2dHeading:
              this.setFollowNav2D().then((res: boolean) => {
                resolve(res);
              });
              break;
            default:
              resolve(true);
              break;
          }
          break;
        case EFollowMode.MOVE_MAP:
          switch (action) {
            case EMapInteraction.enter:
              this.setFollowNav3D().then((res: boolean) => {
                resolve(res);
              });
              break;
            case EMapInteraction.exit:
              this.setFollowNone().then((res: boolean) => {
                resolve(res);
              });
              break;
            case EMapInteraction.switch2dHeading:
              this.setFollowNav2D().then((res: boolean) => {
                resolve(res);
              });
              break;
            default:
              resolve(true);
              break;
          }
          break;

        case EFollowMode.MOVE_MAP_HEADING_2D:
          switch (action) {
            case EMapInteraction.enter:
              this.setFollowTracking2D().then((res: boolean) => {
                resolve(res);
              });
              break;
            case EMapInteraction.exit:
              this.setFollowNone().then((res: boolean) => {
                resolve(res);
              });
              break;
            case EMapInteraction.switch2dHeading:
              // it's already 2d heading mode
              resolve(true);
              break;
            default:
              resolve(true);
              break;
          }
          break;

        case EFollowMode.MOVE_MAP_HEADING_3D:
          switch (action) {
            case EMapInteraction.enter:
              this.setFollowTracking2D().then((res: boolean) => {
                resolve(res);
              });
              break;
            case EMapInteraction.exit:
              this.setFollowNone().then((res: boolean) => {
                resolve(res);
              });
              break;
            case EMapInteraction.switch2dHeading:
              this.setFollowNav2D().then((res: boolean) => {
                resolve(res);
              });
              break;
            default:
              resolve(true);
              break;
          }
          break;
        default:
          resolve(true);
          break;
      }
    });
    return promise;
  }

  initCompassHudState(active: boolean) {
    console.log("init compass hud state: ", active);
    HudUtils.setHudUnlocked(this.hudMsg, EMapHudCodes.compassHeading, active);
    HudUtils.setHudUnlocked(this.hudMsg, EMapHudCodes.gpsHeading, active);
    HudUtils.setHudShow(this.hudMsg, EMapHudCodes.compassHeading, active);
    HudUtils.setHudShow(this.hudMsg, EMapHudCodes.gpsHeading, active);
  }

  /**
   * reset hud to default state
   */
  initHudState() {
    console.log("init hud state");
    HudUtils.setHudHighlightAll(this.hudMsg, HudUtils.getHudDisplayModes().default);
    HudUtils.setHudShowAll(this.hudMsg, true);
    // lock compass hud (unlocked via activity provider only)
    this.initCompassHudState(false);
  }


  checkHudAnyShow() {
    return HudUtils.checkHudAnyShow(this.hudMsg, this.flags.mapDebugMode);
  }

  /**
   * disable GPS heading updates
   * clear HUD
   */
  enableCompassNavMode() {
    console.log("enable compass nav mode");
    this.headingService.switchGPSHeading(false);
    HudUtils.setHudShow(this.hudMsg, EMapHudCodes.compassHeading, true);
    HudUtils.setHudShow(this.hudMsg, EMapHudCodes.gpsHeading, false);
  }

  /**
   * allow GPS heading updates
   * clear HUD
   */
  disableCompassNavMode() {
    console.log("disable compass nav mode");
    this.headingService.switchGPSHeading(true);
    HudUtils.setHudShow(this.hudMsg, EMapHudCodes.compassHeading, false);
    HudUtils.setHudShow(this.hudMsg, EMapHudCodes.gpsHeading, true);
  }

  ionViewWillLeave() {
    console.log("gmap will leave view");
    this.show = false;
  }

  ngOnDestroy() {
    console.log("gmap leave view");
    this.moveActivityProvider.dumpDistanceCounter(null, null);
    this.moveActivityProvider.stopGlobalDistanceWatch();
    this.placesDataProvider.saveCachedPlacePhotoUrlMultiNoAction();
    this.settingsProvider.currentLocationCoords = this.currentLocation.location;
    this.screen.setKeepScreenOn(false);
    this.storageFlags.saveFlagsGroupNoAction(ELocalAppDataKeys.localShowFlags);
    console.log("destroy");
    this.events.unsubscribe(EModalEvents.pickStory);
    this.uiext.enableSidemenu();
    this.navUtils.setTransparentBg(false);
    // clear self params so that there is no mismatch later
    this.nps.clear(ENavParamsResources.gmap);
    // fallbacks
    this.clearTimers();
    this.clearObservables();
    this.uiext.dismissLoadingV2();
  }

  /**
   * deinit all map related resources
   * don't destroy the map, just clear the map and detach from the container
   */
  async deinitResources(showLoading: boolean): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve) => {
      if (this.internalFlags.deinitTriggered) {
        console.error("deinit was already called");
        resolve(false);
        return;
      }

      // this.backButton.pop();

      // test prevent access to map
      this.markerHandler.setEnabled(false);

      this.internalFlags.deinitTriggered = true;
      if (showLoading) {
        await this.uiext.showLoadingV2Queue("Please wait..");
      }

      let mp: IMPGameSession = this.mpGameInterface.getGameContainer();

      if (mp && mp.currentGroup) {
        this.mpManager.quitSession();
        this.disconnectGroup(true);
      }

      this.mapManager.setEnabled(false);
      this.locationManager.setMasterLock(false);
      this.headingService.resetJoystickControl();

      this.mapManager.clearJsMapListeners();
      this.mapManager.resetQueue();
      this.mapManager.quitSession();
      this.itemScanner.resetQueue();

      this.navigationHandler.stopRecording();

      if (SettingsManagerService.settings.app.settings.autoBgMode.value) {
        this.backgroundModeProvider.disable();
      }

      if (this.modeSelect.editor || this.modeSelect.eventWm) {
        this.itemScanner.clearCache();
      } else {
        // only clear attached treasure markers
        this.itemScanner.detachPlaceMarkers();
      }

      console.log("request deinit resources");
      // await this.markerHandler.clearAllResolve();
      if (!this.platform.WEB) {
        // await this.markerHandler.clearAllNoRemove();

        // markers will be removed on map clear() method
      } else {
        // remove the markers from the map
        try {
          await this.markerHandler.clearAllResolve();
        } catch (e) {
          console.error(e);
        }
      }
      console.log("deinit resources: start");

      try {
        await this.clearSession(false, true);
        console.log("deinit resources: session cleared");
      } catch (e) {
        console.error(e);
      }

      try {
        if (this.useNativeMap && !this.isCapacitorMag) {
          // leave markers to be disposed by map clear() method
          await this.mapManager.clearMapWithMarkersNoRemove();
          console.log("deinit resources: markers cleared (mobile)");
        } else {
          // no further clearing is required for js map
        }
        console.log("deinit resources: session cleared");
      } catch (e) {
        console.error(e);
      }

      if (this.useNativeMap && !this.isCapacitorMag) {
        await this.mapManager.detachMapContainer();
      }

      this.deinitMapServices();
      this.mapManager.resetMapDefaultOptions();
      this.clearTimers();
      this.clearObservables();
      this.mapSubscription = ResourceManager.clearSubObj(this.mapSubscription);
      this.clearGeneralTimers();
      this.locationMonitor.resetUnifiedLocationObservable();
      this.mapManager.clearFlags();
      this.virtualPositionService.resetVirtualPosition();
      this.virtualPositionService.setPrimaryLocationSource(EVirtualLocationSource.gps);
      this.requestService.setNetworkSyncMode(false);
      this.networkMonitor.stopMonitor();
      this.mqttService.updateStatus(EMQTTStatusKeys.inGame, { value: false });
      // backup
      this.links.clearLinkData();
      this.mapInitializedFirstStage = false;
      await this.mapManager.removeMap();
      await this.uiext.dismissLoadingV2();
      await SleepUtils.sleep(100);
      resolve(true);
    });
    return promise;
  }

  resetCollectiblesMagnet() {
    this.buttonOptions.magnet.blink = false;
  }

  initCollectiblesContainer() {
    for (let key of Object.keys(this.collectibles)) {
      let mc: INearbyContentMagnet = this.collectibles[key];
      mc.list = [];
      mc.objectsNearby = false;
      mc.selectedSpec = null;
    }
  }

  /**
   * clear game session
   * deinit resources
   */
  clearSession(clearEnv: boolean, dispose: boolean) {
    let promise = new Promise(async (resolve) => {
      console.log("clear session ");
      this.app.start = false;
      this.setState(EGmapStates.INIT);
      if (dispose) {
        this.stopWatchHeading();
      } else {
        this.internalFlags.isHeadingTrackEngaged = false;
      }

      this.internalFlags.receivedMessageFromOperator = false;
      this.internalFlags.returnToStoryline = false;
      this.app.canExitMap = false;
      this.resetChatFlags();
      this.resetTeamFlags();
      this.headingService.cleanup();
      console.log("clear session ok");
      await this.stopWatchPosition();
      this.disableFollow();
      this.itemScanner.stop();
      if (clearEnv) {
        await this.clearEnvMarkers();
      }
      this.resetDisplayValues();
      this.internalFlags.promiseEnabled = false;
      this.uiext.dismissAllWidgets();
      this.clearTimers();
      this.clearObservables();

      this.locationManager.clearLocationWatchdog();
      this.moveActivityProvider.stopDistanceWatch();
      this.locationMonitor.manualLocationRelease();
      this.storyManagerService.unloadStoryLocations();
      this.storyManagerService.clearFlags();
      this.modularViews.clearCurrentGmapDetailParams();
      this.backgroundModeControllerProvider.resumeDefaultBackgroundTimer();

      this.initCollectiblesContainer();
      this.resetCollectiblesMagnet();

      this.messageQueueHandler.cleanup();
      console.log("clear session: 0");
      await this.exitDroneMode(false);
      console.log("clear session: 1");
      await this.exitActivityMain(!dispose);
      console.log("clear session: 2");
      await this.quitChallenge(!dispose);
      console.log("clear session: 3");
      await this.modularViews.dismissLocationDetailsViewResolve();
      console.log("clear session: 4");

      this.geoObjects.clearGlobalObjects();
      this.geoObjects.clearMapFirstObjects();
      this.geoObjects.clearAllBuffers();

      await this.clearNav();
      this.links.clearLinkData();
      this.subscription.navigatePreloadStory = ResourceManager.clearSub(this.subscription.navigatePreloadStory);
      this.unloadStory();
      await PromiseUtils.wrapResolve(this.storyDataProvider.clearResumeStoryCache(), true);
      console.log("clear session complete fn");
      resolve(true);
    });
    return promise;
  }

  /**
   * exit all/any running activities
   * trigger activity finished event then unsubscribe from activity entry state events
   */
  async exitActivityMain(refreshCrates: boolean): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve) => {
      this.isDroneAllowedForActivity = false;
      this.activityProvider.setCollectContext(this.internalFlags.collectMode != null ? this.internalFlags.collectMode : ECheckpointCollectMode.auto, false);
      this.activityProvider.setActivityCollectType(null);
      this.activityProvider.setActivityNavCollectType(null);
      this.collectibles.coins.list = [];
      this.collectibles.coins.objectsNearby = false;
      this.droneSimulator.onCompleteChallengeCheck();
      await this.exitExploreActivityMain(true);
      this.exitGenericTimeoutActivityMain();
      this.exitARExploreActivityMain();
      this.exitPhotoActivityMain();
      await this.exitFindActivityMain();
      await this.exitQuestActivityMain();
      this.initCompassHudState(false);
      this.activityProvider.triggerActivityExit();
      // stop timeout watch
      this.unsubscribeFromTimerWatch();
      // stop activity entry state watch
      this.unsubscribeFromActivityEntryWatch();
      // fallback
      this.activityProvider.deinitActivity(false);
      await this.activityProvider.exitAll();
      this.modularViews.clearCurrentGmapDetailParams();
      // reset HUD state
      this.initHudState();
      // clear geo object buffer so that it can be synced with AR on next refresh
      this.geoObjects.clearAllBuffers();
      if (refreshCrates) {
        // refresh (and re-add) world map objects
        this.itemScanner.refreshARObjects();
      }
      if (this.flags.contextHud) {
        this.setHudState(false, true);
      }
      await this.exitDroneMode(false);
      if (refreshCrates) {
        await this.itemScanner.refreshCratesWQ();
      }
      resolve(true);
    });
    return promise;
  }


  /**
   * set hud state
   * save last state or sync both
   * @param show 
   * @param sync 
   */
  setHudState(show: boolean, sync: boolean) {
    this.flags.showHudPrev = this.flags.showHud;
    this.flags.showHud = show;
    if (sync) {
      this.flags.showHudPrev = show;
    }
  }

  /**
   * start game session
   */
  startSession(first: boolean, allowWorldMapMode: boolean, withStoryOverride: boolean): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve) => {
      console.log("start session, allow world map: ", allowWorldMapMode);
      console.log("with story: ", withStoryOverride);
      // this.initTimedDebuggerTrigger();
      await this.checkPreloadData(withStoryOverride);
      let withStory: boolean = this.checkWithStory(withStoryOverride);
      // enable treasures in world map free roaming
      let enableTreasures: boolean = this.enableScanner && this.isWorldMap && allowWorldMapMode && !this.modeSelect.blank;
      // also enable in storyline
      if (this.checkTreasuresInStoryEnabled()) {
        enableTreasures = this.enableScanner;
      }
      if (withStory && !this.treasuresInStory) {
        enableTreasures = false;
      }
      if (withStory) {
        this.backgroundModeControllerProvider.setExtendedBackgroundTimer();
      } else {
        this.backgroundModeControllerProvider.resumeDefaultBackgroundTimer();
      }
      console.log("enable treasures: ", enableTreasures);
      // in world map, treasures are enabled in the beginning
      this.itemScanner.setUnlockScanner(enableTreasures);
      if (this.modeSelect.storyline) {
        this.setStoryModeLayers(this.isCustomMapStory);
      }
      if (enableTreasures) {
        await this.restoreShowTreasureLayersRetryResolve();
      }

      this.internalFlags.promiseEnabled = true;
      // markers are not singleton because they are reinitialized at view start
      // this.Markers = new MarkerHandler(this.platform);
      // the main modules are singletons and some have to be updated with the current markers
      this.mapManager.setEnabled(true);

      if (!this.platform.WEB) {
        // this.flags.watchLocation = true;
        this.setFollowFlag(EFollowMode.MOVE_MAP);
      }

      this.smartZoom.resetState();
      this.smartZoom.resetTransitions();
      this.initHudState();

      this.geoObjects.getShowLayersResolve(false).then((layers: IActionLayers) => {
        console.log("get show layers: ", layers);
      }).catch((err: Error) => {
        console.error(err);
        this.analytics.dispatchError(err, "gmap");
      });

      let promiseInitMap: Promise<boolean> = new Promise((resolve, reject) => {
        if (this.initialized || !first) {
          resolve(true);
        } else {
          this.dedicatedTimeouts.mapBeforeInitTimeout = setTimeout(() => {
            this.initialized = true;

            this.droneSimulator.initDroneMarker((_data: IPlaceMarkerContent) => {
              console.log("drone marker tapped");
            });

            this.dedicatedTimeouts.mapInitAnimation = setTimeout(() => {
              this.mapManager.loadUserMarker((data: IPlaceMarkerContent) => {
                this.getUserMarkerCallback(data);
              }).then(() => {
                this.initMap().then(() => {
                  console.log("init map done");
                  resolve(true);
                }).catch((err: Error) => {
                  reject(err);
                });
              });
            }, 500);
          }, 500);
        }
      });

      promiseInitMap.then(async () => {
        this.initTimers();
        this.initObservables();
        this.locationManager.setLocationWatchdog(true);

        // init map
        this.mapManager.resetMapDefaultOptions();
        this.mapManager.applyMapOptions();

        this.itemScanner.setLocation(this.currentLocation.location);
        if (enableTreasures) {
          this.handleItemScanner();
        }

        this.handleOtherObjectsGenerator();
        this.subscribeToMessageQueue();
        this.subscribeToQuotaEvents();

        // move map to user location
        this.dedicatedTimeouts.initMapCheck = setTimeout(async () => {
          if (!this.mapInitializedFirstStage) {
            await this.markerHandler.disposeLayerResolve(EMarkerLayers.USER);
            // only for first time init
            // do not auto hide plate if e.g. starting a story from the world map
            // let the timer do this instead
            await this.setPlateVisible(false);
            await this.loadData(withStory);
            this.mapInitializedFirstStage = true;
            this.messageQueueHandler.prepare("Waiting for GPS", true, EQueueMessageCode.warn);
          } else {
            await this.loadData(withStory);
          }

          if (enableTreasures) {
            this.itemScanner.start();
          } else {
            this.itemScanner.init();
          }

          this.loadDefaultStoryArenaInit();

          this.enableFollow(true, true, false, false);
          resolve(true);
        }, 500);
      }).catch((err: Error) => {
        // alert(JSON.stringify(err));
        this.uiext.showAlertNoAction(Messages.msg.error.after.msg, ErrorMessage.parse(err, Messages.msg.error.after.sub));
        resolve(false);
      });
    });
    return promise;
  }

  setPlateVisible(show: boolean): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve) => {
      console.log("set plate visible: ", show);
      if (show) {
        this.showPlate = true;
        this.dedicatedTimeouts.logoAnimation = setTimeout(() => {
          this.showState = 'active';
          resolve(true);
        }, 500);
      } else {
        this.showState = 'inactive';
        this.dedicatedTimeouts.logoAnimation = setTimeout(() => {
          this.showPlate = false;
          resolve(true);
        }, 1000);
      }
    });
    return promise;
  }


  loadStory(reload: boolean): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve, reject) => {
      console.log("request load story");
      this.storyDataProvider.getCurrentOpenStory(this.storyId, this.currentLocation.location, reload).then(async (data: IStoryResponse) => {
        this.story = data.story;
        switch (this.story.droneOnly) {
          case EDroneMode.onlyDrone:
            this.isDroneOnly = true;
            // 2x drone battery by default for drone only stories
            this.droneSimulator.applyUpgrade(EItemCodes.greenTech, 2, true);
            break;
          case EDroneMode.noDrone:
            this.isDroneAllowed = false;
            break;
          default:
            this.isDroneAllowed = true;
            break;
        }

        this.isARUnlocked = this.story.enableAr !== 0;

        if (this.story.teams) {
          this.isMpStory = true;
        }
        // set custom options (check story attributes)
        this.mapManager.setMapBuildingsVisible(this.story.showBuildings !== 0);
        this.mapManager.setMapIndoorMode(this.story.showIndoor === 1);
        // apply map options for current session
        this.mapManager.applyMapOptions();

        if (!this.internalFlags.promiseEnabled) {
          reject(new Error("promise disabled"));
        }
        let title: string = this.story.name;
        this.title = title;
        this.app.storyLocations = [];
        this.internalFlags.alreadyDoneLocationsCount = 0;
        let formattedStory: IStory = LocationUtils.formatStoryLocMulti(this.story);
        this.app.storyLocations = LocationUtils.getAppLocationsFromStory(formattedStory, false);
        this.checkInteractiveModes(true);
        this.internalFlags.levelUpPopups = false;
        this.activityProvider.setCollectContext(this.story.collectMode, true);
        this.tts.setTTSVoiceOptionsContent(this.story.language, null);

        StoryUtils.formatStoryLocationSpecs(this.app.storyLocations);
        console.log("formatted story locations: ", this.app.storyLocations);
        this.storyManagerService.loadStoryLocations(this.app.storyLocations);

        // format as slides
        this.app.storyLocationsActiveSlide = 0;
        this.flags.itemsPerSlide = OtherUtils.checkDivSpec(this.app.storyLocations.length, 2, [3, 4, 5]);
        this.app.storyLocationsSlides = ArrayUtils.splitGrid(this.app.storyLocations, this.flags.itemsPerSlide, false);

        let foundFirstNotDoneLocation: boolean = false;
        this.selectStoryLocationIndex(0);
        for (let i = 0; i < this.story.locations.length; i++) {
          if (this.story.locs[i].merged.done === EStoryLocationDoneFlag.done) {
            this.internalFlags.alreadyDoneLocationsCount++;
          } else {
            if (!foundFirstNotDoneLocation) {
              foundFirstNotDoneLocation = true;
              this.selectStoryLocationIndex(i);
            }
          }
        }
        this.startButtonText = MessageUtils.getStartButtonText(StoryUtils.checkResumeStory(this.story));
        this.storySelected = true;
        this.internalFlags.checkContinue = true;
        this.setOverlayStyle(true);

        this.moveActivityProvider.setGameContext(EGameContext.storyline);

        if (this.story.mapStyle) {
          this.storyParams.mapStyle = this.story.mapStyle.name;
          this.storyParams.mapId = this.story.mapStyle.mapId;
          await this.mapManager.setMapStyleCoreResolve(this.story.mapStyle.name);
        }
        resolve(true);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  checkInteractiveModes(storyDefaults: boolean) {
    console.log("check interactive modes: " + (storyDefaults ? "story defaults" : "checkpoint"));
    this.internalFlags.manualChallengeStart = this.story != null && this.story.navMode === ECheckpointNavMode.auto ? false : true;
    this.internalFlags.collectMode = this.story != null ? this.story.collectMode : null;
    this.internalFlags.audioGuide = this.story != null ? this.story.audioGuide : null;
    this.internalFlags.directionsEnabled = true;
    if (!storyDefaults) {
      let loc: IAppLocation = ((this.app.storyLocations != null) && (this.app.locationIndex < this.app.storyLocations.length) && (this.app.locationIndex != null) && (this.app.locationIndex !== -1)) ? this.app.storyLocations[this.app.locationIndex] : null;
      if (loc && loc.loc && loc.loc.merged) {
        let bloc: IBackendLocation = loc.loc.merged;
        this.internalFlags.manualChallengeStart = bloc != null && bloc.navMode === ECheckpointNavMode.auto ? false : true;
        if (this.story != null && this.story.navMode === ECheckpointNavMode.auto && bloc.navMode == null) {
          this.internalFlags.manualChallengeStart = false; // story nav mode auto overrides checkpoint settings if undefined
        }
        this.internalFlags.collectMode = bloc != null ? bloc.collectMode : null;
        if (this.story != null && bloc.collectMode == null) {
          this.internalFlags.collectMode = this.story.collectMode; // story collect mode overrides checkpoint settings if undefined
        }
        this.internalFlags.audioGuide = bloc != null ? bloc.audioGuide : null;
        if (this.story != null && bloc.audioGuide == null) {
          this.internalFlags.audioGuide = this.story.audioGuide; // story audio guide overrides checkpoint settings if undefined
        }
        if (bloc.directionsMode === 0) {
          this.internalFlags.directionsEnabled = false;
        }
      }
    }
    console.log("manual challenge start: ", this.internalFlags.manualChallengeStart);
  }

  /**
   * check story progress from server (lighter than loading story w/ full detail)
   */
  loadStoryProgressOnly(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve, reject) => {
      console.log("request load story progress");
      this.storyDataProvider.loadStoryProgressOnly(this.storyId, null, null).then((data: IStoryResponse) => {
        let formattedStory: IStory = LocationUtils.formatStoryLocMulti(data.story);
        this.story = LocationUtils.mergeProgress(this.story, formattedStory);
        let appLocsNew: IAppLocation[] = LocationUtils.getAppLocationsFromStory(this.story, false);
        LocationUtils.checkProgressUpdatedMerge(this.app.storyLocations, appLocsNew);
        this.storyManagerService.checkStoryLocationsProgressUpdated();
        resolve(true);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * init observables and subscribers
   */
  initObservables() {
    console.log("init observables");
    /**
    * The main app runner, it runs while the map vew is open
    * the observable checks for state change and runs the state machine function
    * when a state change is detected
    */
    if (this.observables.state === null) {
      this.observables.state = new BehaviorSubject(this.app.state);
      this.setState(this.app.state);
      this.subscription.state = this.observables.state.subscribe(() => {
        this.stateMachine();
      }, (err: Error) => {
        console.error(err);
      });
    }

    this.networkMonitor.startMonitor();
    // watch network for a disconnection
    this.subscription.networkWatch = this.networkMonitor.watchState().subscribe((state: number) => {
      switch (state) {
        case ENetworkMonitorState.disconnected:
          this.messageQueueHandler.prepare("Disconnected from network", true, EQueueMessageCode.warn);
          this.soundManager.ttsWrapper(Messages.tts.networkDisconnected, true);
          this.buttonOptions.menu.blink = true;
          break;
        case ENetworkMonitorState.connected:
          this.buttonOptions.menu.blink = false;
          break;
      }
    }, (err: Error) => {
      console.error(err);
    });

    this.watchKeyEventHandler();
    this.createLinkViewObs();
  }

  /**
   * create/reset link view observables
   */
  createLinkViewObs() {
    if (this.observables.linkViewSend === null) {
      this.observables.linkViewSend = new BehaviorSubject(null);
    } else {
      this.observables.linkViewSend.next(null);
    }

    if (this.observables.linkViewReceive === null) {
      this.observables.linkViewReceive = new BehaviorSubject(null);
    } else {
      this.observables.linkViewReceive.next(null);
    }
  }


  clearObservables() {
    console.log("clear observables");
    this.subscription = ResourceManager.clearSubObj(this.subscription);
    this.subscriptionMp = ResourceManager.clearSubObj(this.subscriptionMp);
    this.observables = ResourceManager.clearSubObj(this.observables);
  }


  /**
   * reset HUD
   */
  resetDisplayValues() {
    let keys = Object.keys(this.hudMsg);
    keys.forEach((key) => {
      this.hudMsg[key].value = "";
    });
    this.closeHud();
  }


  clearHudMessage(code: number) {
    HudUtils.clearHudMessage(this.hudMsg, code);
  }

  showHudEmptyMessage(code: number) {
    HudUtils.showHudEmptyMessage(this.hudMsg, code);
  }

  timeoutUpdate(countdownAutostart: IGmapCountdown) {
    if (this.app.firstStart && this.mapInitialized) {
      if (countdownAutostart.value > 0) {
        let countdownDisplay: string = "" + Math.ceil(countdownAutostart.value / 100);
        this.plateSub = countdownDisplay;
        // this.showHudMessage(EMapHud.timerAutostart, countdownDisplay, null);
        if (!this.app.firstCountdownTick) {
          countdownAutostart.value -= 50;
        }
        this.app.firstCountdownTick = false;
      } else {
        // this.clearHudMessage(EMapHud.timerAutostart);
        this.app.firstStart = false;
        this.app.firstCountdownTick = true;
        this.setPlateVisible(false).then(() => {
          this.plateSub = "Loading";
          this.start();
        }).catch(() => {
          this.start();
        });
      }
    }
  }

  clearGeneralTimers() {
    this.generalTimers = ResourceManager.clearSubObj(this.generalTimers);
  }

  /**
  * check required
  * show walkthrough w/ resolve
  */
  showWalkthroughCore(always: boolean, fn: () => Promise<any>): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      if (SettingsManagerService.settings.app.settings.enableWalkthroughs.value || always) {
        if (always) {
          fn().then(() => {
            resolve(true);
          });
        } else {
          let flagsGen: IAppFlagsGenContent = await this.storageFlags.loadFlagsGroup(ELocalAppDataKeys.localShowFlags, null, true);
          let flags: ILocalShowFlags = flagsGen as any;
          if (!flags.gmapTutorial) {
            this.dedicatedTimeouts.showWalkthrough = setTimeout(() => {
              flags.gmapTutorial = true;
              this.storageFlags.updateFlagsGroup(ELocalAppDataKeys.localShowFlags, flags);
              this.storageFlags.saveFlagsGroup(ELocalAppDataKeys.localShowFlags).then(() => {
                fn().then(() => {
                  resolve(true);
                });
              });
            }, 3000);
          } else {
            resolve(false);
          }
        }
      } else {
        resolve(false);
      }
    });
  }

  async handleMeetingPlaceTransfer(action: boolean) {
    if (this.isDroneOnly && action) {
      let central: ILatLng = StoryUtils.getCentralLocation(this.app.storyLocations);
      await PromiseUtils.wrapResolve(this.itemScanner.jumpToMeetingPlaceWhenReady(central, this.currentLocation), true);
      // timeout launch drone
    }
  }

  /**
   * show warning and walkthrough
   * request once per app opened
   */
  async handleShowWarningCheck() {
    let showFn = async () => {
      if (!this.internalFlags.gameplayTutorialShown) {
        this.internalFlags.gameplayTutorialShown = true;
        let slideData: IGenericSlideData[] = [];
        try {
          // get story / world map slider tutorial
          slideData = await this.storyDataProvider.getSliderTutorial(this.storyId);
          await this.modularViews.showGameplayOverviewSlider(slideData, null);
          if (this.isDroneOnly) {
            await this.uiext.showRewardPopupQueue(Messages.msg.isDroneOnly.before.msg, Messages.msg.isDroneOnly.before.sub, null, false, 5000, false);
          }
        } catch (err) {
          console.error(err);
        }
      }
    };
    if (!GeneralCache.warningShown) {
      GeneralCache.warningShown = true;
      // await this.showWarningResolve();
      await showFn();
      this.showMapUnlockRequest();
    } else {
      await showFn();
    }
  }

  initTimedDebuggerTrigger() {
    if (this.dedicatedTimers.debug != null) {
      return;
    }
    let timer1 = timer(0, 100);
    this.dedicatedTimers.debug = timer1.subscribe(() => {
      console.log("runtime check");
    });
  }

  /**
   * start timer actions
   */
  initTimers() {
    console.log("init timers");

    if (!this.generalTimers.ping) {
      let timer1 = timer(0, 1000);
      this.generalTimers.ping = timer1.subscribe(() => {
        // console.log("view id: ", this.viewId, "flags: ", this.flags);
      }, (err: Error) => {
        console.error(err);
      });
    }

    if (!this.dedicatedTimeouts.mapInitTimeout) {
      this.dedicatedTimeouts.mapInitTimeout = setTimeout(() => {
        if (!this.mapInitialized) {
          // this.uiext.showAlertNoAction(Messages.msg.mapInitializedTimeout.after.msg, Messages.msg.mapInitializedTimeout.after.sub);
          this.messageQueueHandler.prepare("GPS signal not available", true, EQueueMessageCode.warn);
          this.soundManager.ttsWrapper(Messages.tts.GPSNotAvailable, true);
        }
      }, 20000);
    }

    if (!this.dedicatedTimeouts.mapExitLockTimeout) {
      this.dedicatedTimeouts.mapExitLockTimeout = setTimeout(async () => {
        // allow exit map after some timeout (e.g. gps not available)
        this.internalFlags.canExitMap = true;
      }, 10000);
    }

    if (!this.dedicatedTimeouts.showWarning) {
      this.dedicatedTimeouts.showWarning = setTimeout(async () => {
        if (!this.modeSelect.storyline) {
          await this.handleShowWarningCheck();
        }
      }, 10000);
    }

    if (!this.dedicatedTimers.dispDone) {
      let timer1 = timer(0, 500);
      let prescaler: number = 0;
      let prescalerTop: number = 1;
      // this.checkButtons();
      this.dedicatedTimers.dispDone = timer1.subscribe(async () => {
        this.timeoutUpdate(this.countdownAutostart);
        this.checkEventTimer();
        // check dump place photos cache to server
        this.placesDataProvider.saveCachedPlacePhotoUrlMultiCheckNoAction();
        prescaler += 1;
        if (prescaler >= prescalerTop) {
          prescaler = 0;
          this.internalFlags.tick = !this.internalFlags.tick;
          // blink story location dots
          if (this.app.storyLocations) {
            for (let i = 0; i < this.app.storyLocations.length; i++) {
              let sloc: IAppLocation = this.app.storyLocations[i];
              if (sloc.selected) {
                sloc.dispDone = !sloc.dispDone;
              } else {
                sloc.dispDone = sloc.loc.merged.done === EStoryLocationDoneFlag.done;
              }
            }
          }
        }
        // check world edit
        if (this.userContentProvider.checkWorldEdit(this.itemScanner.getOwnedTreasuresFromCache(false)).length > 0) {
          this.internalFlags.worldEditDetected = true;
        } else {
          this.internalFlags.worldEditDetected = false;
        }
        let viewLoc: ILatLng = null;
        try {
          viewLoc = await this.mapManager.getCurrentMapLocation();
        } catch (err) {
          console.error(err);
        }
        this.mqttService.updateStatus(EMQTTStatusKeys.mapCenter, viewLoc);
        this.mqttService.updateStatus(EMQTTStatusKeys.userMarker, this.mapManager.getCurentUserMarkerLocation());
        this.mqttService.updateStatus(EMQTTStatusKeys.droneMarker, this.mapManager.getCurrentDroneMarkerLocation());
        let gcStatus: IGameContextStatus = {
          storyId: this.storyId,
          worldMap: this.isWorldMap,
          previewMode: false
        };
        this.mqttService.updateStatus(EMQTTStatusKeys.gameContext, gcStatus);
        this.mqttManagerService.changeStoryContext(this.storyId);
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  checkEventTimer() {
    if (this.eventGroupLinkData && this.event && !this.internalFlags.eventTimeoutShown) {
      let timeCrt: number = new Date().getTime();
      if (this.event.endTs - timeCrt <= 0) {
        this.internalFlags.eventTimeoutShown = true;
        this.uiext.showAlertNoAction(Messages.msg.info.after.msg, this.event.timeoutDescription);
      }
    }
  }


  /**
   * init button display logic
   */
  initButtons() {
    this.buttonOptions.scan.isEnabled = () => {
      if (this.isPreloadStory) {
        return false;
      }
      if (this.storySelected) {
        return true;
      }
      // if zoom out and map density filtering is enabled
      if (this.internalFlags.zoomOut && (SettingsManagerService.settings.app.settings.mapDensityFiltering.value && GeneralCache.os !== EOS.ios)) {
        return true;
      }
      return false;
    };


    this.buttonOptions.scan.isDisabled = () => {
      if (this.storySelected) {
        return !this.user.canScan || this.activityStarted.ANY;
      } else {
        // disable if eagle view is already enabled
        return this.internalFlags.eagleView;
      }
    };

    this.buttonOptions.zoom.isEnabled = () => {
      if (this.flags.droneMode) {
        return false;
      }
      if (this.activityStarted.find) {
        // too many buttons, keep compass only
        return false;
      }
      return true;
    };

    this.buttonOptions.zoom.isDisabled = () => {
      return false;
    };

    this.buttonOptions.recalculateDirections.isEnabled = () => {
      return this.internalFlags.routeRecalculateRequested;
    };

    this.buttonOptions.recalculateDirections.isDisabled = () => {
      return false;
    };

    this.buttonOptions.compass.isEnabled = () => {
      // return this.activityStarted.find;
      return false;
    };
    this.buttonOptions.compass.isDisabled = () => {
      return false;
    };

    this.buttonOptions.playVideo.isEnabled = () => {
      return this.internalFlags.hasVideoBefore;
    };
    this.buttonOptions.playVideo.isDisabled = () => {
      return false;
    };

    /** check if the story is not started */
    let checkStartButtonCondition = () => {
      return !(this.app.start || this.app.challengeInProgress || this.checkWorldMapFreeRoamingMode());
    };

    let checkExitCondition = () => {
      return this.app.canExitMap;
    };

    let checkSkipDisabled = () => {
      return !this.user.canSkip || !this.storySelected || this.internalFlags.returnToStoryline || (this.isPreloadStory && !this.activityStarted.ANYTEMP);
    };

    this.buttonOptions.start.isEnabled = () => {
      return checkStartButtonCondition() && !this.app.canCompleteActivity && !(checkExitCondition() || this.checkGoBack());
    };
    this.buttonOptions.start.isDisabled = () => {
      let defaultCond: boolean = !this.mapInitialized || !this.storySelected;
      let preloadCond: boolean = this.isPreloadStory && !this.app.start;
      return defaultCond || preloadCond;
    };
    // pause is enabled when activity is in progress and not finished, and (mot group context)
    this.buttonOptions.pause.isEnabled = () => {
      return !checkStartButtonCondition() && !this.app.canCompleteActivity && !(checkExitCondition() || this.checkGoBack());
    };
    this.buttonOptions.pause.isDisabled = () => {
      // return !checkSkipDisabled();
      return false;
    };
    this.buttonOptions.completeActivity.isEnabled = () => {
      return !checkStartButtonCondition() && this.app.canCompleteActivity && !checkExitCondition();
    };
    this.buttonOptions.completeActivity.isDisabled = () => {
      return false;
    };

    this.buttonOptions.exit.isEnabled = () => {
      return checkExitCondition() || this.checkGoBack();
    };
    this.buttonOptions.exit.isDisabled = () => {
      return false;
    };

    this.buttonOptions.exitFab.isEnabled = () => {
      return checkExitCondition();
    };
    this.buttonOptions.exitFab.isDisabled = () => {
      return false;
    };

    // show when groupContext button is replaced by another button
    this.buttonOptions.group.isEnabled = () => {
      // return !checkStoryEnabled();
      let mp = this.mpGameInterface.getGameContainer();
      return mp && mp.online;
    };
    this.buttonOptions.group.isDisabled = () => {
      return false;
    };

    this.buttonOptions.operatorChat.isEnabled = () => {
      return this.internalFlags.receivedMessageFromOperator;
    };

    this.buttonOptions.operatorChat.isDisabled = () => {
      return false;
    };


    // check places button enable
    let checkPlacesEnabled = () => {
      // if (this.mp && this.mp.online) {
      //   if (this.storySelected || this.app.challengeInProgress) {
      //     return true;
      //   }
      // } else {
      //   return true;
      // }
      return true;

      // return false;
    };
    let checkPlacesDisabled = () => {
      if (this.storySelected || this.app.challengeInProgress) {
        return false;
      }
      return true;
    };

    this.buttonOptions.places.isEnabled = () => {
      return checkPlacesEnabled();
    };
    this.buttonOptions.places.isDisabled = () => {
      return checkPlacesDisabled();
    };

    let checkStartLoc = () => {
      let start: boolean = false;
      if (this.internalFlags.manualChallengeStartRequested) {
        // world map challenge
        start = true;
      } else {
        // start = (this.internalFlags.nearbyStoryLocationIndex != null) && (this.internalFlags.nearbyStoryLocationIndex !== this.app.locationIndex);
        start = this.internalFlags.nearbyStoryLocationIndex != null && !this.activityStarted.ANYTEMP;
      }
      return start;
    };

    let checkResumeLoc = () => {
      return this.activityStarted.ANYTEMP && !checkStartLoc();
    };

    let checkSkip = () => {
      return !checkStartLoc() && (!this.checkNearbyChallenge() || this.storySelected);
    };

    this.buttonOptions.skip.isEnabled = () => {
      return checkSkip();
    };
    this.buttonOptions.skip.isDisabled = () => {
      return checkSkipDisabled();
    };

    this.buttonOptions.startLoc.isEnabled = () => {
      return checkStartLoc() && !checkExitCondition();
    };
    this.buttonOptions.startLoc.isDisabled = () => {
      // activity start in progress
      return this.activityStarted.ANYTEMP && !this.activityStarted.ANY;
    };
    this.buttonOptions.resumeLoc.isEnabled = () => {
      return checkResumeLoc() && !checkExitCondition();
    };
    this.buttonOptions.resumeLoc.isDisabled = () => {
      // activity start in progress
      // return this.activityStarted.ANYTEMP && !this.activityStarted.ANY;
      return false;
    };

    this.buttonOptions.startChallenge.isEnabled = () => {
      return !checkSkip() && !checkStartLoc() && !checkExitCondition();
    };
    this.buttonOptions.startChallenge.isDisabled = () => {
      return this.activityStarted.ANYTEMP && !this.activityStarted.ANY;
    };

    this.buttonOptions.inventory.isEnabled = () => {
      return !this.app.activeItems;
    };
    this.buttonOptions.inventoryFlag.isEnabled = () => {
      return this.app.activeItems;
    };
    this.buttonOptions.ar.isEnabled = () => {
      return this.isAREnabled();
    };
    this.buttonOptions.ar.isDisabled = () => {
      return !this.isARAvailable();
    };
    this.buttonOptions.drone.isEnabled = () => {
      // only if drone is not already launched
      return (this.isDroneAllowed || this.isDroneAllowedForActivity) && !this.flags.droneMode;
    };
    this.buttonOptions.drone.isDisabled = () => {
      return false;
    };
    this.buttonOptions.editPlace.isEnabled = () => {
      // show if a new place is added, or changed
      return this.internalFlags.worldEditDetected;
    };
    this.buttonOptions.editPlace.isDisabled = () => {
      return false;
    };
    this.buttonOptions.undoEditPlace.isEnabled = () => {
      // show if a new place is added, or changed
      return this.internalFlags.worldEditDetected;
    };
    this.buttonOptions.undoEditPlace.isDisabled = () => {
      return false;
    };
    this.buttonOptions.addPlace.isEnabled = () => {
      if (this.modeSelect.editor) {
        // show by default, when edit place is not active, in editor mode only
        return !this.internalFlags.worldEditDetected;
      } else {
        return false;
      }
    };
    this.buttonOptions.addPlace.isDisabled = () => {
      return false;
    };
    this.buttonOptions.quickAddPlace.isEnabled = () => {
      if (this.modeSelect.editor) {
        // show by default, when edit place is not active, in editor mode only
        return !this.internalFlags.worldEditDetected && this.collectibles.treasures.selectedSpec != null;
      } else {
        return false;
      }
    };
    this.buttonOptions.quickAddPlace.isDisabled = () => {
      return false;
    };
    this.buttonOptions.magnet.isEnabled = () => {
      return this.collectibles.treasures.objectsNearby || this.collectibles.coins.objectsNearby;
    };
    this.buttonOptions.magnet.isDisabled = () => {
      return false;
    };
    // scanner progress (cooldown) / placeholder
    this.buttonOptions.progress.isEnabled = () => {
      let enabled: boolean = false;
      if (SettingsManagerService.settings.app.settings.showScanCooldown.value) {
        // check map initialized
        if (!this.loading && this.mapInitialized) {
          // check not storyline / activity started
          if ((!this.modeSelect.storyline || this.flags.treasuresInStoryline) && !this.activityStarted.ANY) {
            if ((this.progress != null) && (this.progress > 1)) {
              enabled = true;
            }
          }
        }
      }
      // default: show progress no fill (keep layout style)
      if (!enabled) {
        this.progress = 0;
      }
      // don't overlap with existing progress bar
      if (this.loading || !this.mapInitialized) {
        return false;
      }
      return true;
      // return false;
    };
    this.buttonOptions.progressLoading.isEnabled = () => {
      return this.loading || !this.mapInitialized;
    };
    this.buttonsLoaded = true;
  }

  isARAvailable() {
    return this.isARUnlocked && this.internalFlags.enableAR && !this.flags.droneMode;
  }

  isAREnabled() {
    return (!(this.platform.WEB && GeneralCache.isPublicDistribution)) && (this.isARUnlocked && SettingsManagerService.settings.app.settings.ARShortcut.value) || this.activityStarted.screenshotAR;
  }

  /**
   * stop timer actions
   */
  clearTimers() {
    console.log("clear timers");
    this.timers = ResourceManager.clearSubArray(this.timers);
    this.dedicatedTimers = ResourceManager.clearSubObj(this.dedicatedTimers);
    this.coinCollectTimeouts = ResourceManager.clearTimeoutArray(this.coinCollectTimeouts);
    this.dedicatedTimeouts = ResourceManager.clearTimeoutObj(this.dedicatedTimeouts);
    this.triggerableTimeouts = ResourceManager.clearTriggerableTimeoutObj(this.triggerableTimeouts);
  }


  /**
   * watch the user position using geolocation
   * @param enable
   */
  watchPosition() {
    console.log("watch position entry");
    this.locationManager.startWatchPosition();
    if (this.subscription.myUnifiedGeolocation === null) {
      // subscribe to real location updates (GPS / manual override) only
      this.subscription.myUnifiedGeolocation = this.locationMonitor.getUnifiedLocationObservable().subscribe((data: IGeolocationResult) => {
        // console.log(data);
        if (data != null) {
          // console.log("watch position update:", this.viewId, new Date().getTime());
          if (!this.mapManager.checkViewId(this.viewId)) {
            console.warn("duplicate instance found");
            if (!this.internalFlags.duplicateViewDetected) {
              this.internalFlags.duplicateViewDetected = true;
              this.supportModals.reportErrorWizard("gmap duplicate instance found");
            }
          }
          // if (!(!this.isDroneOnly || (this.isDroneOnly && !this.isDroneOnlyEngaged))) {
          if (this.isDroneOnly) {
            // don't set player position based on gps, use the drone/virtual position to place the user on the map
          } else {
            this.watchPositionAction(data);
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
    // check for location timeout to display in app
    if (this.subscription.locationTimeout === null) {
      this.subscription.locationTimeout = this.locationMonitor.getLocationTimeoutObservable().subscribe((timeout: number) => {
        // console.log("location timeout watch: " + timeout);
        if (!this.isDroneOnly) {
          switch (timeout) {
            case ELocationTimeoutEvent.timeout:
              this.buttonOptions.location.blink = true;
              break;
            case ELocationTimeoutEvent.timeoutWatchdog:
              if (!this.flags.droneMode) {
                this.plateSub = "Waiting for GPS";
                this.messageQueueHandler.prepare("Waiting for GPS", true, EQueueMessageCode.warn);
                if (!this.itemScanner.checkTreasuresLoaded()) {
                  this.messageQueueHandler.prepare("Waiting for location", false, EQueueMessageCode.warn);
                }
                this.buttonOptions.location.blink = true;
              }
              break;
            case ELocationTimeoutEvent.timeoutNotFound:
              if (!this.flags.droneMode) {
                this.plateSub = "GPS signal not found";
                this.messageQueueHandler.prepare("GPS signal not found", true, EQueueMessageCode.warn);
                this.soundManager.ttsWrapper(Messages.tts.GPSNotAvailable, true);
                this.uiext.showAlertNoAction(Messages.msg.gpsNotAvailableRestartDevice.after.msg, Messages.msg.gpsNotAvailableRestartDevice.after.sub, true);
                this.buttonOptions.location.blink = true;
              }
              break;
            case ELocationTimeoutEvent.timeoutSlowUpdate:
              if (!this.platform.WEB) {
                if (!this.flags.droneMode) {
                  this.messageQueueHandler.prepare("GPS seems slow", true, EQueueMessageCode.warn);
                  this.messageQueueHandler.prepare("Check location services", false, EQueueMessageCode.warn);
                  this.buttonOptions.location.blink = true;
                }
              }
              break;
            case ELocationTimeoutEvent.timeoutSlowUpdateWarning:
              if (!this.platform.WEB) {
                if (!this.flags.droneMode) {
                  // this.messageQueueHandler.prepare("GPS fix: restart app/phone", false, EQueueMessageCode.warn);
                  this.messageQueueHandler.prepare("GPS seems unresponsive", false, EQueueMessageCode.warn);
                }
              }
              break;
            case ELocationTimeoutEvent.reset:
              this.buttonOptions.location.blink = false;
              break;
            default:
              break;
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  stopWatchPosition(): Promise<boolean> {
    console.log("stop watch position");
    let promise: Promise<boolean> = new Promise((resolve) => {
      this.subscription.myUnifiedGeolocation = ResourceManager.clearSub(this.subscription.myUnifiedGeolocation);
      this.subscription.locationTimeout = ResourceManager.clearSub(this.subscription.locationTimeout);
      this.locationManager.stopWatchPositionResolve().then(() => {
        resolve(true);
      }).catch(() => {
        resolve(false);
      });
    });
    return promise;
  }


  /**
   * on location updated
   * handle virtual position updates
   */
  watchPositionAction(data: IGeolocationResult) {
    if (data != null) {
      // move map if location is available and follow flag is set 
      // do not accept inaccurate locations
      if (data.accuracy > AppConstants.gameConfig.locationAccuracyThreshold) {
        this.showHudMessage(EMapHudCodes.locationWarning, "low accuracy", null);
        this.mapManager.setLocationFound(false, !this.isDroneOnly);
        return;
      } else {
        this.clearHudMessage(EMapHudCodes.locationWarning);
        this.mapManager.setLocationFound(true, !this.isDroneOnly);
      }

      this.currentLocation.elapsed = data.elapsed;
      let coordinates: ILatLng = data.coords;

      let checkDroneModeEngage = (wplace: boolean) => {
        if (this.isDroneOnly && !this.isDroneOnlyEngaged) {
          // disable gps/compass
          this.stopWatchPosition();
          // this.stopWatchHeading();
          this.internalFlags.isHeadingTrackEngaged = false;
          if (wplace) {
            this.currentLocation.location = coordinates;
            this.virtualPositionService.placeUserAtLocation(this.currentLocation.location, true);
          }
          this.isDroneOnlyEngaged = true;
          return true;
        }
        return false;
      };

      // now the GPS should be fixed, so set mapInitialized flag no matter what
      if (!this.mapInitialized) {
        console.log("watch position move map init");
        this.moveMapToLocationInit(coordinates, false).then(() => {
          console.log("move map init done (2)");
          this.mapInitialized = true;
          this.dedicatedTimeouts.mapInitTimeout = ResourceManager.clearTimeout(this.dedicatedTimeouts.mapInitTimeout);
          checkDroneModeEngage(true);
        }).catch((err: Error) => {
          console.error(err);
          this.analytics.dispatchError(err, "gmap");
          // this.mapInitialized = true;
        });
      } else {
        if (checkDroneModeEngage(true)) {
          console.log("move map init done (3)");
          return;
        }
      }

      this.currentLocation.speed = data.speed;

      if (data.altitude != null) {
        this.currentLocation.altitude = data.altitude;
        this.showHudMessage(EMapHudCodes.altitude, "" + data.altitude.toFixed(2), "");
      } else {
        this.showHudMessage(EMapHudCodes.altitude, "N/A", "");
      }

      //////////////////////// TEST
      // this.currentLocation.speed = 10;

      // check speed
      let crtSpeed: number = ((this.currentLocation.speed != null) && (this.currentLocation.speed > 0)) ? this.currentLocation.speed : 0;

      if (!this.flags.droneMode) {
        let speedDisp: number = crtSpeed * 3.6;
        this.updateHudSpeed(speedDisp);

        // this.localNotifications.setPersistentNotification(null, "Speed: " + formatDisp.value + " " + formatDisp.unit, false);
      }

      let heading: number = data.heading;
      // if (SettingsManagerService.settings.app.settings.locationInterpolate.value) {
      //   heading = data.averageHeading;
      // }

      this.headingService.updateHeadingViaGPS(heading, crtSpeed);

      // if (this.remoteLogEnabled) {
      //   let message: string = "follow: " + this.flags.follow + ", update pos marker: " + this.internalFlags.updateUserMarkerGPS + 
      // ", use gps heading: " + this.flags.useGpsHeading + ", map update flag: " + this.checkEnableMapUpdate();
      //   this.bench.pushLogData(message);
      //   console.log(message);
      // }

      // this.showHudMessage(EMapHudCodes.debug, this.flags.follow + (this.internalFlags.updateUserMarkerGPS ? "U1" : "U0") + 
      // (this.flags.useGpsHeading ? "H1" : "H0") + (this.checkEnableMapUpdate() ? "M1" : "M0"), null);

      let updatePosition = (updateMarker: boolean) => {
        // updateMarker has no effect (deprecated?)
        let loc: IVirtualLocation = {
          coords: coordinates,
          speed: data.speed,
          heading: heading,
          source: EVirtualLocationSource.gps,
          updateMarker: updateMarker
        };
        this.virtualPositionService.updateVirtualPosition(loc);
        this.currentLocation.location = coordinates;
        if (updateMarker) {
          this.moveUserMarker(coordinates);
        }
      };

      console.log("watchPositionAction follow mode: ", this.flags.follow);

      switch (this.flags.follow) {
        case EFollowMode.NONE:
          // follow is disabled (only the marker and virtual position will be updated)
          updatePosition(true);
          break;
        case EFollowMode.DRONE:
          if (this.checkEnableGPSMapUpdate()) {
            // just set the user marker and update the position
            PromiseUtils.wrapResolve(this.moveMapOptions(coordinates), true).then(() => {
              updatePosition(true);
            });
          } else {
            updatePosition(this.internalFlags.updateUserMarkerGPS);
          }
          break;
        default:
          // move map follow no heading/3d gps heading/2d compass heading
          if (this.internalFlags.updateUserMarkerGPS) {
            // console.log("move  mp");
            // move map to the user location and set the user marker then update the position
            // let animateFlag = this.flags.follow === FollowMode.MOVE_MAP ? true : false; // only animate when compass is disable
            // let bearing: number = 0;
            if (this.checkCompassNavModeOrComposite()) {
              // let the compass update the map and marker
              updatePosition(false);
            } else {
              if ((this.headingService.flags.useGpsHeading && this.checkEnableGPSMapUpdate()) || this.headingService.status.compassNotAvailable) {
                // let the gps update the map
                PromiseUtils.wrapResolve(this.moveMapOptions(coordinates), true).then(() => {
                  updatePosition(true);
                });
              } else {
                // let the compass update the map, just move marker
                updatePosition(true);
              }
            }
          } else {
            updatePosition(false);
          }
          break;
      }
    }
  }

  /**
   * update hud speed, set highlight for move challenge target speed
   * @param speedDisp km/h
   */
  updateHudSpeed(speedDisp: number) {
    let decimals: boolean = speedDisp <= 30;
    let formatDisp: IFormatDisp = MathUtils.formatSpeedDisp(speedDisp, decimals);
    this.showHudMessage(EMapHudCodes.currentSpeedMove, formatDisp.value, formatDisp.unit);
    // change color based on target speed (if set)
    let mp: IMoveMonitorParams = this.moveActivityProvider.getMoveParams();
    if (mp && mp.targetSpeed) {
      if (speedDisp >= mp.targetSpeed) {
        HudUtils.setHudHighlight(this.hudMsg, EMapHudCodes.currentSpeedMove, HudUtils.getHudDisplayModes().complete);
      } else {
        HudUtils.setHudHighlight(this.hudMsg, EMapHudCodes.currentSpeedMove, HudUtils.getHudDisplayModes().key);
      }
    } else {
      HudUtils.setHudHighlight(this.hudMsg, EMapHudCodes.currentSpeedMove, HudUtils.getHudDisplayModes().default);
    }
  }

  /**
   * function to be called on GPS position update
   * moving map based on the view mode 2d/3d
   * @param coordinates 
   */
  moveMapOptions(coordinates: ILatLng, duration: number = 200): Promise<boolean> {
    let options: IMoveMapContext = {
      duration: duration,
      mapInitialized: this.mapInitialized,
      filteredHeading: this.headingService.status.filteredHeading,
      followMode: this.flags.follow
    };
    return this.mapManager.moveMapContext(coordinates, options);
  }

  /**
   * function to be called on GPS position update
   * moving user marker only
   * @param coordinates 
   */
  moveUserMarker(coordinates: ILatLng) {
    PromiseUtils.wrapNoAction(this.mapManager.moveUserMarkerToCoords(coordinates), true);
  }

  updateUserHeadingPointer(heading: number) {
    if (this.flags.directionPointer) {
      PromiseUtils.wrapNoAction(this.mapManager.updateUserHeading(heading), true);
    }
  }

  /**
   * check if navigation mode assumes compass handling of the map
   */
  checkCompassNavMode() {
    return this.checkNavModeCore() && !this.headingService.flags.useGpsHeading && !this.headingService.status.compassNotAvailable;
  }

  /**
   * check if navigation mode assumes compass handling of the map or composite
   */
  checkCompassNavModeOrComposite() {
    return this.checkNavModeCore() && (!this.headingService.flags.useGpsHeading || this.headingService.flags.useCompositeHeading) && !this.headingService.status.compassNotAvailable;
  }

  /**
   * check if navigation mode allows rotating map based on heading
   */
  checkNavModeCore() {
    return this.mapInitialized && this.isNavModeCheck(this.flags.follow);
  }

  watchHeadingStart() {
    this.headingService.watchHeading(this.platform.WEB ? 250 : 100, true);
  }

  /**
   * watch device heading using compass
   * it can be used to rotate the map accordingly
   * @param enable
   */
  watchHeading() {
    // watch heading default ts
    this.watchHeadingStart();
    this.internalFlags.isHeadingTrackEngaged = true;
    if (!this.subscription.heading) {
      let t0: number = null;
      let t1: number = null;
      this.headingService.checkCompassOrientationAvailable().then((available: boolean) => {
        if (available) {
          this.subscription.heading = this.headingService.getHeadingObservable().subscribe((status: IHeadingStatus) => {
            if (status != null) {
              // handle navigation pointer
              this.updateUserHeadingPointer(status.compassHeading);
              let statusCompassHeading: number = 0;

              if (this.internalFlags.isHeadingTrackEngaged) {
                if (status.compassHeading != null && status.compassHeading >= 0) {
                  this.showHudMessage(EMapHudCodes.compassHeading, "" + Math.floor(status.compassHeading), null);
                  statusCompassHeading = status.compassHeading;
                } else {
                  this.showHudEmptyMessage(EMapHudCodes.compassHeading);
                  statusCompassHeading = 0;
                }

                if (status.gpsHeading != null && status.gpsHeading >= 0) {
                  this.showHudMessage(EMapHudCodes.gpsHeading, "" + Math.floor(status.gpsHeading), null);
                } else {
                  this.showHudEmptyMessage(EMapHudCodes.gpsHeading);
                }

                if (status.compositeHeading != null && status.compositeHeading >= 0) {
                  this.showHudMessage(EMapHudCodes.compositeHeading, "" + Math.floor(status.compositeHeading), null);
                } else {
                  this.showHudEmptyMessage(EMapHudCodes.compositeHeading);
                }

                if (status.compassNeedsCalibrationNotify) {
                  this.headingService.clearCalibrationNotification();
                  this.messageQueueHandler.prepare("Compass needs calibration", true, EQueueMessageCode.warn);
                }

                if (this.checkCompassNavModeOrComposite()) {
                  t1 = new Date().getTime();
                  if (t0 == null || (t1 - t0) >= 200) {
                    let dt: number = t1 - t0;
                    let animationDuration: number = dt - 10;
                    if (animationDuration < 0) {
                      animationDuration = 0;
                    }
                    if (animationDuration > 200) {
                      animationDuration = 200;
                    }
                    t0 = t1;
                    let coordinates = this.currentLocation.location;
                    if (this.checkEnableGPSMapUpdate()) {
                      this.moveMapOptions(coordinates, animationDuration).then(() => {
                      }).catch((err: Error) => {
                        console.error(err);
                      });
                    }
                  }
                  this.findJoystickPosition.headingComp = statusCompassHeading;
                } else {
                  this.findJoystickPosition.headingComp = 0;
                }
              }
            }
          });
        } else {
          this.headingService.flags.useCompositeHeading = false;
          this.findJoystickPosition.headingComp = 0;
        }
      });
    }
  }

  stopWatchHeading() {
    this.headingService.stopWatchHeading();
    this.findJoystickPosition.headingComp = 0;
    this.subscription.heading = ResourceManager.clearSub(this.subscription.heading);
  }

  setMapMobile() {
    this.mapManager.setMap(this.map);
    this.markerHandler.setMap(this.map);
    this.markerHandler.setOptions({
      markerIcons: EMarkerIcons
    });
  }

  setMapWeb() {
    this.markerHandler.setMap(this.jsMap);
    this.mapManager.setJsMap(this.jsMap);
    this.markerHandler.setOptions({
      markerIcons: EMarkerIcons
    });
  }


  /**
   * check if map was already created
   * otherwise create the map
   * reuse the map during the entire application upon creation
   */
  checkMapCreate() {
    console.log("check map create");
    let promise = new Promise((resolve, reject) => {
      this.map = this.mapManager.getMap();
      if (this.map) {
        console.log("existing map.. re-attach");
        this.mapManager.reattachMapContainer("gmap").then(() => {
          resolve(true);
        }).catch((err: Error) => {
          reject(err);
        });
      } else {
        this.mapManager.createMap(this.mapElement.nativeElement).then(() => {
          this.map = this.mapManager.getMap();
          resolve(true);
        }).catch((err: Error) => {
          reject(err);
        });
      }
    });
    return promise;
  }

  /**
   * initialize the ionic native google map if available
     and then call the initialization function for the api
   */
  initMap() {
    console.log("init map");
    let promise = new Promise((resolve, reject) => {

      let afterInit = () => {
        // set initial timeout until markers can be placed on the map (prevent duplicates)
        this.markerHandler.setGlobalMarkerInitTimestamp();
        // init setup
        this.mapManager.loadPlaceMarkerNoAction();
      }

      // let style = mapStyles.aubergine;
      if (this.useNativeMap) {
        let target: ILatLng = new ILatLng(43.0741904, -89.3809802);

        if (this.currentLocation.location) {
          target.lat = this.currentLocation.location.lat;
          target.lng = this.currentLocation.location.lng;
        }

        this.mapManager.initMapCoords(target);

        this.checkMapCreate().then(() => {
          console.log("native map ready");
          afterInit();
          this.setMapMobile();

          this.initMapServices().then(async () => {
            console.log("map services ready");
            // this.map.setCompassEnabled(true);
            // this.map.setOptions(this.mapManager.mapOptions);

            await this.mapManager.setMapStyleCoreResolve(EMapStyles.leplace);

            this.mapManager.addNativeMapListeners();

            if (this.flags.disableFollowOnDrag) {

            }

            if (!this.mapSubscription.mapEvent2) {
              // CAMERA_MOVE_END
              this.mapSubscription.mapEvent2 = this.map.setOnCameraIdleListener((data: CameraIdleCallbackData) => {
                console.log("on camera idle: ", data);
                this.disableGPSHandling(3000);
                this.mapManager.setCameraStats(data);

                if (data && data.zoom) {
                  // let zoom: number = this.map.getCameraZoom();
                  this.onSetZoomLevel(data.zoom);
                }
                this.enableGPSHandling();
              });
            }

            if (!this.mapSubscription.mapEvent3) {
              // MAP_DRAG_START
              this.mapSubscription.mapEvent3 = this.map.setOnCameraMoveStartedListener((data) => {
                console.log("on camera drag start: ", data);
                if (data && data.isGesture) {
                  this.onMapDragStartWrapper();
                }
              });
            }

            if (!this.mapSubscription.mapEvent4) {
              // MAP_DRAG_END
              this.mapSubscription.mapEvent4 = this.map.setOnCameraIdleListener((data) => {
                console.log("on camera drag end: ", data);
                this.onMapDragEndWrapper();
              });
            }

            resolve(true);
          }).catch((err: Error) => {
            console.error(err);
            reject(err);
          });
        }).catch((err: Error) => {
          console.error(err);
          reject(err);
        });
      } else {
        console.log("native map not available");
        this.initMapServices().then(() => {
          afterInit();
          resolve(true);
        });
      }
    });
    return promise;
  }

  onMapDragStartWrapper() {
    console.log("map drag start");
    this.disableGPSHandling(3000);
    this.onMapDragStart();
    this.droneSimulator.disconnectMapUpdates();
  }

  onMapDragEndWrapper() {
    console.log("map drag end");
    this.enableGPSHandling();
    this.onMapDragEnd();
    this.mapManager.reposition(false).then(() => {
      this.droneSimulator.resumeMapUpdates();
    }).catch((err: Error) => {
      console.error(err);
      this.droneSimulator.resumeMapUpdates();
    });
  }

  disableGPSHandling(timeout: number) {
    this.internalFlags.mapDisableGPSHandling = true;
    this.dedicatedTimeouts.mapDisableGPSHandling = ResourceManager.clearTimeout(this.dedicatedTimeouts.mapDisableGPSHandling);
    this.dedicatedTimeouts.mapDisableGPSHandling = setTimeout(() => {
      this.internalFlags.mapDisableGPSHandling = false;
    }, timeout);
  }

  enableGPSHandling() {
    this.dedicatedTimeouts.mapDisableGPSHandling = ResourceManager.clearTimeout(this.dedicatedTimeouts.mapDisableGPSHandling);
    this.internalFlags.mapDisableGPSHandling = false;
  }

  onMapDragStart() {
    if (!this.flags.droneMode) {
      this.onMapDragNormal();
    } else {
      this.onMapDragDroneMode();
    }
  }

  onMapDragNormal() {
    // console.log("map drag");
    this.buttonOptions.pause.blink = false;
    this.buttonOptions.start.blink = false;
    this.internalFlags.canExitMap = false;
    // disable follow
    // this.setFollow(EMapInteraction.exit, true);
    this.disableFollow();
    // cancel move map back to user after showing treasures
    this.dedicatedTimeouts.goToUser = ResourceManager.clearTimeout(this.dedicatedTimeouts.goToUser);
    this.internalFlags.moveMapSequenceEnabled = false;
    // this.smartZoom.stopEventTreasuresZoom();
  }

  onMapDragEnd() {
    // let loc: ILatLng = this.mapManager.getCurrentMapLocation();
    // this.mqttService.updateStatus(EMQTTStatusKeys.mapCenter, loc);
  }

  onMapDragDroneMode() {
    // this.keyEventHandlerDroneMode(EKeyCodes.a);
  }


  onSetZoomLevel(zoom: number) {
    console.log("on set zoom level from gmap: ", zoom);

    if (this.internalFlags.eagleView) {
      // console.log("eagle view");
      zoom = MapSettings.zoomInLevelCrt;
    }

    this.mapManager.setZoom(zoom);

    if (SettingsManagerService.settings.app.settings.mapDensityFiltering.value && ([EOS.android, EOS.ios].indexOf(GeneralCache.os) !== -1)) {
      if (!this.flags.droneMode) {
        this.itemScanner.setZoomLevel(zoom, true);
        if (this.storySelected) {
          this.mapManager.showLayersBasedOnZoom(zoom);
        }
      }
    }

    if (zoom <= MapSettings.zoomOutLevel) {
      // may enable eagle view
      this.internalFlags.zoomOut = true;
    } else {
      this.internalFlags.zoomOut = false;
    }
  }

  deinitMapServices() {
    if (this.useNativeMap) {
      this.mapManager.deinitJsMap(this.auxMapElement);
    } else {
      this.mapManager.deinitJsMap(this.mapElement);
    }
  }

  /**
   * initialize the google maps javascript api for advanced functions
   */
  initMapServices() {
    // for js api (place search, etc)
    console.log("init map services");
    let promise = new Promise((resolve, reject) => {
      this.settingsProvider.checkMapReady().then(() => {
        console.log("map ready");
        if (this.useNativeMap) {
          // this.checkMapServicesReady();
          // this.mapManager.initJsMap(this.auxMapElement, false);
          // this.mapManager.createJsMap(document.createElement('div'));
          // this.jsMap = this.mapManager.getJsMap();
        } else {
          // this.jsMapOptions.styles = style;
          let mapIsInitialized: boolean = this.mapManager.initJsMap(this.mapElement, true);
          this.jsMap = this.mapManager.getJsMap();
          // check if the map was previously initialized
          if (mapIsInitialized) {
            // listener handles are removed on map destroy method
          }

          this.mapManager.addJsMapListeners(() => {
          }, () => {
            this.onMapDragStartWrapper();
          }, () => {
            this.onMapDragEndWrapper();
          }, (zoom: number) => {
            this.onSetZoomLevel(zoom);
          });

          this.setMapWeb();
        }

        // var trafficLayer = new google.maps.TrafficLayer();
        // trafficLayer.setMap(this.jsMap);

        // this.placesService = new google.maps.places.PlacesService(this.jsMap);
        this.placesService = new google.maps.places.PlacesService(this.auxMapElement.nativeElement);
        this.directionsService = new google.maps.DirectionsService();
        this.geocodeService = new google.maps.Geocoder();
        this.directionsHandler.init(this.directionsService);
        this.locationApi.setPlacesService(this.placesService);
        this.locationApi.setGeocodeService(this.geocodeService);
        resolve(true);
      }).catch((err: Error) => {
        console.error(err);
        reject(err);
      });
    });
    return promise;
  }

  getNearbyLocationDetails() {
    if (!this.storySelected) {
      return;
    }
    if (this.activityStarted.ANYTEMP) {
      console.log("get nearby location details > open for preview");
      if (this.internalFlags.nearbyStoryLocationIndex !== this.app.locationIndex) {
        // prevent confusion, if another challenge is in progress
        this.uiext.showAlertNoAction(Messages.msg.challengeInProgress.after.msg, Messages.msg.challengeInProgress.after.sub);
      } else {
        this.getCurrentLocationDetailsViewNoAction();
      }
    } else {
      console.log("get nearby location details > open for startup");
      if (this.internalFlags.manualChallengeStartRequested) {
        PromiseUtils.wrapNoAction(this.navigationHandler.confirmNavigationComplete(), true);
      } else {
        if (this.internalFlags.nearbyStoryLocationIndex != null) {
          let index: number = this.internalFlags.nearbyStoryLocationIndex;
          let loc: IAppLocation = this.app.storyLocations[index];
          let activity: IActivity = loc.loc.merged.activity;
          let activityDroneMode: number = ActivityUtils.getDroneModeAvailable(activity, this.isDroneOnly);
          this.checkDroneModeForActivity(activityDroneMode);
          this.getLocationDetailsForIndexOnClick(this.internalFlags.nearbyStoryLocationIndex, true, true, true, activityDroneMode);
        }
      }
    }
  }

  getLocationDetailsForIndexOnClick(index: number, isPreview: boolean, startReady: boolean, overrideLocationIndex: boolean, activityDroneMode: number) {
    // let moveActivity: boolean = false;
    if (!this.app.storyLocations) {
      return;
    }
    if (index >= this.app.storyLocations.length) {
      return;
    }
    console.log("opening activity: " + index + ", active: ", this.app.locationIndex);
    if (overrideLocationIndex) {
      this.app.locationIndex = index;
    }
    if (this.app.challengeInProgress) {
      startReady = false;
    } else {
      if (!startReady) {
        startReady = null; // tap on marker should open start options if in range
      }
    }
    if (activityDroneMode == null) {
      let loc: IAppLocation = this.app.storyLocations[index];
      let activity: IActivity = loc.loc.merged.activity;
      activityDroneMode = ActivityUtils.getDroneModeAvailable(activity, this.isDroneOnly);
    }
    PromiseUtils.wrapNoAction(this.getLocationDetailsViewForIndex(index, (this.app.locationIndex === index) && this.activityStarted.ANY, isPreview, startReady, overrideLocationIndex, activityDroneMode), true);
  }

  getLocationDetailsViewForIndex(index: number, inProgress: boolean, isPreview: boolean, startReady: boolean, overrideLocationIndex: boolean, activityDroneMode: number): Promise<IGmapDetailReturnParams> {
    if (index === -1) {
      return Promise.resolve(null);
    }
    if ((startReady == null) && (this.internalFlags.nearbyStoryLocationIndex === index)) {
      startReady = true; // tap on marker should open start options if in range
    }
    let opts: IGmapActivityPreviewOptions = {
      inProgress: inProgress,
      withDismiss: false,
      withDrone: activityDroneMode,
      startReady: startReady,
      isPreview: isPreview,
      isAutostart: false,
      overrideLocationIndex: overrideLocationIndex
    };
    return this.getLocationDetailsView(this.app.storyLocations[index], null, opts, null);
  }

  /**
   * select story location index, activate blink mode on story location
   * @param index 
   */
  selectStoryLocationIndex(index: number) {
    if (index < 0) {
      index = 0;
    }
    if (this.app.storyLocations) {
      if (index >= this.app.storyLocations.length) {
        index = this.app.storyLocations.length - 1;
      }
      for (let i = 0; i < this.app.storyLocations.length; i++) {
        if (i === index) {
          this.app.storyLocations[i].selected = true;
        } else {
          this.app.storyLocations[i].selected = false;
        }
      }
    }
    console.log("get location details view for index: ", index);
    this.app.locationIndex = index;
    this.app.storyLocationsActiveSlide = Math.floor(index / this.flags.itemsPerSlide);
    return this.app.locationIndex;
  }

  getCurrentLocationCheckpointWrapper() {
    if (!this.storySelected) {
      PromiseUtils.wrapNoAction(this.getLocationDetailsViewInProgress(), true);
    } else {
      this.getCurrentLocationDetailsViewNoAction();
    }
  }

  getCurrentLocationDetailsViewNoAction() {
    if (this.app.locationIndex == null) {
      return;
    }
    PromiseUtils.wrapNoAction(this.getLocationDetailsViewForIndex(this.app.locationIndex, this.activityStarted.ANY, false, false, false, null), true);
  }

  checkPreloadLocationIndex(appLocation: IAppLocation, overrideIndex: boolean) {
    if (this.isPreloadStory && !this.activityStarted.ANYTEMP) {
      // inProgress = true;
      // activate story location index for story engine
      let locindex: number = this.storyManagerService.getCurrentStoryLocationIndex(appLocation);
      if (locindex !== -1) {
        this.selectStoryLocationIndex(this.storyManagerService.getCurrentStoryLocationIndex(appLocation));
        // get latest version (some links are not updated on master)
        let applyIndex: number = overrideIndex ? locindex : this.app.locationIndex;

        appLocation = this.app.storyLocations[applyIndex];
      } else {
        appLocation = null;
      }
    }
    return appLocation;
  }


  getLocationDetailsView(appLocation: IAppLocation, treasure: ILeplaceTreasure, viewOptions: IGmapActivityPreviewOptions, startOptions: IGmapActivityStartOptions): Promise<IGmapDetailReturnParams> {
    let promise: Promise<IGmapDetailReturnParams> = new Promise(async (resolve, reject) => {
      await this.onModalOpened();
      console.log("get location details view: ", appLocation);
      console.log("from treasure: ", treasure);
      appLocation = this.checkPreloadLocationIndex(appLocation, viewOptions.overrideLocationIndex);
      let interactions: IGmapDetailInteractions = {
        includeQuestOptions: (appLocation != null) ? appLocation.loc.merged.activity.code === EActivityCodes.quest : false,
        startReady: viewOptions.startReady,
        withDismiss: viewOptions.withDismiss,
        withStartOption: this.isPreloadStory || (treasure != null), // preload story location or gmap challenge
        withDrone: viewOptions.withDrone
      };
      let fullScreen: boolean = !viewOptions.isPreview;
      fullScreen = viewOptions.inProgress;
      fullScreen = true; // override

      if (!startOptions) {
        startOptions = {};
      }
      if (viewOptions.isAutostart) {
        startOptions.autostart = true;
      }

      this.modularViews.getLocationDetailsView(this.story, appLocation, treasure, viewOptions.inProgress, interactions, fullScreen, startOptions).then((data: IGmapDetailReturnParams) => {
        console.log("returned from gmap detail: ", data);
        // check actions
        if (viewOptions.inProgress) {
          this.buttonOptions.startLoc.blinkOnChange = !this.buttonOptions.startLoc.blinkOnChange;
        }
        if (data) {
          switch (data.code) {
            case EGmapDetailReturnCode.showMapClue:
              let coords: ILatLng = data.data;
              this.showMapClue(coords.lat, coords.lng);
              break;
            case EGmapDetailReturnCode.proceed:
              if (this.storySelected && (this.isPreloadStory || viewOptions.startReady) && !viewOptions.inProgress && !this.activityStarted.ANYTEMP && appLocation) {
                let promiseCheckStart: Promise<boolean> = new Promise((resolve) => {
                  if (this.isPreloadStory) {
                    this.storyManagerService.checkStoryLocationCooldown(appLocation);
                  }
                  if (appLocation.lockedForSession) {
                    let isFind: boolean = ActivityUtils.isFindTypeActivity(appLocation.loc);
                    let message: string = isFind ? Messages.msg.storyLocationLockedCompletedNoReplay.after.msg : Messages.msg.storyLocationLockedCompleted.after.sub;
                    let sub: string = isFind ? Messages.msg.storyLocationLockedCompletedNoReplay.after.sub : Messages.msg.storyLocationLockedCompleted.after.sub;
                    let nopt: number = isFind ? 1 : 2;
                    if (appLocation.lockedMessage != null) {
                      message = Messages.msg.storyLocationLockedCompletedNoReplay.after.msg;
                      sub = appLocation.lockedMessage;
                      nopt = 1;
                    }
                    this.uiext.showAlert(message, sub, nopt, null, true).then((res: number) => {
                      resolve((isFind || appLocation.lockedForSessionNoAccess) ? false : res === EAlertButtonCodes.ok);
                    }).catch(() => {
                      resolve(false);
                    });
                  } else if (!appLocation.unlocked) {
                    this.uiext.showAlertNoAction(Messages.msg.storyLocationLockedTooFar.after.msg, Messages.msg.storyLocationLockedTooFar.after.sub);
                    resolve(false);
                  } else {
                    resolve(true);
                  }
                });

                promiseCheckStart.then((start: boolean) => {
                  if (start) {
                    this.internalFlags.showPreviewStartEnabled = false;
                    // activate non-linear story location
                    if (ActivityUtils.isFindTypeActivity(appLocation.loc)) {
                      this.setState(EGmapStates.NAVIGATE);
                    } else {
                      this.setState(EGmapStates.REACHED);
                    }
                    // set other markers locked
                  }
                });
              }
              break;
            case EGmapDetailReturnCode.skip:
              this.finalizeActivity();
              break;
            default:
              break;
          }
        }
        this.onModalClosed();
        resolve(data);
      }).catch((err: Error) => {
        this.onModalClosed();
        this.analytics.dispatchError(err, "gmap");
        reject(err);
      });
    });
    return promise;
  }

  /**
   * open challenge in progress view
   */
  getLocationDetailsViewInProgress(): Promise<IGmapDetailReturnParams> {
    let opts: IGmapActivityPreviewOptions = {
      inProgress: true,
      withDismiss: false,
      withDrone: null,
      startReady: false,
      isPreview: false,
      isAutostart: !this.internalFlags.manualChallengeStart,
      overrideLocationIndex: false
    };
    return this.getLocationDetailsView(this.challengeEntry.getCurrentAppLocation(), this.challengeEntry.getCurrentChallengeItem(), opts, null);
  }

  /**
   * add marker callback data (when the marker is clicked)
   * @param placeMarkerContent 
   * @param data 
   * @param customCallback 
   */
  getPlaceMarkerCallback(data: IAppLocation) {
    let callback = null;
    let appLocation: IAppLocation = DeepCopy.deepcopy(data);
    // let appLocation: IAppLocation = data;
    // console.log("marker content callback data: ", data);
    if (appLocation) {
      callback = (placeMarkerContent: IPlaceMarkerContent) => {
        if (!placeMarkerContent) {
          return;
        }
        let appLocationCB: IAppLocation = placeMarkerContent.data;
        if (appLocationCB.loc.index != null) {
          appLocationCB = this.app.storyLocations[appLocationCB.loc.index];
        } else {
          console.warn("null index");
          console.log("at: ", appLocationCB);
        }

        let block: boolean = (this.app.locationIndex === appLocationCB.loc.index) && this.activityStarted.ANY;
        let treasure: ILeplaceTreasure = this.challengeEntry.getCurrentChallengeItem();
        let lat: number = null;
        let lng: number = null;
        if (placeMarkerContent.location) {
          lat = placeMarkerContent.location.lat;
          lng = placeMarkerContent.location.lng;
        }
        if (placeMarkerContent.fakeLocation) {
          lat = placeMarkerContent.fakeLocation.lat;
          lng = placeMarkerContent.fakeLocation.lng;
        }

        let opts: IGmapActivityPreviewOptions = {
          inProgress: block,
          withDismiss: false,
          withDrone: null,
          startReady: false,
          isPreview: false,
          isAutostart: !this.internalFlags.manualChallengeStart,
          overrideLocationIndex: false
        };

        if ((this.internalFlags.nearbyStoryLocationIndex === appLocation.index) && !this.activityStarted.ANY && !this.activityStarted.ANYTEMP) {
          opts.startReady = true;
        }

        let loc: IAppLocation = appLocation;
        let activity: IActivity = loc.loc.merged.activity;
        opts.withDrone = ActivityUtils.getDroneModeAvailable(activity, this.isDroneOnly);

        this.checkOverlapsZoom(lat, lng).then((result: boolean) => {
          if (result) {
            console.log("opening activity: " + appLocationCB.loc.index + ", active: ", this.app.locationIndex);
            PromiseUtils.wrapNoAction(this.getLocationDetailsView(appLocationCB, treasure, opts, null), true);
          }
        }).catch((err: Error) => {
          console.error(err);
          this.analytics.dispatchError(err, "gmap");
          // fallback
          PromiseUtils.wrapNoAction(this.getLocationDetailsView(appLocationCB, treasure, opts, null), true);
        });
      };
    }

    // if (customCallback) {
    //   callback = customCallback;
    // }

    // placeMarkerContent.callback = callback;
    return callback;
  }

  mapSearch(currentLocation: ILatLng, placeSpecs: IAppLocation, excludeLocationIdList: string[] = [], fromBuffer: boolean, fromBufferDirection: number, withLoading: boolean): Promise<IAppPlaceResult> {
    let promise: Promise<IAppPlaceResult> = new Promise(async (resolve, reject) => {
      let appPlaceResult: IAppPlaceResult = {
        googlePlaceSelected: null,
        type: null,
        samePlace: false,
        fallback: false,
        skip: false
      };
      console.log("map search place specs: ", placeSpecs);
      console.log("exclude location ids", excludeLocationIdList);
      // console.log("map search, from buffer: ", fromBuffer);
      // check if fixed location
      let useSamePlace: boolean = false;
      if (placeSpecs.loc && placeSpecs.loc.merged) {
        if (placeSpecs.loc.merged.samePlace) {
          let googleId: string = null;
          if (placeSpecs.prevLoc && placeSpecs.prevLoc.merged) {
            googleId = placeSpecs.prevLoc.merged.googleId;
            // should be defined as the same type to make sense
            if (!googleId) {
              useSamePlace = false;
            } else {
              placeSpecs.loc.merged.googleId = googleId;
              useSamePlace = true;
            }
          }
          console.log("map search, same place: " + googleId);
        }
      }

      let scanSpecs: ILocationScanSpecs = this.locationApi.getScanSpecs(placeSpecs);
      let promiseSearch: Promise<IAppPlaceResult> = new Promise(async (resolve, reject) => {
        if (this.testFlags.enableRetryFallbackTest) {
          if (this.testFlags.retryCounter > 0) {
            this.testFlags.retryCounter -= 1;
            reject(new Error("retry fallback test"));
            return;
          }
        }
        if (useSamePlace) {
          console.log("map search v1");
          if (withLoading) {
            await this.uiext.showLoadingV2Queue("Loading saved place..");
          }
          this.locationApi.searchFixedLocation(currentLocation, scanSpecs, excludeLocationIdList,
            fromBuffer, fromBufferDirection, false).then((result: ILeplaceRegMulti) => {
              console.log("search fixed location: ", result);
              let selected: ILeplaceReg = (result.selected != null) ? DeepCopy.deepcopy(result.selected) : null;
              console.log("selected: ", selected);
              this.locationApi.checkFixedLocationOverrides(selected, placeSpecs);
              appPlaceResult.googlePlaceSelected = selected;
              appPlaceResult.googlePlaceArray = result.array;
              appPlaceResult.type = placeSpecs.flag;
              appPlaceResult.samePlace = true;
              this.smartZoom.updateTransition(ESmartZoomTransitions.scanFixedPlace);
              resolve(appPlaceResult);
            }).catch((err: Error) => {
              reject(err);
            });
        } else {
          switch (placeSpecs.flag) {
            case ELocationFlag.FIXED:
              console.log("map search v2");
              if (withLoading) {
                await this.uiext.showLoadingV2Queue("Loading place..");
              }
              this.locationApi.searchFixedLocation(currentLocation, scanSpecs, excludeLocationIdList,
                fromBuffer, fromBufferDirection, true).then((result: ILeplaceRegMulti) => {
                  console.log("search fixed location: ", result);
                  let selected: ILeplaceReg = (result.selected != null) ? DeepCopy.deepcopy(result.selected) : null;
                  console.log("selected: ", selected);
                  this.locationApi.checkFixedLocationOverrides(selected, placeSpecs);
                  appPlaceResult.googlePlaceSelected = selected;
                  appPlaceResult.googlePlaceArray = result.array;
                  appPlaceResult.type = placeSpecs.flag;
                  this.smartZoom.updateTransition(ESmartZoomTransitions.scanFixedPlace);
                  resolve(appPlaceResult);
                }).catch((err: Error) => {
                  reject(err);
                });
              break;
            case ELocationFlag.SAVED:
              console.log("map search v3");
              if (withLoading) {
                await this.uiext.showLoadingV2Queue("Loading saved place..");
              }
              this.locationApi.searchFixedLocation(currentLocation, scanSpecs, excludeLocationIdList,
                fromBuffer, fromBufferDirection, false).then((result: ILeplaceRegMulti) => {
                  console.log("search saved location: ", result);
                  let selected: ILeplaceReg = (result.selected != null) ? DeepCopy.deepcopy(result.selected) : null;
                  console.log("selected: ", selected);
                  this.locationApi.checkFixedLocationOverrides(selected, placeSpecs);
                  appPlaceResult.googlePlaceSelected = selected;
                  appPlaceResult.googlePlaceArray = result.array;
                  appPlaceResult.type = placeSpecs.flag;
                  this.smartZoom.updateTransition(ESmartZoomTransitions.scanFixedPlace);
                  resolve(appPlaceResult);
                }).catch((err: Error) => {
                  reject(err);
                });
              break;
            case ELocationFlag.RANDOM:
              console.log("map search v4");
              if (withLoading) {
                await this.uiext.showLoadingV2Queue("Loading new places..");
              }
              this.locationApi.searchRandomLocation(currentLocation, scanSpecs, excludeLocationIdList,
                fromBuffer, fromBufferDirection).then((result: ILeplaceRegMulti) => {
                  console.log("search random location: ", result);
                  let selected: ILeplaceReg = (result.selected != null) ? DeepCopy.deepcopy(result.selected) : null;
                  console.log("selected: ", selected);
                  appPlaceResult.googlePlaceSelected = selected;
                  appPlaceResult.googlePlaceArray = result.array;
                  appPlaceResult.type = placeSpecs.flag;
                  this.smartZoom.updateTransition(ESmartZoomTransitions.scanRandomPlace);
                  resolve(appPlaceResult);
                }).catch((err: Error) => {
                  reject(err);
                });
              break;
            default:
              console.log("map search unknown request");
              reject(new Error("map search unknown request"));
              break;
          }
        }
      });

      promiseSearch.then((res) => {
        resolve(res);
      }).catch((err) => {
        console.error(err);
        if ([ELocationFlag.FIXED, ELocationFlag.SAVED].indexOf(placeSpecs.flag) !== -1) {
          let stat = this.locationApi.handlePlaceErrorStat();
          if (stat && stat.fallbackAllowed) {
            // last fallback to random location
            this.locationApi.searchRandomLocation(currentLocation, scanSpecs, excludeLocationIdList,
              fromBuffer, fromBufferDirection).then((result: ILeplaceRegMulti) => {
                console.log("search random location: ", result);
                appPlaceResult.googlePlaceSelected = result.selected;
                appPlaceResult.googlePlaceArray = result.array;
                appPlaceResult.type = placeSpecs.flag;
                appPlaceResult.fallback = true;
                this.smartZoom.updateTransition(ESmartZoomTransitions.scanRandomPlace);
                resolve(appPlaceResult);
              }).catch((err: Error) => {
                console.error(err);
                // reject(err);
                // no further fallback allowed
                appPlaceResult.skip = true;
                resolve(appPlaceResult);
              });
          } else {
            // no fallback allowed
            appPlaceResult.skip = true;
            resolve(appPlaceResult);
          }
        } else {
          reject(err);
        }
      });
    });
    // https://developers.google.com/maps/documentation/javascript/3.exp/reference#PlaceSearchRequest
    return promise;
  }

  clearLastPlace(clearData: boolean) {
    this.markerHandler.clearLastArrayMarker(EMarkerLayers.PLACES, clearData);
  }

  reloadLastStoryMarker(toggle: boolean, lock: boolean, customLabel: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let apploc: IAppLocation = this.app.storyLocations[this.app.locationIndex];
        let currentLocationIndex: number = this.app.locationIndex;
        if (toggle) {
          apploc.loc.merged.done = (apploc.loc.merged.done !== EStoryLocationDoneFlag.done) ? EStoryLocationDoneFlag.done : EStoryLocationDoneFlag.pending;
        }
        let isDone: boolean = apploc.loc.merged.done === EStoryLocationDoneFlag.done;
        let addLabel: string = "" + (currentLocationIndex + 1);
        if (isDone) {
          addLabel += (customLabel != null ? customLabel : ECheckpointMarkerStatus.done);
        } else {
          addLabel += (customLabel != null ? customLabel : "");
        }
        if (lock == null) {
          lock = isDone;
        }
        await this.storyManagerService.updateStoryMarker(this.app.locationIndex, lock, isDone, addLabel);
        resolve(true);
      } catch (err) {
        reject(err);
      }
    });
  }

  showNewPlacesRetry(placeMarkerData: IPlaceMarkerContent[], placeMarkerAuxData: IPlaceMarkerContent[], removePrevious: boolean, waitForAuxMarkers: boolean) {
    return FallbackUtils.retry(() => {
      return this.showNewPlaces(placeMarkerData, placeMarkerAuxData, removePrevious, waitForAuxMarkers);
    }, 3, 3000, () => {
      return new Promise<boolean>((resolve) => {
        this.uiext.showAlert(Messages.msg.networkErrorRetry.after.msg, Messages.msg.networkErrorRetry.after.sub, 1, null, false).then((res: number) => {
          if (res === EAlertButtonCodes.ok) {
            resolve(true);
          } else {
            resolve(false);
          }
        }).catch(() => {
          resolve(false);
        });
      })
    }, () => {
      this.uiext.showLoadingV2Queue("Retry loading..");
    });
  }

  /**
   * prepare map to show the new layer of places
   * it removes the old layer (waypoints, aux places, circles)
   * it removes the current place marker (places) but only from view, so that the history is kept (story places)
   * then it shows the new places (the old places will be re-drawn)
   * @param placeMarkerData 
   * @param placeMarkerAuxData 
   * @param removePrevious 
   */
  showNewPlaces(placeMarkerData: IPlaceMarkerContent[], placeMarkerAuxData: IPlaceMarkerContent[], removePrevious: boolean, waitForAuxMarkers: boolean) {
    // console.log("show new places: ", placeMarkerData);
    let promise = new Promise(async (resolve, reject) => {
      // this.Markers.clearMarkers(EMarkerLayers.places);
      let clearMarkerList: string[] = [EMarkerLayers.MAIN_PATH, EMarkerLayers.WAYPOINTS, EMarkerLayers.TESTS, EMarkerLayers.PLACES_AUX, EMarkerLayers.FIND_CIRCLES, EMarkerLayers.CIRCLE_AUX_MARKERS, EMarkerLayers.MARKER_CIRCLES];
      let clearLastMarkerList: string[] = [EMarkerLayers.PLACES];
      for (let e of clearMarkerList) {
        await this.markerHandler.disposeLayerResolve(e);
      }
      if (placeMarkerData.length > 0) {
        // remove previous place, but just the marker (it will be shown again)
        if (removePrevious) {
          for (let e of clearLastMarkerList) {
            this.markerHandler.clearLastArrayMarker(e, true);
          }
        }
        // add current place list
        try {
          if (waitForAuxMarkers) {
            await this.markerHandler.insertMultipleMarkers(placeMarkerData.concat(placeMarkerAuxData), true);
            console.log("place and aux markers added");
          } else {
            await this.markerHandler.insertMultipleMarkers(placeMarkerData, true);
            this.markerHandler.insertMultipleMarkers(placeMarkerAuxData, true).then(() => {
              console.log("aux markers added");
              this.buttonOptions.zoom.blinkOnChange = !this.buttonOptions.zoom.blinkOnChange;
            }).catch((err) => {
              console.error(err);
            });
          }
          resolve(true);
        } catch (e) {
          console.error(e);
          reject(e);
        }
      } else {
        resolve(true);
      }
    });
    return promise;
  }

  getSelectedPlaceMessage(result: IAppPlaceResult, selectedPlace: ILeplaceReg) {
    let selectedIndex = 1; // 1 based, for display
    for (let i = 0; i < result.googlePlaceArray.length; i++) {
      if (result.googlePlaceArray[i].place.googleId === selectedPlace.place.googleId) {
        selectedIndex = i + 1;
        break;
      }
    }
    this.app.msg = "" + selectedIndex + "/" + result.googlePlaceArray.length;
  }

  /**
   * show next location
   * search if exact location not defined
   * Note: Does not dismiss loading (should be dismissed from caller)
   */
  selectPlace(placeSpecs: IAppLocation, excludeLocationIdList: string[], fromBuffer: boolean, fromBufferDirection: number): Promise<IAppPlaceResult> {
    console.log("select place, exclude locations: ", excludeLocationIdList);
    let promise: Promise<IAppPlaceResult> = new Promise((resolve, reject) => {
      if (this.testFlags.enableRetryFallbackTest) {
        this.testFlags.retryCounter = 2;
      }
      FallbackUtils.retry(() => {
        return this.mapSearch(this.currentLocation.location, placeSpecs, excludeLocationIdList, fromBuffer, fromBufferDirection, true);
      }, 3, 5000, () => {
        return new Promise<boolean>((resolve) => {
          this.uiext.showAlert(Messages.msg.networkErrorRetry.after.msg, Messages.msg.networkErrorRetry.after.sub, 1, null, false).then((res: number) => {
            if (res === EAlertButtonCodes.ok) {
              resolve(true);
            } else {
              resolve(false);
            }
          }).catch(() => {
            resolve(false);
          });
        })
      }, () => {
        this.uiext.showLoadingV2Queue("Retry loading..");
      }).then(async (result: IAppPlaceResult) => {
        if ((result.fallback && (result.googlePlaceSelected == null)) || result.skip) {
          // no result, skip
          resolve(result);
          return;
        }
        // console.log("nearby search done");
        // console.log(result, placeSpecs);
        let selectedPlace: ILeplaceReg = result.googlePlaceSelected;
        placeSpecs.loc.merged.dynamic = selectedPlace.registeredBusiness ? 1 : 0; // set dynamic flag if registered location found through random search
        // update story location details

        let getPhotoOptions: IGetPhotoOptions = {
          noPlaceholder: true,
          redirect: true,
          cacheDisk: true,
          useGeneric: SettingsManagerService.settings.app.settings.useDefaultPlacePhotos.value
        };

        let useDefaultPhoto: boolean = SettingsManagerService.settings.app.settings.useDefaultPlacePhotos.value;

        placeSpecs = LocationUtils.updateAppLocation(placeSpecs, selectedPlace, true, getPhotoOptions);
        LocationUtils.selectPlaceDispPhoto(placeSpecs.loc, null, {
          hidden: useDefaultPhoto ? true : null
        });

        // update place marker data details
        let placeMarkerData: IPlaceMarkerContent = MarkerUtils.getPlaceMarkerDataFromPlaceResult(placeSpecs, selectedPlace, false);
        MarkerUtils.setMarkerDisplayOptions(placeMarkerData, EMarkerScope.place);
        placeMarkerData.callback = this.getPlaceMarkerCallback(placeSpecs);
        placeSpecs = LocationUtils.setPlaceMarker(placeSpecs, placeMarkerData);

        this.app.auxPlaceMarkers = [];

        let showAlternatives: boolean = true;
        if (ActivityUtils.isFindTypeActivity(placeMarkerData.data.loc) || ActivityUtils.isHiddenLoc(placeMarkerData.data.loc)) {
          showAlternatives = false;
        }
        if (result.googlePlaceArray && result.googlePlaceArray.length && showAlternatives) {
          this.user.hasScanned = true; // zoom to fit all scanned places

          for (let i = 0; i < result.googlePlaceArray.length && i < AppConstants.gameConfig.maxPlaceMarkers; i++) {
            let googlePlaceAux: ILeplaceReg = result.googlePlaceArray[i];

            let placeSpecsAux: IAppLocation = LocationUtils.updateAppLocation(placeSpecs, googlePlaceAux, false, getPhotoOptions);
            LocationUtils.selectPlaceDispPhoto(placeSpecsAux.loc, null, {
              hidden: useDefaultPhoto ? true : null
            });

            let placeMarkerAuxData: IPlaceMarkerContent = MarkerUtils.getPlaceMarkerDataFromPlaceResult(placeSpecsAux, googlePlaceAux, false);
            MarkerUtils.setMarkerDisplayOptions(placeMarkerAuxData, EMarkerScope.auxPlace, false);
            placeMarkerAuxData.heading = null;
            placeMarkerAuxData.callback = this.getPlaceMarkerCallback(placeSpecsAux);
            placeMarkerAuxData.layer = EMarkerLayers.PLACES_AUX;

            if (placeMarkerAuxData.data.loc.merged.googleId !== placeMarkerData.data.loc.merged.googleId) {
              this.app.auxPlaceMarkers.push(placeMarkerAuxData);
            }

            // replace callback for aux markers
            placeMarkerAuxData.callback = () => {
              // set this as selected location
              // this.setMarkerMode(placeMarkerAuxData, MarkerTypes.canvas, null);
              // make it the selected marker, remove it from the current aux array
              // disable changing the destination while doing the following activities: find, explore
              let keys = Object.keys(this.activityStarted);
              let canSelect: boolean = true;
              for (let i = 0; i < keys.length; i++) {
                if (this.activityStarted[keys[i]]) {
                  canSelect = false;
                  break;
                }
              }
              if (canSelect) {
                let placeMarkerArrayAux2: IPlaceMarkerContent[] = [];
                for (let j = 0; j < this.app.auxPlaceMarkers.length; j++) {
                  let p: IPlaceMarkerContent = this.app.auxPlaceMarkers[j];
                  if (p.data.loc.merged.googleId !== placeMarkerAuxData.data.loc.merged.googleId) {
                    MarkerUtils.setMarkerDisplayOptions(p, EMarkerScope.auxPlace, false);
                    p.layer = EMarkerLayers.PLACES_AUX;
                    placeMarkerArrayAux2.push(p);
                  }
                }
                // add the current place marker to the aux markers as it will be replaced by the clicked marker
                // update story location details
                LocationUtils.updateAppLocation(placeSpecs, googlePlaceAux, true, getPhotoOptions);
                LocationUtils.selectPlaceDispPhoto(placeSpecs.loc, null, {
                  hidden: useDefaultPhoto ? true : null
                });

                // update place marker data details
                placeMarkerData = MarkerUtils.getPlaceMarkerDataFromPlaceResult(placeSpecs, googlePlaceAux, false);
                MarkerUtils.setMarkerDisplayOptions(placeMarkerData, EMarkerScope.place, false);
                placeMarkerData.callback = this.getPlaceMarkerCallback(placeSpecs);
                placeMarkerData.layer = EMarkerLayers.PLACES;
                // console.log("new place marker: ", placeMarkerData);
                // this.scanPlaces(false);     
                placeSpecs = LocationUtils.setPlaceMarker(placeSpecs, placeMarkerData);

                let markers: IPlaceMarkerContent[] = this.getShownMarkersForPlace(placeMarkerData, false);
                PromiseUtils.wrapResolve(this.uiext.showLoadingV2Queue("Loading new destination.."), true).then(() => {
                  this.showNewPlacesRetry(markers, placeMarkerArrayAux2, true, this.flags.waitForAuxMarkers).then(() => {
                    // this.scanPlaces(false);
                    this.recalculateDirections();
                    // save the place
                    // this.uiext.showLoadingV2Queue("Updating location..");
                    this.storyDataProvider.saveLocation(this.story, this.app.locationIndex, placeSpecs.loc.ext, true).then(() => {
                      this.uiext.dismissLoadingV2();
                      if (placeSpecs.loc.merged.flag !== ELocationFlag.FIXED) {
                        // show xp reward
                        PromiseUtils.wrapNoAction(this.mapGeneralUtils.showRewardResolve(GameStatsUtils.getGradedStatAdjusted(EStatCodes.placesDiscovered, null, null, null, this.story != null ? this.story.xpScaleFactor : null, true), true, this.internalFlags.levelUpPopups, this.isWorldMap), true);
                      }
                    }).catch((err: Error) => {
                      console.error(err);
                      this.uiext.dismissLoadingV2();
                    });
                  }).catch((err: Error) => {
                    console.error(err);
                    this.uiext.dismissLoadingV2();
                  });
                });
              } else {
                this.uiext.showAlertNoAction(Messages.msg.cannotSelectNewDestination.after.msg, Messages.msg.cannotSelectNewDestination.after.sub);
              }
            };
          }
        }

        let markers: IPlaceMarkerContent[] = this.getShownMarkersForPlace(placeMarkerData, false);
        console.log("show markers: ", markers);

        await this.uiext.showLoadingV2Queue("Placing destination..");
        this.showNewPlacesRetry(markers, this.app.auxPlaceMarkers, false, this.flags.waitForAuxMarkers).then(async () => {
          console.log("show new places resolved");
          // save the place
          await this.uiext.showLoadingV2Queue("Updating location..");
          try {
            await this.storyDataProvider.saveLocation(this.story, this.app.locationIndex, placeSpecs.loc.ext, true);
            // this.uiext.dismissLoadingV2();
            if (placeSpecs.loc.merged.flag !== ELocationFlag.FIXED) {
              // show xp reward
              PromiseUtils.wrapNoAction(this.mapGeneralUtils.showRewardResolve(GameStatsUtils.getGradedStatAdjusted(EStatCodes.placesDiscovered, null, null, null, this.story != null ? this.story.xpScaleFactor : null, true), true, this.internalFlags.levelUpPopups, this.isWorldMap), true);
            }
          } catch (err) {
            console.error(err);
            // this.uiext.dismissLoadingV2();
          }
          resolve(result);
        }).catch((err: Error) => {
          console.log("show new places error");
          console.error(err);
          reject(err);
        });
      }).catch((err: Error) => {
        console.error(err);
        reject(err);
      });
    });
    return promise;
  }

  /**
   * get the list of shown markers for a given location
   * checks if the activity is find then creates a circle marker
   * @param placeMarkerData 
   */
  getShownMarkersForPlace(placeMarkerData: IPlaceMarkerContent, isDone: boolean) {
    let markers: IPlaceMarkerContent[] = [placeMarkerData];
    if ((ActivityUtils.isFindTypeActivity(placeMarkerData.data.loc) || ActivityUtils.isHiddenLoc(placeMarkerData.data.loc)) && !isDone) {
      let activityParams: IFindActivityDef = placeMarkerData.data.loc.merged.activity.params;
      let offsetCenter: ILatLng = null;
      placeMarkerData.extRadius = activityParams.circleRadius;
      if (placeMarkerData.data.keepOffsetCenter && placeMarkerData.fakeLocation) {
        offsetCenter = placeMarkerData.fakeLocation;
      }
      let fm: IFindMarkers = this.findActivityProvider.getFindMarkers(placeMarkerData, activityParams.circleRadius, offsetCenter);
      fm.circleAuxMarker.callback = (data: IPlaceMarkerContent) => {
        this.getMarkerCallback(data, { title: "Search Zone", heading: null, description: "Find the hidden place", large: false });
      };
      // offset center is now set
      // circle is offset from target destination
      offsetCenter = fm.circleMarker.location;
      this.app.offsetCenter = offsetCenter;
      markers.push(fm.circleMarker);
      if (this.isPreloadStory) {
        markers.push(fm.circleAuxMarker);
      }
    }
    return markers;
  }

  toggleLastMarkerDisplayMode(index: number, checked: boolean) {
    let sloc: IAppLocation = this.app.storyLocations[index];
    console.log("lock story marker: ", sloc);
    if (!(sloc && sloc.placeMarker)) {
      console.warn("lock story marker undefined");
      return;
    }
    MarkerUtils.setMarkerDisplayOptions(sloc.placeMarker, EMarkerScope.place, checked);
    MarkerUtils.setMarkerSpecialModeChecked(sloc, sloc.placeMarker, checked);
    console.log("toggle last marker display mode: ", DeepCopy.deepcopy(sloc.placeMarker));
    return this.markerHandler.updateArrayMarkerCore(EMarkerLayers.PLACES, sloc.placeMarker);
  }

  toggleLastMarkerDisplay(show: boolean) {
    this.markerHandler.toggleLastArrayMarkerShow(EMarkerLayers.PLACES, show);
  }

  toggleStoryLocationMarkerDisplay(storyLocationId: number, show: boolean) {
    this.markerHandler.toggleStoryMarkerShow(EMarkerLayers.PLACES, storyLocationId, show);
  }

  /**
   * move map to show destination
   * consider the case where the place is hidden
   * then center on fake center
   */
  showDestination(): Promise<boolean> {
    let pos: ILatLng;
    let ok: boolean = false;

    let promise: Promise<boolean> = new Promise((resolve, reject) => {
      // pos = this.Markers.getMarkerLocationByIndex(null, EMarkerLayers.places);
      let mkdata = this.markerHandler.getMarkerDataMultiLayer(EMarkerLayers.PLACES);
      console.log(mkdata);
      let marker: IPlaceMarkerContent = this.markerHandler.getMarkerDataByIndex(null, EMarkerLayers.PLACES);
      if (!marker) {
        reject(new Error("next destination is not available"));
        return;
      }
      pos = marker.fakeLocation ? marker.fakeLocation : marker.location;
      // console.log(marker, pos);
      if (pos != null) {
        ok = true;
      }
      console.log("show destination: ", pos);
      if (ok) {
        this.mapManager.moveMapWrapper(pos, {
          animateCamera: true,
          animateMarker: false,
          zoom: MapSettings.zoomInLevelCrt,
          bearing: null,
          tilt: null,
          force: false,
          moveMap: true,
          userMarker: false,
          duration: this.flags.animationDuration
        }).then(() => {
          // console.log("set zoom level (4)");
          this.onSetZoomLevel(MapSettings.zoomInLevelCrt);
          resolve(true);
        }).catch((err: Error) => {
          console.error(err);
          reject(err);
        });
      } else {
        reject(new Error("next destination is not available"));
      }
    });
    return promise;
  }

  /**
   * move map back to user
   */
  goToUser(customZoom: number, force: boolean): Promise<boolean> {
    let zoom: number = null;
    if (this.flags.zoomToUserAfterFitBounds) {
      zoom = MapSettings.zoomInLevelCrt;
    }
    if (customZoom != null) {
      zoom = customZoom;
    }

    let promise: Promise<boolean> = new Promise((resolve) => {
      this.mapManager.moveMapWrapper(this.currentLocation.location, {
        animateCamera: true,
        animateMarker: false,
        bearing: null,
        tilt: null,
        zoom: zoom,
        force: force,
        moveMap: true,
        userMarker: true,
        duration: this.flags.animationDuration
      }).then(() => {
        resolve(true);
      }).catch((err: Error) => {
        console.error(err);
        this.analytics.dispatchError(err, "gmap");
        // reject(err);
        resolve(false);
      });
    });
    return promise;
  }

  /**
   * move map to fit bounds, e.g. waypoints
   */
  moveMapToFitWaypoints(): Promise<boolean> {
    let wpoints: ILatLng[] = [];
    this.locationData.waypoints.forEach((wpt) => {
      wpoints.push(wpt.coords);
    });
    return this.mapManager.moveMapToFitBounds(wpoints, this.flags.animationDuration, true, true);
  }

  moveMapToFitTreasures(includeUserLocation: boolean): Promise<boolean> {
    let wpoints: ILatLng[] = [];
    let pm: IPlaceMarkerContent[] = this.itemScanner.getAttachedTreasurePlaceMarkers();
    wpoints = pm.map(pm1 => pm1.location);

    if (includeUserLocation && this.currentLocation) {
      wpoints.push(this.currentLocation.location);
    }
    // console.log("move map to fit treasures: ", wpoints);
    return this.mapManager.moveMapToFitBounds(wpoints, this.flags.animationDuration, true, true);
  }

  /**
   * move map to fit scanned places, e.g. aux places
   */
  moveMapToFitScannedPlaces(): Promise<boolean> {
    let wpoints: ILatLng[] = [];
    this.app.auxPlaceMarkers.forEach((auxPlaceMarker: IPlaceMarkerContent) => {
      wpoints.push(auxPlaceMarker.location);
    });
    return this.mapManager.moveMapToFitBounds(wpoints, this.flags.animationDuration, true, true);
  }

  /**
   * move map to fit all story locations
   */
  moveMapToFitStoryLocations(): Promise<boolean> {
    let wpoints: ILatLng[] = [];
    this.app.storyLocations.forEach((sloc: IAppLocation) => {
      let auxPlaceMarker: IPlaceMarkerContent = sloc.placeMarker;
      wpoints.push(auxPlaceMarker.location);
    });
    return this.mapManager.moveMapToFitBounds(wpoints, this.flags.animationDuration, true, true);
  }

  getNextLocationCoords() {
    let marker: IPlaceMarkerContent = this.markerHandler.getMarkerDataByIndex(null, EMarkerLayers.PLACES);
    let locationCoords: ILatLng = null;
    let locationFakeCoords: ILatLng = null;
    if (marker != null) {
      // locationCoords = marker.fakeLocation ? marker.fakeLocation : marker.location;
      locationCoords = marker.location;
      locationFakeCoords = marker.fakeLocation;
    } else {
      // fallback
      let loc: IAppLocation = this.app.storyLocations[this.app.locationIndex];
      locationCoords = new ILatLng(loc.loc.merged.lat, loc.loc.merged.lng);
    }
    return [locationCoords, locationFakeCoords];
  }

  /**
   * show directions to next found location
   * if the location is hidden (find activity) show directions to the fake location
   */
  showDirectionsToNextLocation(show: boolean): Promise<boolean> {
    let locationCoords: ILatLng[] = this.getNextLocationCoords();
    let promise: Promise<boolean> = new Promise(async (resolve, reject) => {
      try {
        if (!this.internalFlags.directionsEnabled) {
          console.log("skip directions");
          resolve(false);
          return;
        }
        if (locationCoords != null) {
          await this.uiext.showLoadingV2Queue("Loading directions..");
          await this.showDirectionsRetry(this.currentLocation.location, locationCoords[0], locationCoords[1], show);
          await this.uiext.dismissLoadingV2();
          resolve(true);
        } else {
          await this.uiext.dismissLoadingV2();
          reject(new Error("destination not available"));
        }
      } catch (err) {
        await this.uiext.dismissLoadingV2();
        reject(err);
      }
    });
    return promise;
  }

  checkDirectionsDistance(start: ILatLng, end: ILatLng) {
    return new Promise<boolean>(async (resolve) => {
      let dist: number = GeometryUtils.getDistanceBetweenEarthCoordinates(start, end, 0);
      console.log("show directions check distance: ", dist);
      if (dist > AppConstants.gameConfig.directionsMaxDistance) {
        await this.uiext.dismissLoadingV2();
        await this.uiext.showAlert(Messages.msg.destinationTooFarAwayForDirections.after.msg, Messages.msg.destinationTooFarAwayForDirections.after.sub, 1, null, true);
        resolve(false);
      } else {
        resolve(true);
      }
    });
  }

  showDirectionsRetry(start: ILatLng, realEnd: ILatLng, fakeEnd: ILatLng, show: boolean) {
    return new Promise((resolve, reject) => {
      if (this.testFlags.enableRetryFallbackTest) {
        this.testFlags.retryCounter = 2;
      }
      this.checkDirectionsDistance(start, realEnd).then((directionsOk: boolean) => {
        if (directionsOk) {
          FallbackUtils.retry(() => {
            return this.showDirections(start, realEnd, fakeEnd, show);
          }, 3, 5000, () => {
            return new Promise<boolean>((resolve) => {
              this.uiext.showAlert(Messages.msg.networkErrorRetry.after.msg, Messages.msg.networkErrorRetry.after.sub, 1, null, false).then((res: number) => {
                if (res === EAlertButtonCodes.ok) {
                  resolve(true);
                } else {
                  resolve(false);
                }
              }).catch(() => {
                resolve(false);
              });
            });
          }, () => {
            this.uiext.showLoadingV2Queue("Retry loading..");
          }).then((res: boolean) => {
            resolve(res);
          }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "gmap");
            reject(err);
          });
        } else {
          this.internalFlags.postponeDirectionsRequest = true;
          resolve(false);
        }
      });
    });
  }


  getDirectionsForExploreActivityTest() {
    this.directionsHandler.getDirectionsForActivity(EActivityDirectionsMode.multi, this.currentLocation.location, 100, null).then(() => {
      console.log("done");
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * use the google maps api to get directions from A to B
   * reset the waypoint index for navigation
   * @param start
   * @param end
   */
  showDirections(start: ILatLng, realEnd: ILatLng, fakeEnd: ILatLng, show: boolean): Promise<boolean> {
    console.log("show directions");
    let promise: Promise<boolean> = new Promise(async (resolve, reject) => {
      if (start == null || realEnd == null) {
        console.log("end points undefined");
        reject(new Error("direction end points undefined"));
        return;
      }

      if (this.testFlags.enableRetryFallbackTest) {
        if (this.testFlags.retryCounter > 0) {
          this.testFlags.retryCounter -= 1;
          reject(new Error("retry fallback test"));
          return;
        }
      }

      let directionsOk: boolean = await this.checkDirectionsDistance(start, realEnd);
      if (!directionsOk) {
        resolve(false);
        return;
      }

      // the actual end that will be displayed on the map
      // if there is a fake end (e.g. find activity) then display that, but navigate to the real end instead
      let directionsEndDisp: ILatLng = fakeEnd != null ? fakeEnd : realEnd;
      let wpoints: ILatLng[] = [];

      // check waypoints  
      let fc: IFixedCoin[] = [];
      if (this.app.storyLocations != null && this.app.locationIndex !== -1) {
        let loc = this.app.storyLocations[this.app.locationIndex];
        fc = StoryUtils.getFixedCoins(loc, true);
      }
      let req: google.maps.DirectionsRequest = {
        origin: start,
        destination: directionsEndDisp,
        // travelMode: (this.story && this.story.navMode === ECheckpointNavMode.auto) ? google.maps.TravelMode.BICYCLING : google.maps.TravelMode.WALKING
        travelMode: google.maps.TravelMode.WALKING
        // bicycling directions request does not always work, so we stick to walking directions
      };
      if (fc != null && fc.length > 0) {
        fc = fc.slice(0, 10);  // max 10 waypoints
        req.waypoints = fc.map(fcoin => {
          let wp: google.maps.DirectionsWaypoint = {
            location: new google.maps.LatLng(fcoin.lat, fcoin.lng)
          };
          return wp;
        });
      }
      console.log("get directions request: ", req);
      try {
        this.directionsService.route(req, async (response, status) => {
          this.locationData.waypoints = [];
          // clear previous nav
          await this.clearNav();
          this.locationData.waypointIndex = 0;
          if (status === google.maps.DirectionsStatus.OK) {
            let route = response.routes[0].overview_path;
            // calculate path bounds
            let bounds = new google.maps.LatLngBounds();
            for (let i = 0; i < route.length; i++) {
              bounds.extend(route[i]);
            }
            // add waypoints to array           
            route.forEach(element => {
              wpoints.push(new ILatLng(element.lat(), element.lng()));
              let waypoint: IWaypoint = {
                coords: new ILatLng(element.lat(), element.lng()),
                visited: false
              };
              this.locationData.waypoints.push(waypoint);
              this.locationData.bounds = bounds;
            });
          } else {
            // no directions were returned from the google api
            // add simple line
            // add waypoints to array          
            wpoints.push(start);
            wpoints.push(directionsEndDisp);

            let bounds = new google.maps.LatLngBounds();
            for (let i = 0; i < wpoints.length; i++) {
              bounds.extend(wpoints[i]);
            }

            wpoints.forEach(element => {
              let waypoint: IWaypoint = {
                coords: element,
                visited: false
              };
              this.locationData.waypoints.push(waypoint);
              this.locationData.bounds = bounds;
            });
            // reject(status);
          }
          this.navigationHandler.setWaypoints(this.locationData.waypoints);
          let waypointCoords: ILatLng[] = this.navigationHandler.getWaypointCoords();

          let layer: string = EMarkerLayers.MAIN_PATH;
          let pd: IPathContent = {
            arrows: false,
            waypoints: [start, directionsEndDisp],
            distanceLabels: true,
            path: layer,
            callback: null
          };

          await this.markerHandler.updatePath(waypointCoords, layer, pd, show, false);
          await this.navigationHandler.showMilestones(pd, EMarkerLayers.WAYPOINTS, wpoints);

          if (this.flags.showTestMarkers) {
            let markerContentArray: IPlaceMarkerContent[] = [];
            let index: number = 0;
            for (let wp of this.locationData.waypoints) {
              markerContentArray.push(MarkerUtils.getWaypointMarkerData(wp.coords, "" + index + "/" + this.locationData.waypoints.length));
              index++;
            }
            await this.markerHandler.insertMultipleMarkers(markerContentArray, this.flags.showTestMarkers);
          }
          await this.uiext.dismissLoadingV2();
          resolve(true);
        });
      } catch (err) {
        await this.uiext.dismissLoadingV2();
        reject(err);
      }
    });
    return promise;
  }

  /**
   * clear nav gauge and sub
   */
  async clearNav() {
    this.subscription.navigate = ResourceManager.clearSub(this.subscription.navigate);
    this.navGauge.reset();
    this.flags.showFindJoystick = false;
    await this.navigationHandler.deinit();
    await this.markerHandler.disposeLayerResolve(EMarkerLayers.WAYPOINTS);
    await this.markerHandler.disposeLayerResolve(EMarkerLayers.MAIN_PATH);
  }

  /**
   * init find activity challenge
   * w/ location scan, show directions, init find activity core
   * @param activityParams 
   * @param customParams 
   * @param specificFindActivityCode 
   */
  initFindChallengeActivityWrapper(activity: IActivity, activityParams: IFindActivityDef, customParams: ICustomParamForActivity[], specificFindActivityCode: number, preparedFindSpecs: IFindSpecsMpSyncData, onSearchAreaReachedAddon: () => any): Promise<IFindActivityResult> {
    let promise: Promise<IFindActivityResult> = new Promise(async (resolve, reject) => {
      let dest: ILatLng = null;

      console.log("init find challenge activity wrapper: ", activity);
      let rtype: string = await this.placesDataProvider.getRandomPlaceType();
      let standard: number[] = this.placesDataProvider.getDefaultPlaceStandard(rtype);

      let scanSpecs: ILocationScanSpecs = {
        fixedName: null,
        type: rtype,
        standardLow: standard[0],
        standardHigh: standard[1],
        googleId: null,
        providerCode: EPlaceUnifiedSource.google,
        radius: null,
        minDistance: 2 * activityParams.circleRadius,
        locationId: null
      };

      let appPlaceResult: IAppPlaceResult = {
        googlePlaceSelected: null,
        type: null,
        fallback: false,
        skip: false
      };

      let promiseLocation: Promise<ILeplaceRegMulti>;

      if (preparedFindSpecs) {
        scanSpecs.googleId = preparedFindSpecs.googleId;
        promiseLocation = this.locationApi.searchFixedLocation(this.currentLocation.location, scanSpecs, this.excludeLocationIdList, false, null, true);
      } else {
        promiseLocation = this.locationApi.searchRandomLocation(this.currentLocation.location, scanSpecs, this.excludeLocationIdList, false, null);
      }

      promiseLocation.then((result: ILeplaceRegMulti) => {
        console.log("search random location: ", result);
        appPlaceResult.googlePlaceSelected = result.selected;
        appPlaceResult.googlePlaceArray = result.array;
        appPlaceResult.type = ELocationFlag.RANDOM;
        let loc: IPlaceExtContainer = result.selected.place;
        dest = new ILatLng(loc.lat, loc.lng);
        this.smartZoom.updateTransition(ESmartZoomTransitions.scanFixedPlace);
        let selectedPlace: ILeplaceReg = result.selected;

        // update place marker data details
        let appLocation: IAppLocation = LocationUtils.createNewAppLocationFromLeplaceReg(dest, selectedPlace, activity);
        console.log("create new app location: ", appLocation);
        let useDefaultPhoto: boolean = SettingsManagerService.settings.app.settings.useDefaultPlacePhotos.value;

        let getPhotoOptions: IGetPhotoOptions = {
          noPlaceholder: true,
          redirect: true,
          cacheDisk: true,
          useGeneric: useDefaultPhoto
        };

        appLocation = LocationUtils.updateAppLocation(appLocation, selectedPlace, true, getPhotoOptions);
        appLocation.loc.dispPhoto.photoUrl = appLocation.loc.merged.photoUrl;

        let placeMarkerData: IPlaceMarkerContent = MarkerUtils.getPlaceMarkerDataFromPlaceResult(appLocation, selectedPlace, false);
        MarkerUtils.setMarkerDisplayOptions(placeMarkerData, EMarkerScope.place);
        // placeMarkerData.callback = this.getPlaceMarkerCallback(placeSpecs);
        let markers: IPlaceMarkerContent[] = [placeMarkerData];
        let offsetCenter: ILatLng = null;

        if (preparedFindSpecs) {
          if (preparedFindSpecs.offsetCenter) {
            offsetCenter = new ILatLng(preparedFindSpecs.offsetCenter.lat, preparedFindSpecs.offsetCenter.lng);
          }
        }

        console.log("find activity params: ", activityParams);

        let fm: IFindMarkers = this.findActivityProvider.getFindMarkers(placeMarkerData, activityParams.circleRadius, offsetCenter);
        // offset center is now set
        // circle is offset from target destination
        offsetCenter = fm.circleMarker.location;
        this.app.offsetCenter = offsetCenter;
        markers.push(fm.circleMarker);
        if (this.isPreloadStory) {
          markers.push(fm.circleAuxMarker);
        }
        if (this.mpGameInterface.isLeader()) {
          let fs: IFindSpecsMpSyncData = {
            googleId: selectedPlace.place.googleId,
            offsetCenter: {
              lat: placeMarkerData.fakeLocation.lat,
              lng: placeMarkerData.fakeLocation.lng
            }
          };
          this.mpGameInterface.broadcastFindSpecs(fs);
        }

        console.log("show markers: ", markers);
        this.excludeLocationIdList.push(appLocation.loc.merged.googleId);

        this.showNewPlacesRetry(markers, [], false, this.flags.waitForAuxMarkers).then(async () => {
          await this.uiext.showLoadingV2Queue("Loading directions..");
          PromiseUtils.wrapResolve(this.showDirectionsRetry(this.currentLocation.location, placeMarkerData.location, placeMarkerData.fakeLocation, true), true).then(() => {
            // watch coin generator
            this.subscription.coinGenerator = this.exploreUtils.getWatchCoins().subscribe((val: IExploreCoinGen) => {
              if (val != null) {
                switch (val.action) {
                  case EExploreCoinAction.create:
                    this.app.itemsGenerated = val.index;
                    this.showHudMessage(EMapHudCodes.collectedCoins, this.app.collectedItemsCurrentActivity + "/" + this.app.itemsGenerated, null);
                    break;
                  case EExploreCoinAction.collect:
                    this.app.collectedItemsCurrentActivity = val.amount; // cumulative
                    this.app.collectedItemsValueCurrentActivity = val.value; // cumulative
                    this.soundManager.vibrateContext(true);
                    this.soundEffects.playQueueNoAction(SoundUtils.soundBank.coin.id);
                    this.showHudMessage(EMapHudCodes.collectedCoins, this.app.collectedItemsCurrentActivity + "/" + this.app.itemsGenerated, null);
                    break;
                  case EExploreCoinAction.update:
                    // update circle
                    this.findActivityProvider.updateFindCircleMarkerWrapper().then((pm: IPlaceMarkerContent) => {
                      console.log("find circle updated");
                      let fd: IFormatDisp = MathUtils.formatDistanceDisp(val.target);
                      this.messageQueueHandler.prepare("Find radius updated: " + fd.disp, false, EQueueMessageCode.info);
                      this.animateZoomTarget(pm.location);
                    }).catch((err) => {
                      console.error(err);
                    });
                    break;
                  default:
                    break;
                }
              }
            }, (err: Error) => {
              console.error(err);
            });

            this.initFindActivityCore(appLocation.loc, activityParams, customParams, specificFindActivityCode, dest, offsetCenter, onSearchAreaReachedAddon, false, null).then((res: number) => {
              console.log("find activity returned: ", res);
              let result: IFindActivityResult = {
                status: res,
                appLocation: appLocation,
                activityStats: null
              };
              resolve(result);
            }).catch((err) => {
              reject(err);
            });
          }).catch((err: Error) => {
            reject(err);
          });
        }).catch((err: Error) => {
          console.error(err);
          reject(err);
        });
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * init find activity w/ navigation
   * returns find activity code: ENavigateReturnCodes
   * @param activityParams 
   * @param customParams 
   * @param specificFindActivityCode 
   * @param realDestination 
   */
  initFindActivityCore(bloc: ILocationContainer, activityParams: IFindActivityDef, customParams: ICustomParamForActivity[], specificFindActivityCode: number, realDestination: ILatLng, offsetCenter: ILatLng, onSearchAreaReachedAddon: () => any, alreadyReachedSearchArea: boolean, storyLocationId: number): Promise<number> {
    let promise: Promise<number> = new Promise(async (resolve, reject) => {
      let revealDistance: number = activityParams.revealDistance;
      let startTimerDistance: number = activityParams.circleRadius;
      let timeLimit: number = activityParams.timeLimit;

      if (revealDistance == null) {
        revealDistance = AppConstants.gameConfig.revealDistance;
      }

      let treasureSpecs: ITreasureSpec[] = GameUtils.getCoinSpecs(customParams);
      if (treasureSpecs && (treasureSpecs.length > 0)) {
        this.findActivityProvider.changeTargetSpecs(treasureSpecs[0], false);
      } else {
        this.findActivityProvider.setDefaultTargetSpecs();
      }

      let params: IExploreFindActivityInit = {
        initialLocation: this.currentLocation.location,
        coinCap: activityParams.coinCap,
        collectDistance: AppConstants.gameConfig.collectDistance,
        circleRadius: activityParams.circleRadius,
        target: realDestination,
        currentLocation: this.currentLocation,
        activeInventoryItems: this.activeInventoryItems,
        circleOffsetCenter: offsetCenter,
        // coin specs pre-synced from MP
        syncData: null,
        // enable publishing sync data to mp
        publishSyncData: false
      };

      console.log("init find activity, dest: ", realDestination, ", offset center: ", offsetCenter, ", params: ", params);

      this.findActivityProvider.initStage1(params);
      this.animateZoomFindChallenge();

      this.exploreUtils.useDefaultCoinSpecs();
      this.activityProvider.setCollectContext(this.internalFlags.collectMode != null ? this.internalFlags.collectMode : ECheckpointCollectMode.manual, false);
      this.activityStarted.ANYTEMP = true;

      try {
        if (!this.flags.droneMode) {
          await this.setFollowNav3D();
        }

        let apploc: IAppLocation = null;
        let customReachedDistance: number = null;
        if ((this.app.storyLocations != null) && (this.app.locationIndex < this.app.storyLocations.length)) {
          apploc = this.app.storyLocations[this.app.locationIndex];
          if (apploc.loc && apploc.loc.merged) {
            customReachedDistance = apploc.loc.merged.collectRadius;
          }
        }

        let res: number = await this.navigateToLocation(bloc, apploc, true, true, specificFindActivityCode, revealDistance, startTimerDistance, timeLimit, realDestination, offsetCenter, onSearchAreaReachedAddon, alreadyReachedSearchArea, activityParams.circleRadius, storyLocationId, customReachedDistance, true, false);
        await this.clearNav();
        this.exitLinkView();
        resolve(res);
      } catch (err) {
        await this.clearNav();
        reject(err);
      }
    });
    return promise;
  }

  /**
   * handle more complex navigation scenarios
   * such as find place mode where the destination is not shown on the map
   */
  navigateToStoryLocationWrapper(): Promise<IFindActivityResult> {
    let promise: Promise<IFindActivityResult> = new Promise(async (resolve, reject) => {
      let apploc: IAppLocation = this.app.storyLocations[this.app.locationIndex];
      let bloc: ILocationContainer = apploc.loc;

      let res: IFindActivityResult = {
        status: ENavigateReturnCodes.inProgress,
        appLocation: null,
        activityStats: null
      };

      // let circleRevealSteps: number[] = [150, 100, 50];
      if (ActivityUtils.isFindTypeActivity(bloc)) {
        let activity: IActivity = bloc.merged.activity;
        let activityParams: IFindActivityDef = activity.params;
        let customParams: ICustomParamForActivity[] = activity.customParams;
        let specificFindActivityCode: number = activity.code;
        let realDestination: ILatLng = new ILatLng(bloc.merged.lat, bloc.merged.lng);
        let alreadyReachedSearchArea: boolean = false;

        let onSearchAreaReachedAddon = () => {
          PromiseUtils.wrapNoAction(this.onBeforeActivityStart(specificFindActivityCode, activity), true);
        }

        if (this.isPreloadStory) {
          alreadyReachedSearchArea = true;
        }

        // offset center is already set by now in story mode, when showing destination (circle)
        this.initFindActivityCore(bloc, activityParams, customParams, specificFindActivityCode, realDestination, this.app.offsetCenter, onSearchAreaReachedAddon, alreadyReachedSearchArea, apploc.storyLocationId).then(async (resCode: number) => {
          console.log("find activity returned: ", resCode);
          res.status = resCode;
          if (res.status === ENavigateReturnCodes.reached) {
            res.activityStats = this.activityStatsProvider.getFindStats();
            this.droneSimulator.onCompleteChallengeCheck();
            res.activityStats.stats.droneUsed = this.activityStatsTracker.checkDroneUsed();
            console.log("activity result wrapper: ", res);
          }
          resolve(res);
        }).catch((err) => {
          reject(err);
        });
      } else {
        let startRadius: number = ActivityUtils.getStartRadiusOrDefault(bloc, AppConstants.gameConfig.itemNotifyDistance);
        let useOffsetRef: boolean = ActivityUtils.isHiddenLoc(bloc);
        let showCircleDistance: boolean = !useOffsetRef;
        apploc.isNavigating = true;
        try {
          if (!this.flags.droneMode) {
            await this.setFollowNav3D();
          }
          let resCode: number = await this.navigateToLocation(bloc, apploc, true, false, 0, 0, 0, 0, null, null, null, false, null, null, startRadius, showCircleDistance, useOffsetRef);
          await this.clearNav();
          apploc.isNavigating = false;
          res.status = resCode;
          resolve(res);
        } catch (err) {
          await this.clearNav();
          apploc.isNavigating = false;
          reject(err);
        }
      }
    });
    return promise;
  }

  initJoystickPos() {
    this.findJoystickPosition = { x: 0, y: 0, heading: 0, headingDeg: 0, headingComp: 0, distance: 0 };
  }

  /**
   * navigate to location core
   * handle basic navigation towards a destination
   * the destination is given by the waypoints
   * if there is also a real location specified, the navigation will end only when reached that location instead
   * this is used for find activity
   * @param show 
   * @param find 
   * @param specificFindActivityCode 
   * @param revealDistance 
   * @param startTimerDistance 
   * @param timeLimit 
   * @param realDestinationOverride 
   */
  navigateToLocation(
    loc: ILocationContainer,
    apploc: IAppLocation,
    show: boolean,
    find: boolean,
    specificFindActivityCode: number,
    revealDistance: number,
    startTimerDistance: number,
    timeLimit: number,
    realDestinationOverride: ILatLng,
    offsetCenter: ILatLng,
    onSearchAreaReachedAddon: () => any,
    alreadyReachedSearchArea: boolean,
    findCircleRadius: number,
    storyLocationId: number,
    customReachedDistance: number,
    showCircleAround: boolean,
    useOffsetRef: boolean): Promise<number> {
    let promise: Promise<number> = new Promise((resolve, reject) => {
      let wpts: IWaypoint[] = [];

      this.locationData.waypoints.forEach((wp: IWaypoint) => {
        wpts.push({
          coords: new ILatLng(wp.coords.lat, wp.coords.lng),
          visited: wp.visited
        });
      });

      console.log("navigate to location, storyLocationId: ", storyLocationId);

      let lastWpCoords: ILatLng = null;
      if (wpts.length > 0) {
        lastWpCoords = wpts[wpts.length - 1].coords;
      }

      let destination: ILatLng = null;
      if (loc && loc.merged) {
        destination = new ILatLng(loc.merged.lat, loc.merged.lng);
      }

      this.navigationHandler.init(destination, wpts, this.currentLocation.location, realDestinationOverride, revealDistance, offsetCenter, startTimerDistance, alreadyReachedSearchArea, customReachedDistance, useOffsetRef, (this.internalFlags.manualChallengeStart || find), find, !find);

      if (find) {
        this.navGauge.setMode(ENavGaugeDict.left, ENavGaugeMode.distLeftInverted, ENavGaugeTooltip.distanceToTarget, null);
        this.navGauge.setIcon(ENavGaugeDict.left, EAppIcons.distanceTarget, true);
      } else {
        // use distance to last waypoint (target destination)
        this.navGauge.setMode(ENavGaugeDict.left, ENavGaugeMode.distLeft, ENavGaugeTooltip.distanceToTarget, GeometryUtils.getDistanceBetweenEarthCoordinates(this.currentLocation.location, lastWpCoords, null));
        this.navGauge.setIcon(ENavGaugeDict.left, EAppIcons.distanceTarget, true);
        this.navGauge.show(ENavGaugeDict.left);
      }

      let onSearchAreaReached = () => {
        console.log("search radius reached");
        this.messageQueueHandler.prepare("Find area reached", false, EQueueMessageCode.info);
        if (findCircleRadius != null) {
          this.navGauge.setMode(ENavGaugeDict.left, ENavGaugeMode.distLeftInverted, ENavGaugeTooltip.distanceFromTarget, findCircleRadius);
        } else {
          this.navGauge.setMode(ENavGaugeDict.left, ENavGaugeMode.distLeftInverted, ENavGaugeTooltip.distanceFromTarget, GeometryUtils.getDistanceBetweenEarthCoordinates(this.currentLocation.location, realDestinationOverride, 0));
        }

        this.navGauge.show(ENavGaugeDict.left);

        if (timeLimit) {
          this.navGauge.setMode(ENavGaugeDict.right, ENavGaugeMode.timeLeft, ENavGaugeTooltip.timeLeft, timeLimit);
          this.navGauge.setIcon(ENavGaugeDict.right, EAppIcons.stopwatch, true);
          this.navGauge.show(ENavGaugeDict.right);
        }

        this.buttonOptions.compass.blinkOnChange = !this.buttonOptions.compass.blinkOnChange;

        // this.initCompassHudState(true);
        HudUtils.setHudShow(this.hudMsg, EMapHudCodes.compassHeading, true);

        if (onSearchAreaReachedAddon) {
          onSearchAreaReachedAddon();
        }
      };

      let onTargetReachedComplete = () => {
        this.clearNav().then(() => {
          this.clearHudMessage(EMapHudCodes.navigateDistanceToTarget);
          resolve(ENavigateReturnCodes.reached);
          return;
        });
      }

      // this can be called by multiple services with the same end result
      let onTargetReached = async () => {
        console.log("on target reached");
        onTargetReachedComplete();
      };

      let targetFound: boolean = false;
      let onTargetFound = async () => {
        if (targetFound) {
          console.warn("target already found");
          return;
        }
        console.log("on target reached / found");
        targetFound = true;
        await this.uiext.showRewardPopupQueue("Got it!", "You have found the target location", null, false, 5000, false);
        onTargetReachedComplete();
      };

      let onTargetRecalculate = () => {
        console.log("on target recalculate");
        // route recalculation is disabled for find activity
        this.clearNav().then(() => {
          this.clearHudMessage(EMapHudCodes.navigateDistanceToTarget);
          resolve(ENavigateReturnCodes.recalculate);
          return;
        });
      };

      let onTimeExpired = () => {
        console.log("on time expired");
        this.clearNav().then(() => {
          this.clearHudMessage(EMapHudCodes.navigateDistanceToTarget);
          resolve(ENavigateReturnCodes.findActivityExpired);
          return;
        });
      };

      let showTargetDist: boolean = true;
      this.subscription.navigate = ResourceManager.clearSub(this.subscription.navigate);
      console.log("subscribing to navigate observable");

      let showCircleAroundLock: boolean = !find; // allow circle around the location unless find activity (which will unlock after checkpoint revealed)

      this.flags.showFindJoystick = true;
      this.initJoystickPos();
      this.findJoystickPositionUpdate = !this.findJoystickPositionUpdate;

      this.subscription.navigate = this.virtualPositionService.watchVirtualPosition().subscribe((data: IVirtualLocation) => {
        try {
          if (this.virtualPositionService.checkNavContext(data, true)) {
            // console.log("wdcs: " + data);
            // console.log("nav update: ", data.coords);
            let res: INavUpdateResult = this.navigationHandler.updateNav(data.coords, show);

            if (find && realDestinationOverride != null && !this.flags.droneMode && this.headingService.isCompassAvailable() && this.checkCompassNavModeOrComposite()) {
              // compass mode
              // include heading in gauge level (besides distance)
              this.navGauge.setLevel(ENavGaugeDict.left, this.findActivityProvider.computeFindGaugeLevel(data.coords, realDestinationOverride, res.distanceToRealDestination));
            } else {
              this.navGauge.setLevel(ENavGaugeDict.left, res.distanceToRealDestination);
            }

            this.findJoystickPosition.heading = res.headingToRealDestination;
            this.findJoystickPosition.headingDeg = GeometryUtils.toDeg(res.headingToRealDestination);
            this.findJoystickPosition.distance = Math.max(res.distanceToRealDestination / 10, 1);
            this.findJoystickPositionUpdate = !this.findJoystickPositionUpdate;

            if (res.result === ENavUpdateResult.navigationError) {
              this.subscription.navigate = ResourceManager.clearSub(this.subscription.navigate);
              this.clearHudMessage(EMapHudCodes.navigateDistanceToTarget);
              reject(new Error("navigation error"));
              return;
            }

            // handle postponed directions request
            if (this.internalFlags.postponeDirectionsRequest) {
              // check for location in range to calculate directions
              let locationCoords: ILatLng[] = this.getNextLocationCoords();
              if (GeometryUtils.getDistanceBetweenEarthCoordinates(data.coords, locationCoords[0], Number.MAX_VALUE) <= AppConstants.gameConfig.directionsMaxDistance) {
                this.recalculateDirections();
                this.internalFlags.postponeDirectionsRequest = false;
              }
            }

            // handle navigation for find activity 
            if (find) {
              // FIND ACTIVITY CONTEXT
              if (res.result === ENavUpdateResult.searchRadiusReached) {
                onSearchAreaReached();
                this.findActivityProvider.initStage2(this.currentLocation.location);
                showTargetDist = true;
                showCircleAroundLock = true;

                // search radius reached
                if (this.flags.contextHud) {
                  this.setHudState(true, true);
                }

                this.activityStarted.ANY = true;
                this.activityStarted.find = true; // disable scanner

                if (loc) {
                  this.activityProvider.loadActivity(loc.merged.activity, "Activity", loc, true);
                }

                // add AR object on destination
                this.findActivityProvider.placeDestinationAR(realDestinationOverride);
                this.findActivityProvider.start(onTargetFound);

                if (loc) {
                  // check for photo activity
                  if (specificFindActivityCode === EActivityCodes.snapshot) {
                    this.activityStarted.snapshot = true;
                    // let activityParams: IPhotoActivityDef = loc.merged.activity.params;

                    let params: IPhotoActivityParams = {
                      storyId: this.storyId,
                      storyLocationId: loc.merged.id,
                      timeLimit: timeLimit,
                      activity: loc.merged.activity,
                      loc: loc,
                      photoValidated: false,
                      updateGauge: true
                    };

                    this.initPhotoActivityMain(loc.merged.activity, params).then(() => {

                    }).catch((err) => {
                      console.error(err);
                    });
                  }
                } else {
                  // may open the AR to look for the target/destination
                  this.activityStarted.screenshotAR = true;
                  this.buttonOptions.ar.blink = true;
                }

                show = false; // hide the nav line as it's not useful anymore

                // switch to compass mode
                // PromiseUtils.wrapNoAction(this.setFollowCore(EMapInteraction.switch2dHeading, false, false, false), true);
                console.log("navigation time limit: ", timeLimit);

                // start timeout (for gmap detail disp)
                this.initTimeoutActivity(timeLimit, true).then(() => {
                  onTimeExpired();
                });
              }

              if (res.result === ENavUpdateResult.destinationRevealed) {
                console.log("destination revealed");
                console.log(loc);
                if (storyLocationId != null) {
                  this.toggleStoryLocationMarkerDisplay(storyLocationId, true);
                } else {
                  this.toggleLastMarkerDisplay(true);
                }
              }
              // stop conditions, find place/destination handled by find activity service
              // END FIND ACTIVITY CONTEXT
            } else {
              // NAVIGATION CONTEXT
              // stop conditions, default navigation
              if (res.result === ENavUpdateResult.destinationFound) {
                onTargetReached();
              }

              if (res.result === ENavUpdateResult.destinationFoundAckRequired) {
                this.internalFlags.manualChallengeStartRequested = true;
                this.buttonOptions.startLoc.blink = true;
              } else {
                this.internalFlags.manualChallengeStartRequested = false;
                this.buttonOptions.startLoc.blink = false;
              }
              // END NAVIGATION CONTEXT
            }

            if (res.result === ENavUpdateResult.recalculateRouteAckRequired) {
              this.internalFlags.routeRecalculateRequested = true;
              this.buttonOptions.recalculateDirections.blink = true;
            } else {
              this.internalFlags.routeRecalculateRequested = false;
              this.buttonOptions.recalculateDirections.blink = false;
            }

            if (res.result === ENavUpdateResult.recalculateRoute) {
              onTargetRecalculate();
            }

            // console.log("show circle around: ", showCircleAround);

            if (apploc != null && showCircleAround && showCircleAroundLock) {
              let showCircleDistance: number = this.navigationHandler.getReachedDistance() * 2;
              let margin: number = 0.1;
              let showAlways: boolean = LocationDispUtils.checkHiddenMarkerModeCircle(apploc.loc);
              // console.log("show circle around distance: ", showCircleDistance, res.distanceToRealDestination);
              if (showAlways || (res.distanceToRealDestination < (showCircleDistance * (1 - margin)))) {
                // show reached circle
                if (!apploc.virtualCircle) {
                  let cm: IPlaceMarkerContent = this.findActivityProvider.getCircleReachedMarker(destination, this.navigationHandler.getReachedDistance());
                  apploc.virtualCircle = cm;
                  PromiseUtils.wrapNoAction(this.markerHandler.insertMultipleMarkers([cm], true), true);
                }
              } else if (res.distanceToRealDestination > (showCircleDistance * (1 + margin))) {
                // remove reached circle
                if (apploc.virtualCircle != null) {
                  apploc.virtualCircle = null;
                  PromiseUtils.wrapNoAction(this.markerHandler.disposeLayerResolve(EMarkerLayers.MARKER_CIRCLES), true);
                }
              }
            }

            if (showTargetDist) {
              let formatDisp: IFormatDisp = MathUtils.formatDistanceDisp(res.distanceToRealDestination);
              this.showHudMessage(EMapHudCodes.navigateDistanceToTarget, formatDisp.value, formatDisp.unit);
            } else {
              this.clearHudMessage(EMapHudCodes.navigateDistanceToTarget);
            }
          }
        } catch (e) {
          console.error(e);
        }
      }, (err: Error) => {
        console.error(err);
        reject(err);
      });
    });
    return promise;
  }

  navGaugeClick(ng: INavGaugeStat) {
    if (ng.callback != null) {
      ng.callback();
    }
  }

  onTimerUpdate(timerValue: number) {
    console.log("on timer update: ", timerValue);
    let message: string = this.tts.getTimeLeftMessageWithContext(timerValue, false, true);
    if (message) {
      this.soundManager.ttsWrapper(message, true);
    }
  }

  /**
   * subscribe to timeout monitor watch
   */
  subscribeToTimerWatchDisplay(updateGauge: boolean) {
    if (!this.subscription.displayTimeout) {
      this.subscription.displayTimeout = this.timeoutMonitor.getWatch().subscribe((tmData: ITimeoutMonitorData) => {
        // console.log(tmData);
        if (tmData) {
          if (!tmData.isFallbackTimer) {
            this.onTimerUpdate(tmData.timerValue);
            // wait for timeout complete
            if (updateGauge) {
              this.navGauge.setLevel(ENavGaugeDict.right, tmData.timerValue);
              this.navGauge.checkLowLevelSetLockTheme(ENavGaugeDict.right, 0.2);
            } else {
              this.showHudMessage(EMapHudCodes.timer, tmData.timerDisp, null);
            }
          }
          switch (tmData.status) {
            case ETimeoutStatus.expired:
              this.clearHudMessage(EMapHudCodes.timer);
              this.subscription.displayTimeout = ResourceManager.clearSub(this.subscription.displayTimeout);
              break;
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  unsubscribeFromTimerWatch() {
    this.subscription.displayTimeout = ResourceManager.clearSub(this.subscription.displayTimeout);
    this.clearHudMessage(EMapHudCodes.timer);
  }

  unsubscribeFromActivityEntryWatch() {
    this.subscription.activityEntry = ResourceManager.clearSub(this.subscription.activityEntry);
  }


  /**
   * debug test trigger expire activity timeout
   */
  triggerExpireActivity() {
    console.log("trigger expire");
    this.exploreProvider.triggerExpired();
    this.moveActivityProvider.triggerExpired();
    this.timeoutMonitor.triggerExpired();
  }

  triggerDecreaseTimer() {
    if (this.timeoutMonitor.triggerDecreaseTimer(30)) {
      this.triggerExpireActivity();
    }
  }

  /**
   * used for simulating the user location
   * it can be set as the current map position
   * overrides internal location monitor and clears location updates
   * the location monitor switches to manual!
   */
  setCurrentLocationSimulation() {
    this.mapManager.getCurrentMapLocation().then((loc: ILatLng) => {
      console.log("get current map location set: ", loc);
      if (loc != null) {
        this.setCurrentLocationSimulationCore(loc, true);
      }
    });
  }

  setCurrentLocationSimulationCore(coords: ILatLng, internal: boolean) {
    // this.currentLocation.location = loc;
    this.disableFollow();
    this.internalFlags.updateUserMarkerGPS = false;
    if (!coords) {
      console.warn("undefined coords");
      return;
    }
    this.mapManager.moveMapExt(coords).then(() => {
      this.currentLocation.location = coords;
      console.log("set location: " + coords.lat + ", " + coords.lng);
      let loc: IVirtualLocation = {
        coords: coords,
        speed: 0,
        heading: 0,
        source: EVirtualLocationSource.override,
        updateMarker: true
      };
      this.virtualPositionService.updateVirtualPosition(loc);
      if (internal) {
        this.locationMonitor.manualLocationOverrideLock(coords);
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  goToNextLocationTest() {
    let bloc: ILocationContainer = this.app.storyLocations[this.app.locationIndex].loc;
    this.goToLocationTest(new ILatLng(bloc.merged.lat, bloc.merged.lng));
  }

  goToLocationTest(loc: ILatLng) {
    // move map to user input
    console.log("move map user input: ", loc);
    this.setCurrentLocationSimulationCore(loc, true);
  }

  startStop() {
    if (!this.app.start) {
      this.start();
    } else {
      this.pause();
    }
  }

  start() {
    if (!this.app.start) {
      this.app.start = true;
      this.user.canSkip = true;
      this.user.canRequestDirections = false;
      this.countdownAutostart.value = 0;
      // check if first start really
      if (this.observables.state !== null) {
        this.observables.state.next(this.app.state);
        // this.observables.state.next(EGmapStates.SEARCH);
        this.analytics.sendCustomEvent(ETrackedEvents.story, "start", this.storyId + "", this.storyId, true);
        this.userStatsProvider.registerStoryStarted(this.storyId).then(() => {

        }).catch((err: Error) => {
          console.error(err);
        });
      }
    }
  }


  /**
   * pause/different activities
   * main story
   * challenge
   * mp game
   */
  async pause() {
    if (this.flags.droneMode) {
      let proceed: boolean = await this.exitDroneModePrompt();
      if (!proceed) {
        this.buttonOptions.pause.blink = true;
        return;
      }
    }
    if (this.app.start) {
      // the game engine is started (in STORY mode)
      if (this.mpGameInterface.isOnline()) {
        // mp mode
        // return to arena
        // this.openArenaContinue();
        let res: number = await PromiseUtils.wrapResolve(this.uiext.showAlert(Messages.msg.disconnectGroup.before.msg, Messages.msg.disconnectGroup.before.sub, 2, ["Dismiss", "Ok"], true), true);
        if (res === EAlertButtonCodes.ok) {
          await this.leaveGroupSession();
        }
      } else if (this.isPreloadStory && this.app.challengeInProgress) {
        // a single challenge is in progress
        // quit challenge
        let msg: string = Messages.msg.quitChallenge.before.msg;
        let sub: string = Messages.msg.quitChallenge.before.sub;
        let res: number = await PromiseUtils.wrapResolve(this.uiext.showAlert(msg, sub, 2, ["Dismiss", "Ok"], true), true);
        if (res === EAlertButtonCodes.ok) {
          // this.quitChallenge();
          // make sure the check activity handler returns on this event, for any activity
          this.finalizeActivity();
        }
      } else {
        if (this.isWorldMap) {
          // free roaming
          let res: number = await PromiseUtils.wrapResolve(this.uiext.showAlert(Messages.msg.pauseOrStopStory.before.msg, Messages.msg.pauseOrStopStory.before.sub, 2, ["Dismiss", "Ok"], true), true);
          if (res === EAlertButtonCodes.ok) {
            await this.refreshSession();
            await this.restoreShowTreasureLayersRetryResolve();
          }
        } else {
          let res: number = await PromiseUtils.wrapResolve(this.uiext.showAlert(Messages.msg.pauseOrStopStoryFromStoryline.before.msg,
            Messages.msg.pauseOrStopStoryFromStoryline.before.sub, 2, ["Dismiss", "Ok"], true), true);
          if (res === EAlertButtonCodes.ok) {
            this.goBackRequest(true, true);
          }
        }
      }
    } else if (this.app.challengeInProgress) {
      // a single challenge is in progress
      // quit challenge
      let res: number = await PromiseUtils.wrapResolve(this.uiext.showAlert(Messages.msg.quitChallenge.before.msg, Messages.msg.quitChallenge.before.sub, 2, ["Dismiss", "Ok"], true), true);
      if (res === EAlertButtonCodes.ok) {
        // this.quitChallenge();
        // make sure the check activity handler returns on this event, for any activity
        this.finalizeActivity();
      }
    } else {
      // go back (exit map)
      this.goBackRequest(true, true);
    }
  }

  checkGoBack() {
    let canExit: boolean = true;
    if (this.flags.droneMode) {
      canExit = false;
    } else {
      // check story started
      if (this.app.start) {
        if (this.mpGameInterface.isOnline()) {
          canExit = false;
        } else if (this.isPreloadStory && this.app.challengeInProgress) {
          canExit = false;
        } else {
          if (this.isWorldMap) {
            canExit = false;
          } else {
            canExit = false;
          }
        }
      } else if (this.app.challengeInProgress) {
        canExit = false;
      } else {
        canExit = true;
      }
    }
    return canExit;
  }

  checkDroneModeForActivity(activityDroneMode: number) {
    this.isDroneAllowedForActivity = activityDroneMode !== EDroneMode.noDrone;
  }

  /** 
   * handle challenge entry point
   * use activity handler to run the game engine
   */
  handleChallenge(item: ILeplaceTreasure, infoHTML: string) {
    console.log("handle challenge");
    let testChallenge: boolean = false;
    // let testChallenge: boolean = true;
    let promiseLoad: Promise<boolean>;

    if (testChallenge) {
      promiseLoad = new Promise((resolve) => {
        this.placesDataProvider.loadTreasure(39144).then((treasure: ILeplaceTreasure) => {
          item = treasure;
          resolve(true);
        }).catch((err: Error) => {
          console.error(err);
          resolve(false);
        });
      });
    } else {
      promiseLoad = Promise.resolve(true);
    }

    this.uiext.dismissAllWidgets();
    // prepare link view observable for ext input
    this.createLinkViewObs();

    promiseLoad.then(async (res: boolean) => {
      if (!res) {
        return;
      }

      let activity: IActivity = this.challengeEntry.getActivity(item);
      let appLocation: IAppLocation = this.challengeEntry.getAppLocationWithActivity(item);
      let mp = this.mpGameInterface.getGameContainer();

      let activityDroneMode: number = ActivityUtils.getDroneModeAvailable(activity, this.isDroneOnly);
      this.checkDroneModeForActivity(activityDroneMode);

      let itemFoundParams: IItemFound = {
        infoHTML: infoHTML != null ? infoHTML : "<p>You have found a challenge</p>",
        shareMessage: null,
        amount: 0,
        opened: false,
        xp: 0,
        title: TreasureUtils.getChallengeName(item, false),
        item: item,
        place: item.place,
        inputObservable: this.observables.linkViewSend,
        previewCallback: () => {
          let opts: IGmapActivityPreviewOptions = {
            inProgress: false,
            withDismiss: true,
            withDrone: activityDroneMode,
            startReady: true,
            isPreview: false,
            isAutostart: !this.internalFlags.manualChallengeStart,
            overrideLocationIndex: false
          };
          return this.getLocationDetailsView(appLocation, item, opts, null);
        },
        withDismissOptions: false
      };

      if (item.activity) {
        let dispName: string = TreasureUtils.getChallengeName(item, false);
        if (dispName) {
          itemFoundParams.infoHTML += "<p>" + dispName + " Level " + item.activity.level + "</p>";
        }
      }

      itemFoundParams.infoHTML += "<p>Tap the button to continue</p>";
      await this.onModalOpened();

      if (mp && mp.online && mp.currentGroupRole === EGroupRole.member) {
        // the member may reject the challenge
        itemFoundParams.withDismissOptions = true;
      }

      if (item && item.type === ETreasureType.challenge) {
        // the user may reject the challenge too
        itemFoundParams.withDismissOptions = true;
      }

      this.modularViews.getItemFoundView(itemFoundParams).then(async (res: ITreasureFoundReturnData) => {
        console.log("challenge init setup: ", res);
        this.onModalClosed();
        let status: number = res && (res.status != null) ? res.status : null;
        switch (status) {
          case EFinishedActionParams.cancel:
          case null:
            console.log("challenge dismissed");
            // dispatch to MP challenge rejected
            this.mpGameInterface.dispatchRejectChallengeToMP();
            break;
          default:
            if (activity) {
              this.mpGameInterface.dispatchChallengeToMP(item);
              let proceed: boolean = await this.mpGameInterface.waitForChallengeReadyToStart();
              // leader/member
              // leader waits for the others to start the challenge
              // a member may reject the challenge
              // members wait for the host to start
              // the host may stop the challenge
              console.log("wait for challenge ready to start: ", proceed);
              if (proceed) {
                this.app.challengeInProgress = true;
                this.buttonOptions.places.blink = true;
                this.title = this.challengeEntry.getChallengeName(item);
                // await SleepUtils.sleep(5000);
                // breakpoint here
                await this.disableEagleViewBeforeActivityStart();
                await this.exitARSync();
                // await SleepUtils.sleep(5000);
                let startOptionsSelected: IGmapActivityStartOptions = res.startOptionsSelected;
                this.analytics.sendCustomEvent(ETrackedEvents.startChallenge, "start", "challenge", activity.code, true);
                this.checkActivityWrapper(activity, appLocation, item, startOptionsSelected, null, false).then(async (res: IActivityResultCore) => {
                  console.log("challenge return status: ", res.status);
                  console.log("challenge return data: ", res);
                  // if the activity is finished, remove the item from the map (and register as collected)
                  // to prevent doing the same activity multiple times in a row and earning easy rewards
                  this.mpGameInterface.dispatchChallengeCompleteToMP(item, res, activity.paramsList);
                  // clear activity
                  // await SleepUtils.sleep(5000);
                  // breakpoint here
                  await this.quitChallenge(true);
                  // await SleepUtils.sleep(5000);
                  await this.closeGmapDetailResolve();
                  switch (res.status) {
                    case ECheckActivityResult.done:
                      this.analytics.sendCustomEvent(ETrackedEvents.completeChallenge, "complete", "challenge", activity.code, true);
                      await this.uiext.dismissLoadingV2();
                      // await this.gmapModals.checkNewAchievementsCoreResolveOnly();
                      this.quitChallengeAfter(true);
                      break;
                    default:
                      this.quitChallengeAfter(true);
                      break;
                  }
                }).catch((err: Error) => {
                  console.error(err);
                  this.quitChallengeAfter(true);
                });
              } else {
                console.log("challenge dismissed");
                let mp = this.mpGameInterface.getGameContainer();
                if (mp && mp.online) {
                  switch (mp.currentGroupRole) {
                    case EGroupRole.leader:
                      this.uiext.showAlertNoAction(Messages.msg.mpChallengeRejected.after.msg, Messages.msg.mpChallengeRejected.after.sub);
                      break;
                    case EGroupRole.member:
                      break;
                  }
                }
              }
            }
            break;
        }
      }).catch((err: Error) => {
        this.onModalClosed();
        this.analytics.dispatchError(err, "gmap");
        console.error(err);
      });
    });
  }

  /**
   * cleanup challenge
   * refresh map treasures
   * show nearby places
   */
  async quitChallenge(refreshCrates: boolean): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve) => {
      // await SleepUtils.sleep(1000);
      await this.exitActivityMain(refreshCrates);
      this.app.challengeInProgress = false;
      this.buttonOptions.places.blink = false;
      this.buttonOptions.ar.blink = false;
      // reset coins collected for current activity
      // clear previous buffer with locations
      this.cleanupPrevActivity();
      // await SleepUtils.sleep(1000);
      resolve(true);
    });
    return promise;
  }

  closeGmapDetailResolve(): Promise<boolean> {
    return this.modularViews.dismissLocationDetailsViewResolve();
  }

  /**
   * after cleanup
   * restore map
   * wait for mp endgame (if enabled)
   */
  quitChallengeAfter(waitMP: boolean) {
    this.title = "World Map";
    this.restoreShowTreasureLayersRetryResolve().then(() => {
      this.disableCompassNavMode();
      this.enableFollowTrueAnimate(); // re-enable user follow mode

      let promiseWaitMP: Promise<boolean>;
      let mp = this.mpGameInterface.getGameContainer();

      if (mp && mp.online && waitMP) {
        switch (mp.currentGroupRole) {
          case EGroupRole.leader:
            promiseWaitMP = this.mpManager.watchStateTransitionResolve([ELeaderStates.ENDGAME]);
            break;
          case EGroupRole.member:
            promiseWaitMP = this.mpManager.watchStateTransitionResolve([EMemberStates.ENDGAME]);
            break;
          default:
            promiseWaitMP = Promise.resolve(true);
            break;
        }
      } else {
        promiseWaitMP = Promise.resolve(true);
      }

      promiseWaitMP.then(() => {
        let showNearbyPlacesPopup: boolean = true;
        if (this.story != null) {
          let currentLocation: IAppLocation = this.app.storyLocations[this.app.locationIndex];
          if (currentLocation && currentLocation.loc && currentLocation.loc.merged.flag === ELocationFlag.FIXED) {
            this.loading = false;
            showNearbyPlacesPopup = false;
          }
        }
        console.log("show nearby places popup: ", showNearbyPlacesPopup);
        if (showNearbyPlacesPopup) {
          this.showNearbyPlacesPopupResolve(false).then(() => {
            this.loading = false;
          });
        }
      });
    });
  }


  /**
   * refresh session
   * only for WORLD MAP
   * e.g. loading another story, exiting current story
   * it clears all resources
   * then re-adds the existing treasure markers
   */
  refreshSession(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve) => {
      await this.uiext.showLoadingV2Queue("Returning to world map..");
      await this.clearSession(true, false);
      this.title = "World Map";
      this.dedicatedTimeouts.refreshSession = setTimeout(async () => {
        await this.uiext.dismissLoadingV2();
        PromiseUtils.wrapNoAction(this.startSession(false, true, false), true);
        this.enableFollowTrueAnimate(); // re-enable user follow mode
        resolve(true);
      }, 2000);
    });
    return promise;
  }

  refreshSessionNoAction() {
    this.refreshSession().then(() => {

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * go back on user request
   * @param prompt 
   * @param force 
   */
  async goBackRequest(prompt: boolean, force?: boolean) {
    if (this.internalFlags.deinitInProgress) {
      console.error("map deinit in progress");
      return;
    }

    let canExitMap: boolean = false;
    console.log("go back on user request, can exit map: ", this.internalFlags.canExitMap);
    let promise: Promise<boolean>;

    if (!this.mapInitializedFirstStage && !this.internalFlags.canExitMap) {
      canExitMap = false;
    } else {
      if (!this.internalFlags.canExitMap && !force) {
        // if (this.mapManager.getZoom() !== MapSettings.zoomOutLevel) {
        canExitMap = false;
        this.buttonOptions.pause.blink = true;
        this.buttonOptions.start.blink = true;
        this.disableFollow();
        this.internalFlags.canExitMap = true;
        this.goToUser(MapSettings.zoomOutLevel, true).then(() => {
          // this.blinkBtn.exit = false;
          this.internalFlags.canExitMap = true;
        }).catch((err: Error) => {
          // this.blinkBtn.exit = false;
          console.error(err);
          this.analytics.dispatchError(err, "gmap");
          this.internalFlags.canExitMap = true;
          this.smartZoom.resetState();
          this.enableFollowTrueAnimate(); // re-enable user follow mode
        });
        // } else {
        //   this.internalFlags.canExitMap = true;
        //   // canExitMap = true;
        // }
      } else {
        canExitMap = true;
      }
    }

    let deinitHandler = (restoreLayers: boolean): Promise<boolean> => {
      return new Promise(async (resolve) => {
        if (restoreLayers) {
          await this.restoreShowTreasureLayersRetryResolve();
        }
        this.internalFlags.deinitInProgress = true;
        await this.deinitResources(true);
        console.log("resource deinit complete");
        resolve(true);
      });
    };

    if (canExitMap) {
      // exit by user request
      // this.backButton.pop();

      if (this.modeSelect.storyline) {
        // STORYLINE
        if (prompt && this.app.start) {
          // promise = Util.getAlertReturnStatus(this.uiext.showAlert(Messages.msg.exitMap.before.msg, Messages.msg.exitMap.before.sub, 2, null, false));
          promise = new Promise((resolve) => {
            resolve(true);
          });
        } else {
          promise = new Promise((resolve) => {
            resolve(true);
          });
        }

        promise.then(async (res: boolean) => {
          if (res) {
            this.sendFinishedStoryReport();
            this.storyParams.reload = this.internalFlags.storyProgressUpdated;
            let navParams: INavParams = {
              view: null,
              params: this.storyParams
            };
            this.nps.set(ENavParamsResources.storyList, navParams);
            await deinitHandler(false);

            this.router.navigate([ERouteDef.storyList], { replaceUrl: true }).then(() => {
            }).catch((err: Error) => {
              console.error(err);
            });
          }
        });
      } else {
        // WORLD MAP
        let mp: IMPGameSession = this.mpGameInterface.getGameContainer();
        let requireRefresh: boolean = false;

        if (prompt && this.storySelected) {
          promise = Util.getAlertReturnStatus(() => { return this.uiext.showAlert(Messages.msg.exitMapStory.before.msg, Messages.msg.exitMapStory.before.sub, 2, null, false) });
          requireRefresh = true;
        } else if (prompt && mp.currentGroup) {
          promise = Util.getAlertReturnStatus(() => { return this.uiext.showAlert(Messages.msg.exitMapMP.before.msg, Messages.msg.exitMapMP.before.sub, 2, null, false) });
          requireRefresh = true;
        } else if (prompt && this.activityStarted.ANYTEMP) {
          promise = Util.getAlertReturnStatus(() => { return this.uiext.showAlert(Messages.msg.exitMapChallenge.before.msg, Messages.msg.exitMapChallenge.before.sub, 2, null, false) });
          requireRefresh = true;
        } else {
          promise = new Promise((resolve) => {
            resolve(true);
          });
        }

        promise.then(async (res: boolean) => {
          if (res) {
            let mp: IMPGameSession = this.mpGameInterface.getGameContainer();
            if (mp && mp.currentGroup) {
              this.mpManager.quitSession();
              this.disconnectGroup(true);
              this.userStatsProvider.registerWorldMapStatNoAction(EStatCodes.groupEventsClosed, 1);
            }

            if (this.storySelected) {
              await this.refreshSession();
            }

            await deinitHandler(requireRefresh);
            this.router.navigate([ERouteDef.home], { replaceUrl: true }).then(() => {
            }).catch((err: Error) => {
              console.error(err);
            });
          }
        });
      }
    }
  }

  /**
   * check user option (can stay on the map in mp game to continue to view others on the map)
   * @param prompt 
   */
  async checkGoBackToStoryline(prompt: boolean, promptAlways: boolean): Promise<boolean> {
    let promiseReturn: Promise<boolean> = new Promise((resolve) => {
      console.log("map go back to storyline");
      if (this.internalFlags.deinitInProgress) {
        console.error("denied / map deinit in progress");
        resolve(false);
        return;
      }
      let mp: IMPGameSession = this.mpGameInterface.getGameContainer();
      if ((mp && mp.online && prompt) || promptAlways) {
        console.log("mp is online, show prompt")
        this.uiext.showAlert(Messages.msg.stayOnMapOrReturnToStoryline.before.msg, Messages.msg.stayOnMapOrReturnToStoryline.before.sub, 2, null, true).then((res: number) => {
          switch (res) {
            case EAlertButtonCodes.ok:
              resolve(true);
              break;
            default:
              resolve(false);
              break;
          }
        }).catch((err: Error) => {
          console.error(err);
          resolve(false);
        })
      } else {
        resolve(true);
      }
    });
    return promiseReturn;
  }

  /**
   * go back to storyline by app intention (story mode)
   */
  async goBackToStoryline() {
    console.log("map go back to storyline");
    if (this.internalFlags.deinitInProgress) {
      console.error("denied / map deinit in progress");
      return;
    }

    let navParams: INavParams = {
      view: null,
      params: this.storyParams
    };

    // exit by app (e.g. finished story)
    this.sendFinishedStoryReport();
    this.storyParams.reload = this.internalFlags.storyProgressUpdated;
    this.nps.set(ENavParamsResources.storyList, navParams);
    this.internalFlags.deinitInProgress = true;
    await this.deinitResources(true);
    console.log("resource deinit complete");
    this.router.navigate([ERouteDef.storyList], { replaceUrl: true }).then(() => {
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  sendFinishedStoryReport() {
    if (this.app.finishedStory) {
      this.analytics.sendCustomEvent(ETrackedEvents.story, "done", this.storyId + "", this.storyId, true);
    } else {
      this.analytics.sendCustomEvent(ETrackedEvents.story, "stop", this.storyId + "", this.storyId, true);
    }
  }

  onGaugeSelect(event: any) {
    console.log("select gauge: ", event);
  }


  openFinder() {
    let params: IPlaceSearch = {

    };

    this.onModalOpened();
    this.uiext.showCustomModal(null, PlaceSearchViewComponent, {
      view: {
        fullScreen: false,
        transparent: false,
        large: true,
        addToStack: true,
        frame: true
      },
      params: params
    }).then((res: google.maps.places.PlaceResult) => {
      this.onModalClosed();
      if (res) {
        this.loadPlaceResult(res);
      }
    }).catch((err: Error) => {
      this.onModalClosed();
      console.error(err);
      this.analytics.dispatchError(err, "gmap");
    });
  }

  async loadPlaceResult(place: google.maps.places.PlaceResult) {
    console.log(place);
    if (!(place && place.geometry)) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      console.error("no details available");
      return;
    }
    let coords: ILatLng = new ILatLng(place.geometry.location.lat(), place.geometry.location.lng());
    this.goToLocationTest(coords);
  }


  watchKeyEventHandler() {
    if (!this.platform.WEB || (GeneralCache.isPublicDistribution && !AppSettings.testerMode)) {
      console.warn("watch key event handler not available. public dist: " + GeneralCache.isPublicDistribution + ", tester mode: " + AppSettings.testerMode);
      return;
    }
    if (this.subscription.keyEvent != null) {
      return;
    }
    this.subscription.keyEvent = this.keyHandler.watchKeyPress().subscribe((key: number) => {
      if (key != null) {
        this.keyEventHandlerGlobal(key);
        if (this.flags.droneMode) {
          this.keyEventHandlerDroneMode(key);
        } else {
          this.keyEventHandlerNormal(key);
        }
      }
    }, (err: Error) => {
      console.error(err);
    });
  }

  keyEventHandlerGlobal(key: number) {
    switch (key) {
      case EKeyCodes.j:
        this.mpManager.simulateConnectionLost();
        break;
      case EKeyCodes.l:
        this.mpManager.simulateConnectionResume();
        break;
      case EKeyCodes.t:
        PromiseUtils.wrapNoAction(this.itemScanner.treasureScan(), true);
        break;
      case EKeyCodes.p:
        this.setCurrentLocationSimulation();
        break;
      case EKeyCodes.o:
        this.networkMonitor.simulateNetworkState(null);
        break;
      default:
        break;
    }
  }

  keyEventHandlerNormal(key: number) {
    let delta: number = 0.0001;
    let move: boolean = true;
    let loc: ILatLng = this.currentLocation.location;
    // location change commands
    if (loc) {
      switch (key) {
        case EKeyCodes.w:
          loc.lat += delta;
          break;
        case EKeyCodes.s:
          loc.lat -= delta;
          break;
        case EKeyCodes.a:
          loc.lng -= delta;
          break;
        case EKeyCodes.d:
          loc.lng += delta;
          break;
        case EKeyCodes.n:
          this.headingService.simulateCompassHeading(this.headingService.getCompassHeading() - 10);
          break;
        case EKeyCodes.m:
          this.headingService.simulateCompassHeading(this.headingService.getCompassHeading() + 10);
          break;
        default:
          move = false;
          break;
      }
    } else {
      console.log("location undefined");
    }

    if (move) {
      // disable follow
      this.goToLocationTest(loc);
    }
  }

  keyEventHandlerDroneMode(key: number) {
    // drone controls
    let deltaHeading: number = 10;
    let deltaTilt: number = 10;
    let deltaAltitude: number = 0.5;
    switch (key) {
      case EKeyCodes.w:
        this.droneSimulator.setTiltDelta(-deltaTilt);
        break;
      case EKeyCodes.s:
        this.droneSimulator.setTiltDelta(deltaTilt);
        break;
      case EKeyCodes.a:
        this.droneSimulator.setHeadingDelta(-deltaHeading);
        break;
      case EKeyCodes.d:
        this.droneSimulator.setHeadingDelta(deltaHeading);
        break;
      case EKeyCodes.n:
        this.droneSimulator.setAltitudeDelta(-deltaAltitude);
        break;
      case EKeyCodes.m:
        this.droneSimulator.setAltitudeDelta(deltaAltitude);
        break;
      default:
        break;
    }
  }

  onJoystickActionButton(mode: number) {
    this.droneSimulator.onButtonAction(mode);
  }

  onJoystickAction(event: IJoystickStatusUpdate) {
    this.droneSimulator.onJoystickAction(event);
  }

  onCompassAdjustJoystickAction(event: IJoystickStatusUpdate) {
    this.headingService.onCompassAdjustJoystickAction(event);
  }

  /**
   * change state machine state
   */
  async setState(state: number) {
    await SleepUtils.sleep(100);
    this.app.state = state;
    this.app.entry = true;
    console.log("GMAP state change " + this.getStateName());
    if (this.observables.state !== null) {
      this.observables.state.next(state);
    }
  }

  /**
   * run state machine entry action i.e. called once when entering the state
   * @param func 
   */
  runEntryAction(func: () => any) {
    if (this.app.entry) {
      this.app.entry = false;
      func();
    }
  }

  getStateName(): string {
    return GeneralUtils.getPropName(EGmapStates, this.app.state);
  }

  /**
   * clear environment markers, keep user marker and other users marker though
   * useful for refresh session
   */
  async clearEnvMarkers(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve) => {
      console.log("clear map before story init");
      // this.markerHandler.clearAll();
      let keys: string[] = Object.keys(EMarkerLayers);
      let excludeList: string[] = [EMarkerLayers.USER, EMarkerLayers.OTHER_PLAYERS];
      for (let i = 0; i < keys.length; i++) {
        if (excludeList.indexOf(EMarkerLayers[keys[i]]) === -1) {
          try {
            await this.markerHandler.disposeLayerResolve(EMarkerLayers[keys[i]]);
          } catch (e) {
            console.error(e);
          }
        }
      }
      resolve(true);
    });
    return promise;
  }


  /**
   * skip location click handler
   */
  skipPlaceOnClick(direction: number, index: number) {
    let skipFn = () => {
      if (!this.isPreloadStory) {
        this.skipPlace(direction, index);
      } else {
        this.finalizeActivity();
      }
    };
    if (this.app.start) {
      this.popupFeatures.showSkipModal(this.isPreloadStory).then((res: IPopupSkipResult) => {
        if (res && res.skip) {
          if (res.comment) {
            let loc: ILocationContainer = this.app.storyLocations[this.app.locationIndex].loc;
            PromiseUtils.wrapNoAction(this.miscProvider.rateStory(this.storyId, loc.merged.id, 0, res.comment, false), true);
          }
          skipFn();
        }
      });
    } else {
      this.skipPlace(direction, index);
    }
  }

  /**
  * skip location
  */
  skipPlace(direction: number, index: number) {
    let currentLocationIndex: number = this.app.locationIndex;
    if (index != null) {
      this.internalFlags.preselectIndex = (index >= 0) ? index : ((index < this.app.storyLocations.length) ? index : 0);
    }
    if (!this.app.start) {
      this.storyManagerService.handleSkipOptions(this.app, direction);
      this.selectStoryLocationIndex(this.app.locationIndex);
    } else {
      // override the exit state check sub (exit event will be triggered instead of skip event)
      this.exitActivityMain(false).then(async () => {
        // set as done if any coins were collected during explore activity
        if (this.checkTreasuresInStoryEnabled()) {
          await this.afterChallengeScannerSetup();
        }
        let loc: ILocationContainer = this.app.storyLocations[this.app.locationIndex].loc;
        this.excludeLocationIdList.push(loc.merged.googleId);
        let baseActivityCode: number = ActivityUtils.checkSimilarActivity(loc.merged.activity);
        loc.merged.doneStatus = ECheckActivityResult.skipped;
        if (baseActivityCode === EActivityCodes.explore) {
          // if at least one coin/item was collected then consider the activity done (within a story)
          if (this.exploreProvider.checkCoinsCollected(loc.merged.activity, this.app.collectedItemsCurrentActivity)) {
            loc.merged.done = EStoryLocationDoneFlag.done;
            loc.merged.doneStatus = ECheckActivityResult.done;
            this.handleActivityFinishedResolve(EActivityCodes.explore, null, null, loc, null).then((_status: number) => {
              this.skipPlaceCore(false, currentLocationIndex, (currentLocationIndex + 1) + ECheckpointMarkerStatus.done);
            });
          } else {
            this.skipPlaceCore(true, currentLocationIndex, (currentLocationIndex + 1) + ECheckpointMarkerStatus.skipped);
          }
        } else {
          this.skipPlaceCore(true, currentLocationIndex, (currentLocationIndex + 1) + ECheckpointMarkerStatus.skipped);
        }
      });
    }
  }

  // on pause click ok
  // or direct call
  finalizeActivity() {
    this.activityProvider.triggerActivitySkip();
  }

  async skipPlaceCore(removeMarker: boolean, skipLocationIndex: number, addLabel: string) {
    console.log("skip place");
    await this.clearNav();
    if (removeMarker) {
      // this.clearLastPlace(true);
      await this.storyManagerService.updateStoryMarker(skipLocationIndex, true, false, addLabel);
      await PromiseUtils.wrapResolve(this.storyDataProvider.updateStatus(this.story, skipLocationIndex, EStoryLocationStatusFlag.skipped), true);
    }
    this.analytics.sendCustomEvent(ETrackedEvents.story, "skip place", "story: " + this.storyId + ", location: " + skipLocationIndex, this.storyId, true);
    this.dedicatedTimeouts.clearMap = ResourceManager.clearTimeout(this.dedicatedTimeouts.clearMap);
    this.internalFlags.placeSkipped = true;
    this.dedicatedTimeouts.clearMap = setTimeout(() => {
      this.setState(EGmapStates.FINISHED_LOCATION);
    }, 500);
  }

  /**
   * recalculate directions when the user selects another place from the map suggestions
   */
  async recalculateDirections() {
    this.internalFlags.routeRecalculateRequested = false;
    this.buttonOptions.recalculateDirections.blink = false;
    await this.clearNav();
    // this.clearLastPlace();
    this.dedicatedTimeouts.clearMap = setTimeout(() => {
      this.setState(EGmapStates.GET_DIRECTIONS);
    }, 500);
  }

  /**
   * enable eagle view
   * show all treasures regardless of the zoom level
   */
  enableEagleView() {
    if (!this.internalFlags.eagleView) {
      this.internalFlags.eagleView = true;
      this.onSetZoomLevel(MapSettings.zoomInLevelCrt);
      this.setHudState(true, true);
      this.initTimeoutActivity(300, false).then(() => {
        this.disableEagleView();
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }

  async disableEagleViewBeforeActivityStart(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve) => {
      await this.exitActivityMain(true);
      this.disableEagleView();
      resolve(true);
    });
    return promise;
  }

  /**
   * disable eagle view
   * reset zoom level
   */
  disableEagleView() {
    this.internalFlags.eagleView = false;
    this.setHudState(false, true);
    this.onSetZoomLevel(this.mapManager.getZoom());
  }

  /**
   * scan places on user button click
   * uses 1 scan energy (from the user inventory)
   */
  scanPlaces(scan: boolean, direction: number) {
    let amount: number = AppConstants.gameConfig.defaultScanEnergy;
    if (!this.storySelected) {
      // may enable eagle view      
      if (this.internalFlags.zoomOut) {
        this.inventoryWizard.consumeScanEnergyGenericWizard(() => {
          this.messageQueueHandler.prepare("-" + amount + " energy", false, EQueueMessageCode.info);
          this.enableEagleView();
        }, () => {
          this.inventoryWizard.goToInventoryForScanEnergy().then(() => {

          }).catch((err: Error) => {
            console.error(err);
          });
        }, null, amount);
      }
      return;
    }

    this.inventoryWizard.consumeScanEnergyGenericWizard(async () => {
      this.messageQueueHandler.prepare("-" + amount + " energy", false, EQueueMessageCode.info);
      this.user.hasScanned = true;
      this.internalFlags.bufferDirection = direction;
      this.user.clickedScan = true;

      // this.exploreProvider.exitExploreActivity(true);
      await this.exitActivityMain(true);
      await this.clearNav();
      // this.clearLastPlace(false);
      this.clearLastPlace(true);

      // can search new location even for saved locations
      if (this.app.storyLocations[this.app.locationIndex].flag !== ELocationFlag.FIXED) {
        this.app.storyLocations[this.app.locationIndex].flag = ELocationFlag.RANDOM;
      }
      this.dedicatedTimeouts.clearMap = ResourceManager.clearTimeout(this.dedicatedTimeouts.clearMap);
      // return from buffer only if user position not changed by more than 100 m since last (real) search
      this.mapEngineUtils.checkLocationScanDistance(this.currentLocation.location);
      this.analytics.sendCustomEvent(ETrackedEvents.story, "scan places", "story: " + this.storyId + ", location: " + this.app.locationIndex, this.storyId, true);
      this.dedicatedTimeouts.clearMap = setTimeout(() => {
        if (scan) {
          this.setState(EGmapStates.SEARCH);
        } else {
          this.setState(EGmapStates.GET_DIRECTIONS);
        }
      }, 500);
    }, () => {
      this.inventoryWizard.goToInventoryForScanEnergy().then(() => {

      }).catch((err: Error) => {
        console.error(err);
      });
    }, null, amount);
  }

  /**
   * handle timeout activity
   * to be integrated within composed activities
   * does not notify on complete
   */
  initTimeoutActivity(timeLimit: number, updateGauge: boolean): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve) => {
      if (timeLimit) {
        this.subscribeToTimerWatchDisplay(updateGauge);
      }
      this.activityProvider.initGenericTimeoutActivity(timeLimit);
      this.subscription.activityStatusMonitor = this.timeoutMonitor.getWatch().subscribe((tmData: ITimeoutMonitorData) => {
        // console.log(tmData);
        if (tmData) {
          switch (tmData.status) {
            case ETimeoutStatus.expired:
              this.subscription.activityStatusMonitor = ResourceManager.clearSub(this.subscription.activityStatusMonitor);
              resolve(true);
              break;
          }
        }
      }, (err: Error) => {
        console.error(err);
        resolve(false);
      });
    });
    return promise;
  }

  /**
   * includes timeout
   */
  initARExploreActivityMain(timeLimit: number): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve) => {
      this.arexploreProvider.initActivity(timeLimit);
      this.subscribeToTimerWatchDisplay(false);
      this.subscription.activityStatusMonitor = this.arexploreProvider.watchStatus().subscribe((status: IARExploreStatus) => {
        // console.log(status);
        if (status) {
          switch (status.status) {
            case ECheckActivityResult.failed:
              this.subscription.activityStatusMonitor = ResourceManager.clearSub(this.subscription.activityStatusMonitor);
              resolve(false);
              break;
            case ECheckActivityResult.done:
              this.subscription.activityStatusMonitor = ResourceManager.clearSub(this.subscription.activityStatusMonitor);
              resolve(true);
              break;
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    });
    return promise;
  }

  /**
  * includes timeout
  */
  initQuestActivityMain(activity: IActivity, qad: IQuestActivityDef): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve) => {
      let descriptionParams: IActivityParamsView = this.activityProvider.getCustomActivityParams(activity, null, null, this.story);
      let questData: IActivityQuestSpecs = descriptionParams.questData;
      // handle dynamic quest params
      activity.questData = questData;

      this.activityProvider.initQuestActivity(qad, questData);
      this.subscribeToTimerWatchDisplay(false);

      this.subscription.activityStatusMonitor = this.questActivityProvider.watchStatus().subscribe((status: IQuestActivityStatus) => {
        // console.log(status);
        if (status) {
          switch (status.status) {
            case ECheckActivityResult.failed:
              this.subscription.activityStatusMonitor = ResourceManager.clearSub(this.subscription.activityStatusMonitor);
              resolve(false);
              break;
            case ECheckActivityResult.done:
              // handle qr code (check) in activity service (disabled)
              this.subscription.activityStatusMonitor = ResourceManager.clearSub(this.subscription.activityStatusMonitor);
              resolve(true);
              break;
            case ECheckActivityResult.validated:
              this.subscription.activityStatusMonitor = ResourceManager.clearSub(this.subscription.activityStatusMonitor);
              resolve(true);
              break;
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    });
    return promise;
  }

  /**
   * photo detect
   * @param params 
   */
  initPhotoActivityMain(activity: IActivity, params: IPhotoActivityParams): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve, reject) => {
      let descriptionParams: IActivityParamsView = this.activityProvider.getCustomActivityParams(activity, null, null, this.story);
      let photoData: IActivityPhotoSpecs = descriptionParams.photoData;
      if (photoData != null) {
        params.referencePhotoUrl = photoData.ref;
      }
      if (descriptionParams.multiPhotoData) {
        params.photoGridCount = descriptionParams.multiPhotoData.length;
      }

      this.activityProvider.initPhotoActivity(params);
      if (params.timeLimit) {
        this.subscribeToTimerWatchDisplay(params.updateGauge ? true : false);
        this.subscription.activityStatusMonitor = this.photoActivityProvider.watchStatus().subscribe((status: IPhotoActivityStatus) => {
          // console.log("photo activity status: ", status);
          if (status) {
            switch (status.status) {
              case ECheckActivityResult.failed:
                this.subscription.activityStatusMonitor = ResourceManager.clearSub(this.subscription.activityStatusMonitor);
                resolve(false);
                break;
              case ECheckActivityResult.done:
                this.subscription.activityStatusMonitor = ResourceManager.clearSub(this.subscription.activityStatusMonitor);
                resolve(true);
                break;
            }
          }
        }, (err: Error) => {
          console.error(err);
        });
      } else {
        reject(new Error("photo activity requires timeout"));
      }
    });
    return promise;
  }

  /**
   * does not include timeout
   * resolve: done flag
   * true if activity validated (enough coins were collected, even if time expired)
   * false if activity failed (time expired)
   */
  initExploreActivityMain(params: IExploreActivityInit, switchNav: boolean, isMain: boolean): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve) => {
      params = this.activityProvider.initExploreActivityStage1(params);
      // let navMode: number = EMapInteraction.switch2dHeading;

      if (params.timeLimit && isMain) {
        console.log("explore activity timer watch");
        if (params.timeGauge) {
          console.log("explore activity timer gauge enabled");
          this.navGauge.setMode(ENavGaugeDict.right, ENavGaugeMode.timeLeft, ENavGaugeTooltip.timeLeft, params.timeLimit);
          this.navGauge.setIcon(ENavGaugeDict.right, EAppIcons.stopwatch, true);
          this.navGauge.show(ENavGaugeDict.right);
        }
        this.subscribeToTimerWatchDisplay(params.timeGauge);
      }

      console.log("init explore activity main: ", params);

      let promiseNav: any;
      if (switchNav && !this.flags.droneMode) {
        // promiseNav = this.setFollowCore(navMode, false, false, false);
        promiseNav = this.setFollowNav3D();
      } else {
        promiseNav = Promise.resolve(true);
      }

      if (params.collectGauge) {
        this.navGauge.setMode(ENavGaugeDict.left, ENavGaugeMode.collectibles, ENavGaugeTooltip.coins, params.coinCap);
        this.navGauge.setIcon(ENavGaugeDict.left, "ribbon", false);
        this.navGauge.show(ENavGaugeDict.left);
      }

      promiseNav.then(() => {
        // may update params if not fully defined (e.g. coinCap and minCollectedCoins for explore-x, generated at runtime)
        params = this.activityProvider.initExploreActivityStage2(params);
        this.activityStarted.ANY = true;
        this.activityStarted.explore = true;

        this.activityProvider.checkExploreMoveTransition(EExploreMoveStat.simulateExploreComplete).then(async () => {
          await this.exitExploreActivityMain(isMain);
          resolve(true);
        });

        // watch coin generator
        this.subscription.coinGenerator = this.exploreUtils.getWatchCoins().subscribe(async (stat: IExploreCoinGen) => {
          if (stat != null) {
            switch (stat.action) {
              case EExploreCoinAction.create:
                this.app.itemsGenerated = stat.index;
                this.showHudMessage(EMapHudCodes.collectedCoins, this.app.collectedItemsCurrentActivity + "/" + this.app.itemsGenerated, null);
                break;
              case EExploreCoinAction.collectAvailable:
                this.collectibles.coins.list = this.exploreUtils.getCoinObjectsInRange().map(coinObject => {
                  let obj: INearbyContentMagnetElem = {
                    elem: coinObject,
                    type: ENearbyContentType.coin
                  };
                  return obj;
                });
                this.collectibles.coins.objectsNearby = true;
                this.buttonOptions.magnet.blink = true;
                break;
              case EExploreCoinAction.collectNotAvailable:
                this.collectibles.coins.list = [];
                this.collectibles.coins.objectsNearby = false;
                this.resetCollectiblesMagnet();
                break;
              case EExploreCoinAction.collect:
                console.log("watch coins collect stat: ", stat);
                this.app.collectedItemsCurrentActivity = stat.amount; // cumulative
                this.app.collectedItemsValueCurrentActivity = stat.value; // cumulative
                this.soundManager.vibrateContext(true);
                this.soundEffects.playQueueNoAction(SoundUtils.soundBank.coin.id);
                this.showHudMessage(EMapHudCodes.collectedCoins, this.app.collectedItemsCurrentActivity + "/" + this.app.itemsGenerated, null);

                if (params.collectGauge) {
                  this.navGauge.setLevel(ENavGaugeDict.left, stat.amount);
                }

                if ((stat.amount >= params.minCollectedCoins) || params.singleTarget) {
                  HudUtils.setHudHighlight(this.hudMsg, EMapHudCodes.collectedCoins, HudUtils.getHudDisplayModes().complete);

                  if (params.collectGauge) {
                    let target: number = !params.singleTarget ? params.minCollectedCoins : 1;
                    this.navGauge.checkTargetSetLockThemeCore(ENavGaugeDict.left, target);
                  }

                  if (params.isMainObjective) {
                    // enable complete activity button
                    this.app.canCompleteActivity = true;
                  }
                }

                if ((stat.amount === params.coinCap) || params.singleTarget) {
                  // finished activity (collected all coins)
                  await SleepUtils.sleep(1000);
                  await this.exitExploreActivityMain(isMain);
                  resolve(true);
                }
                break;
              case EExploreCoinAction.finished:
                await this.exitExploreActivityMain(isMain);
                resolve(true);
                break;
              case EExploreCoinAction.exception:
                console.log("time expired registered");
                await this.exitExploreActivityMain(isMain);
                if (this.app.collectedItemsCurrentActivity >= params.minCollectedCoins) {
                  resolve(true);
                } else {
                  resolve(false);
                }
                break;
            }
          }
        }, (err: Error) => {
          console.error(err);
        });
      }).catch((err: Error) => {
        console.error(err);
      });
    });
    return promise;
  }


  /**
  * does not include timeout
  * resolve: done flag
  * true if activity validated (enough coins were collected, even if time expired)
  * false if activity failed (time expired)
  */
  initExploreNavMain(params: IExploreActivityInit) {
    params = this.activityProvider.initExploreActivityStage1(params);
    console.log("init explore nav main: ", params);
    this.activityProvider.setActivityNavCollectType(EActivityCodes.explore);
    // may update params if not fully defined (e.g. coinCap and minCollectedCoins for explore-x, generated at runtime)
    params = this.activityProvider.initExploreActivityStage2(params);
    // watch coin generator
    this.subscription.coinGenerator = this.exploreUtils.getWatchCoins().subscribe(async (val: IExploreCoinGen) => {
      if (val != null) {
        console.log("watch coins: ", val);
        switch (val.action) {
          case EExploreCoinAction.create:
            this.app.itemsGenerated = val.index;
            this.showHudMessage(EMapHudCodes.collectedCoins, this.app.collectedItemsCurrentActivity + "/" + this.app.itemsGenerated, null);
            break;
          case EExploreCoinAction.collectAvailable:
            this.collectibles.coins.list = this.exploreUtils.getCoinObjectsInRange().map(coinObject => {
              let obj: INearbyContentMagnetElem = {
                elem: coinObject,
                type: ENearbyContentType.coin
              };
              return obj;
            });
            this.collectibles.coins.objectsNearby = true;
            this.buttonOptions.magnet.blink = true;
            break;
          case EExploreCoinAction.collectNotAvailable:
            this.collectibles.coins.list = [];
            this.collectibles.coins.objectsNearby = false;
            this.resetCollectiblesMagnet();
            break;
          case EExploreCoinAction.collect:
            this.app.collectedItemsCurrentActivity = val.amount; // cumulative
            this.app.collectedItemsValueCurrentActivity = val.value; // cumulative
            this.soundManager.vibrateContext(true);
            this.soundEffects.playQueueNoAction(SoundUtils.soundBank.coin.id);
            this.showHudMessage(EMapHudCodes.collectedCoins, this.app.collectedItemsCurrentActivity + "/" + this.app.itemsGenerated, null);
            break;
          case EExploreCoinAction.overlap:
            break;
        }
      }
    }, (err: Error) => {
      console.error(err);
    });
  }

  /**
  * does not include timeout
  * resolve: done flag
  * true if activity validated (enough coins were collected, even if time expired)
  * false if activity failed (time expired)
  */
  initEvadeActivityMain(params: IExploreActivityInit, switchNav: boolean): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve) => {
      params = this.activityProvider.initExploreActivityStage1(params);
      // let navMode: number = EMapInteraction.switch2dHeading;

      if (params.timeLimit) {
        if (params.timeGauge) {
          this.navGauge.setMode(ENavGaugeDict.right, ENavGaugeMode.timeLeft, ENavGaugeTooltip.timeLeft, params.timeLimit);
          this.navGauge.setIcon(ENavGaugeDict.right, EAppIcons.stopwatch, true);
          this.navGauge.show(ENavGaugeDict.right);
        }
        this.subscribeToTimerWatchDisplay(params.timeGauge);
      }

      if (params.evadeGauge) {
        this.navGauge.setMode(ENavGaugeDict.left, ENavGaugeMode.distLeftInverted, ENavGaugeTooltip.distanceFromTarget, params.evadeRadius);
        this.navGauge.setIcon(ENavGaugeDict.left, EAppIcons.starred, true);
        this.navGauge.show(ENavGaugeDict.left);
      }

      let promiseNav: any;
      if (switchNav && !this.flags.droneMode) {
        // promiseNav = this.setFollowCore(navMode, false, false, false);
        promiseNav = this.setFollowNav3D();
      } else {
        promiseNav = Promise.resolve(true);
      }

      promiseNav.then(() => {
        // may update params if not fully defined (e.g. coinCap and minCollectedCoins for explore-x, generated at runtime)
        params = this.activityProvider.initExploreActivityStage2(params);
        this.activityStarted.ANY = true;
        this.activityStarted.explore = true;

        // watch coin collector
        this.subscription.coinGenerator = this.exploreUtils.getWatchCoins().subscribe(async (val: IExploreCoinGen) => {
          if (val != null) {
            switch (val.action) {
              case EExploreCoinAction.create:
                break;
              case EExploreCoinAction.collect:
                // reached the target location
                resolve(true);
                break;
              case EExploreCoinAction.overlap:
                // got caught
                await SleepUtils.sleep(1000);
                await this.exitExploreActivityMain(true);
                console.log("got caught");
                setTimeout(() => {
                  resolve(false);
                }, 1);
                break;
              case EExploreCoinAction.exception:
                resolve(true);
                break;
              case EExploreCoinAction.evadeComplete:
                resolve(true);
                break;
            }
          }
        }, (err: Error) => {
          console.error(err);
        });
      }).catch((err: Error) => {
        console.error(err);
      });

      let navInitComplete: boolean = false;

      this.subscription.exploreCollectStatus = this.exploreProvider.watchCollectStatus().subscribe((status: boolean) => {
        if (status) {
          if (params.evadeGauge) {
            console.log("update evade gauge");
            let stat: IExploreActivityStatus = this.exploreProvider.getEStatus();
            // console.log("evade radius computed: ", stat.evadeRadiusComputed);
            if (stat.evadeRadiusComputed && !navInitComplete) {
              navInitComplete = true;
              console.log("evade radius computed: ", stat.evadeRadiusComputed);
              this.navGauge.setMode(ENavGaugeDict.left, ENavGaugeMode.distLeftInverted, ENavGaugeTooltip.distanceFromTarget, stat.evadeRadiusComputed);
            }
            if (stat.minDistanceToAnyObject != null) {
              this.navGauge.setLevel(ENavGaugeDict.left, stat.minDistanceToAnyObject);
            }
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    });
    return promise;
  }

  showHudMessage(code: number, value: string, unit: string) {
    let shown: boolean = HudUtils.showHudMessage(this.hudMsg, code, value, unit, this.flags.mapDebugMode);
    if (shown) {
      // don't open hud if no element is shown (check)
      this.app.hud = true;
    }
  }

  closeHud() {
    this.app.hud = false;
  }

  /**
   * clear all resources associated with the explore activity
   */
  async exitExploreActivityMain(isMain: boolean): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve) => {
      try {
        await this.activityProvider.exitExploreActivity(isMain);
        this.exploreUtils.setCollectRadiusOverride(null);
        await this.markerHandler.clearMarkersResolve(EMarkerLayers.COINS);
      } catch (e) {
        console.error(e);
      }
      this.markerHandler.clearMarkerLayer(EMarkerLayers.COINS);
      this.subscription.navigateToCollectible = ResourceManager.clearSub(this.subscription.navigateToCollectible);
      this.subscription.coinGenerator = ResourceManager.clearSub(this.subscription.coinGenerator);
      this.subscription.exploreCollectStatus = ResourceManager.clearSub(this.subscription.exploreCollectStatus);
      this.unsubscribeFromTimerWatch();
      resolve(true);
    });
    return promise;
  }

  async exitFindActivityMain(): Promise<boolean> {
    this.subscription.targetReachedAR = ResourceManager.clearSub(this.subscription.targetReachedAR);
    this.subscription.navigate = ResourceManager.clearSub(this.subscription.navigate);
    await this.clearNav();
    return this.findActivityProvider.exitFindActivity();
  }

  exitARExploreActivityMain() {
    this.subscription.activityStatusMonitor = ResourceManager.clearSub(this.subscription.activityStatusMonitor);
    this.unsubscribeFromTimerWatch();
    this.arexploreProvider.exitActivity();
  }

  exitGenericTimeoutActivityMain() {
    this.subscription.activityStatusMonitor = ResourceManager.clearSub(this.subscription.activityStatusMonitor);
    this.unsubscribeFromTimerWatch();
    this.activityProvider.exitGenericTimeoutActivity();
  }

  exitPhotoActivityMain() {
    this.subscription.activityStatusMonitor = ResourceManager.clearSub(this.subscription.activityStatusMonitor);
    this.unsubscribeFromTimerWatch();
    this.activityProvider.exitPhotoActivity();
  }

  async exitQuestActivityMain() {
    this.subscription.activityStatusMonitor = ResourceManager.clearSub(this.subscription.activityStatusMonitor);
    this.unsubscribeFromTimerWatch();
    this.activityProvider.exitQuestActivity();
    await this.removeFixMarker();
  }

  /** 
   * open AR view modal
   */
  async openAR() {
    console.log("opening ar view");
    this.createLinkViewObs();
    let activity: IActivity;
    if (this.app.challengeInProgress) {
      activity = this.challengeEntry.getActivity();
    } else {
      if (this.storySelected) {
        activity = this.app.storyLocations[this.app.locationIndex].loc.merged.activity;
      }
    }

    console.log(activity);
    await SleepUtils.sleep(100);

    this.messageQueueHandler.cleanup();
    let params: IARViewNavParams = {
      linkMapSendToAR: this.observables.linkViewSend,
      linkARSendToMap: this.observables.linkViewReceive,
      hudMsg: this.hudMsg,
      activity: activity
    };

    // check for special AR activities
    if (this.activityStarted.screenshotAR) {
      let customParams: ICustomParamForActivity[] = activity.customParams;
      params.specialActivity = {
        code: EARSpecialActivity.screenshotAR,
        customParams: customParams
      };
    }

    if (this.activityStarted.find) {
      let customParams: ICustomParamForActivity[] = activity.customParams;
      params.specialActivity = {
        code: EARSpecialActivity.find,
        customParams: customParams
      };
    }

    let navParams: INavParams = {
      view: {
        fullScreen: true,
        transparent: false,
        large: true,
        addToStack: false,
        frame: false
      },
      params: params
    };

    this.beforeInitAR(activity);
    let compassAvailable: boolean = await this.headingService.checkCompassOrientationAvailable();
    let gyroscopeAvailable: boolean = await this.headingService.checkGyroscopeAvailable();
    let message: string = "<p>Required sensors not available on this device:</p>";
    if (!compassAvailable) {
      message += "<p>>compass</p>";
    }
    if (!gyroscopeAvailable) {
      message += "<p>>gyroscope</p>";
    }
    if (!(compassAvailable && gyroscopeAvailable) && !this.platform.WEB) {
      await this.uiext.showAlert(Messages.msg.ARNotSupportedMissingSensors.after.msg, message, 1, null);
      return;
    }

    try {
      let req: boolean = false;
      let granted = {
        camera: false,
        orientation: false
      };
      let permissionsNotGranted: string[] = [];
      req = !await this.permissionsService.checkCameraPermission();
      if (req) {
        await this.permissionsService.requestCameraPermission();
        granted.camera = await this.permissionsService.checkCameraPermission();
        if (!granted.camera) {
          permissionsNotGranted.push("camera");
          permissionsNotGranted.push("storage");
        }
        await SleepUtils.sleep(500);
      } else {
        granted.camera = true;
      }
      req = !await this.permissionsService.checkOrientationSensorsPermissionsNotRequired();
      if (req) {
        granted.orientation = await this.permissionsService.requestOrientationSensorsPermissions();
        if (!granted.orientation) {
          permissionsNotGranted.push("orientation");
        }
        await SleepUtils.sleep(500);
      } else {
        granted.orientation = true;
      }
      let permissionsNotGrantedStr: string = "";
      for (let p of permissionsNotGranted) {
        permissionsNotGrantedStr += "<p>> " + p + "</p>";
      }
      if (granted.camera && granted.orientation) {
        this.uiext.showCustomModal(null, ARViewEntryPage, navParams).then((res: any) => {
          console.log("returned from ar view");
          console.log(res);
          this.onExitAR();
        }).catch((err: Error) => {
          console.error(err);
          this.onExitAR();
        });
      } else {
        let res: number = await this.uiext.showAlert(Messages.msg.ARNotAvailablePermissions.after.msg, Messages.msg.ARNotAvailablePermissions.after.sub + permissionsNotGrantedStr, 2, ["dismiss", "check"]);
        if (res === EAlertButtonCodes.ok) {
          // check native settings
          await this.permissionsService.checkNativePermission();
        }
        this.onExitAR();
      }
    } catch (err) {
      console.error(err);
      let res: number = await this.uiext.showAlert(Messages.msg.ARNotAvailablePermissions.after.msg, Messages.msg.ARNotAvailablePermissions.after.sub, 2, ["dismiss", "check"]);
      if (res === EAlertButtonCodes.ok) {
        // check native settings
        await this.permissionsService.checkNativePermission();
      }
      this.onExitAR();
    }
  }


  /**
   * init defaults for game mechanics with AR
   */
  beforeInitAR(activity: IActivity) {
    this.disableGPSMapUpdate();
    this.internalFlags.mapAllowCollectFromAR = true;
    this.internalFlags.AROpen = true;
    let similarActivityCode: number = ActivityUtils.checkSimilarActivity(activity);
    this.itemScanner.setAutoCollect(false);
    this.activityProvider.setAutoCollectOpenARDefault();
    switch (similarActivityCode) {
      case EActivityCodes.explore:
        this.exploreProvider.setAutoCollectInt(SettingsManagerService.settings.app.settings.ARExploreAutoCollect.value);
        break;
      case EActivityCodes.find:
        this.findActivityProvider.setAutoCollectInt(false);
        break;
    }
  }

  /**
   * resume defaults for game mechanics on the map
   */
  async onExitAR() {
    this.internalFlags.AROpen = false;
    if (this.observables.linkViewReceive) {
      this.observables.linkViewReceive.next(EViewLinkCodes.exitAck);
      this.observables.linkViewReceive.next(null);
    }
    this.onModalClosed();
    this.internalFlags.mapAllowCollectFromAR = false;
    this.itemScanner.setAutoCollect(false);
    this.activityProvider.setAutoCollectExitARDefault();

    // set default watch heading
    // this.headingService.stopWatchHeading();
    this.internalFlags.isHeadingTrackEngaged = false;
    this.stopWatchHeading();
    await SleepUtils.sleep(500);
    this.watchHeading();
  }


  exitLinkView() {
    if (this.observables.linkViewSend) {
      this.observables.linkViewSend.next(EViewLinkCodes.exit);
      this.observables.linkViewSend.next(null);
    }
  }

  /**
   * handle activity finished
   * doubles collected coins for activity if watched reward video 
   * only resolve
   */
  handleActivityFinishedResolve(activityCode: number, locationName: string, progressAux: IShareActivityProgress, loc: ILocationContainer, activityStats: IActivityStatsContainer): Promise<number> {
    let promise: Promise<number> = new Promise(async (resolve) => {
      await this.onModalOpened();
      this.droneSimulator.onCompleteChallengeCheck();
      await this.exitARSync();
      await this.exitDroneMode(true);
      this.notifyActivityFinished();

      let activity: IActivity = loc.merged.activity;
      let activityParams: IVisitActivityDef = activity.params;
      let baseActivityCode: number = activityCode;
      let photoUrl: string = null;
      let coinValue: number = AppConstants.gameConfig.coinValue;

      let standardRewardCapCoins: number = activityParams.standardRewardCap;
      let rewardXP: number = GameStatsUtils.getActivityFinishedWeightAdjusted(activity, loc.merged, activityStats, this.story != null ? this.story.xpScaleFactor : null);
      let videoUrl: string = null;

      if (activity) {
        baseActivityCode = ActivityUtils.checkSimilarActivity(activity);
        photoUrl = activity.photoUrl;
        if (activity.customParams) {
          let coinValue1: number = GameUtils.getAverageCoinValue(activity.customParams);
          if (coinValue1 != null) {
            // some items may not have LP value
            coinValue = coinValue1;
          }
        }
        let descriptionParams = this.activityProvider.getCustomActivityParams(activity,
          loc, this.storySelected ? ECustomParamScope.story : ECustomParamScope.challenge, this.story);

        // check video after
        if (descriptionParams.videoGuideSpecs) {
          if (descriptionParams.videoGuideSpecs.after) {
            videoUrl = descriptionParams.videoGuideSpecs.after.url;
          }
        }
      }

      if (loc.dispPhoto.photoUrl != null) {
        photoUrl = loc.dispPhoto.photoUrl;
      }

      console.log("handle activity finished loc: ", loc);

      let shareParams: IShareMsgParams = {
        place: {
          name: locationName,
          place: loc
        },
        story: {
          worldMap: !this.story,
          id: this.story ? this.story.id : null,
          name: this.story ? this.story.name : "World Map",
          rewardCoins: this.story ? this.story.rewardCoins : 0,
          story: this.story
        },
        activityStats: {
          photoUrl: photoUrl,
          videoUrl: videoUrl,
          baseCode: baseActivityCode,
          code: activityCode,
          name: activity.title,
          finishedDescription: activity.finishedDescription,
          progress: progressAux,
          standardRewardCap: standardRewardCapCoins,
          statsList: activityStats ? activityStats.statsList : null,
          stats: activityStats.stats,
          qrCodeTarget: activity.qrCode,
          qrMode: activity.qrMode
        },
        activityFinish: loc.merged.activityFinish,
        customParams: activity.customParams,
        xp: rewardXP,
        scaled: (this.story != null) && ((this.story.xpScaleFactor != null) && (this.story.xpScaleFactor !== 1)),
        items: {
          collected: this.app.collectedItemsCurrentActivity,
          collectedValue: this.app.collectedItemsValueCurrentActivity,
          reward: 0,
          value: coinValue
        },
        placeItems: {
          availableItems: this.currentLocationItems
        },
        actionButtons: {
          watchAd: false,
          scanQR: false,
          share: false
        }
      };

      let navParams: INavParams = {
        view: {
          fullScreen: true,
          transparent: false,
          large: true,
          addToStack: true,
          frame: true
        },
        params: shareParams
      };

      let promiseFinished: Promise<number> = new Promise((resolve) => {
        this.uiext.showCustomModal(null, ActivityFinishedViewComponent, navParams).then((res: IActivityFinishedModalOutput) => {
          this.onModalClosed();
          console.log("activity finished res: ", res);
          if (res != null) {
            this.app.rewardLpCurrentActivity = res.finalCoins;
            this.storyDataProvider.updatePhotoFinishUploadCache(res.photoUpload);
            this.storyDataProvider.updateDoneAuxFlag(res.doneAux);
            rewardXP += res.rewardFinishXP;

            switch (res.status) {
              case EFinishedActionParams.reward:
                // this.analytics.sendCustomEvent("ETrackedEvents.story", "reward video activity", "story: " + this.storyId + ", location: " + this.app.locationIndex, this.storyId);
                resolve(ECheckActivityResult.done);
                break;
              default:
                if (this.mpGameInterface.isOnline()) {
                  // disable ads in mp game (prevent sync errors)
                  resolve(ECheckActivityResult.done);
                } else {
                  this.popupFeatures.watchAdResolve(true).then(() => {
                    resolve(ECheckActivityResult.done);
                  });
                }
                break;
            }
          } else {
            resolve(ECheckActivityResult.done);
          }
        }).catch((err: Error) => {
          console.error(err);
          this.onModalClosed();
          this.analytics.dispatchError(err, "gmap");
          resolve(ECheckActivityResult.done);
        });
      });

      promiseFinished.then((res: number) => {
        // register coins collected
        // even if location not finished (e.g. skipped location)
        if (this.app.rewardLpCurrentActivity) {
          this.app.rewardLpStoryTotal += this.app.rewardLpCurrentActivity;
          this.userStatsProvider.registerLPCollected(this.story ? this.story.id : null, this.app.rewardLpCurrentActivity).then(() => {
            this.app.rewardLpCurrentActivity = 0;
            this.showPlaceVisitedRewardPopup(loc, rewardXP).then(() => {
              resolve(res);
            });
          }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "gmap");
            this.app.rewardLpCurrentActivity = 0;
            this.showPlaceVisitedRewardPopup(loc, rewardXP).then(() => {
              resolve(res);
            });
          });
        } else {
          this.showPlaceVisitedRewardPopup(loc, rewardXP).then(() => {
            resolve(res);
          });
        }
      });
    });
    return promise;
  }

  showPlaceVisitedRewardPopup(loc: ILocationContainer, overrideXP: number): Promise<boolean> {
    // show xp reward
    let xpScaleFactor: number = this.story != null ? this.story.xpScaleFactor : null;
    let applyScaleFactor: boolean = xpScaleFactor != null;
    let hasLocActivity: boolean = (loc != null) && (loc.merged.activity != null);
    let level: number = hasLocActivity ? loc.merged.activity.level : null;
    if (overrideXP == null) {
      overrideXP = hasLocActivity ? loc.merged.rewardXp : null; // use XP from story location
    } else {
      applyScaleFactor = false; // use overrideXP (scale factor included)
    }
    return this.mapGeneralUtils.showRewardResolve(GameStatsUtils.getGradedStatAdjusted(EStatCodes.challengesCompleted, level, overrideXP, null, xpScaleFactor, applyScaleFactor), true, this.internalFlags.levelUpPopups, this.isWorldMap);
  }

  /**
   * handle story finished
   * calculate the reward based on the total collected coins and the standard reward cap defined in the story
   * the actual collected coins are not added to the reward
   * only resolve
   */
  handleStoryFinished(finished: boolean): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve) => {
      let percentFinished: number = Math.floor((this.story.locs.filter((x) => x.merged.done === EStoryLocationDoneFlag.done).length) * 100 / this.story.locations.length);
      let totalCoinsCollected: number = this.app.rewardLpStoryTotal;
      let standardRewardCap: number = this.story.rewardCoins;

      let alreadyFinished: boolean = false;
      try {
        let check: IRegisterStoryFinished = await this.userStatsProvider.checkStoryFinished(this.story.id);
        alreadyFinished = check.existing;
      } catch (err) {
        console.error(err);
      }

      let shareParams: IShareMsgParams = {
        photo: {
          display: this.story.photoUrl,
          share: null
        },
        story: {
          worldMap: false,
          id: this.story.id,
          name: this.story.name,
          rewardCoins: standardRewardCap + totalCoinsCollected,
          progress: {
            finished: finished,
            percentFinished: percentFinished,
            alreadyFinished: alreadyFinished
          },
          xpScaleFactor: this.story.xpScaleFactor,
          story: this.story
        },
        xp: (finished && !alreadyFinished) ? GameStatsUtils.getStoryFinishedWeightAdjusted(this.story) : 0,
        items: {
          collected: 0,
          collectedValue: 0,
          reward: 0,
          value: AppConstants.gameConfig.coinValue
        },
        actionButtons: {
          watchAd: false,
          scanQR: false,
          share: false
        }
      };

      let navParams: INavParams = {
        view: {
          fullScreen: true,
          transparent: false,
          large: true,
          addToStack: true,
          frame: true
        },
        params: shareParams
      };

      await this.onModalOpened();
      this.exitLinkView();
      this.uiext.showCustomModal(null, StoryFinishedViewComponent, navParams).then((res: IStoryFinishedModalOutput) => {
        this.onModalClosed();
        switch (res.status) {
          case EFinishedActionParams.reward:
            this.analytics.sendCustomEvent(ETrackedEvents.story, "reward video story", "story: " + this.storyId, this.storyId, true);
            // reset collected coins to bonus coins, so that it's registered on story finished
            this.app.rewardLpStoryTotal = res.rewardLP;
            resolve(alreadyFinished);
            break;
          default:
            if (this.mpGameInterface.isOnline()) {
              // disable ads in mp game (prevent sync errors)
              this.app.rewardLpStoryTotal = 0;
              // do not double the coins
              resolve(alreadyFinished);
            } else {
              this.popupFeatures.watchAdResolve(true).then(() => {
                this.app.rewardLpStoryTotal = 0;
                // do not double the coins
                resolve(alreadyFinished);
              });
            }
            break;
        }
      }).catch((err: Error) => {
        console.error(err);
        this.onModalClosed();
        this.app.rewardLpStoryTotal = 0;
        this.analytics.dispatchError(err, "gmap");
        resolve(alreadyFinished);
      });
    });
    return promise;
  }

  /**
   * request activity retry
   * resolve only
   */
  retryActivityCore(): Promise<number> {
    let promise: Promise<number> = new Promise((resolve) => {
      this.analytics.sendCustomEvent(ETrackedEvents.story, "retry activity", "story: " + this.storyId + ", location: " + this.app.locationIndex, this.storyId, true);
      // request retry activity (use scan energy)
      this.inventory.retryActivity().then((res: IRemovedMultipleItemsResponse) => {
        console.log("remaining energy: ", res);
        this.premiumProvider.checkFeaturePrice(EFeatureCode.retryActivity, null, false).then((price: IFeatureDef) => {
          this.showUsedEnergy(price.price);
          resolve(ECheckActivityResult.retry);
        }).catch((err: Error) => {
          console.error(err);
          resolve(ECheckActivityResult.failed);
        });
      }).catch((_err: Error) => {
        // scan energy depleted or another error occured
        this.uiext.showAlert(Messages.msg.scanEnergyDepleted.after.msg, Messages.msg.scanEnergyDepleted.after.sub, 2, ["Dismiss", "Recharge"]).then((res: number) => {
          if (res === EAlertButtonCodes.ok) {
            this.inventoryWizard.goToInventoryForScanEnergy().then(() => {
              this.retryActivityCore().then((res: number) => {
                resolve(res);
              }).catch(() => {
                resolve(ECheckActivityResult.failed);
              });
            }).catch(() => {
              resolve(ECheckActivityResult.failed);
            });
          } else {
            resolve(ECheckActivityResult.failed);
          }
        }).catch(() => {
          resolve(ECheckActivityResult.failed);
        });
      });
    });
    return promise;
  }

  /**
  * handle activity failed
  * only resolve
  */
  handleActivityFailedResolve(info: string, reasonCode: number, retryEnabled: boolean): Promise<number> {
    let promise: Promise<number> = new Promise(async (resolve) => {
      let activityFailedParams: IActivityFailed = {
        infoHTML: info,
        reasonCode: reasonCode,
        retryEnabled: retryEnabled,
        shareMessage: null,
        xpScaleFactor: this.story != null ? this.story.xpScaleFactor : null
      };

      let navParams: INavParams = {
        view: {
          fullScreen: true,
          transparent: false,
          large: true,
          addToStack: true,
          frame: true
        },
        params: activityFailedParams
      };

      await this.onModalOpened();
      await this.exitARSync();
      await this.exitDroneMode(true);
      this.notifyActivityFailed();

      this.uiext.showCustomModal(null, ActivityFailedViewComponent, navParams).then((res: number) => {
        this.onModalClosed();
        console.log("res");
        switch (res) {
          case EFinishedActionParams.retry:
            console.log("retry activity");
            this.retryActivityCore().then((res: number) => {
              resolve(res);
            });
            break;
          default:
            resolve(ECheckActivityResult.failed);
            break;
        }
      }).catch((err: Error) => {
        console.error(err);
        this.onModalClosed();
        this.analytics.dispatchError(err, "gmap");
        resolve(ECheckActivityResult.failed);
      });
    });
    return promise;
  }

  /**
   * check photo activity before actually reaching the (possible) destination
   * so that activity can be completed earlier (maybe)
   */
  checkPhotoFindActivityNow() {
    let loc: ILocationContainer = this.app.storyLocations[this.app.locationIndex].loc;
    this.checkPhotoFindActivity(true, loc).then((res: boolean) => {
      if (res) {
        this.uiext.showAlertNoAction(Messages.msg.photoValidateOk.after.msg, Messages.msg.photoValidateOk.after.sub);
        loc.merged.photoValidated = true;
      } else {
        // this.uiext.showAlertNoAction(Messages.msg.mediaServiceNotAvailable.after.msg, Messages.msg.mediaServiceNotAvailable.after.sub);
        loc.merged.photoValidated = false;
      }
    }).catch((err: Error) => {
      console.error(err);
      loc.merged.photoValidated = false;
    });
  }

  checkPhotoFindActivity(intro: boolean, loc: ILocationContainer): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve) => {
      this.activityProvider.validatePhoto(!intro, false, false).then((res: IPhotoResultResponse) => {
        if (res) {
          this.user.canTakePhoto = false;
          if (loc && loc.merged) {
            this.storyManagerService.syncPhotoUploadResponse(loc.merged, res);
          }
          resolve(res.valid);
        } else {
          resolve(false);
        }
      }).catch((err: Error) => {
        console.error(err);
        this.analytics.dispatchError(err, "gmap");
        this.uiext.showAlertNoAction(Messages.msg.mediaServiceError.after.msg, ErrorMessage.parseAddBefore(err, Messages.msg.mediaServiceError.after.sub));
        resolve(false);
      });
    });
    return promise;
  }

  /**
   * subscribe to activity entry events
   * e.g. detect exit on skip activity and resolve promise
   * resolve true
   * ONLY check for skip event
   */
  checkActivitySkip(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve) => {
      if (!this.subscription.activityEntry) {
        this.subscription.activityEntry = this.activityProvider.watchExitState().subscribe((status: number) => {
          console.log("exit state changed: ", status);
          if (status != null) {
            // null is for refresh
            switch (status) {
              // case EActivityExitState.exit:
              //   // resolve false if the activity is exited normally
              //   resolve(false);
              //   break;
              case EActivityExitState.skip:
                // resolve true if the activity is skipped
                resolve(true);
                break;
              // default:
              //   resolve(false);
              //   break;
              default:
                break;
            }
          }
        }, (err: Error) => {
          console.error(err);
          resolve(false);
        });
      } else {
        // intentional skip event
        // the sub should be cleared on exiting the previous activity
        resolve(true);
      }
    });
    return promise;
  }

  /**
   * register treasure if specified
   * this does not trigger a notification of type treasure opened
   * @param item 
   */
  registerChallengeCompletedTreasureCheck(item: ILeplaceTreasure): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve) => {
      console.log("register challenge completed treasure check: ", item);
      if (!item) {
        resolve(false);
        return;
      }
      // wait for the item collect to complete (server checks for achievements, unlocked items, etc)
      // this will NOT be reflected in the item collect observable
      this.itemScanner.registerChallengeComplete(item).then(() => {
        resolve(true);
      }).catch((err: Error) => {
        console.error(err);
        resolve(false);
      });
    });
    return promise;
  }

  /**
  * register the item as collected on the server
  * remove from map or set as locked
  * the item may become available after a given time frame managed by the server
  * triggers a notofication of type treasure opened
  * @param item 
  */
  registerItemCollected(item: ILeplaceTreasure) {
    this.itemScanner.collectItemNoAction(item, null, false);
    // this will be reflected in the item collect observable as well
  }


  processScore(activity: IActivity, res: IActivityResultCore, treasure: ILeplaceTreasure, customRewardXp: number): Promise<number> {
    return this.processScoreCore(activity, res.activityStats, treasure, customRewardXp);
  }

  processScoreCore(activity: IActivity, activityStats: IActivityStatsContainer, treasure: ILeplaceTreasure, customRewardXp: number): Promise<number> {
    let promise: Promise<number> = new Promise(async (resolve) => {
      if (activityStats) {
        let score: IActivityScoreResponse = await this.activityStatsProvider.getActivityScoreResolve(activity.code, activity.similarCode, activity.paramsList, activityStats.stats, treasure, customRewardXp);
        if (score != null) {
          activityStats.stats.computed = {
            defaultScore: score.defaultScore,
            specScore: score.score
          };
          // register stats handled by get activity score function on the server
          resolve(score.score);
        }
        resolve(null);
      } else {
        resolve(null);
      }
    });
    return promise;
  }

  /**
   * handle scanner after challenge complete/exit
   * check world map / story mode for restore show treasure layers
   */
  async afterChallengeScannerSetup() {
    if (this.checkWorldMapFreeRoamingMode()) {
      this.itemScanner.setUnlockScanner(this.enableScanner);
    } else {
      if (this.checkTreasuresInStoryEnabled()) {
        if (this.enableScanner) {
          this.itemScanner.setUnlockScanner(true);
          await this.restoreShowTreasureLayersRetryResolve();
        } else {
          this.itemScanner.setUnlockScanner(false);
        }
      }
    }
  }

  checkTreasuresInStoryEnabled() {
    return this.flags.treasuresInStoryline && this.treasuresInStory;
  }

  /**
   * additional checks and popups
   * cleanup included
   * returns status code
   */
  checkActivityWrapper(activity: IActivity, appLocation: IAppLocation, treasure: ILeplaceTreasure, startOptions: IGmapActivityStartOptions, customRewardXp: number, fromSequence: boolean): Promise<IActivityResultCore> {
    let promise: Promise<IActivityResultCore> = new Promise(async (resolve, reject) => {
      this.internalFlags.reachedChallengeWithDrone = this.droneSimulator.simulationStarted;
      let specificActivityCode: number = activity.code;
      console.log("check activity wrapper: ", activity);
      await this.disableEagleViewBeforeActivityStart();
      this.app.challengeInProgress = true;
      this.activityStarted.ANYTEMP = true;
      let currentLocationIndex: number = this.app.locationIndex;
      MapSettings.setZoomInLevel(MapSettings.zoomInLevelChallenge, this.getCurrentZoomLevelDelta());

      this.checkActivityCore(activity, appLocation, treasure, startOptions, fromSequence).then(async (res: IActivityResultCore) => {
        console.log("check activity core result: ", res);
        this.buttonOptions.places.blink = false;
        this.internalFlags.enableAR = true;
        if (res.placeFound) {
          appLocation = res.placeFound;
        }
        let loc: ILocationContainer = appLocation ? appLocation.loc : null;
        let hasStats: boolean = res.activityStats != null;
        if (hasStats) {
          this.droneSimulator.onCompleteChallengeCheck();
          res.activityStats.stats.droneUsed = this.activityStatsTracker.checkDroneUsed();
        }
        MapSettings.setZoomInLevel(MapSettings.zoomInLevelDefault, this.getCurrentZoomLevelDelta());
        // clear activity
        await this.quitChallenge(false);
        await this.afterChallengeScannerSetup();
        await this.closeGmapDetailResolve();
        this.navGauge.reset();
        this.activityStarted.ANYTEMP = false;
        switch (res.status) {
          case ECheckActivityResult.done:
            await this.registerChallengeCompletedTreasureCheck(treasure);
            // get activity score (processed by the server)
            if (hasStats) {
              await this.processScore(activity, res, treasure, customRewardXp);
            }
            let status: number = await this.handleActivityFinishedResolve(specificActivityCode, loc.merged.name, res.shareParams, loc, res.activityStats);
            res.status = status;
            await this.storyManagerService.updateStoryMarker(currentLocationIndex, true, true, (currentLocationIndex + 1) + ECheckpointMarkerStatus.done);
            resolve(res);
            break;
          case ECheckActivityResult.failed:
          case ECheckActivityResult.skipped:
            this.app.challengeInProgress = false;
            this.activityStarted.ANYTEMP = false;
            if (res.failCode === null) {
              if (!this.mpGameInterface.isOnline()) {
                await this.popupFeatures.watchAdResolve(true);
              }
              resolve(res);
            } else {
              let status: number = await this.handleActivityFailedResolve(res.message, res.failCode, res.retryEnabled);
              if (res.status === ECheckActivityResult.failed) {
                res.status = status;
                await this.storyManagerService.updateStoryMarker(currentLocationIndex, true, false, (currentLocationIndex + 1) + ECheckpointMarkerStatus.failed);
              }
              if (!this.mpGameInterface.isOnline()) {
                await this.popupFeatures.watchAdResolve(true);
              }
              resolve(res);
            }
            break;
          default:
            resolve(res);
            break;
        }
      }).catch((err: Error) => {
        this.internalFlags.enableAR = true;
        this.buttonOptions.places.blink = false;
        reject(err);
      });
    });
    return promise;
  }

  /**
   * check if mode is world map free roaming
   */
  checkWorldMapFreeRoamingMode() {
    // unlock scanner in world map free roaming
    let enableTreasures: boolean = this.isWorldMap;
    // a story may be in progress though (still in world map mode, by starting a story from the map)
    if (this.storySelected) {
      enableTreasures = false;
    }
    return enableTreasures;
  }

  /**
   * show activity tutorial with drone launcher option
   * @param loaderCode 
   * @param title 
   * @param withDrone 
   * resolve drone mode enabled
   */
  showActivityTutorial(activity: IActivity, withDrone: number): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve) => {
      this.activityProvider.showActivityTutorialResolve(activity, withDrone, !this.internalFlags.manualChallengeStart).then((enterDrone: boolean) => {
        if (enterDrone) {
          this.enterDroneMode(true).then(() => {
            resolve(true);
          })
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  }

  /**
  * show gmap detail with drone launcher option
  * resolve drone mode enabled
  */
  showActivityTutorialInContext(appLocation: IAppLocation, withDrone: number, startOptions: IGmapActivityStartOptions, start: boolean): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve) => {
      try {
        let enterDrone: boolean = false;
        if (this.internalFlags.usingFallbackNav) {
          resolve(false);
          return;
        }
        if (startOptions && startOptions.optionsPreloaded) {
          enterDrone = startOptions.launchDrone;
        } else {
          // show gmap detail
          let opts: IGmapActivityPreviewOptions = {
            inProgress: false,
            withDismiss: !start,
            withDrone: withDrone,
            startReady: start,
            isPreview: true,
            isAutostart: !this.internalFlags.manualChallengeStart,
            overrideLocationIndex: false
          };
          let res: IGmapDetailReturnParams = await this.getLocationDetailsView(appLocation, this.challengeEntry.getCurrentChallengeItem(), opts, startOptions);
          if (res) {
            enterDrone = (res.code === EGmapDetailReturnCode.proceed && res.startOptionsSelected != null) ? res.startOptionsSelected.launchDrone : false;
          }
        }
        console.log("enter drone: ", enterDrone);
        if (enterDrone) {
          await this.enterDroneMode(true);
          resolve(true);
        } else {
          resolve(false);
        }
      } catch (err) {
        console.error(err);
        resolve(false);
      };
    });
    return promise;
  }

  /**
   * show activity walkthroughs, etc
   * @param activityCode 
   */
  onBeforeActivityStart(activityCode: number, activity: IActivity) {
    return new Promise<boolean>(async (resolve) => {
      if (SettingsManagerService.settings.app.settings.enableWalkthroughs.value) {
        this.dedicatedTimeouts.showWalkthrough = setTimeout(async () => {
          let tutorials: IActivityTutorialContainer = await PromiseUtils.wrapResolve(this.activityDataService.getActivityTutorials(activityCode), true);
          await this.walkthrough.showChallengeIntro(tutorials.walkthrough);
          console.log("walkthrough return to gmap");
          resolve(true);
        }, 3000);
      } else {
        await PromiseUtils.wrapResolve(this.showActivityTutorial(activity, EDroneMode.noDrone), true);
        resolve(true);
      }
    });
  }

  /**
   * don't show initial popup for drone mode selection if already selected (non-linear stories)
   * @param fromSequence 
   * @returns 
   */
  useCurrentStartOptionsLaunchDrone(fromSequence: boolean) {
    if (!this.internalFlags.showPreviewStartEnabled && fromSequence) {
      let currentParams: IGmapDetailReturnParams = this.modularViews.getCurrentGmapDetailParams();
      if (!(currentParams && currentParams.startOptionsSelected)) {
        return null;
      }
      return currentParams.startOptionsSelected.launchDrone;
    } else {
      return null;
    }
  }

  /**
   * the gmap entry point for activities
   * returns status object
   */
  checkActivityCore(activity: IActivity, appLocation: IAppLocation, item: ILeplaceTreasure, startOptions: IGmapActivityStartOptions, fromSequence: boolean): Promise<IActivityResultCore> {
    let baseActivityCode: number;
    let specificActivityCode: number;
    let photoValidate: boolean = false;

    baseActivityCode = ActivityUtils.checkSimilarActivity(activity);
    specificActivityCode = activity.code;
    photoValidate = activity.photoValidate === 1;

    let loc: ILocationContainer = null;
    if (appLocation) {
      loc = appLocation.loc;
    }

    let timeCrt = new Date().getTime();
    this.activityProvider.loadActivity(activity, "Challenge", loc, true);

    let promise: Promise<IActivityResultCore>;

    if (this.flags.contextHud) {
      this.setHudState(true, true);
    }

    let formatExtras = () => {
      let extras: IExploreCollectibleParams = {
        mode: this.internalFlags.audioGuide,
        lang: (this.story && this.story.language) ? this.story.language.langCode : null,
        voice: (this.story && this.story.language) ? this.story.language.ttsVoice : null
      };
      return extras;
    };

    let res: IActivityResultCore = {
      status: ECheckActivityResult.failed,
      shareParams: null,
      failCode: null,
      message: null,
      retryEnabled: !this.checkWorldMapFreeRoamingMode(),
      activityStats: null
    };

    let preparedCoinSpecs: ICoinSpecsMpSyncData = null;
    console.log("check activity core: ", activity);
    this.initHudState();
    ActivityUtils.getContextHudDisplayEnabled(activity, this.hudMsg);
    this.enableFollowTrueAnimate(); // re-enable user follow mode

    // test
    // baseActivityCode = EActivityCodes.escape;
    this.itemCollectorCore.initCollectTs(new Date().getTime());
    // reset treasure magnet
    this.initCollectiblesContainer();
    this.resetCollectiblesMagnet();

    let enterDrone: boolean = false;
    let activityDroneMode: number = ActivityUtils.getDroneModeAvailable(activity, this.isDroneOnly);
    this.checkDroneModeForActivity(activityDroneMode);

    let ap: IExploreActivityDef = activity.params;
    ActivityUtils.checkActivityParams(ap, activity.customParams);

    this.activityStatsTracker.startNewSession();

    switch (baseActivityCode) {
      /**
       * EXPLORE
       */
      case EActivityCodes.explore:
      case EActivityCodes.pursue:
        // includes exploreTarget
        promise = new Promise(async (resolve, reject) => {
          // disable treasure scanner
          // NOTE: this behavior is defined for each activity
          this.itemScanner.setUnlockScanner(false);
          this.activityProvider.setActivityCollectType(EActivityCodes.explore);
          try {
            await this.hideTreasureLayersRetryResolve();
            console.log("show activity tutorial");
            enterDrone = this.useCurrentStartOptionsLaunchDrone(fromSequence);
            if (enterDrone === null && !this.isPreloadStory) {
              enterDrone = await this.showActivityTutorialInContext(appLocation, activityDroneMode, startOptions, true);
            } else {
              if (enterDrone) {
                await this.enterDroneMode(true);
              }
            }
            if (enterDrone) {
              // wait for drone takeoff and virtual position capture
              await this.virtualPositionService.waitForDroneTakeOffCapture();
            }
          } catch (e) {
            console.error(e);
          }

          let ap: IExploreActivityDef = activity.params;
          this.mpGameInterface.broadcastSyncStart();
          await this.mpGameInterface.waitForSyncStart();
          preparedCoinSpecs = await this.mpGameInterface.waitForLeaderBroadcastCoinSpecs();
          let objectDynamics: number = EExploreObjectDynamics.static;
          this.buttonOptions.places.blink = true;

          switch (baseActivityCode) {
            case EActivityCodes.explore:
              objectDynamics = EExploreObjectDynamics.static;
              break;
            case EActivityCodes.pursue:
              objectDynamics = EExploreObjectDynamics.moveOnDirections;
              break;
            default:
              break;
          }

          let exploreInitParams: IExploreActivityInit = {
            timeLimit: ap.timeLimit,
            startTime: timeCrt,
            coinCap: ap.coinCap,
            coinRadius: ap.coinRadius,
            collectDistance: AppConstants.gameConfig.collectDistance,
            objectDynamics: objectDynamics,
            exploreMode: ap.mode,
            useCheckpoints: false,
            activeInventoryItems: this.activeInventoryItems,
            currentLocation: this.virtualPositionService.getCurrentPosition(),
            minCollectedCoins: this.exploreProvider.getMinRequiredCoins(activity),
            randomCoins: true,
            targetSpeed: 0,
            minRadius: 0,
            evadeRadius: null,
            isMainObjective: true,
            syncData: preparedCoinSpecs,
            singleTarget: false,
            publishSyncData: this.mpGameInterface.isLeader(),
            collectGauge: baseActivityCode === EActivityCodes.explore,
            timeGauge: true,
            evadeGauge: false,
            fixedCoins: activity.fixedCoins,
            fixedCoinCap: false,
            coinLabel: "<collect>",
            extras: formatExtras()
          };

          if (loc.merged.collectRadius != null) {
            exploreInitParams.collectDistance = loc.merged.collectRadius;
            this.exploreUtils.setCollectRadiusOverride(loc.merged.collectRadius);
          }

          if ((activity.fixedCoins != null) && (activity.fixedCoins.length > 0)) {
            exploreInitParams.coinCap = activity.fixedCoins.length;
            exploreInitParams.fixedCoinCap = true;
          }

          console.log("explore init params: ", exploreInitParams);

          this.exploreUtils.setCoinSpecsForActivity(activity);
          this.activityProvider.setCollectContext(this.internalFlags.collectMode != null ? this.internalFlags.collectMode : ECheckpointCollectMode.manual, true);
          await this.onBeforeActivityStart(activity.code, activity);

          this.initExploreActivityMain(exploreInitParams, true, true).then((done: boolean) => {
            if (done) {
              res.status = ECheckActivityResult.done;
              res.activityStats = this.activityStatsProvider.getExploreStats();
              resolve(res);
            } else {
              res.failCode = EStandardActivityFailedCode.timeExpired;
              res.status = ECheckActivityResult.failed;
              if (exploreInitParams.minCollectedCoins != null) {
                res.message = this.getFailMessage(res.failCode, "<p>You have to collect at least " + exploreInitParams.minCollectedCoins + " items</p>", this.storySelected);
              } else {
                res.message = this.getFailMessage(res.failCode, "<p>You have to collect all items</p>", this.storySelected);
              }
              res.activityStats = this.activityStatsProvider.getExploreStats();
              resolve(res);
            }
          }).catch((err: Error) => {
            reject(err);
            console.error(err);
          });

          // subscribe to activity exit event
          this.checkActivitySkip().then((skip: boolean) => {
            console.log("check activity skip: ", skip);
            if (skip) {
              // check if there are enough collected coins (at least half)
              if ((exploreInitParams.minCollectedCoins != null) && (this.app.collectedItemsCurrentActivity >= exploreInitParams.minCollectedCoins)) {
                res.activityStats = this.activityStatsProvider.getExploreStats();
                res.status = ECheckActivityResult.done;
                resolve(res);
              } else {
                // activity failed popup is disabled for this activity
                res.failCode = null;
                res.status = ECheckActivityResult.skipped;
                res.activityStats = this.activityStatsProvider.getExploreStats();
                resolve(res);
              }
            }
          });
        });
        break;

      /**
      * EVADE
      */
      case EActivityCodes.escape:
        promise = new Promise(async (resolve, reject) => {
          // disable treasure scanner
          // NOTE: this behavior is defined for each activity
          this.itemScanner.setUnlockScanner(false);
          this.activityProvider.setActivityCollectType(EActivityCodes.explore);
          try {
            await this.hideTreasureLayersRetryResolve();
            console.log("show activity tutorial");
            enterDrone = this.useCurrentStartOptionsLaunchDrone(fromSequence);
            if (enterDrone === null && !this.isPreloadStory) {
              enterDrone = await this.showActivityTutorialInContext(appLocation, activityDroneMode, startOptions, true);
            } else {
              if (enterDrone) {
                await this.enterDroneMode(true);
              }
            }
            if (enterDrone) {
              // wait for drone takeoff and virtual position capture
              await this.virtualPositionService.waitForDroneTakeOffCapture();
            }
          } catch (e) {
            console.error(e);
          }

          this.internalFlags.enableAR = false;
          let ap: IExploreActivityDef = activity.params;
          this.mpGameInterface.broadcastSyncStart();
          await this.mpGameInterface.waitForSyncStart();
          preparedCoinSpecs = await this.mpGameInterface.waitForLeaderBroadcastCoinSpecs();

          let exploreInitParams: IExploreActivityInit = {
            timeLimit: ap.timeLimit,
            startTime: timeCrt,
            collectDistance: AppConstants.gameConfig.collectDistance,
            objectDynamics: EExploreObjectDynamics.moveTowardsUser,
            exploreMode: ap.mode,
            useCheckpoints: false,
            activeInventoryItems: this.activeInventoryItems,
            currentLocation: this.virtualPositionService.getCurrentPosition(),
            minCollectedCoins: 1,
            randomCoins: true,
            targetSpeed: ap.targetSpeed,
            minRadius: ap.distance,
            evadeRadius: ap.evadeDistance,
            coinCap: ap.coinCap,
            coinRadius: ap.coinRadius,
            isMainObjective: false,
            syncData: preparedCoinSpecs,
            singleTarget: false,
            publishSyncData: this.mpGameInterface.isLeader(),
            timeGauge: true,
            collectGauge: false,
            evadeGauge: true, // with the scope of evade distance
            fixedCoins: activity.fixedCoins,
            fixedCoinCap: false,
            coinLabel: "<escape>",
            extras: formatExtras()
          };

          if (loc.merged.collectRadius != null) {
            exploreInitParams.collectDistance = loc.merged.collectRadius;
            this.exploreUtils.setCollectRadiusOverride(loc.merged.collectRadius);
          }

          if ((activity.fixedCoins != null) && (activity.fixedCoins.length > 0)) {
            exploreInitParams.coinCap = activity.fixedCoins.length;
            exploreInitParams.fixedCoinCap = true;
          }

          this.exploreUtils.setCoinSpecsForActivity(activity);
          this.activityProvider.setCollectContext(this.internalFlags.collectMode != null ? this.internalFlags.collectMode : ECheckpointCollectMode.auto, false);
          await this.onBeforeActivityStart(activity.code, activity);

          this.initEvadeActivityMain(exploreInitParams, true).then((evaded: boolean) => {
            if (evaded) {
              res.status = ECheckActivityResult.done;
              res.activityStats = this.activityStatsProvider.getExploreStats();
              resolve(res);
            } else {
              res.failCode = EStandardActivityFailedCode.gotCaught;
              res.status = ECheckActivityResult.failed;
              res.message = this.getFailMessage(res.failCode, null, this.storySelected);
              res.activityStats = this.activityStatsProvider.getExploreStats();
              resolve(res);
            }
          }).catch((err: Error) => {
            reject(err);
            console.error(err);
          });

          // subscribe to activity exit event
          this.checkActivitySkip().then((skip: boolean) => {
            console.log("check activity skip: ", skip);
            if (skip) {
              // activity failed popup is disabled for this activity
              res.failCode = null;
              res.status = ECheckActivityResult.skipped;
              res.activityStats = this.activityStatsProvider.getExploreStats();
              resolve(res);
            }
          });

        });
        break;
      /**
       * SCREENSHOT AR
       */
      case EActivityCodes.screenshotAR:
        // find ar objects and take photos to validate them
        this.activityProvider.setActivityCollectType(null);
        promise = new Promise(async (resolve) => {
          let ap: IARExploreActivityDef = activity.params;
          // timeLimit = activityParams.timeLimit;
          this.activityStarted.screenshotAR = true;
          this.buttonOptions.ar.blink = true;
          this.activityStarted.ANY = true;
          // refresh (and re-add) world map objects
          // this.itemScanner.refreshARObjects();

          if (this.modeSelect.storyline) {
            // scan nearby items
            PromiseUtils.wrapNoAction(this.itemScanner.treasureScan(), true);
          }

          try {
            console.log("show activity tutorial");
            enterDrone = this.useCurrentStartOptionsLaunchDrone(fromSequence);
            if (enterDrone === null && !this.isPreloadStory) {
              enterDrone = await this.showActivityTutorialInContext(appLocation, activityDroneMode, startOptions, true);
            } else {
              if (enterDrone) {
                await this.enterDroneMode(true);
              }
            }
            if (enterDrone) {
              // wait for drone takeoff and virtual position capture
              await this.virtualPositionService.waitForDroneTakeOffCapture();
            }
          } catch (e) {
            console.error(e);
          }

          this.mpGameInterface.broadcastSyncStart();
          await this.mpGameInterface.waitForSyncStart();
          preparedCoinSpecs = await this.mpGameInterface.waitForLeaderBroadcastCoinSpecs();
          await this.onBeforeActivityStart(activity.code, activity);

          this.initARExploreActivityMain(ap.timeLimit).then((done: boolean) => {
            if (done) {
              res.status = ECheckActivityResult.done;
              resolve(res);
            } else {
              res.status = ECheckActivityResult.failed;
              res.failCode = EStandardActivityFailedCode.timeExpired;
              res.message = this.getFailMessage(res.failCode, null, this.storySelected);
              resolve(res);
            }
          });

          // subscribe to activity exit event
          this.checkActivitySkip().then((skip: boolean) => {
            if (skip) {
              res.failCode = null;
              res.status = ECheckActivityResult.skipped;
              resolve(res);
            }
          });
        });
        break;
      /**
       * FIND
       */
      case EActivityCodes.find:
        // this is called when the user reaches the location
        // this also means that the activity is finished
        promise = new Promise(async (resolve, reject) => {
          this.itemScanner.setUnlockScanner(false);
          this.activityProvider.setActivityCollectType(EActivityCodes.find);
          try {
            await this.hideTreasureLayersRetryResolve();
          } catch (e) {
            console.error(e);
          }

          if (this.storySelected) {
            let promise1: Promise<any>;
            if (specificActivityCode === EActivityCodes.snapshot && !loc.merged.photoValidated) {
              promise1 = this.checkPhotoFindActivity(true, loc);
            } else {
              promise1 = Promise.resolve(true);
            }

            promise1.then((valid: boolean) => {
              if (valid) {
                this.toggleLastMarkerDisplay(true);
                res.status = ECheckActivityResult.done;
                res.activityStats = this.activityStatsProvider.getFindStats();
                this.droneSimulator.onCompleteChallengeCheck();
                res.activityStats.stats.droneUsed = this.activityStatsTracker.checkDroneUsed();
                console.log("activity result wrapper gen: ", res);
                resolve(res);
              } else {
                res.status = ECheckActivityResult.failed;
                res.failCode = EStandardActivityFailedCode.photoValidationFailed;
                res.message = this.getFailMessage(res.failCode, null, this.storySelected);
                resolve(res);
              }
            }).catch((err: Error) => {
              reject(err);
            });
          } else {
            try {
              console.log("show activity tutorial");
              enterDrone = this.useCurrentStartOptionsLaunchDrone(fromSequence);
              if (enterDrone === null && !this.isPreloadStory) {
                enterDrone = await this.showActivityTutorialInContext(appLocation, activityDroneMode, startOptions, true);
              } else {
                if (enterDrone) {
                  await this.enterDroneMode(true);
                }
              }
              if (enterDrone) {
                // wait for drone takeoff and virtual position capture
                await this.virtualPositionService.waitForDroneTakeOffCapture();
              }
            } catch (e) {
              console.error(e);
            }

            let ap: IFindActivityDef = activity.params;
            let findInitParams: IFindActivityInit = {
              timeLimit: ap.timeLimit,
              startTime: timeCrt
            };

            this.mpGameInterface.broadcastSyncStart();
            await this.mpGameInterface.waitForSyncStart();
            let preparedFindSpecs: IFindSpecsMpSyncData = await this.mpGameInterface.waitForLeaderBroadcastFindSpecs();
            this.activityProvider.initFindActivity(findInitParams);

            let onSearchAreaReachedAddon = async () => {
              try {
                console.log("show activity tutorial");
                enterDrone = this.useCurrentStartOptionsLaunchDrone(fromSequence);
                if (enterDrone === null) {
                  enterDrone = await this.showActivityTutorialInContext(appLocation, activityDroneMode, startOptions, true);
                } else {
                  if (enterDrone) {
                    await this.enterDroneMode(true);
                  }
                }
                if (enterDrone) {
                  // wait for drone takeoff and virtual position capture
                  await this.virtualPositionService.waitForDroneTakeOffCapture();
                }
              } catch (e) {
                console.error(e);
              }
              PromiseUtils.wrapNoAction(this.onBeforeActivityStart(activity.code, activity), true);
            }

            this.initFindChallengeActivityWrapper(activity, ap, activity.customParams, null, preparedFindSpecs, onSearchAreaReachedAddon).then((resFind: IFindActivityResult) => {
              if (resFind.status === ENavigateReturnCodes.reached) {
                res.status = ECheckActivityResult.done;
                res.placeFound = resFind.appLocation;
                res.activityStats = this.activityStatsProvider.getFindStats();
                console.log("find activity stats: ", res.activityStats);
                this.clearLastPlace(true);
                resolve(res);
              } else {
                res.failCode = EStandardActivityFailedCode.timeExpired;
                res.status = ECheckActivityResult.failed;
                res.message = this.getFailMessage(res.failCode, "<p>The target was not found within the required time limit</p>", this.storySelected);
                res.activityStats = this.activityStatsProvider.getFindStats();
                console.log("find activity stats: ", res.activityStats);
                this.clearLastPlace(true);
                resolve(res);
              }
            }).catch((err) => {
              reject(err);
            });
          }

          // subscribe to activity exit event
          this.checkActivitySkip().then((skip: boolean) => {
            if (skip) {
              res.failCode = null;
              res.status = ECheckActivityResult.skipped;
              this.clearLastPlace(true);
              resolve(res);
            }
          });
        });
        break;

      /**
       * MOVE
       */
      case EActivityCodes.run:
      case EActivityCodes.walk:
      case EActivityCodes.enduranceRun:
        // this.flags.droneAllowed = false;
        promise = new Promise(async (resolve, reject) => {

          if (this.subscription.watchMove) {
            reject(new Error("Challenge already initialized"));
            return;
          }

          this.itemScanner.setUnlockScanner(false);
          this.activityProvider.setActivityCollectType(EActivityCodes.explore);
          try {
            await this.hideTreasureLayersRetryResolve();
            console.log("show activity tutorial");
            enterDrone = this.useCurrentStartOptionsLaunchDrone(fromSequence);
            if (enterDrone === null && !this.isPreloadStory) {
              enterDrone = await this.showActivityTutorialInContext(appLocation, activityDroneMode, startOptions, true);
            } else {
              if (enterDrone) {
                await this.enterDroneMode(true);
              }
            }
            if (enterDrone) {
              // wait for drone takeoff and virtual position capture
              await this.virtualPositionService.waitForDroneTakeOffCapture();
            }
          } catch (e) {
            console.error(e);
          }

          let moveParams: IMoveMonitorParams = this.moveActivityProvider.extractMoveParams(baseActivityCode, activity.params);
          let ap: IGenericMoveActivityDef = activity.params;
          this.activityStarted.ANY = true;
          this.activityStarted.move = true;
          let isMoveX: boolean = [EActivityCodes.walkTarget, EActivityCodes.runTarget].indexOf(specificActivityCode) !== -1;
          let isMoveXConditional: boolean = true;
          let isRunType: boolean = [EActivityCodes.run, EActivityCodes.enduranceRun, EActivityCodes.runTarget].indexOf(specificActivityCode) !== -1;
          this.mpGameInterface.broadcastSyncStart();
          await this.mpGameInterface.waitForSyncStart();
          preparedCoinSpecs = await this.mpGameInterface.waitForLeaderBroadcastCoinSpecs();
          let withCoins: boolean = true;

          let promiseInitPreview: Promise<any>;

          let opts: IGmapActivityPreviewOptions = {
            inProgress: true,
            withDismiss: false,
            withDrone: null,
            startReady: false,
            isPreview: false,
            isAutostart: !this.internalFlags.manualChallengeStart,
            overrideLocationIndex: false
          };

          await this.onBeforeActivityStart(activity.code, activity);

          if (photoValidate) {
            console.log("photo validation enabled");
            // photo validation e.g. bike
            promiseInitPreview = new Promise((resolve) => {
              this.getLocationDetailsView(appLocation, this.challengeEntry.getCurrentChallengeItem(), opts, null).then((res: IGmapDetailReturnParams) => {
                // resolved only if photo validated or skipped
                console.log(res);
                // set photo validation status
                // this.app.storyLocations[locationIndex].photoValidated = res;
                resolve(res);
              }).catch((err: Error) => {
                console.error(err);
                resolve(true);
              });
            });
          } else {
            console.log("no photo validation");
            if (!enterDrone) {
              // RUN only (walk can be on the map, with coins)
              if (!isMoveX && isRunType) {
                if (!AppConstants.gameConfig.enableCoinsForRunActivity) {
                  withCoins = false; // here coins can be disabled for run activity (only a single coin would be shown)
                }
                SleepUtils.sleep(1000).then(() => {
                  this.getLocationDetailsView(appLocation, this.challengeEntry.getCurrentChallengeItem(), opts, null).then(() => {

                  }).catch((err: Error) => {
                    console.error(err);
                  });
                });
              }
            }
            promiseInitPreview = Promise.resolve(true);
          }
          if (!this.flags.droneMode) {
            await this.setFollowNav3D();
          }
          promiseInitPreview.then((res1: boolean) => {
            console.log("init done: ", res1);
            if (res1) {
              // start explore activity main i.e. the coin collector function
              // don't care if coins collected or not
              // coins are secondary to this type of activity

              let exploreInitParams: IExploreActivityInit = {
                timeLimit: 0,
                startTime: timeCrt,
                coinCap: ap.coinCap ? ap.coinCap : null,
                coinRadius: ap.coinRadius ? ap.coinRadius : moveParams.targetDistance,
                collectDistance: AppConstants.gameConfig.collectDistance,
                objectDynamics: EExploreObjectDynamics.static,
                exploreMode: EExploreModes.guided,
                useCheckpoints: true,
                activeInventoryItems: this.activeInventoryItems,
                currentLocation: this.virtualPositionService.getCurrentPosition(),
                targetSpeed: 0,
                minRadius: 0,
                randomCoins: !isMoveX,
                minCollectedCoins: !isMoveX ? this.exploreProvider.getMinRequiredCoins(activity) : null,
                evadeRadius: null,
                isMainObjective: false,
                syncData: preparedCoinSpecs,
                singleTarget: isMoveX,
                publishSyncData: this.mpGameInterface.isLeader(),
                timeGauge: false, // handled by move activity
                collectGauge: false, // no more slots
                evadeGauge: false,
                fixedCoins: activity.fixedCoins,
                fixedCoinCap: false,
                coinLabel: "<collect>",
                extras: formatExtras()
              };

              if (loc.merged.collectRadius != null) {
                exploreInitParams.collectDistance = loc.merged.collectRadius;
                this.exploreUtils.setCollectRadiusOverride(loc.merged.collectRadius);
              }

              if (!withCoins) {
                exploreInitParams.coinCap = null;
              }

              if ((activity.fixedCoins != null) && (activity.fixedCoins.length > 0)) {
                exploreInitParams.coinCap = activity.fixedCoins.length;
                exploreInitParams.fixedCoinCap = true;
              }

              console.log("explore init params: ", exploreInitParams);
              this.exploreUtils.setCoinSpecsForActivity(activity);
              this.activityProvider.setCollectContext(this.internalFlags.collectMode != null ? this.internalFlags.collectMode : ECheckpointCollectMode.auto, true);
              let exploreComplete: boolean = false;
              let moveComplete: boolean = false;

              // init explore activity without timer (time left is set to 0)
              console.log("init explore promise");
              this.initExploreActivityMain(exploreInitParams, false, false).then((done: boolean) => {
                if (done) {
                  exploreComplete = true;
                  this.activityProvider.setExploreMoveTransition(EExploreMoveStat.exploreComplete);
                  if (withCoins) {
                    if (exploreInitParams.fixedCoins != null && (exploreInitParams.fixedCoins.length > 0)) {
                      // fixed coins complete the challenge
                      this.moveActivityProvider.setDone();
                    } else {
                      if (!moveComplete) {
                        // target coins collected                        
                        if (!GeneralCache.paused) {
                          this.uiext.showAlertNoAction(
                            isRunType ? Messages.msg.runTargetExploreComplete.after.msg : Messages.msg.walkTargetExploreComplete.after.msg,
                            isRunType ? Messages.msg.runTargetExploreComplete.after.sub : Messages.msg.walkTargetExploreComplete.after.sub
                          );
                        }
                        this.localNotifications.notify(Messages.notification.checkpointReached.after.msg, Messages.notification.checkpointReached.after.sub, false, null);
                        this.soundManager.vibrateContext(true);
                        this.soundManager.ttsWrapper(Messages.tts.checkpointReached, true);
                      }
                    }
                  }
                }
              }).catch((err: Error) => {
                this.analytics.dispatchError(err, "gmap");
                console.error(err);
              });

              let formatDistanceDisp: IFormatDisp = MathUtils.formatDistanceDisp(moveParams.targetDistance);
              // let formatSpeedDisp: IFormatDisp = MathUtils.formatSpeedDisp(moveParams.targetSpeed);
              this.activityProvider.initMoveActivity(moveParams); // this starts move timer
              this.showHudMessage(EMapHudCodes.targetDistanceMove, formatDistanceDisp.value, formatDistanceDisp.unit);
              // this.showHudMessage(EMapHudCodes.targetSpeedMove, moveParams.targetSpeed ? formatSpeedDisp.value : null, moveParams.targetSpeed ? formatSpeedDisp.unit : null);

              // move activity is monitored by location monitor
              // this is the main controller for move activities
              // activity is finished if the move params are accomplished (distance, speed)
              // activity is failed if the time expired, or cheating is detected

              this.navGauge.setMode(ENavGaugeDict.left, ENavGaugeMode.dist, ENavGaugeTooltip.distanceLeft, moveParams.targetDistance);
              this.navGauge.setIcon(ENavGaugeDict.left, EAppIcons.distance, true);
              this.navGauge.show(ENavGaugeDict.left);

              if (moveParams.timeLimit) {
                this.navGauge.setMode(ENavGaugeDict.right, ENavGaugeMode.timeLeft, ENavGaugeTooltip.timeLeft, moveParams.timeLimit);
                this.navGauge.setIcon(ENavGaugeDict.right, EAppIcons.stopwatch, true);
                this.navGauge.show(ENavGaugeDict.right);
              }

              let promiseMove: Promise<IActivityResultCore> = new Promise((resolve, reject) => {
                let moveConsumed: boolean = false;
                this.subscription.watchMove = this.moveActivityProvider.getMoveActivityWatch().subscribe(async (moveData: IMoveMonitorData) => {
                  if (moveData) {
                    // let formatDisp: IFormatDisp = MathUtils.formatDistanceDisp(moveData.distance);
                    // this.showHudMessage(EMapHudCodes.currentDistanceMove, formatDisp.value, formatDisp.unit);
                    // this.showHudMessage(EMapHudCodes.timer, moveParams.timeLimit ? moveData.timerDisp : null, null);
                    this.navGauge.setLevel(ENavGaugeDict.left, moveData.distance);
                    // this.localNotifications.setPersistentNotification("Leplace World", "Distance", false, null, this.localNotifications.getProgressLevel(moveData.distance, moveParams.targetDistance));

                    if (moveParams.timeLimit) {
                      this.navGauge.setLevel(ENavGaugeDict.right, moveData.timerValue);
                      this.navGauge.checkLowLevelSetLockTheme(ENavGaugeDict.right, 0.2);
                      this.onTimerUpdate(moveData.timerValue);
                    }

                    if (moveParams.targetSpeed) {
                      if (moveData.speed >= moveParams.targetSpeed) {
                        this.navGauge.setTheme(ENavGaugeDict.left, EChartTheme.accent1);
                      } else {
                        this.navGauge.setTheme(ENavGaugeDict.left, EChartTheme.default);
                      }
                    }
                    console.log("move activity stat ticker");
                    let status = moveData.status;
                    switch (status) {
                      case EMoveActivityStatus.done:
                        if (!moveConsumed) {
                          console.log("move activity done");
                          // this.activityProvider.exitMoveActivity();
                          // this.subscription.watchMove = ResourceManager.clearSub(this.subscription.watchMove);
                          // may have to wait for explore complete (target) - but still keep timer running
                          res.status = ECheckActivityResult.done;
                          res.shareParams = {
                            target: [moveParams.targetDistance, moveParams.targetSpeed, moveParams.timeLimit],
                            current: [moveData.distance, moveData.averageSpeed != null ? moveData.averageSpeed : moveParams.targetSpeed, moveData.elapsedValue]
                          };
                          moveComplete = true;
                          moveConsumed = true;
                          this.activityProvider.setExploreMoveTransition(EExploreMoveStat.moveComplete);
                          resolve(res);
                        }
                        break;
                      case EMoveActivityStatus.cheated:
                        await this.exitExploreActivityMain(false);
                        this.activityProvider.exitMoveActivity();
                        this.subscription.watchMove = ResourceManager.clearSub(this.subscription.watchMove);
                        res.failCode = EStandardActivityFailedCode.speedLimitExceeded;
                        res.message = this.getFailMessage(res.failCode, null, this.storySelected);
                        res.status = ECheckActivityResult.failed;
                        moveConsumed = true;
                        resolve(res);
                        break;
                      case EMoveActivityStatus.failed:
                        await this.exitExploreActivityMain(false);
                        this.activityProvider.exitMoveActivity();
                        this.subscription.watchMove = ResourceManager.clearSub(this.subscription.watchMove);
                        res.failCode = EStandardActivityFailedCode.timeExpired;
                        res.message = this.getFailMessage(res.failCode, null, this.storySelected);
                        res.status = ECheckActivityResult.failed;
                        moveConsumed = true;
                        resolve(res);
                        break;
                      default:
                        break;
                    }
                  }
                }, (err: Error) => {
                  console.error(err);
                  reject(err);
                });
              });

              promiseMove.then((res: IActivityResultCore) => {
                // check explore activity complete (if required, e.g. walkCollect, runCollect)
                let promiseCheckExploreActivity: Promise<boolean>;
                // check move activity done
                if (res.status === ECheckActivityResult.done) {
                  // check explore activity required
                  if (isMoveX && isMoveXConditional && !exploreComplete) {
                    console.log("waiting for explore complete");
                    if (!GeneralCache.paused) {
                      this.uiext.showAlertNoAction(Messages.msg.moveTargetMoveComplete.after.msg, Messages.msg.moveTargetMoveComplete.after.sub);
                    }
                    this.localNotifications.notify(Messages.notification.checkpointReached.after.msg, Messages.notification.checkpointReached.after.sub, false, null);
                    this.soundManager.vibrateContext(true);
                    this.soundManager.ttsWrapper(Messages.tts.checkpointReached, true);
                    promiseCheckExploreActivity = this.activityProvider.checkExploreMoveTransition(EExploreMoveStat.exploreComplete);
                  } else {
                    promiseCheckExploreActivity = Promise.resolve(true);
                  }

                  promiseCheckExploreActivity.then(async (done: boolean) => {
                    console.log("explore complete resolve: ", done);
                    if (done) {
                      // all done
                      await this.exitExploreActivityMain(false);
                      this.activityProvider.exitMoveActivity();
                      this.subscription.watchMove = ResourceManager.clearSub(this.subscription.watchMove);
                      res.status = ECheckActivityResult.done;
                      switch (baseActivityCode) {
                        case EActivityCodes.walk:
                          res.activityStats = this.activityStatsProvider.getRunStats();
                          break;
                        case EActivityCodes.run:
                          res.activityStats = this.activityStatsProvider.getRunStats();
                          break;
                        case EActivityCodes.enduranceRun:
                          res.activityStats = this.activityStatsProvider.getEnduranceRunStats();
                          break;
                      }
                      resolve(res);
                    } else {
                      await this.exitExploreActivityMain(false);
                      this.activityProvider.exitMoveActivity();
                      this.subscription.watchMove = ResourceManager.clearSub(this.subscription.watchMove);
                      res.failCode = EStandardActivityFailedCode.timeExpired;
                      res.message = this.getFailMessage(res.failCode, null, this.storySelected);
                      res.status = ECheckActivityResult.failed;
                      resolve(res);
                    }
                  });
                } else {
                  resolve(res);
                }
              }).catch((err) => {
                reject(err);
              });

            } else {
              // activity failed/skipped
              res.failCode = null;
              res.status = ECheckActivityResult.failed;
              resolve(res);
            }

            // subscribe to activity exit event
            this.checkActivitySkip().then((skip: boolean) => {
              console.log("check activity skip detected: " + skip);
              if (skip) {
                // activity failed/skipped
                res.failCode = null;
                res.status = ECheckActivityResult.skipped;
                resolve(res);
              }
            });
          }).catch((err: Error) => {
            console.error(err);
            reject(err);
          });
        });
        break;
      /**
       * PHOTO DETECT
       */
      case EActivityCodes.photo:
        promise = new Promise(async (resolve, reject) => {
          this.itemScanner.setUnlockScanner(false);
          let ap: IPhotoActivityDef = activity.params;

          let params: IPhotoActivityParams = {
            storyId: this.storyId,
            storyLocationId: loc.merged.id,
            timeLimit: ap.timeLimit,
            activity: activity,
            loc: loc,
            photoValidated: false,
            updateGauge: true
          };

          this.mpGameInterface.broadcastSyncStart();
          await this.mpGameInterface.waitForSyncStart();
          preparedCoinSpecs = await this.mpGameInterface.waitForLeaderBroadcastCoinSpecs();

          this.initPhotoActivityMain(activity, params).then(() => {

          }).catch((err: Error) => {
            reject(err);
          });

          // activity is managed from the gmap detail
          this.checkGmapDetailActivity(appLocation, specificActivityCode, item, false).then((gmapRes: IActivityResultCore) => {
            if (gmapRes.status === ECheckActivityResult.done) {
              // check photo uploaded
              let prr: IPhotoResultResponse = this.photoActivityProvider.getPhotoResultResponse();
              gmapRes.activityStats = this.activityStatsProvider.getPhotoStats();
              this.storyManagerService.syncPhotoUploadResponse(loc.merged, prr);
            }
            resolve(gmapRes);
          }).catch((err: Error) => {
            reject(err);
          });
        });
        break;

      case EActivityCodes.audio:
      case EActivityCodes.video:
        promise = new Promise(async (resolve, reject) => {
          let ap: IMediaActivityDef = activity.params;
          this.activityProvider.initMediaActivity(ap);
          this.mpGameInterface.broadcastSyncStart();
          await this.mpGameInterface.waitForSyncStart();
          preparedCoinSpecs = await this.mpGameInterface.waitForLeaderBroadcastCoinSpecs();

          // activity is managed from the gmap detail
          this.checkGmapDetailActivity(appLocation, specificActivityCode, item, false).then((gmapRes: IActivityResultCore) => {
            if (gmapRes.status === ECheckActivityResult.done) {
              gmapRes.activityStats = this.activityStatsProvider.getGenericStats();
            }
            resolve(gmapRes);
          }).catch((err: Error) => {
            reject(err);
          });
        });
        break;

      case EActivityCodes.dance:
        promise = new Promise(async (resolve, reject) => {
          let ap: IDanceActivityDef = activity.params;
          this.activityProvider.initDanceActivity(ap);
          this.mpGameInterface.broadcastSyncStart();
          await this.mpGameInterface.waitForSyncStart();
          preparedCoinSpecs = await this.mpGameInterface.waitForLeaderBroadcastCoinSpecs();

          // activity is managed from the gmap detail
          this.checkGmapDetailActivity(appLocation, specificActivityCode, item, true).then((gmapRes: IActivityResultCore) => {
            if (gmapRes.status === ECheckActivityResult.done) {
              gmapRes.activityStats = this.activityStatsProvider.getGenericStats();
            }
            resolve(gmapRes);
          }).catch((err: Error) => {
            reject(err);
          });
        });
        break;
      case EActivityCodes.decibel:
        promise = new Promise(async (resolve, reject) => {
          let ap: IDecibelActivityDef = activity.params;
          this.activityProvider.initDecibelActivity(ap);
          this.mpGameInterface.broadcastSyncStart();
          await this.mpGameInterface.waitForSyncStart();
          preparedCoinSpecs = await this.mpGameInterface.waitForLeaderBroadcastCoinSpecs();

          // activity is managed from the gmap detail
          this.checkGmapDetailActivity(appLocation, specificActivityCode, item, true).then((gmapRes: IActivityResultCore) => {
            if (gmapRes.status === ECheckActivityResult.done) {
              gmapRes.activityStats = this.activityStatsProvider.getGenericStats();
            }
            resolve(gmapRes);
          }).catch((err: Error) => {
            reject(err);
          });
        });
        break;
      /**
      * QUEST
      */
      case EActivityCodes.quest:
        promise = new Promise(async (resolve) => {
          // disable treasure scanner
          // NOTE: this behavior is defined for each activity
          this.itemScanner.setUnlockScanner(false);
          try {
            await this.hideTreasureLayersRetryResolve();
          } catch (e) {
            console.error(e);
          }
          let ap: IQuestActivityDef = activity.params;
          this.activityStarted.ANY = true;
          this.activityStarted.quest = true;
          this.mpGameInterface.broadcastSyncStart();
          await this.mpGameInterface.waitForSyncStart();
          // this.onBeforeActivityStart(activity.code, activity);

          this.initQuestActivityMain(activity, ap).then((done: boolean) => {
            if (done) {
              res.status = ECheckActivityResult.done;
              res.activityStats = this.activityStatsProvider.getQuestStats();
              resolve(res);
            } else {
              res.status = ECheckActivityResult.failed;
              res.failCode = EStandardActivityFailedCode.timeExpired;
              res.message = this.getFailMessage(res.failCode, "<p>Or maximum number of attempts registered</p>", this.storySelected);
              resolve(res);
            }
          });

          // subscribe to activity exit event
          this.checkActivitySkip().then((skip: boolean) => {
            if (skip) {
              res.failCode = null;
              res.status = ECheckActivityResult.skipped;
              resolve(res);
            }
          });

          let opts: IGmapActivityPreviewOptions = {
            inProgress: true,
            withDismiss: false,
            withDrone: null,
            startReady: false,
            isPreview: false,
            isAutostart: !this.internalFlags.manualChallengeStart,
            overrideLocationIndex: false
          };

          // show gmap detail as primary view
          this.getLocationDetailsView(appLocation, this.challengeEntry.getCurrentChallengeItem(), opts, null).then((res: IGmapDetailReturnParams) => {
            console.log(res);
          }).catch((err: Error) => {
            console.error(err);
          });
        });
        break;
      /**
       * visit type activities
       */
      case EActivityCodes.visit:
      default:
        promise = new Promise(async (resolve, reject) => {
          // activity is managed from the gmap detail
          this.mpGameInterface.broadcastSyncStart();
          await this.mpGameInterface.waitForSyncStart();
          preparedCoinSpecs = await this.mpGameInterface.waitForLeaderBroadcastCoinSpecs();
          this.checkGmapDetailActivity(appLocation, specificActivityCode, item, true).then((gmapRes: IActivityResultCore) => {
            if (gmapRes.status === ECheckActivityResult.done) {
              gmapRes.activityStats = this.activityStatsProvider.getGenericStats();
            }
            resolve(gmapRes);
          }).catch((err: Error) => {
            reject(err);
          });
        });
        break;
    }
    return promise;
  }


  /**
   * the main entry point for activity validation
   * handles each activity in particular and returns the finish state (finished, failed, etc) to the app
   */
  checkStoryActivity(locationIndex: number): Promise<IActivityResultCore> {
    let appLocation: IAppLocation = this.app.storyLocations[locationIndex];
    let customRewardXp: number = appLocation.loc.merged.rewardXp;
    PromiseUtils.wrapNoAction(this.storyDataProvider.updateStatus(this.story, locationIndex, EStoryLocationStatusFlag.started), true);
    this.storyDataProvider.clearStatusCache();
    return this.checkActivityWrapper(appLocation.loc.merged.activity, appLocation, null, null, customRewardXp, true);
  }


  /**
   * notify event for activities that are handled by gmap
   * e.g. move + explore
   */
  notifyActivityFinished(msg?: string, sub?: string) {
    this.localNotifications.notify(msg ? msg : Messages.notification.activityFinished.after.msg, sub ? sub : Messages.notification.activityFinished.after.sub, false, null);
    this.soundManager.vibrateContext(false);
    this.soundManager.ttsWrapper(Messages.tts.challengeComplete, true, SoundUtils.soundBank.complete.id);
  }

  /**
  * notify event for activities that are handled by gmap
  * e.g. move + explore
  */
  notifyActivityFailed() {
    this.localNotifications.notify(Messages.notification.activityFailed.after.msg, Messages.notification.activityFailed.after.sub, false, null);
    this.soundManager.vibrateContext(false);
    this.soundManager.ttsWrapper(Messages.tts.challengeFailed, true, SoundUtils.soundBank.failed.id);
  }



  /**
   * activity may be completed/skipped from the gmap detail
   * this is especially important for activities that cannot return to map while in progress
   */
  checkGmapDetailActivity(appLocation: IAppLocation, _specificActivityCode: number, _item: ILeplaceTreasure, initTimeout: boolean): Promise<IActivityResultCore> {
    let promise: Promise<IActivityResultCore> = new Promise((resolve, reject) => {
      // activity is managed from the gmap detail
      let res: IActivityResultCore = {
        status: ECheckActivityResult.failed,
        message: null,
        failCode: null,
        retryEnabled: !this.checkWorldMapFreeRoamingMode(),
        shareParams: null,
        activityStats: null
      };

      this.itemScanner.setUnlockScanner(false);

      if (initTimeout) {
        let activityParams: IVisitActivityDef = appLocation.loc.merged.activity.params;
        let timeLimit: number = activityParams.timeLimit;
        if (timeLimit) {
          this.activityProvider.initCasualActivity(timeLimit);
          this.subscribeToTimerWatchDisplay(false);
        }
      }

      let opts: IGmapActivityPreviewOptions = {
        inProgress: true,
        withDismiss: false,
        withDrone: null,
        startReady: false,
        isPreview: false,
        isAutostart: !this.internalFlags.manualChallengeStart,
        overrideLocationIndex: false
      };

      this.getLocationDetailsView(appLocation, this.challengeEntry.getCurrentChallengeItem(), opts, null).then(async (gmapResult: IGmapDetailReturnParams) => {
        if (gmapResult) {
          console.log("gmap detail result: ", gmapResult);
          switch (gmapResult.code) {
            case EGmapDetailReturnCode.done:
              await this.exitActivityMain(true);
              res.status = ECheckActivityResult.done;
              resolve(res);
              break;
            case EGmapDetailReturnCode.failed:
              await this.exitActivityMain(true);
              res.status = ECheckActivityResult.failed;
              res.failCode = EStandardActivityFailedCode.timeExpired;
              res.message = this.getFailMessage(res.failCode, null, this.storySelected);
              resolve(res);
              break;
            case EGmapDetailReturnCode.skip:
              // skip on user request
              await this.exitActivityMain(true);
              res.status = ECheckActivityResult.failed;
              res.failCode = null;
              resolve(res);
              break;
            default:
              await this.exitActivityMain(true);
              res.status = ECheckActivityResult.failed;
              res.failCode = null;
              resolve(res);
              break;
          }
        }
      }).catch((err: Error) => {
        reject(err);
      });

      // subscribe to activity exit event
      this.checkActivitySkip().then((skip: boolean) => {
        if (skip) {
          // activity failed/skipped
          res.failCode = null;
          res.status = ECheckActivityResult.skipped;
          resolve(res);
        }
      });
    });
    return promise;
  }

  getFailMessage(code: number, customMessage: string, isStoryMode: boolean) {
    let message: string = "";
    switch (code) {
      case EStandardActivityFailedCode.timeExpired:
        message = "<p>Time's up</p>";
        break;
      case EStandardActivityFailedCode.gotCaught:
        message = "<p>You got caught</p>";
        break;
      case EStandardActivityFailedCode.photoValidationFailed:
        message = "<p>Your photo does not look quite the same</p>";
        break;
      case EStandardActivityFailedCode.speedLimitExceeded:
        message = "<p>Maximum allowed speed exceeded</p><p>You were moving way faster than normal for this activity</p>";
        break;
      default:
        break;
    }
    if (customMessage) {
      message += customMessage;
    }
    if (isStoryMode) {
      message += "<p>You may try again</p>";
    }
    return message;
  }

  checkNextActivity() {
    this.getLocationDetailsViewForIndex(this.app.locationIndex, false, false, false, false, null).then(() => {
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  showNearbyPlacesPopupOnClick() {
    this.showNearbyPlacesPopupResolve(true).then(() => {

    });
  }

  /**
   * show popup after finished activity, for advertising purpose
   */
  showNearbyPlacesPopupResolve(always: boolean) {
    let promise = new Promise(async (resolve) => {
      this.showNearbyPlacesPopup(always).then((res) => {
        resolve(res);
      }).catch((err: Error) => {
        console.error(err);
        this.analytics.dispatchError(err, "gmap");
      })
    });
    return promise;
  }


  showNearbyPlacesPopup(always: boolean) {
    let promise = new Promise(async (resolve, reject) => {
      if ((AppConstants.gameConfig.showPlaceAds !== 1) && !always) {
        resolve(false);
        return;
      }
      try {
        if (this.platform.WEB) {
          await this.uiext.showRewardPopupQueue(null, "Skip scan nearby places", null, false);
          resolve(true);
        } else {
          await this.uiext.showLoadingV2Queue("Scanning nearby places..");
          this.itemScanner.findNearbyPlacesSuggestionAds(this.currentLocation.location).then(async (places: ILeplaceReg[]) => {
            let params: IPlacesPopup = {
              places: places,
              title: "Place scan"
            };

            let navParams: INavParams = {
              view: {
                fullScreen: false,
                transparent: false,
                large: true,
                addToStack: true,
                frame: false
              },
              params: params
            };

            await this.onModalOpened();
            this.uiext.showCustomModal(null, PlacesPopupViewComponent, navParams).then(() => {
              console.log("returned from modal to map");
              this.onModalClosed();
              resolve(true);
            }).catch((err: Error) => {
              this.onModalClosed();
              reject(err);
            });
          }).catch(async (err: Error) => {
            await this.uiext.dismissLoadingV2();
            reject(err);
          });
        }
      } catch (err) {
        reject(err);
      }
    });
    return promise;
  }


  moveMapTarget(coords: ILatLng, zoom: number) {
    // disable tracking
    this.onMapDragStart();
    // move map to designated location
    this.mapManager.moveMapWrapper(coords, {
      animateCamera: true,
      animateMarker: false,
      zoom: zoom,
      bearing: null,
      tilt: null,
      moveMap: true,
      force: true,
      userMarker: false,
      duration: null
    }).then(() => {
      this.onMapDragEnd();
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * show map clue for quest activity
   * @param lat 
   * @param lng 
   */
  async showMapClue(lat: number, lng: number) {
    let coords: ILatLng = new ILatLng(lat, lng);
    this.moveMapTarget(coords, 17);
    let opts: IMoveMapOptions = {
      animateCamera: false,
      animateMarker: false,
      zoom: null,
      bearing: null,
      tilt: null,
      moveMap: false,
      force: false,
      userMarker: false,
      duration: null
    };

    let callback = (data: IPlaceMarkerContent) => {
      console.log(data);
      // return to challenge dashboard
      this.getCurrentLocationDetailsViewNoAction();
    };

    await this.removeFixMarker();
    this.mapManager.syncMarkerGenNoAction(EMarkerLayers.PLACE_FIXER, coords, EMarkerIcons.clue, callback, opts);
  }

  removeFixMarker(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.mapManager.removeMarkerGen(EMarkerLayers.PLACE_FIXER).then(() => {
        resolve(true);
      }).catch(() => {
        resolve(false);
      });
    });
  }


  checkVideoTutorial() {
    if (this.storyParams && this.storyParams.category && this.storyParams.category.videoCode != null) {
      return this.storyParams.category.video.videoId;
    }
    return null;
  }

  /**
   * process treasures on the server
   * no external provider request
   */
  reSyncTreasures() {
    let customMap: boolean = this.isCustomMapStory;
    let radius: number = customMap ? AppConstants.gameConfig.placeScanRadiusCustomMap : AppConstants.gameConfig.placeScanRadius;
    this.itemScanner.treasureScanProcessing(null, {
      partialSync: false,
      radius: radius
    }).then(() => {

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  checkDroneWalkthroughRequired(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (SettingsManagerService.settings.app.settings.enableWalkthroughs.value) {
        this.storageFlags.loadFlagsGroup(ELocalAppDataKeys.localShowFlags, null, true).then((flags: ILocalShowFlags) => {
          if (!flags.droneTutorial) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      } else {
        resolve(false);
      }
    });
  }

  /**
   * launch a drone and start flying around
   * resolve when drone is up and running (including overlay tutorial)
   */
  enterDroneMode(fromChallenge: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let lastDronePosition: ILatLng = this.virtualPositionService.getLastDronePosition();
      // this.internalFlags.reachedChallengeWithDrone = true;
      let currentLoc: ILatLng = (fromChallenge && this.internalFlags.reachedChallengeWithDrone && lastDronePosition != null) ? lastDronePosition : this.currentLocation.location;
      // console.log("get challenge reached with drone location: ", lastDronePosition, this.currentLocation.location, currentLoc);
      this.moveActivityProvider.dumpDistanceCounter(null, null);
      this.flags.showCompassAdjustControl = false;
      this.flags.droneMode = true;
      this.disableGPSMapUpdate();
      this.exploreUtils.setAutoCollectOverride(true);
      this.buttonOptions.drone.blink = false;
      this.disableFollow();
      this.setFollowFlag(EFollowMode.DRONE);
      this.mapManager.setMapDroneMode();
      this.mapManager.applyMapOptions();
      this.itemScanner.setVirtualMode(true);
      this.setHudState(true, false);
      this.droneSimulator.startSimulation(currentLoc);
      this.virtualPositionService.setPrimaryLocationSource(EVirtualLocationSource.drone);
      HudUtils.setHudShow(this.hudMsg, EMapHudCodes.currentSpeedMove, true);
      this.subscription.droneStatusUpdate = this.droneSimulator.watchStatus().subscribe((status: IDroneStatusUpdate) => {
        if (status != null) {
          switch (status.code) {
            case EDroneStatusUpdateCode.batteryLevel:
              this.showHudMessage(EMapHudCodes.droneBatteryLevel, "" + Math.floor(status.value), "%");
              break;
            case EDroneStatusUpdateCode.batteryWarn:
              this.messageQueueHandler.prepare(status.message, true, EQueueMessageCode.warn);
              break;
            case EDroneStatusUpdateCode.statusCheck:
              let ds: IDroneStatus = this.droneSimulator.getDroneStatus();
              let speedDisp: number = ds.speed * 3.6;
              this.updateHudSpeed(speedDisp);
              if (ds.averageTs != null) {
                this.showHudMessage(EMapHudCodes.droneSimulationRate, "" + Math.floor(ds.averageTs), null);
              }
              break;
            case EDroneStatusUpdateCode.lowFPSMode:
              this.messageQueueHandler.prepare(status.message, true, EQueueMessageCode.warn);
              break;
            case EDroneStatusUpdateCode.exitBatteryEmpty:
              this.uiext.showAlertNoAction(Messages.msg.info.after.msg, status.message);
              this.exitDroneMode(true);
              break;
            default:
              break;
          }
        }
      }, (err: Error) => {
        console.error(err);
      });

      if (SettingsManagerService.settings.app.settings.enableWalkthroughs.value) {
        this.dedicatedTimeouts.showWalkthrough = setTimeout(() => {
          this.droneSimulator.pauseSimulation();
          this.storageFlags.loadFlagsGroup(ELocalAppDataKeys.localShowFlags, null, true).then((flags: ILocalShowFlags) => {
            if (!flags.droneTutorial) {
              this.walkthrough.showDroneModeIntro().then(() => {
                flags.droneTutorial = true;
                this.storageFlags.updateFlagsGroup(ELocalAppDataKeys.localShowFlags, flags);
                this.storageFlags.saveFlagsGroup(ELocalAppDataKeys.localShowFlags).then(() => {
                  this.droneSimulator.resumeSimulation();
                  resolve(true);
                });
              });
            } else {
              this.droneSimulator.resumeSimulation();
              resolve(true);
            }
          });
        }, 3000);
      } else {
        resolve(true);
      }
    });
  }

  /**
   * returns true if can exit map
   * returns false if drone has landed (can't exit map yet)
   */
  exitDroneModePrompt(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve) => {
      if (this.flags.droneMode) {
        let msg: string = this.isDroneOnly ? Messages.msg.exitDroneModeOnly.before.msg : Messages.msg.exitDroneMode.before.msg;
        let sub: string = this.isDroneOnly ? Messages.msg.exitDroneModeOnly.before.sub : Messages.msg.exitDroneMode.before.sub;
        this.uiext.showAlert(msg, sub, 2, ["dismiss", "ok"]).then((res: number) => {
          switch (res) {
            case EAlertButtonCodes.ok:
              this.exitDroneMode(true).then(() => {
                resolve(false);
              });
              break;
            default:
              resolve(false);
              break;
          }
        }).catch((err: Error) => {
          console.error(err);
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
    return promise;
  }


  /**
   * exit drone mode and resume navigation
   * @param resumeFollow re-enable user follow mode
   */
  exitDroneMode(resumeFollow: boolean): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve) => {
      if (this.flags.droneMode) {
        this.flags.droneMode = false;
        let ldp: ILatLng = this.virtualPositionService.getLastDronePosition();
        if (this.isDroneOnly && ldp != null) {
          this.currentLocation.location = ldp;
        }
        this.moveActivityProvider.dumpDistanceCounter(null, null);
      }
      if (this.isDroneOnly) {
        this.buttonOptions.drone.blink = true;
      }
      this.enableGPSMapUpdate();
      this.flags.showHud = this.flags.showHudPrev;
      this.subscription.droneStatusUpdate = ResourceManager.clearSub(this.subscription.droneStatusUpdate);
      this.mapManager.setMapNormalMode();
      this.mapManager.applyMapOptions();
      this.mapManager.clearDroneMarker();
      this.itemScanner.setVirtualMode(false);
      this.exploreUtils.resetAutoCollectOverride();
      if (resumeFollow) {
        this.enableFollowTrueAnimate(); // re-enable user follow mode
      }
      await this.droneSimulator.stopSimulation();
      this.clearHudMessage(EMapHudCodes.currentSpeedMove);
      this.clearHudMessage(EMapHudCodes.droneBatteryLevel);
      this.virtualPositionService.setPrimaryLocationSource(EVirtualLocationSource.gps);
      if (this.isDroneOnly) {
        this.virtualPositionService.placeUserAtLocation(this.currentLocation.location, false);
      }
      resolve(true);
    });
    return promise;
  }

  showOptionsNoAction() {
    this.showOptions().then(() => {
      console.log("show options returned to gmap");
    });
  }

  showOptions(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve) => {
      let actions: IPopoverActions = {};
      actions = {
        exit: {
          name: "Exit map",
          code: EGmapShowOptions.exitMap,
          icon: EAppIconsStandard.exit,
          enabled: true
        },
        tutorial: {
          name: "Tutorial",
          code: EGmapShowOptions.tutorial,
          icon: EAppIconsStandard.tutorial,
          enabled: true
        },
        openAR: {
          name: "AR View",
          code: EGmapShowOptions.arview,
          icon: EAppIcons.arView,
          customIcon: true,
          enabled: this.isARAvailable()
        },
        mapLayers: {
          name: "Map Layers",
          code: EGmapShowOptions.mapFilter,
          icon: EAppIconsStandard.mapFilter,
          enabled: !this.storySelected
        },
        toggleHud: {
          name: this.flags.showHud ? "Disable HUD" : "Enable HUD",
          code: EGmapShowOptions.toggleHud,
          icon: EAppIconsStandard.hud,
          enabled: true
        },
        directions: {
          name: "Load directions",
          code: EGmapShowOptions.directions,
          icon: EAppIcons.distance,
          customIcon: true,
          enabled: this.user.canRequestDirections
        }
      };

      if (this.isDroneOnly) {
        actions.jumpToMeetingPlace = {
          name: "Jump to start",
          code: EGmapShowOptions.jumpToMeetingPlace,
          icon: EAppIcons.gpsFixed,
          customIcon: true,
          enabled: !this.flags.droneMode
        }
      }

      if (this.modeSelect.editor || this.flags.mapDebugMode) {
        let actions3: IPopoverActions = {
          placeTreasure: {
            name: "place treasure",
            code: EGmapShowOptions.placeTreasure,
            icon: EAppIconsStandard.placeTreasure,
            enabled: true
          }
        };
        Object.assign(actions, actions3);
      }

      if (AppSettings.testerMode) {
        let actions4: IPopoverActions = {
          placeTreasure: {
            name: !this.flags.mapDebugMode ? "Enter Dev Mode" : "Exit Dev Mode",
            code: EGmapShowOptions.debugMode,
            icon: EAppIconsStandard.settings,
            enabled: true
          }
        };
        Object.assign(actions, actions4);
      }

      if (this.flags.mapDebugMode) {
        let actions2: IPopoverActions = {
          skipToLocationIndex: {
            name: "skip to index",
            code: EGmapShowOptions.skipToLocationIndex,
            icon: null,
            enabled: true
          },
          reloadLastStoryMarker: {
            name: "reload last marker",
            code: EGmapShowOptions.reloadLastStoryMarker,
            icon: null,
            enabled: true
          },
          compass: {
            name: (this.flags.follow === this.followModes.MOVE_MAP_HEADING_2D) ? "Disable compass" : "Enable compass",
            code: EGmapShowOptions.compassMode,
            icon: EAppIconsStandard.compass,
            enabled: true
          },
          setSimulateWatchLocationFailTest: {
            name: !this.locationManager.getSimulateLocationFailTest() ? "Location watch fail" : "Location watch resume",
            code: EGmapShowOptions.locationFailTest,
            icon: EAppIconsStandard.location,
            enabled: true
          },
          disableCompass: {
            name: "Simulate missing compass",
            code: EGmapShowOptions.simulateNoCompass,
            enabled: true
          },
          showCompassAdjustControl: {
            name: !this.flags.showCompassAdjustControl ? "Show compass adjust*" : "Hide compass adjust*",
            code: EGmapShowOptions.showCompassAdjustControl,
            enabled: !this.flags.droneMode
          },
          showCompassAdjustInput: {
            name: "Compass adjust*",
            code: EGmapShowOptions.showCompassAdjustInput,
            enabled: !this.flags.droneMode
          },
          setARDemoMode: {
            name: !this.flags.setARDemoMode ? "Enable AR Demo*" : "Disable AR Demo*",
            code: EGmapShowOptions.demoModeAR,
            enabled: true
          },
          useARWebkitCompass: {
            name: !this.flags.useARWebkitCompass ? "Enable Webkit Compass*" : "Disable Webkit Compass*",
            code: EGmapShowOptions.webkitCompassAR,
            enabled: true,
          },
          toggleScannerEnabled: {
            name: this.itemScanner.checkEnabledExt() ? "Pause scan" : "Resume scan",
            code: EGmapShowOptions.toggleScannerEnabled,
            enabled: true
          },
          placeScan: {
            name: "Place scan",
            code: EGmapShowOptions.placeScan,
            enabled: true
          },
          teleport: {
            name: "Teleport*",
            code: EGmapShowOptions.teleport,
            enabled: true
          },
          placeUser: {
            name: "Place User*",
            code: EGmapShowOptions.placeUser,
            enabled: true
          },
          placeSearch: {
            name: "Place search*",
            code: EGmapShowOptions.placeSearch,
            enabled: true
          },
          locationFab: {
            name: "Location fab*",
            code: EGmapShowOptions.locationFab,
            enabled: true
          },
          expire: {
            name: "Expire now*",
            code: EGmapShowOptions.expire,
            enabled: true
          },
          expireIn30s: {
            name: "Expire in 30s*",
            code: EGmapShowOptions.expireLater,
            enabled: true
          },
          decreaseTimer: {
            name: "Decrease timer 30s*",
            code: EGmapShowOptions.decreaseTimer,
            enabled: true
          },
          preloadStory: {
            name: "Preload story*",
            code: EGmapShowOptions.preloadStory,
            enabled: true
          },
          preloadStoryFull: {
            name: "Preload story full*",
            code: EGmapShowOptions.preloadStoryFull,
            enabled: true
          },
          treasureScan: {
            name: "Treasure Scan*",
            code: EGmapShowOptions.treasureScan,
            enabled: true
          },
          treasureScanCore: {
            name: "Treasure Scan Core*",
            code: EGmapShowOptions.treasureScanCore,
            enabled: true
          },
          clearMap: {
            name: "Clear map",
            code: EGmapShowOptions.clearMap,
            enabled: true
          },
          refreshLayout: {
            name: "Refresh layout",
            code: EGmapShowOptions.refreshLayout,
            enabled: true
          },
          showLayers: {
            name: "Show layers",
            code: EGmapShowOptions.showLayers,
            enabled: true
          },
          hideLayers: {
            name: "Hide layers",
            code: EGmapShowOptions.hideLayers,
            enabled: true
          },
          refreshStoryLocationMarkers: {
            name: "Refresh story loc",
            code: EGmapShowOptions.refreshStoryLocationMarkers,
            enabled: true
          },
          hideStoryLocationMarkers: {
            name: "Hide story loc",
            code: EGmapShowOptions.hideStoryLocationMarkers,
            enabled: true
          },
          reloadStoryLocations: {
            name: "Reload story locs",
            code: EGmapShowOptions.reloadStoryLocations,
            enabled: true
          },
          setAppendTreasuresMode: {
            name: !this.itemScanner.getExpandTreasuresMode() ? "Set expand treasure scan" : "Set default treasure scan",
            code: EGmapShowOptions.setAppendTreasuresMode,
            enabled: true
          }
        };
        Object.assign(actions, actions2);
      }

      this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
        view: {
          fullScreen: false,
          transparent: AppConstants.transparentMenus,
          large: true,
          addToStack: true,
          frame: false
        },
        params: { actions: actions }
      }, () => {
        resolve(true);
      }).then((code: number) => {
        switch (code) {
          case EGmapShowOptions.exitMap:
            this.goBackRequest(true, true);
            break;
          case EGmapShowOptions.tutorial:
            this.onHelpClick();
            break;
          case EGmapShowOptions.mapFilter:
            this.selectLayers();
            break;
          case EGmapShowOptions.jumpToMeetingPlace:
            let central: ILatLng = StoryUtils.getCentralLocation(this.app.storyLocations);
            PromiseUtils.wrapNoAction(this.itemScanner.jumpToMeetingPlaceWhenReady(central, this.currentLocation), true);
            break;
          case EGmapShowOptions.placeScan:
            this.showNearbyPlacesPopupOnClick();
            break;
          case EGmapShowOptions.switchDroneMode:
            if (!this.flags.droneMode) {
              this.enterDroneMode(false);
            } else {
              this.exitDroneMode(true);
            }
            break;
          case EGmapShowOptions.compassMode:
            this.switchCompass();
            break;
          case EGmapShowOptions.arview:
            this.openAR();
            break;
          case EGmapShowOptions.toggleHud:
            this.setHudState(!this.flags.showHud, true);
            break;
          case EGmapShowOptions.directions:
            this.recalculateDirections();
            break;
          case EGmapShowOptions.shareOptions:
            this.onShareClick(null);
            break;
          case EGmapShowOptions.locationFab:
            // switch fab
            this.flags.mapDebugModeFab = !this.flags.mapDebugModeFab;
            break;
          case EGmapShowOptions.debugMode:
            this.flags.mapDebugMode = !this.flags.mapDebugMode;
            this.applyPreset();
            this.checkApplyDebugMode();
            break;
          // test controls
          case EGmapShowOptions.teleport:
            this.goToNextLocationTest();
            break;
          case EGmapShowOptions.placeUser:
            this.setCurrentLocationSimulation();
            break;
          case EGmapShowOptions.placeSearch:
            this.openFinder();
            break;
          case EGmapShowOptions.decreaseTimer:
            this.triggerDecreaseTimer();
            break;
          case EGmapShowOptions.expire:
            this.triggerExpireActivity();
            break;
          case EGmapShowOptions.expireLater:
            setTimeout(() => {
              this.triggerExpireActivity();
            }, 30000);
            break;
          case EGmapShowOptions.preloadStory:
            this.preloadStorySyncNoAction(true, false);
            break;
          case EGmapShowOptions.preloadStoryFull:
            this.preloadStorySyncNoAction(false, false);
            break;
          case EGmapShowOptions.treasureScan:
            PromiseUtils.wrapNoAction(this.itemScanner.treasureScan(), true);
            break;
          case EGmapShowOptions.treasureScanCore:
            this.reSyncTreasures();
            break;
          case EGmapShowOptions.placeTreasure:
            this.placeTreasureContent(false);
            break;
          case EGmapShowOptions.clearMap:
            // clear map, re-add treasures
            this.clearEnvMarkers().then(() => {
              this.reSyncTreasures();
            });
            break;
          case EGmapShowOptions.showLayers:
            this.restoreShowTreasureLayersRetryResolve();
            break;
          case EGmapShowOptions.hideLayers:
            this.hideTreasureLayersRetryResolve();
            break;
          case EGmapShowOptions.refreshLayout:
            this.mapManager.refreshMapLayout();
            break;
          case EGmapShowOptions.refreshStoryLocationMarkers:
            this.storyManagerService.refreshStoryLocationMarkersNoAction();
            break;
          case EGmapShowOptions.hideStoryLocationMarkers:
            this.storyManagerService.clearStoryLocationMarkersNoAction();
            break;
          case EGmapShowOptions.reloadStoryLocations:
            this.preloadStoryNew();
            break;
          case EGmapShowOptions.setAppendTreasuresMode:
            this.itemScanner.setExpandTreasuresMode(!this.itemScanner.getExpandTreasuresMode());
            break;
          case EGmapShowOptions.showCompassAdjustControl:
            if (!this.flags.droneMode) {
              this.flags.showCompassAdjustControl = !this.flags.showCompassAdjustControl;
              this.headingService.resetJoystickControl();
            }
            break;
          case EGmapShowOptions.showCompassAdjustInput:
            this.headingService.openCompSelector();
            break;
          case EGmapShowOptions.toggleScannerEnabled:
            this.itemScanner.toggleExt();
            break;
          case EGmapShowOptions.demoModeAR:
            this.flags.setARDemoMode = !this.flags.setARDemoMode;
            this.applyPreset();
            break;
          case EGmapShowOptions.webkitCompassAR:
            this.flags.useARWebkitCompass = !this.flags.useARWebkitCompass;
            this.applyPreset();
            break;
          case EGmapShowOptions.simulateNoCompass:
            this.headingService.setCompassNotAvailableFlags();
            break;
          case EGmapShowOptions.locationFailTest:
            this.locationManager.setSimulateWatchLocationFailTest(null);
            break;
          case EGmapShowOptions.reloadLastStoryMarker:
            this.reloadLastStoryMarker(true, null, null);
            break;
          case EGmapShowOptions.skipToLocationIndex:
            this.popupFeatures.openNumericInputSelector("Skip to location", 1, this.app.storyLocations.length, this.app.locationIndex + 1).then((index: number) => {
              if (index != null) {
                this.skipPlaceOnClick(null, index - 1);
              }
            });
            break;
          default:
            break;
        }
      }).catch((err: Error) => {
        console.error(err);
        resolve(false);
      });
    });
    return promise;
  }

  switchCompass() {
    switch (this.flags.follow) {
      case this.followModes.MOVE_MAP_HEADING_2D:
        // disable compass
        this.setFollow(EMapInteraction.enter, true);
        break;
      default:
        // enable compass
        this.setFollow(EMapInteraction.switch2dHeading, true);
        break;
    }
  }

  /**
   * place content treasure on the map
   */
  placeTreasureContent(fromCache: boolean) {
    let np: ILeplaceWrapper = this.itemScanner.getNearestPlaceFromCache(this.currentLocation.location);
    if (!np) {
      this.uiext.showAlertNoAction(Messages.msg.noNearbyPlaces.after.msg, Messages.msg.noNearbyPlaces.after.sub);
    } else {
      let push: boolean = !this.internalFlags.isRecording;
      let refreshMap: boolean = false;
      // tslint:disable-next-line:max-line-length
      this.contentCreator.placeContent(this.currentLocation.location, np.place.place.googleId, !push, push, !push, fromCache ? this.collectibles.treasures.selectedSpec : null).then((res: ITreasureSpecInput) => {
        // re-sync partial
        // this.itemScanner.treasureScanByPlaceId(np.id).then(() => {

        // }).catch((err: Error) => {
        //   console.error(err);
        // });

        console.log("content created: ", res);
        let promiseRefreshMap: Promise<boolean>;
        this.collectibles.treasures.selectedSpec = res;

        switch (res.code) {
          case ETreasureSpecInput.syncPositions:
            refreshMap = true;
            break;
        }

        // get updated coords (using filter)
        if (push) {
          // treasure was pushed to the server
          // reload treasures and the new treasure should appear on the same layer

          if (refreshMap) {
            promiseRefreshMap = this.itemScanner.refreshWorldMapMarkerLayerWrapperResolve(EMarkerLayers.CRATES, this.itemScanner.getAttachedTreasurePlaceMarkers(), null);
          } else {
            promiseRefreshMap = Promise.resolve(true);
          }

          promiseRefreshMap.then(() => {
            switch (res.code) {
              case ETreasureSpecInput.add:
                this.messageQueueHandler.prepare("-" + AppConstants.gameConfig.placeContentScanEnergy + " energy", false, EQueueMessageCode.info);
                // this.reSyncTreasures();
                break;
              case ETreasureSpecInput.syncPositions:
                this.editTreasuresLoc(false);
                break;
            }
          });
        }
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }

  /**
   * update the location of treasures as placed on the map
   * @param ack 
   */
  editTreasuresLoc(ack: boolean) {
    let treasures: ILeplaceTreasure[] = this.itemScanner.getOwnedTreasuresFromCache(false);
    // console.log("custom content data: ", treasures);
    this.userContentProvider.updateFixedTreasureArray(treasures, this.modeSelect.editor).then(() => {
      this.reSyncTreasures();
      if (ack) {
        this.uiext.showAlertNoAction(Messages.msg.worldMapUpdated.after.msg, Messages.msg.worldMapUpdated.after.sub);
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * undo the location update of treasures on the map
   */
  undoEditTreasuresLoc() {
    this.reSyncTreasures();
  }

  /**
   * select layers (checkbox)
   */
  selectLayers() {
    this.uiextStandard.showStandardModal(null, EModalTypes.checkboxGrid, "Map layers", {
      view: {
        fullScreen: false,
        transparent: false,
        large: true,
        addToStack: true,
        frame: false
      },
      params: { actions: this.layers }
    }).then((data: ICheckboxFrameStatus) => {
      if (data) {
        if (data.update) {
          // refresh object layers
          // also check for less/more items
          // less does not require a new place scan
          this.layers = data.status as IActionLayers;
          this.layersBak = DeepCopy.deepcopy(this.layers);
          this.setShowLayers(this.layers).then(() => {

          }).catch((err: Error) => {
            console.error(err);
          });
        }
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * resolve only
   * @param action 
   */
  async layerSyncRetryResolveFn(action: () => Promise<boolean>): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve) => {
      let retryCount: number = 3;
      let retryTimeout: number = 500;
      let retryFn = async () => {
        try {
          await action();
          resolve(true);
        } catch (e) {
          console.log("retry");
          if (retryCount > 0) {
            retryCount -= 1;
            this.dedicatedTimeouts.refreshTreasureLayersRetry = setTimeout(() => {
              retryFn();
            }, retryTimeout);
          } else {
            resolve(false);
          }
        }
      }
      retryFn();
    });
    return promise;
  }


  /**
   * hide treasures when starting a challenge/activity
   * switch by activity code
   */
  async hideTreasureLayers(): Promise<boolean> {
    console.log("layers > set hide layers");
    let layers: IActionLayers = {};
    let keys: string[] = Object.keys(this.layers);
    for (let i = 0; i < keys.length; i++) {
      let key: string = keys[i];
      layers[key] = Object.assign({}, this.layers[key]);
      switch (layers[key].type) {
        case ETreasureMode.worldMap:
          layers[key].enabled = false;
          break;
        case ETreasureMode.collectible:
          layers[key].enabled = true;
          break;
      }
    }
    return this.setShowLayers(layers);
  }

  async hideTreasureLayersRetryResolve(): Promise<boolean> {
    // return this.hideTreasureLayers();
    return this.layerSyncRetryResolveFn(() => { return this.hideTreasureLayers(); });
  }

  /**
   * restoring show state when completing a challenge
   */
  async restoreShowTreasureLayers(): Promise<boolean> {
    console.log("layers > restore show layers");
    return this.setShowLayers(this.layers);
  }

  async restoreShowTreasureLayersRetryResolve(): Promise<boolean> {
    return this.layerSyncRetryResolveFn(() => { return this.restoreShowTreasureLayers(); });
    // return this.restoreShowTreasureLayers();
  }

  /**
   * set show layers (e.g. select filters from a list)
   * refresh map
   * @param layers 
   */
  async setShowLayers(layers: IActionLayers): Promise<boolean> {
    console.log("layers > set show layers: ", layers);
    this.setShowLayersCore(layers);
    // include reject, to be able to retry
    return this.itemScanner.refreshWorldMapMarkerLayerWrapperResolve(EMarkerLayers.CRATES, this.itemScanner.getAttachedTreasurePlaceMarkers(), null);
  }

  /**
   * set show layers (e.g. select filters from a list)
   * @param layers 
   */
  setShowLayersCore(layers: IActionLayers) {
    this.geoObjects.setShowLayers(layers);
    this.itemScanner.setShowLayers(layers);
    this.itemScanner.setShowMarkersFromUnlockedLayers();
  }

  setShowLayerNoAction(layers: IActionLayers) {
    this.setShowLayers(layers).then(() => {

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  enableGPSMapUpdate() {
    this.internalFlags.mapAllowGPSHandling = true;
  }

  disableGPSMapUpdate() {
    this.internalFlags.mapAllowGPSHandling = false;
  }

  checkEnableGPSMapUpdate() {
    return this.internalFlags.mapAllowGPSHandling && !this.internalFlags.mapDisableGPSHandling;
  }

  /**
   * before opening modal
   * disable map rendering
   * dismiss loading (prevent hanging modals)
   */
  async onModalOpened() {
    this.droneSimulator.pauseSimulation();
    this.disableGPSMapUpdate();
    this.flags.showCompassAdjustControl = false;
    this.headingService.resetJoystickControl();
    await this.uiext.dismissLoadingV2();
  }

  onModalClosed() {
    this.droneSimulator.resumeSimulation();
    this.enableGPSMapUpdate();
    this.showMapUnlockRequest();
  }

  /**
   * tap to unlock map
   * handle bug with map not moving under certain conditions e.g. first open w/ no GPS update, after modal closed
   */
  showMapUnlockRequest() {
    this.buttonOptions.location.blinkOnChange = !this.buttonOptions.location.blinkOnChange;
    // this.messageQueueHandler.prepare("Tap to unlock the map", true, EQueueMessageCode.warn);
  }

  /**
  * load active items from the inventory
  * reload is mandatory so that expired items are not included
  */
  loadInventory(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve, reject) => {
      this.inventory.getActiveItemList(true, [EItemCategoryCodes.powers]).then((activeItems: IGameItem[]) => {
        // this.activeInventoryItems = activeItems.map((e) => e.code);
        this.activeInventoryItems = activeItems;
        this.app.activeItems = activeItems.length;
        this.checkActionItems(this.activeInventoryItems, true);
        console.log("inventory loaded");
        resolve(true);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  checkActionItems(items: IGameItem[], active: boolean) {
    for (let item of items) {
      this.checkActionItem(item, active);
    }
  }

  checkActionItem(item: IGameItem, active: boolean) {
    let itemCode: number = GameUtils.getItemCode(item.code);
    let value: number = item.value;
    console.log("check action item: ", itemCode, active);
    switch (itemCode) {
      case EItemCodes.coinMagnet:
        // update the explore circle radius
        // and the collect distance
        if (!active) {
          value = 0;
        }
        let collectDistance: number = GameUtils.getCoinCollectDistance(value);
        if (this.activityStarted.explore) {
          this.exploreUtils.initSession(collectDistance);
          let collectDistanceOverride: number = this.exploreUtils.getCollectRadiusOverride();
          if (collectDistanceOverride != null) {
            collectDistance = collectDistanceOverride;
          }
          this.mapManager.setExploreRadiusCircleSize(collectDistance);
        }
        break;
      case EItemCodes.coinMultiplier:
        break;
      case EItemCodes.greenTech:
        this.droneSimulator.applyUpgrade(itemCode, item.value, active);
        break;
      case EItemCodes.turboTech:
        this.droneSimulator.prepareUpgrade(item, active);
        break;
    }
  }

  applyDroneUpgrade(apply: boolean) {
    if (apply == null) {
      this.internalFlags.droneUpgradeState = !this.internalFlags.droneUpgradeState;
      apply = this.internalFlags.droneUpgradeState;
    }
    this.droneSimulator.applyUpgradePrepared(apply);
  }

  hasDroneUpgradePrepared() {
    return this.flags.droneMode && this.droneSimulator.hasPreparedUpgrade();
  }

  /**
   * go to inventory
   * returns when the user leaves the inventory
   */
  goToInventory(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve, reject) => {
      await this.onModalOpened();
      this.gmapModals.goToInventoryGmap(true).then((rets: IInventoryReturnItem[]) => {
        console.log("returned from modal to map: ", rets);
        // maybe the user recharged the scan energy from inventory directly
        this.itemScanner.resume();
        this.onModalClosed();
        let setMapName: string = null;
        if (rets) {
          for (let i = 0; i < rets.length; i++) {
            let ret: IInventoryReturnItem = rets[i];
            switch (ret.action) {
              case EItemActions.buy:
                // an item was purchased that may be activated immediately
                if (ret.item.itemCategoryCode === EItemCategoryCodes.maps) {
                  // only keep the last map selected
                  setMapName = ret.item.tag;
                }
                break;
              case EItemActions.use:
                // an item was activated
                if (ret.item.itemCategoryCode === EItemCategoryCodes.maps) {
                  // only keep the last map selected
                  setMapName = ret.item.name;
                } else {
                  if (this.activeInventoryItems.find(e => e.code === ret.item.code) == null) {
                    this.app.activeItems += 1;
                    this.activeInventoryItems.push(ret.item);
                  }
                  this.checkActionItem(ret.item, true);
                }
                break;
              case EItemActions.drop:
                if (ret.item.itemCategoryCode === EItemCategoryCodes.maps) {
                  // revert to original story map skin
                  this.mapManager.setMapStyle(this.storyParams.mapStyle);
                  this.app.activeItems -= 1;
                  if (this.app.activeItems < 0) {
                    this.app.activeItems = 0;
                  }
                  // remove item from active inventory items
                  // this.activeInventoryItems = this.activeInventoryItems.filter((e) => e.code !== ret.item.code);
                } else {
                  this.app.activeItems -= 1;
                  if (this.app.activeItems < 0) {
                    this.app.activeItems = 0;
                  }

                  this.activeInventoryItems = this.activeInventoryItems.filter((e) => e.code !== ret.item.code);
                  this.checkActionItem(ret.item, false);
                }
                break;
            }
          }

          if (setMapName != null) {
            this.mapManager.setMapStyle(setMapName);
            // this.app.activeItems += 1;
            // this.activeInventoryItems.push(ret.item);
          }

          // if (GeneralCache.checkOSReal() === EOS.ios) {
          //   this.uiext.showAlertNoAction(Messages.msg.info.after.msg, "Tap on a button to unlock the map (this is a known bug)");
          // }

          resolve(true);
        } else {
          resolve(true);
        }
      }).catch((err: Error) => {
        this.onModalClosed();
        console.error(err);
        reject(err);
      });
    });
    return promise;
  }

  goToInventoryNoAction() {
    this.goToInventory().then(() => {

    }).catch((err: Error) => {
      console.error(err);
    });
  }


  /**
  * show other objects e.g. user generated content on the map
  */
  handleOtherObjectsGenerator() {
    this.subscription.otherObjectsGen = this.geoObjects.getGlobalObjectWatch().subscribe((data: ILeplaceObjectGenerator) => {
      console.log("object generator: ", data);
      let placeMarkers: IPlaceMarkerContent[] = [];
      let objects: ILeplaceObjectContainer[] = this.geoObjects.getAllBufferedObjects();
      for (let i = 0; i < objects.length; i++) {
        let obj: ILeplaceObjectContainer = objects[i];
        let placeMarker: IPlaceMarkerContent;
        // console.log(obj);
        if (!obj.treasure) {
          placeMarker = this.markerUtils.getARObjectPlaceMarker(obj, EMarkerLayers.OTHER_OBJECTS);
        } else {
          placeMarker = this.attachPlaceMarkerToTreasure(obj.treasure);
        }
        placeMarkers.push(placeMarker);
      }

      // console.log(placeMarkers);
      this.itemScanner.refreshWorldMapMarkerLayerNoAction(EMarkerLayers.CRATES, placeMarkers, null);
    }, (err: Error) => {
      console.error(err);
    });
  }

  /**
   * get nearby objects from container
   * filter by type
   * @param container 
   * @param type (treasure, challenge), (coin)
   * @returns 
   */
  getNearbyObjectsType(container: INearbyContentMagnet, type: number) {
    if (!(container.list && container.list.length > 0)) {
      return null;
    }
    // collect the first item in the available list
    // the item scanner should issue the remove command that will remove the item from this array as well, via observable
    let treasures: INearbyContentMagnetElem[] = type != null ? container.list.filter(elem => elem.type === type) : container.list;
    if (!(treasures.length > 0)) {
      return null;
    }
    return treasures;
  }

  /**
   * collectible objects = treasures, challenges, coins
   * priority: coins, treasures, challenges
   * treasures are handled directly, other objects are handled by calling associated methods
   */
  collectNearbyObject() {
    if (this.collectNearbyCoin()) {
      return true;
    }
    let treasures: INearbyContentMagnetElem[] = this.getNearbyObjectsType(this.collectibles.treasures, ENearbyContentType.treasure);
    if (treasures == null) {
      return this.startNearbyChallenge();
    }
    let item: ILeplaceTreasure = treasures[0].elem as ILeplaceTreasure;
    this.registerItemCollected(item);
    return true;
  }

  collectNearbyCoin() {
    let coins: INearbyContentMagnetElem[] = this.getNearbyObjectsType(this.collectibles.coins, ENearbyContentType.coin);
    if (coins == null) {
      return false;
    }
    let item: ILeplaceObjectContainer = coins[0].elem as ILeplaceObjectContainer;
    let activityBaseType: number = this.activityProvider.getActivityNavCollectType();
    if (activityBaseType == null) {
      activityBaseType = this.activityProvider.getActivityCollectType();
    }
    let check: ICheckCollectItem = this.itemCollector.collectFromAR(item.object.uid, activityBaseType);
    console.log("tap collect object: ", item.object);
    console.log("tap collect check: ", check);
    return true;
  }

  startNearbyChallenge() {
    let treasures: INearbyContentMagnetElem[] = this.getNearbyObjectsType(this.collectibles.challenges, ENearbyContentType.challenge);
    if (treasures == null) {
      return false;
    }
    let item: ILeplaceTreasure = treasures[0].elem as ILeplaceTreasure;
    this.onTreasureOpened(item);
    return true;
  }

  checkNearbyChallenge() {
    return this.getNearbyObjectsType(this.collectibles.challenges, ENearbyContentType.challenge) != null;
  }

  /**
   * remove item from the map after collect/remove event
   */
  removeMapFeatureItem(item: ILeplaceTreasure) {
    let check: ICheckCollectItem = ItemCollectorUtils.checkCollectItemGenericCodeCore(item.type, item.ontap);
    console.log("remove map feature item: ", item, check);
    if (check.map) {
      // this.markerHandler.clearMarkerIndex(EMarkerLayers.CRATES, markerIndex);
      this.markerHandler.clearMarkerByUid(EMarkerLayers.CRATES, item.uid);
      this.itemScanner.clearAttachedTreasurePlaceMarkerUid(item.uid, true);
    }
    if (check.ar) {
      this.geoObjects.removeAnyObjectByUid(item.uid, false);
    }
  }

  /**
   * user marker callback with overlap check
   */
  getUserMarkerCallback(data: IPlaceMarkerContent) {
    this.getMarkerCallback(data, { title: "Player", heading: "User " + GeneralCache.userId, description: "You are here", large: false });
  }

  /**
   * generic marker callback with overlap check
   */
  getMarkerCallback(data: IPlaceMarkerContent, context: IMarkerDetailsOpenContext) {
    let lat: number;
    let lng: number;
    if (data) {
      if (data.location) {
        lat = data.location.lat;
        lng = data.location.lng;
      }
      if (data.fakeLocation) {
        lat = data.fakeLocation.lat;
        lng = data.fakeLocation.lng;
      }
    }
    console.log("generic marker callback: ", data, context);
    this.checkOverlapsZoom(lat, lng).then(async (result: boolean) => {
      if (result) {
        let res: boolean = await PromiseUtils.wrapResolve(this.markerUtils.showContextMarkerModal(data, context), true);
        console.log("show context marker modal res: ", res);
        if (data.appContext && res) {
          console.log("trigger magnet");
          // trigger magnet
          let activityBaseType: number = this.activityProvider.getActivityNavCollectType();
          if (activityBaseType == null) {
            activityBaseType = this.activityProvider.getActivityCollectType();
          }
          data.appContext.disabled = true; // don't show collect button afterwards
          data.appContext.collectedNow = true; // pop up only once (but make sure to count as collected, regardless of the disabled flag)
          this.itemCollector.collectFromAR(data.uid, activityBaseType);
        }
        // this.uiext.showAlertNoAction(context.title, context.description);
      }
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "gmap");
      // fallback
      PromiseUtils.wrapNoAction(this.markerUtils.showContextMarkerModal(data, context), true);
      // this.uiext.showAlertNoAction(context.title, context.description);
    });
  }

  /**
   * check for overlapping features and zoom in if required
   * move to center if no overlapping features
   */
  checkOverlapsZoom(lat: number, lng: number): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve, reject) => {
      // disable follow
      this.setFollow(EMapInteraction.exit, true);
      let smartMapSelect: boolean = SettingsManagerService.settings.app.settings.smartMapSelect.value;

      if ((lat == null) || (lng == null)) {
        resolve(true);
        return;
      }

      if (smartMapSelect) {
        // check for overlaps
        let overlaps: IPlaceMarkerContent[] = this.markerHandler.checkOverlaps(lat, lng, this.mapManager.getZoom(), [EMarkerLayers.MARKER_CIRCLES, EMarkerLayers.FIND_CIRCLES, EMarkerLayers.CIRCLE_AUX_MARKERS]);
        if (overlaps && (overlaps.length > 1)) {
          console.log("overlaps detected");
          console.log(overlaps);
          this.buttonOptions.zoom.blink = true;
          // zoom in to fit overlapping markers
          this.mapManager.moveMapToFitBounds(overlaps.map(p => p.location), this.flags.animationDuration, true, false).then(() => {
            this.buttonOptions.zoom.blink = false;
            resolve(false);
          }).catch((err: Error) => {
            this.buttonOptions.zoom.blink = false;
            reject(err);
          });
        } else {
          resolve(true);
        }
      } else {
        resolve(true);
      }
      // console.log("overlaps: ", overlaps);
    });
    return promise;
  }

  async onZoomClick() {
    let zoomState: number = this.smartZoom.processStateTransition();
    console.log("zoom state: ", zoomState);
    let commandWrapper = async (command: () => Promise<any>) => {
      this.disableGPSHandling(3000);
      await SleepUtils.sleep(300);
      await command();
      this.enableGPSHandling();
    };
    try {
      switch (zoomState) {
        case ESmartZoomState.zoomInUser:
          this.enableFollowTrueAnimate(); // enable user follow mode
          break;
        // world map
        case ESmartZoomState.zoomOutFixed:
          this.setFollowNoResetZoom(EMapInteraction.exit); // disable user follow mode       
          await commandWrapper(() => this.goToUser(MapSettings.zoomOutLevel, false));
          this.onSetZoomLevel(MapSettings.zoomOutLevel);
          break;
        // storyline
        case ESmartZoomState.zoomInDestination:
          this.setFollowNoResetZoom(EMapInteraction.exit); // disable user follow mode
          await commandWrapper(() => this.showDestination());
          break;
        case ESmartZoomState.zoomOutToFitDestination:
          this.setFollowNoResetZoom(EMapInteraction.exit); // disable user follow mode
          await commandWrapper(() => this.moveMapToFitWaypoints());
          break;
        case ESmartZoomState.zoomOutToFitAuxPlaces:
          this.setFollowNoResetZoom(EMapInteraction.exit); // disable user follow mode
          await commandWrapper(() => this.moveMapToFitScannedPlaces());
          break;
        case ESmartZoomState.zoomToFitPreloadStory:
          this.setFollowNoResetZoom(EMapInteraction.exit); // disable user follow mode
          await commandWrapper(() => this.moveMapToFitStoryLocations());
          break;
        // event treasures
        case ESmartZoomState.zoomToFitTreasures:
          this.setFollowNoResetZoom(EMapInteraction.exit); // disable user follow mode
          await commandWrapper(() => this.moveMapToFitTreasures(false));
          break;
        case ESmartZoomState.zoomToFitUserAndTreasures:
          this.setFollowNoResetZoom(EMapInteraction.exit); // disable user follow mode
          await commandWrapper(() => this.moveMapToFitTreasures(true));
          break;
      }
    } catch (err) {
      console.error(err);
      this.smartZoom.resetState();
      this.enableFollowTrueAnimate(); // re-enable user follow mode
    }
  }

  animateZoomTreasures(): void {
    this.disableFollow();
    this.internalFlags.moveMapSequenceEnabled = true;

    let sequence: Promise<boolean> = new Promise(async (resolve) => {
      try {
        await SleepUtils.sleep(1000);
        if (!this.internalFlags.moveMapSequenceEnabled) {
          resolve(false);
        }
        await this.moveMapToFitTreasures(false);
        await SleepUtils.sleep(3000);
        if (!this.internalFlags.moveMapSequenceEnabled) {
          resolve(false);
        }
        await this.moveMapToFitTreasures(true);
        await SleepUtils.sleep(2000);
        if (!this.internalFlags.moveMapSequenceEnabled) {
          resolve(false);
        }
        this.enableFollowTrueAnimate(); // re-enable user follow mode
        resolve(true);
      } catch (e) {
        console.error(e);
        this.enableFollowTrueAnimate(); // re-enable user follow mode
        resolve(false);
      }
    });

    sequence.then((res: boolean) => {
      console.log("animate zoom sequence complete: " + res);
    });
  }

  animateZoomFindChallenge(): void {
    this.disableFollow();
    this.internalFlags.moveMapSequenceEnabled = true;

    let sequence: Promise<boolean> = new Promise(async (resolve) => {
      try {
        await SleepUtils.sleep(1000);
        if (!this.internalFlags.moveMapSequenceEnabled) {
          resolve(false);
        }
        await this.moveMapToFitWaypoints();
        await SleepUtils.sleep(3000);
        if (!this.internalFlags.moveMapSequenceEnabled) {
          resolve(false);
        }
        this.enableFollowTrueAnimate(); // re-enable user follow mode
        resolve(true);
      } catch (e) {
        console.error(e);
        this.enableFollowTrueAnimate(); // re-enable user follow mode
        resolve(false);
      }
    });

    sequence.then((res: boolean) => {
      console.log("animate zoom sequence complete: " + res);
    });
  }


  animateZoomTarget(target: ILatLng): void {
    this.disableFollow();
    this.internalFlags.moveMapSequenceEnabled = true;

    let sequence: Promise<boolean> = new Promise(async (resolve) => {
      try {
        await SleepUtils.sleep(1000);
        if (!this.internalFlags.moveMapSequenceEnabled) {
          resolve(false);
        }

        await this.moveMapOptions(target);
        await SleepUtils.sleep(3000);
        if (!this.internalFlags.moveMapSequenceEnabled) {
          resolve(false);
        }
        this.enableFollowTrueAnimate(); // re-enable user follow mode
        resolve(true);
      } catch (e) {
        console.error(e);
        this.enableFollowTrueAnimate(); // re-enable user follow mode
        resolve(false);
      }
    });

    sequence.then((res: boolean) => {
      console.log("animate zoom sequence complete: " + res);
    });
  }

  /**
   * attach place marker to (single) treasure
   * @param treasure 
   */
  attachPlaceMarkerToTreasure(treasure: ILeplaceTreasure) {
    let placeMarker: IPlaceMarkerContent = this.markerUtils.attachPlaceMarkerToTreasure(treasure, EMarkerLayers.CRATES,
      this.isWorldMap, this.platform.WEB, (item: ILeplaceTreasure) => {
        this.checkOverlapsZoom(item.lat, item.lng).then((result: boolean) => {
          if (result) {
            this.onTreasureOpened(item);
          }
        }).catch((err: Error) => {
          console.error(err);
          this.analytics.dispatchError(err, "gmap");
          // fallback
          this.onTreasureOpened(item);
        });
      }, !this.platform.WEB);
    return placeMarker;
  }

  /**
   * load cached items from the item scanner
   * (re)attach place markers
   */
  loadCachedItems() {
    let mk: ILeplaceTreasure[] = this.itemScanner.getItemCache();
    // check registered places
    let registeredPlaces: boolean = mk.filter(mk1 => {
      return TreasureUtils.checkRegisteredTreasurePlace(mk1);
    }).length > 0;
    if (registeredPlaces) {
      // this.messageQueueHandler.prepare("There are verified places nearby");
      // this.soundManager.vibrateContext(true);
      // this.soundManager.ttsWrapper(Messages.tts.verifiedPlaces, true);
    }
    console.log("attach place markers");
    this.itemScanner.attachPlaceMarkers(this.viewId, this.isWorldMap,
      this.platform.WEB, EMarkerLayers.CRATES, (item: ILeplaceTreasure) => {
        this.checkOverlapsZoom(item.lat, item.lng).then((result: boolean) => {
          if (result) {
            this.onTreasureOpened(item);
          }
        }).catch((err: Error) => {
          console.error(err);
          this.analytics.dispatchError(err, "gmap");
          // fallback
          this.onTreasureOpened(item);
        });
      });
    // console.log("item scanner entry: ", this.itemScanner.getAttachedTreasurePlaceMarkers());
    this.excludeLocationIdList = [];
    this.itemScanner.refreshWorldMapMarkerLayerNoAction(EMarkerLayers.CRATES, this.itemScanner.getAttachedTreasurePlaceMarkers(), null);
    if (this.eventId != null) {
      this.smartZoom.updateTransition(ESmartZoomTransitions.scanEventTreasures);
      if (this.internalFlags.eventMapInitScan) {
        this.internalFlags.eventMapInitScan = false;
        this.animateZoomTreasures();
      }
    }
  }

  /**
   * subscribe to item scanner and display items on the map
   * the item scanner returns multiple crates for each place
   * the place is defined in the item data, so each item has the info about the associated place
   */
  handleItemScanner() {
    if (!this.subscription.itemGenerateWatch) {
      // watch item generate so that they are shown on the map
      this.subscription.itemGenerateWatch = this.itemScanner.getItemGenerateObservable().subscribe((mk: ILeplaceTreasure[]) => {
        // receive all available items with details about the associated place
        if (mk) {
          this.loadCachedItems();
        }
        if (this.isPreloadStory) {
          // PromiseUtils.wrapNoAction(this.preloadStory(true), true);
          // PromiseUtils.wrapNoAction(this.loadStoryProgressOnly(), true);
        }
      }, (err: Error) => {
        console.error(err);
      });
      // check for scan event, show loading
      if (!this.subscription.itemScanEventWatch) {
        this.subscription.itemScanEventWatch = this.itemScanner.getScanEventObservable().subscribe((scan: boolean) => {
          if (scan != null) {
            if (scan) {
              this.loading = true;
            } else {
              this.loading = false;
            }
          }
        }, (err: Error) => {
          console.error(err);
        });
      }
      if (!this.subscription.itemScanCooldownWatch) {
        this.subscription.itemScanCooldownWatch = this.itemScanner.getScanCooldownObservable().subscribe((progress: number) => {
          // console.log("scan cooldown: " + progress);
          if (!this.loading) {
            this.progress = progress;
          } else {
            this.progress = null;
          }
        }, (err: Error) => {
          console.error(err);
        });
      }

      if (!this.subscription.initVirtualPosition) {
        this.subscription.initVirtualPosition = this.itemScanner.getInitVirtualPositionObservable().subscribe((status: boolean) => {
          if (status) {
            if (this.isPreloadStory) {
              this.showStoryLocationsOverview();
            }
          }
        }, (err: Error) => {
          console.error(err);
        });
      }

      // check for available items event
      if (!this.subscription.itemAvailableWatch) {
        this.subscription.itemAvailableWatch = this.itemScanner.getItemAvailableObservable().subscribe((itemAction: ILeplaceTreasureAction) => {
          // handle treasures CRUD (only features of type treasure)
          // this only handles the nearby objects list, NOT actual add/remove items on the map
          let isMagnet: boolean = false;
          if (itemAction && itemAction.treasure) {
            console.log("item action [" + itemAction.code + "]", itemAction);
            let container: INearbyContentMagnet = null;
            let type: number = null;
            switch (itemAction.treasure.type) {
              case ETreasureType.treasure:
                type = ENearbyContentType.treasure;
                container = this.collectibles.treasures;
                isMagnet = true;
                break;
              case ETreasureType.challenge:
                type = ENearbyContentType.challenge;
                container = this.collectibles.challenges;
                break;
            }

            if (container != null) {
              switch (itemAction.code) {
                case ECRUD.add:
                  // a new item of type treasure was loaded
                  // add to the treasure list buffer
                  // blink button notification
                  // check empty
                  if (!itemAction.treasure.lockedForUser && (type != null)) {
                    if (!container.list.find(item => (item.elem as ILeplaceTreasure).uid === itemAction.treasure.uid)) {
                      // console.log("add: ", itemAction.treasure.uid);
                      // don't add already existing items
                      let elem: INearbyContentMagnetElem = {
                        type: type,
                        elem: itemAction.treasure
                      };
                      container.list.push(elem);
                      container.objectsNearby = true;
                      if (isMagnet) {
                        this.buttonOptions.magnet.blink = true;
                      }
                    }
                    // this.notifyTreasureNearby(itemAction.treasure);
                  }
                  break;
                case ECRUD.remove:
                  // remove the treasure from the treasure list buffer as well
                  // disable the magnet if there are no more treasures in range
                  let index: number = container.list.findIndex(item => (item.elem as ILeplaceTreasure).uid === itemAction.treasure.uid);
                  if (index !== -1) {
                    // console.log("remove: ", itemAction.treasure.uid);
                    container.list.splice(index, 1);
                  }
                  console.log("removed index: ", index);
                  // console.log(container.list);
                  if (container.list.length > 0) {
                    container.objectsNearby = true;
                    if (isMagnet) {
                      this.buttonOptions.magnet.blink = true;
                    }
                  } else {
                    container.objectsNearby = false;
                    if (isMagnet) {
                      this.resetCollectiblesMagnet();
                    }
                  }
                  break;
              }
            }
          }
        }, (err: Error) => {
          console.error(err);
        });
      }

      // watch for collected items
      // this can be triggered by the user tapping on the magnet button or automatically by the item scanner
      // collect event is triggered after collectItemCore
      if (!this.subscription.itemCollectWatch) {
        this.subscription.itemCollectWatch = this.itemScanner.getItemCollectObservable().subscribe((item: ILeplaceTreasure) => {
          // don't allow collecting treasures while the app is paused
          console.log("item collect detected: ", item);
          let enableCollect: boolean = true;
          let checkLockedContext: boolean = false;
          if (item && item.lockedForUser) {
            if (this.mpGameInterface.isOnline()) {
              // don't show locked popup when finishing challenge in mp game
              checkLockedContext = false;
            } else {
              checkLockedContext = true;
            }
          }

          if (checkLockedContext) {
            this.uiext.showAlertNoAction(Messages.msg.treasureLocked.after.msg, Messages.msg.treasureLocked.after.sub);
            enableCollect = false;
          }

          if (item && !GeneralCache.paused && enableCollect) {
            // console.log("item collected: ", item);
            if (item.type === ETreasureType.challenge) {
              // refresh marker layer first
              // otherwise the treasures are hidden and cannot remove the item
              this.itemScanner.refreshWorldMapMarkerLayerWrapperResolve(EMarkerLayers.CRATES, this.itemScanner.getAttachedTreasurePlaceMarkers(), null).then(() => {
                this.removeMapFeatureItem(item);
              });
            } else {
              this.removeMapFeatureItem(item);
            }

            // do not open another modal if there is already one open
            // check for map enabled 
            // or allow collect from AR flag
            console.log("allow collect flags: ", item.notifyCollectPopup, this.internalFlags.treasureOpened, this.checkEnableGPSMapUpdate(), this.internalFlags.mapAllowCollectFromAR);
            // item.notifyCollectPopup = false;
            if (item.notifyCollectPopup && !this.internalFlags.treasureOpened && (this.checkEnableGPSMapUpdate() || this.internalFlags.mapAllowCollectFromAR)) {
              console.log("allow collect");
              this.internalFlags.treasureOpened = true;
              this.dedicatedTimeouts.openTreasureModal = setTimeout(async () => {
                switch (item.type) {
                  case ETreasureType.treasure:
                    let collectedCoins: number = item.coins;
                    await this.onModalOpened();
                    let itemFoundParams: IItemFound = {
                      infoHTML: "<p>You have found a treasure</p>",
                      shareMessage: null,
                      amount: collectedCoins,
                      opened: true,
                      xp: GameStatsUtils.getGradedStatWeightAdjusted(EStatCodes.treasuresOpened, null, null, null, this.story != null ? this.story.xpScaleFactor : null, true),
                      title: "Treasure",
                      item: item,
                      place: item.place,
                      inputObservable: this.observables.linkViewSend,
                      previewCallback: null,
                      withDismissOptions: false
                    };
                    this.modularViews.getItemFoundView(itemFoundParams).then((res: ITreasureFoundReturnData) => {
                      console.log("returned from modal to map");
                      this.internalFlags.treasureOpened = false;
                      this.onModalClosed();
                      let status: number = res ? res.status : null;
                      let waitAd: Promise<boolean>;
                      waitAd = new Promise((resolve) => {
                        if (this.mpGameInterface.isOnline()) {
                          // disable ads in mp game (prevent sync errors)
                          resolve(true);
                        } else {
                          switch (status) {
                            case EFinishedActionParams.reward:
                              this.analytics.sendCustomEvent(ETrackedEvents.story, "reward video treasure", "story: " + this.storyId + ", location: " + this.app.locationIndex, this.storyId, true);
                              collectedCoins = res.finalLP;
                              resolve(true);
                              break;
                            default:
                              this.popupFeatures.watchAdResolve(true).then(() => {
                                resolve(true);
                              });
                              break;
                          }
                        }
                      });

                      waitAd.then(() => {
                        // register coins collected from crates as private coins, not linked to a story
                        // so that it does not increase the level/score
                        this.userStatsProvider.registerLPCollected(null, collectedCoins).then(() => {

                        }).catch((err: Error) => {
                          this.internalFlags.treasureOpened = false;
                          console.error(err);
                        });
                        PromiseUtils.wrapNoAction(this.mapGeneralUtils.showRewardResolve(GameStatsUtils.getGradedStatAdjusted(EStatCodes.treasuresOpened, null, null, null, this.story != null ? this.story.xpScaleFactor : null, true), true, this.internalFlags.levelUpPopups, this.isWorldMap), this.internalFlags.levelUpPopups);
                      });
                    }).catch((err: Error) => {
                      console.error(err);
                      this.internalFlags.treasureOpened = false;
                      this.onModalClosed();
                      this.analytics.dispatchError(err, "gmap");
                    });
                    break;
                  default:
                    // maybe make the marker bounce or smth
                    // this.internalFlags.treasureOpened = false;
                    console.log("item collect default case");
                    // this may only be triggered from AR View
                    this.onTreasureOpened(item);
                    this.internalFlags.treasureOpened = false;
                    break;
                }
              }, 500);
            }
          }
        }, (err: Error) => {
          console.error(err);
        });
      }
    }
  }

  /**
   * set show layers for story mode extension
   */
  setStoryModeLayers(isCustomMap: boolean) {
    if (!this.checkTreasuresInStoryEnabled()) {
      return;
    }

    let storyModeLayers: number[] = [ETreasureType.treasure];
    let customMapLayers: number[] = [ETreasureType.treasure, ETreasureType.challenge, ETreasureType.arena];

    // update show layers for story mode (e.g. only treasures)
    let keys: string[] = Object.keys(this.layers);
    for (let i = 0; i < keys.length; i++) {
      let key: string = keys[i];
      this.layers[key].enabled = false;
      if (isCustomMap) {
        if (customMapLayers.indexOf(this.layers[key].code) !== -1) {
          this.layers[key].enabled = true;
        }
      } else {
        if (storyModeLayers.indexOf(this.layers[key].code) !== -1) {
          this.layers[key].enabled = true;
        }
      }
    }
    this.setShowLayersCore(this.layers);
  }

  /**
   * open story from world map
   * @param data 
   */
  async onStoryOpened(data: IStoryListNavParams) {
    if (this.storyId !== data.storyId || data.loadStory) {
      // exit AR
      this.exitLinkView();
      await this.disableEagleViewBeforeActivityStart();
      this.setStoryModeLayers(this.isCustomMapStory);
      // clear all data from previous story and reinitialize everything
      await this.clearSession(true, false);
      this.storyId = data.storyId;
      this.storyParams = data;
      await this.startSession(false, false, true);
      // load all data incl. story
      await this.loadData(true);
    }
  }

  /**
   * check for locked items on the map because of internal logic
   * for actual locked items (level, premium content) use another method
   * e.g. challenge is in progress and another challenge cannot be selected
   */
  checkLogicLockItemNotify(item: ILeplaceTreasure) {
    let locked: boolean = false;

    // check for challenge in progress
    if (this.app.challengeInProgress) {
      this.uiext.showAlertNoAction(Messages.msg.challengeInProgress.after.msg, Messages.msg.challengeInProgress.after.sub);
      locked = true;
    } else {
      locked = false;
    }

    if (locked) {
      return locked;
    }

    // check for story selected/in progress
    // if (this.storySelected && !this.isPreloadStory) {
    //   locked = true;
    //   // some unexpected error, maybe the story was not filled properly
    //   let dispName: string = TreasureUtils.getChallengeName(item, false);
    //   let name: string = dispName ? dispName.toUpperCase() : "This item";
    //   this.uiext.showAlertNoAction(Messages.msg.treasureNotAvailable.after.msg, name + " is not available at the moment");
    // } else {
    //   locked = false;
    // }

    return locked;
  }


  /**
   * exit AR, wait for the modal to close
   */
  exitARSync(): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      this.exitLinkView();
      if (this.internalFlags.AROpen) {
        await WaitUtils.waitObsTimeout(this.observables.linkViewReceive, EViewLinkCodes.exitAck, 5000);
        console.log("AR exit ack passed");
        await SleepUtils.sleep(1000);
      }
      resolve(true);
    });
  }

  /**
   * on story found action (world map mode)
   * this is triggered when the user clicks on a treasure/world map feature
   * @param item 
   */
  async onTreasureOpened(item: ILeplaceTreasure) {
    console.log("on treasure opened: ", item);
    let itemFoundParams: IItemFound;
    let mp: IMPGameSession = this.mpGameInterface.getGameContainer();

    let checkMasterUnlocked = (item: ILeplaceTreasure) => {
      if (item.lockedForUser) {
        this.uiext.showAlertNoAction(Messages.msg.treasureLocked.after.msg, Messages.msg.treasureLocked.after.sub);
        return false;
      }

      // check for app version min
      if (item.activity && !AppSettings.testerMode && !this.appVersionService.compareVersion(item.activity.appVersionMin, item.activity.appVersionMinIos)) {
        this.uiext.showAlertNoAction(Messages.msg.updateAppRequiredLock.after.msg, Messages.msg.updateAppRequiredLock.after.sub);
        return false;
      }
      return true;
    };

    let checkMpUnlocked = (item: ILeplaceTreasure) => {
      if (mp && mp.online) {
        let av: IMPAvailableItems = MPUtils.checkMpAvailableItems(item.type, item.activity ? item.activity.gameContextCode : null, mp.currentGroupRole);
        if (!av.available) {
          this.uiext.showAlertNoAction(av.message, av.sub);
        }
        return av.available;
      }
      return true;
    };

    switch (item.type) {
      case ETreasureType.treasure:
        if (checkMasterUnlocked(item) && checkMpUnlocked(item)) {
          itemFoundParams = {
            infoHTML: "<p>You have revealed a treasure location</p><p>Go there and use the magnet to collect the reward</p>",
            shareMessage: null,
            amount: 0,
            opened: false,
            title: "Treasure",
            xp: 0,
            item: item,
            place: item.place,
            previewCallback: null,
            inputObservable: this.observables.linkViewSend,
            withDismissOptions: false
          };

          await this.onModalOpened();
          this.modularViews.getItemFoundView(itemFoundParams).then((_res: ITreasureFoundReturnData) => {
            this.onModalClosed();
          }).catch((err: Error) => {
            console.error(err);
            this.onModalClosed();
          });
        }
        break;

      case ETreasureType.challenge:
        if (checkMasterUnlocked(item) && checkMpUnlocked(item)) {
          if (this.checkLogicLockItemNotify(item)) {
            // locked, no action
          } else {
            // this.exitARSync().then(() => {
            //   this.handleChallenge(item, null);
            // });
            this.handleChallenge(item, null);
          }
        } else {
          // locked, no action
        }
        break;
      case ETreasureType.story:
        // ignore locked for user, enter story and proceed to unlock if required
        if (item.story && item.story.id && !this.checkLogicLockItemNotify(item) && checkMpUnlocked(item)) {
          console.log("on story found: ", item);

          let storyNavParams: IStoryListNavParams = {
            storyId: item.story.id,
            dynamic: false,
            reload: true,
            category: null,
            categoryCode: null,
            localStories: false,
            includeGlobal: true,
            parentView: GmapPage,
            selectedCityId: null,
            loadStory: true,
            entryPlace: item.place,
            storyOverview: item.story,
            links: this.eventGroupLinkData,
            fromMapOpened: true
          };

          let navParams: INavParams = {
            view: {
              fullScreen: true,
              transparent: false,
              large: true,
              addToStack: false,
              frame: false
            },
            params: storyNavParams
          };

          await this.onModalOpened();
          let checkStoryMasterLock: any;
          if (!item.story.locked) {
            checkStoryMasterLock = Promise.resolve(true);
          } else {
            checkStoryMasterLock = new Promise((resolve) => {
              this.popupFeatures.openStoryUnlock(item.story, null, false, this.currentLocation.location, null).then((resp: IMasterLockResponse) => {
                if (resp && resp.unlocked) {
                  resolve(true);
                } else {
                  resolve(false);
                }
              }).catch((err: Error) => {
                console.error(err);
                resolve(false);
              });
            });
          }

          checkStoryMasterLock.then((unlocked: boolean) => {
            if (unlocked) {
              this.uiext.showCustomModal(null, StoryHomePage, navParams).then((data: IStoryListNavParams) => {
                console.log("returned from modal to map: ", data);
                // check if should load a story from modal
                if (data.loadStory) {
                  // load discovered story

                  // check for mp story
                  switch (item.story.mode) {
                    case EStoryMode.mp:
                      // refresh event map after story unlocked
                      PromiseUtils.wrapNoAction(this.itemScanner.treasureScan(), true);
                      break;
                    case EStoryMode.linear:
                    default:
                      // check for story in progress first
                      if (this.storySelected) {
                        this.uiext.showAlert(Messages.msg.storyInProgress.before.msg,
                          Messages.msg.storyInProgress.before.sub, 2, ["Continue", "Change"], true).then((res: number) => {
                            this.onModalClosed();
                            if (res === EAlertButtonCodes.ok) {
                              this.onStoryOpened(data);
                            }
                          }).catch((err: Error) => {
                            this.onModalClosed();
                            console.error(err);
                          });
                      } else {
                        this.onModalClosed();
                        this.exitARSync().then(() => {
                          this.onStoryOpened(data);
                        });
                      }
                      break;
                  }
                } else {
                  this.onModalClosed();
                }
              }).catch((err: Error) => {
                console.error(err);
                this.onModalClosed();
              });
            } else {
              // nop
              this.onModalClosed();
            }
          });
        } else {
          // nop
          this.onModalClosed();
        }
        break;
      case ETreasureType.arena:
        if (checkMasterUnlocked(item) && checkMpUnlocked(item)) {
          if (!this.checkLogicLockItemNotify(item)) {
            this.openArenaInit(item);
          } else {
            // nop
          }
        }
        break;
      case ETreasureType.event:
        let eventTitle: string = this.event ? this.event.name : "";
        this.uiext.showAlertNoAction("Event", eventTitle);
        break;
      case ETreasureType.customContent:
        if (checkMasterUnlocked(item)) {

          let itemFoundParams: IItemFound = {
            infoHTML: "<p>You have found a story crate with unique content</p>",
            shareMessage: null,
            amount: 0,
            opened: false,
            xp: 0,
            title: item ? item.spec.dispName : "Story Crate",
            item: item,
            place: item.place,
            previewCallback: null,
            inputObservable: this.observables.linkViewSend,
            withDismissOptions: false
          };

          if (item.treasureContent && item.treasureContent.description) {
            itemFoundParams.infoHTML += item.treasureContent.description;
          }

          itemFoundParams.infoHTML += "<p>Tap the button to continue</p>";

          await this.onModalOpened();
          this.modularViews.getItemFoundView(itemFoundParams).then((res: ITreasureFoundReturnData) => {
            this.onModalClosed();
            let status: number = res ? res.status : null;
            switch (status) {
              case EFinishedActionParams.removeTreasure:
                console.log("remove treasure: ", item.uid);
                this.geoObjects.removeGlobalObjectByUid(item.uid, true);
                this.geoObjects.removeMapFirstObjectByUid(item.uid, true);
                this.geoObjects.refreshAll(null);
                // re-sync partial
                if (!item.isLocal) {
                  this.reSyncTreasures();
                }
                break;
              default:
                break;
            }
          }).catch((err: Error) => {
            console.error(err);
            this.onModalClosed();
          });
        }
        break;
      default:
        checkMasterUnlocked(item);
        checkMpUnlocked(item);
        break;
    }
  }

  loadDefaultStoryArenaInit() {
    let mp: IMPGameSession = this.mpGameInterface.getGameContainer();
    console.log("load default story arena: ", mp);
    if (!(mp && mp.online)) {
      return;
    }
    this.initMPSession(null);
  }


  initMPSession(response: IArenaReturnToMapData) {
    // exit AR
    this.exitLinkView();
    this.enableFollowTrueAnimate(); // re-enable user follow mode
    let mp = this.mpGameInterface.getGameContainer();
    console.log("game container: ", mp);
    let memberCount: number = response != null ? response.group.members.length : 0;
    this.showHudMessage(EMapHudCodes.groupStatus, "" + memberCount, "people");
    this.mpGameInterface.connect();
    this.internalFlags.storyFinishedTeamNotify = false;
    this.connectToGroupHandler();
    this.updateWorldMapRefreshContext(EGameContext.mpOnly);
    // refresh treasures
    this.itemScanner.refreshWorldMapMarkerLayerNoAction(EMarkerLayers.CRATES, this.itemScanner.getAttachedTreasurePlaceMarkers(), null);
    if (!this.storySelected) {
      // set track group progress
      this.links.setGroupLinkDataWrapper(mp.currentGroup.id);
      this.dedicatedTimeouts.startMpGame = setTimeout(() => {
        switch (mp.currentGroupRole) {
          case EGroupRole.leader:
            PromiseUtils.wrapNoAction(this.uiext.showRewardPopupQueue(null, Messages.mp.selectChallenge, null, false), true);
            this.messageQueueHandler.prepare(Messages.mp.selectChallenge, true, EQueueMessageCode.warn);
            break;
          case EGroupRole.member:
            PromiseUtils.wrapNoAction(this.uiext.showRewardPopupQueue(null, Messages.mp.waitForChallenge, null, false), true);
            this.messageQueueHandler.prepare(Messages.mp.waitForChallenge, true, EQueueMessageCode.warn);
            break;
        }
      }, 5000);
    } else {
      // set track group progress
      this.links.setStoryGroupLinkDataWrapper(mp.currentGroup.id, this.storyId, true);
      // reload preload story
      if (this.isPreloadStory) {
        this.preloadStorySyncNoAction(false, false);
      } else {
        this.loadStorySyncNoAction(true);
      }
    }
  }


  /**
   * open arena from map, when the mp is not running
   * @param item 
   */
  async openArenaInit(item: ILeplaceTreasure) {
    let inRange: boolean = (item && item.dynamic) ? item.dynamic.inRange : false;
    this.mpGameInterface.setGameContainerArena(item);

    let params: IArenaNavParams = {
      place: item.place,
      meetingPlace: item,
      group: null,
      testing: false,
      groupRole: null,
      groupId: null,
      chatOnly: false,
      playerId: null,
      inRange: inRange,
      canExit: true,
      fromMapOpened: true,
      enableGroups: this.isWorldMap || this.isMpStory,
      isStoryline: this.storyId != null,
      isLobbyContext: true,
      context: EGroupContext.meetingPlace,
      contextId: item.id,
      synchronized: this.isWorldMap
    };

    if (this.storySelected) {
      params.context = EGroupContext.story;
      params.contextId = this.storyId;
    }

    let navParams: INavParams = {
      view: {
        fullScreen: true,
        transparent: false,
        large: true,
        addToStack: false,
        frame: true
      },
      params: params
    };

    await this.onModalOpened();
    this.uiext.showCustomModal(null, MPHomePage, navParams).then((response: IArenaReturnToMapData) => {
      console.log("return to map data received: ", response);
      this.onModalClosed();
      if (response) {
        this.initMPSession(response);
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  resetChatFlags() {
    this.internalFlags.chatMessageSignaledTimestamp = 0;
    this.internalFlags.chatMessagePrevTimestamp = 0;
    this.internalFlags.chatMessageCounter = 0;
    this.internalFlags.chatMessageFabDisp = "";
  }

  resetTeamFlags() {
    this.internalFlags.teamFabDisp = "";
  }

  /**
   * open arena while the mp is running
   */
  async openArenaContinue() {
    let mp: IMPGameSession = this.mpGameInterface.getGameContainer();

    console.log("open arena continue: ", mp);

    if (!mp) {
      return;
    }

    if (!(mp.currentGroup)) {
      console.warn("arena is not available");
      return;
    }

    if (mp.arenaIsOpen) {
      console.warn("arena is already open");
      return;
    }

    let params: IArenaNavParams = {
      place: mp.currentArenaItem != null ? mp.currentArenaItem.place : null,
      meetingPlace: mp.currentArenaItem != null ? mp.currentArenaItem : null,
      group: mp.currentGroup,
      groupId: null,
      testing: false,
      chatOnly: false,
      groupRole: mp.currentGroupRole,
      playerId: mp.playerId,
      inRange: true,
      // canExit: !(this.app.challengeInProgress || this.app.start) || this.isPreloadStory,
      canExit: true,
      fromMapOpened: true,
      enableGroups: this.isWorldMap || this.isMpStory,
      isStoryline: this.storyId != null,
      isLobbyContext: false,
      context: EGroupContext.global,
      contextId: null,
      synchronized: this.isWorldMap
    };

    let navParams: INavParams = {
      view: {
        fullScreen: true,
        transparent: false,
        large: true,
        addToStack: true,
        frame: true
      },
      params: params
    };

    await this.onModalOpened();
    this.mpGameInterface.setArenaLocked(true);

    this.uiext.showCustomModal(null, MPGroupsHomePage, navParams).then(async (response: IArenaReturnToMapData) => {
      this.resetChatFlags();
      this.buttonOptions.group.blink = false;
      this.onModalClosed();
      this.mpGameInterface.setArenaLocked(false);
      if (response) {
        switch (response.code) {
          case EArenaReturnCodes.resume:
            // if (!response.groupOnline) {
            //   // disconnected on purpose       
            //   this.disconnectGroup(false);
            // } else {
            //   // check group reconnected on purpose
            //   if (response.groupResetConnection) {
            //     // disconnect (ensure idempotency)
            //     this.disconnectGroup(false);
            //     // reinit session
            //     this.initMPSession(response);
            //   }
            // }
            break;
          case EArenaReturnCodes.leave:
            await this.leaveGroupSession();
            break;
        }
      }
    }).catch((err: Error) => {
      console.error(err);
      this.mpGameInterface.setArenaLocked(false);
    });
  }

  async leaveGroupSession() {
    this.enableFollowTrueAnimate(); // re-enable user follow mode
    this.updateWorldMapRefreshContext(EGameContext.all);
    // refresh treasures
    this.itemScanner.refreshWorldMapMarkerLayerNoAction(EMarkerLayers.CRATES, this.itemScanner.getAttachedTreasurePlaceMarkers(), null);
    this.mpManager.quitSession();
    this.disconnectGroup(true);
    this.mpGameInterface.disconnect();
    this.links.setStoryGroupLinkDataWrapper(null, this.storyId, false);
    if (this.storySelected) {
      let ret: boolean = await this.checkGoBackToStoryline(true, true);
      if (ret) {
        await this.goBackToStoryline();
      }
    }
  }

  waitForOthersToFinish() {
    this.loading = true;
    this.mpGameInterface.waitForOthersToFinish().then(() => {
      this.loading = false;
    }).catch((err: Error) => {
      console.error(err);
      this.loading = false;
      this.analytics.dispatchError(err, "gmap");
    });
  }

  /**
   * connect to mp events
   * status: position of players on the map
   * state machine: internal mp state machine
   * arena event: 
   * group chat: chat messages
   */
  connectToGroupHandler() {
    // connect to raw status updates
    let mp: IMPGameSession = this.mpGameInterface.getGameContainer();
    this.internalFlags.teamFabDisp = "";
    if (!this.subscriptionMp.statusWS) {
      this.mpGameInterface.setGameContainerUpdateGroupScope(this.mpManager.getGroupScope(), null, false);
      // console.log("current group scope: ", this.mp.currentGroup);
      this.subscriptionMp.statusWS = this.mpManager.getGroupStatusObservable().subscribe((_groupStatus: IMPGenericGroupStat) => {
        // console.log("status updated: ", _status);
        mp = this.mpGameInterface.getGameContainer();
        let groupScope: IGroup = this.mpManager.getGroupScope();
        this.mpGameInterface.setGameContainerUpdateGroupScope(groupScope, MPUtils.getOthersInGroup(groupScope, mp.playerId), true);
        let userMakers: IPlaceMarkerContent[] = [];
        let droneMarkers: IPlaceMarkerContent[] = [];
        this.internalFlags.teamFabDisp = "" + (mp.currentMemberStatus.length + 1) + "/" + groupScope.members.length;
        for (let i = 0; i < mp.currentMemberStatus.length; i++) {
          let umarker: IPlaceMarkerContent = this.markerUtils.getGroupMemberPlaceMarker(mp.currentMemberStatus[i], EMarkerLayers.OTHER_PLAYERS);
          if (umarker) {
            umarker.callback = (data: IPlaceMarkerContent) => {
              this.getMarkerCallback(data, data.detailsOpenContext);
            };
            userMakers.push(umarker);
          }
          let dmarker: IPlaceMarkerContent = this.markerUtils.getGroupMemberDronePlaceMarker(mp.currentMemberStatus[i], EMarkerLayers.OTHER_PLAYERS_DRONE, EMarkerIcons.drone);
          if (dmarker) {
            dmarker.callback = (data: IPlaceMarkerContent) => {
              this.getMarkerCallback(data, data.detailsOpenContext);
            };
            droneMarkers.push(dmarker);
          }
        }
        this.markerHandler.syncMarkerArrayNoAction(EMarkerLayers.OTHER_PLAYERS, userMakers);
        this.markerHandler.syncMarkerArrayNoAction(EMarkerLayers.OTHER_PLAYERS_DRONE, droneMarkers);

      }, (err: Error) => {
        console.error(err);
      });
    }

    if (!this.subscriptionMp.messageWS) {
      this.subscriptionMp.messageWS = this.mpManager.watchMessageRx().subscribe(async (message: IMPMessageDB) => {
        if (message != null) {
          let playerName: string = MPUtils.getPlayerNameFromMessageDB(message);
          // console.log("mp message: " + message.type + " from player " + message.playerId);
          if (message.playerId !== GeneralCache.userId) {
            // only for messages from another player
            const DEBOUNCE_TIME = 5500;
            switch (message.type) {
              case EMPMessageCodes.storyMapSync:
                if (this.isPreloadStory) {
                  this.timeoutQueueService.debounceTakeLastItemWithTimeout(ETimeoutQueue.mpMessageSync + message.type, async () => {
                    // consider only last message within debounce interval
                    // nonlinear story refresh map
                    this.messageQueueHandler.prepare(Messages.mp.mapSyncInProgress, true, EQueueMessageCode.info);
                    this.preloadStorySyncNoAction(true, true);
                  }, DEBOUNCE_TIME);
                }
                break;
              case EMPMessageCodes.storyProgressSync:
                if (!this.isPreloadStory) {
                  this.timeoutQueueService.debounceTakeLastItemWithTimeout(ETimeoutQueue.mpMessageSync + message.type, async () => {
                    // consider only last message within debounce interval
                    // linear story sync progress, check for target checkpoint completed (and skip to next one based on that)
                    this.messageQueueHandler.prepare(Messages.mp.mapSyncInProgress, true, EQueueMessageCode.info);
                    await PromiseUtils.wrapResolve(this.loadStoryProgressOnly(), true);
                    let loc: ILocationContainer = this.app.storyLocations[this.app.locationIndex].loc;
                    if (loc.merged.done === EStoryLocationDoneFlag.done) {
                      // show notification on complete by another team member
                      let msg: string = playerName != null ? "<p>Current checkpoint completed by " + playerName + "</p>" : "<p>Current checkpoint completed by another team member</p>";
                      msg += !this.activityStarted.ANY ? "<p>Loading next checkpoint..</p>" : "<p>Complete the current challenge or skip to continue</p>";
                      this.localNotifications.notify("Team progress", msg, false, null);
                      this.soundManager.vibrateContext(false);
                      await this.uiext.showRewardPopupQueue("Team progress", msg, null, false, 5000, false);
                      if (!this.activityStarted.ANY) {
                        // auto-skip for current player
                        this.skipPlace(1, null);
                      }
                    }
                  }, DEBOUNCE_TIME);
                }
                break;
              case EMPMessageCodes.storyFinished:
                this.timeoutQueueService.debounceTakeLastItemWithTimeout(ETimeoutQueue.mpMessageSync + message.type, async () => {
                  let excludeStates: number[] = [EGmapStates.SET_NEXT_LOCATION, EGmapStates.FINISHED_LOCATION, EGmapStates.FINISHED_STORY];
                  if (!this.activityStarted.ANY && (excludeStates.indexOf(this.app.state) === -1) && !this.internalFlags.storyFinishedTeamNotify) {
                    this.internalFlags.storyFinishedTeamNotify = true;
                    let msg: string = "<p>Story completed!</p>";
                    msg += !this.activityStarted.ANY ? "<p>Return to storyline..</p>" : "<p>Complete the current challenge or skip to continue</p>";
                    this.localNotifications.notify("Team progress", msg, false, null);
                    this.soundManager.vibrateContext(false);
                    await this.uiext.showRewardPopupQueue("Team progress", msg, null, false, 5000, false);
                    // auto-skip for current player
                    this.setState(EGmapStates.FINISHED_STORY);
                  }
                }, DEBOUNCE_TIME);
                break;
              default:
                break;
            }
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }


    if (!this.subscriptionMp.stateMachine) {
      this.subscriptionMp.stateMachine = this.mpManager.watchStateMachine().subscribe((state: number) => {
        let stateParams: any = this.mpManager.getCurrentStateParams();
        console.log("mp state: ", state, ", params: ", stateParams);
        switch (mp.currentGroupRole) {
          case EGroupRole.leader:
            switch (state) {
              case ELeaderStates.GO:
                this.uiext.dismissLoadingV2();
                break;
              case ELeaderStates.CHALLENGE_LOADED:
                // the challenge is selected by user input, and acknowledged by the mp manager

                break;
              case ELeaderStates.CHALLENGE_READY:
                // all members have received the challenge

                break;
              case ELeaderStates.CHALLENGE_IN_PROGRESS:
                // all members have started the challenge

                break;
              case ELeaderStates.WAIT_FOR_ENDGAME:
                this.waitForOthersToFinish();
                break;
              case ELeaderStates.WAIT_STATS_DISPATCH:
                // request stats via http then dispatch to others (go to next state)
                // request stats as leader (write)
                this.mpData.getGameResultsViaUservice(mp.currentGroup.id, mp.currentGroupRole).then((gameResults: IMPGameResults) => {
                  // console.log(gameResults);
                  this.mpGameInterface.showResults(mp.currentGroup, gameResults).then(() => {
                    // this.messageQueueHandler.prepare(Messages.mp.selectChallenge);
                    PromiseUtils.wrapNoAction(this.uiext.showRewardPopupQueue(null, Messages.mp.selectChallenge, null, false), true);
                    this.messageQueueHandler.prepare(Messages.mp.selectChallenge, true, EQueueMessageCode.warn);
                  });
                  this.mpManager.dispatchEventMux(EMPEventSource.userInput, EMPUserInputCodes.statsLoaded, null);
                }).catch((err: Error) => {
                  console.error(err);
                  this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.msg));
                  // fallback, prevent lockup
                  this.mpManager.dispatchEventMux(EMPEventSource.userInput, EMPUserInputCodes.statsLoaded, null);
                });
                break;
            }
            break;
          case EGroupRole.member:
            switch (state) {
              case EMemberStates.GO:
                // state reset, e.g. the leader has canceled the challenge before the member had time to start the challenge
                this.modularViews.dismissLocationDetailsViewNoAction();
                this.exitLinkView();
                this.uiext.dismissLoadingV2();
                break;
              case EMemberStates.CHALLENGE_LOADED:
                // the challenge has been received from the leader, show challenge modal
                let challengeParams: IMPMessageDataChallenge = stateParams;
                let challengeId: number = null;

                // mock
                if (!challengeParams) {
                  challengeParams = {
                    challengeId: 27839,
                    activityCode: 1
                  };
                }

                console.log("challenge loading: ", challengeParams);

                if (challengeParams != null) {
                  challengeId = challengeParams.challengeId;
                  this.placesDataProvider.loadTreasure(challengeId).then((treasure: ILeplaceTreasure) => {
                    this.handleChallenge(treasure, "<p>The host has proposed a challenge</p>");
                  }).catch((err: Error) => {
                    console.error(err);
                    this.uiext.showAlertNoAction(Messages.msg.mpChallengeLoadError.after.msg, Messages.msg.mpChallengeLoadError.after.sub);
                  });
                }

                break;
              case EMemberStates.CHALLENGE_READY:
                // the challenge is confirmed by user input, and acknowledged by the mp manager

                break;
              case EMemberStates.CHALLENGE_IN_PROGRESS:
                // the leader requested to start the challenge, the challenge will start now

                break;
              case EMemberStates.CHALLENGE_STOPPED_EXT:
                // the leader stopped the challenge
                // stop the challenge
                this.uiext.showAlert(Messages.msg.quitChallengeExt.before.msg, Messages.msg.quitChallengeExt.before.sub, 1, null).then(() => {
                  this.finalizeActivity();
                }).catch((err: Error) => {
                  console.error(err);
                });
                break;
              case EMemberStates.WAIT_FOR_ENDGAME:
                this.waitForOthersToFinish();
                break;
              case EMemberStates.WAIT_REQUEST_STATS:
                // request stats via http then dispatch to others (go to next state)
                // request stats as member (read only)
                this.mpData.getGameResultsViaUservice(mp.currentGroup.id, mp.currentGroupRole).then((gameResults: IMPGameResults) => {
                  // console.log(gameResults);
                  this.mpGameInterface.showResults(mp.currentGroup, gameResults).then(() => {
                    // this.messageQueueHandler.prepare(Messages.mp.waitForChallenge);
                    PromiseUtils.wrapNoAction(this.uiext.showRewardPopupQueue(null, Messages.mp.waitForChallenge, null, false), true);
                    this.messageQueueHandler.prepare(Messages.mp.waitForChallenge, true, EQueueMessageCode.warn);
                  });
                  this.mpManager.dispatchEventMux(EMPEventSource.userInput, EMPUserInputCodes.statsLoaded, null);
                }).catch((err: Error) => {
                  console.error(err);
                  this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.msg));
                  // fallback, prevent lockup
                  this.mpManager.dispatchEventMux(EMPEventSource.userInput, EMPUserInputCodes.statsLoaded, null);
                });
                break;
            }
            break;
        }
      }, (err: Error) => {
        console.error(err);
      });
    }

    // connect to arena events e.g. disconnected member
    if (!this.subscriptionMp.arenaEvent) {
      this.subscriptionMp.arenaEvent = this.mpManager.watchArenaEvent().subscribe((event: number) => {
        console.log("arena event: ", event);
        switch (event) {
          case EArenaEvent.requireUserAction:
            if (!this.storySelected) {
              this.openArenaContinue();
            } else {
              this.messageQueueHandler.prepare("MP action required", true, EQueueMessageCode.warn);
            }
            break;
          case EArenaEvent.connectionProblem:
            this.messageQueueHandler.prepare("MP disconnected", true, EQueueMessageCode.warn);
            break;
          default:
            break;
        }
      }, (err: Error) => {
        console.error(err);
      });
    }

    // discrete MP events
    if (!this.subscriptionMp.eventMux) {
      this.subscriptionMp.eventMux = this.mpManager.watchEventMux().subscribe((event: IMPEventContainer) => {
        if (event != null) {
          switch (event.code) {
            case MPEncoding.encodeEvent(EMPEventSource.virtualMemberState, EMPVirtualMemberCodes.disconnected, null).code:
              this.messageQueueHandler.prepare(event.data, true, EQueueMessageCode.warn);
              break;
            default:
              break;
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }

    if (!this.subscriptionMp.groupChat) {
      this.subscriptionMp.groupChat = this.mpManager.watchChatWS().subscribe((data: IMPMessageDB) => {
        if (data) {
          if (data.type === EMPMessageCodes.chat) {
            let messageInfo: string = MPUtils.getStringMessageFromMessageDB(data, null);
            this.messageQueueHandler.prepare(messageInfo, true, EQueueMessageCode.info);
            let ts: number = new Date().getTime();
            if ((ts - this.internalFlags.chatMessagePrevTimestamp) > 5000) {
              // this.internalFlags.chatMessageSignaledTimestamp = ts;
              this.buttonOptions.group.blink = !this.buttonOptions.group.blink;
            }
            this.internalFlags.chatMessagePrevTimestamp = ts;
            this.internalFlags.chatMessageCounter += 1;
            // this.internalFlags.chatMessageFabDisp = "" + this.internalFlags.chatMessageCounter;
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
    // connect to main state machines
  }

  /**
   * leave group session
   */
  disconnectGroup(leave: boolean) {
    this.mpGameInterface.disconnect();
    if (leave) {
      this.mpGameInterface.dispose();
    }
    this.subscriptionMp = ResourceManager.clearSubObj(this.subscriptionMp);
    this.clearHudMessage(EMapHudCodes.groupStatus);
    this.markerHandler.clearMarkersNoAction(EMarkerLayers.OTHER_PLAYERS);
    this.markerHandler.clearMarkerLayer(EMarkerLayers.OTHER_PLAYERS);
    this.markerHandler.clearMarkersNoAction(EMarkerLayers.OTHER_PLAYERS_DRONE);
    this.markerHandler.clearMarkerLayer(EMarkerLayers.OTHER_PLAYERS_DRONE);
    this.uiext.dismissAllWidgets();
  }

  /**
   * watch quota events
   * e.g. scan energy consumed
   */
  subscribeToQuotaEvents() {
    if (!this.subscription.googleRequestStatus) {
      this.subscription.googleRequestStatus = this.locationApi.getGoogleStatusObs().subscribe((status: number) => {
        console.log("google request status: ", status);
        switch (status) {
          case EGoogleMapsRequestStatus.ok:
            this.itemScanner.resume();
            break;
          case EGoogleMapsRequestStatus.scanEnergyDepleted:
            // pause item scanner, will be resumed after recharge
            this.itemScanner.pause();
            break;
        }
      }, (err: Error) => {
        console.error(err);
      });
    }

    if (!this.subscription.scanEnergyWatch) {
      this.subscription.scanEnergyWatch = this.inventoryWizard.getScanEnergyWatch().subscribe((consumeAmount: number) => {
        if (consumeAmount != null) {
          this.showUsedEnergy(consumeAmount);
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  /**
   * show used energy message (integrated)
   * @param consumeAmount 
   */
  showUsedEnergy(consumeAmount: number) {
    if (consumeAmount) {
      this.messageQueueHandler.prepare("-" + consumeAmount + " energy", false, EQueueMessageCode.info);
    } else {
      this.messageQueueHandler.prepare("used energy", false, EQueueMessageCode.info);
    }

    let messageTTS: string = consumeAmount ? "You have used " + consumeAmount + " energy." : "You have used energy.";
    // messageTTS = null;
    this.soundManager.ttsWrapper(messageTTS, true, SoundUtils.soundBank.energy.id, () => {
      let rem: IRemoveItemResponse = this.inventory.getLastRemoveItemData();
      console.log("remaining energy: ", rem);
      if (rem && rem.amountLeft != null) {
        this.showRemainingScanEnergy(rem.amountLeft);
      }
    });
  }

  /**
   * show remaining scan energy
   * @param amount 
   */
  showRemainingScanEnergy(amount: number) {
    this.messageQueueHandler.prepare("energy: " + amount, false, EQueueMessageCode.info);
    // this.tts.textToSpeechQueue("You have " + amount + " energy left.");
    let showMessage: boolean = false;
    let lowThreshold: number = 10;

    if (!this.internalFlags.showScanEnergyLow && (amount < lowThreshold)) {
      showMessage = true;
    }
    // recharged but not enough, still below threshold
    if ((this.internalFlags.prevScanEnergy != null) && (this.internalFlags.prevScanEnergy < amount)) {
      if (amount < lowThreshold) {
        showMessage = true;
      }
    }

    this.internalFlags.prevScanEnergy = amount;

    if (showMessage) {
      this.internalFlags.showScanEnergyLow = true;
      PromiseUtils.wrapResolve(this.uiext.showAlert(Messages.msg.scanEnergyLow.after.msg, Messages.msg.scanEnergyLow.after.sub, 2, ["Dismiss", "Recharge"]), true).then((res: number) => {
        if (res === EAlertButtonCodes.ok) {
          PromiseUtils.wrapNoAction(this.inventoryWizard.goToInventoryForScanEnergy(), true);
        }
      });
    }
  }


  /**
   * subscribe to message queue
   * it handles reward popups with timeouts and queue for minimum show duration of messages
   */
  subscribeToMessageQueue() {
    if (!this.subscription.messageQueue) {
      this.subscription.messageQueue = this.messageQueueHandler.getQueueWatch().subscribe((qEvent: IMessageQueueEvent) => {
        if (qEvent) {
          // console.log("qEvent: ", qEvent);
          let msg: IQueueMessage = qEvent.data;

          if (msg) {
            let msgString: string = msg.message;
            this.hudConfig = HudUtils.getXpHudClass(msg, this.hudConfig);
            // msgString = StringUtils.trimName(msgString, EMessageTrim.xpMessageHud);
            this.hudMsgXP = msgString;
            this.app.hudXP = qEvent.state;
          } else {
            this.hudMsgXP = "";
            this.app.hudXP = qEvent.state;
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }

    if (!this.subscription.operatorChat) {
      this.subscription.operatorChat = this.mqttService.getWatchInboundChat().subscribe((data: IMQTTChatMessage) => {
        if (data) {
          // this.messageQueueHandler.preparePersistent("operator: " + data.message, true);
          this.internalFlags.receivedMessageFromOperator = true;
          this.buttonOptions.operatorChat.blinkOnChange = !this.buttonOptions.operatorChat.blinkOnChange;
          if (data.message.length > EMessageTrim.xpMessageHud) {
            this.messageQueueHandler.prepareLong("operator: " + data.message, true, EQueueMessageCode.info);
          } else {
            this.messageQueueHandler.prepare("operator: " + data.message, true, EQueueMessageCode.info);
          }
          this.localNotifications.notify("Operator", data.message, data.priority, null);
          this.soundManager.vibrateContext(data.priority);
        }
      }, (err: Error) => {
        console.error(err);
      });
    }

    if (!this.subscription.headingWarning) {
      this.subscription.headingWarning = this.headingService.getWarningObservable().subscribe((data: boolean) => {
        if (data) {
          console.log("show compass ticker");
          this.messageQueueHandler.prepareLong("Nav: Device compass not available. Orientation is limited to GPS updates.", true, EQueueMessageCode.warn);
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  openChatWithOperator() {
    this.mqttChatService.openChatWithOperator();
  }

  onShareClick(message: string) {
    if (!message) {
      if (this.storySelected && this.story) {
        message = "Exploring " + this.story.name + " story";
      } else {
        message = "Exploring the world map";
      }
    }
    this.shareProvider.share(message).then(() => {
    }).catch((err: Error) => {
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
      this.analytics.dispatchError(err, "gmap");
    });

  }

  onHelpClick() {
    let params: IDescriptionFrameParams = {
      title: "Map Tutorial",
      description: null,
      mode: EDescriptionViewStyle.plain,
      photoUrl: null,
      loaderCode: this.storyId != null ? ETutorialEntries.gmapTutorialStory : ETutorialEntries.gmapTutorial,
      videoLoaderCode: this.storyId != null ? ETutorialEntries.storylineTutorialVideo : ETutorialEntries.worldMapTutorialVideo
    };
    this.tutorials.showTutorialResolve(null, null, null, params, true).then((res: number) => {
      if (res === EAlertButtonCodes.ok) {
        PromiseUtils.wrapNoAction(this.handleShowWarningCheck(), true);
      }
    });
  }

  async handleUnrecoverableErrorExitMap() {
    await PromiseUtils.wrapResolve(this.uiext.showAlert(Messages.msg.unrecoverableError.after.msg, Messages.msg.unrecoverableError.after.sub, 1, null, false), true);
    this.goBackRequest(true, true);
  }

  /**
   * warning use case
   */
  showWarningResolve(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve) => {
      let params: IDescriptionFrameParams = {
        title: "Warning",
        description: null,
        mode: EDescriptionViewStyle.withOk,
        photoUrl: null,
        loaderCode: ETutorialEntries.warning
      };

      this.tutorials.showTutorialResolve(null, null, null, params, false).then(() => {
        this.onModalClosed();
        resolve(true);
      }).catch((err: Error) => {
        console.error(err);
        this.onModalClosed();
        this.analytics.dispatchError(err, "gmap");
        resolve(false);
      });
    });

    return promise;
  }

  /**
   * view places on click handler
   */
  async viewPlaces() {
    console.log("view places, challenge in progress: ", this.app.challengeInProgress);
    if (this.app.challengeInProgress && !this.storySelected) {
      // challenge mode
      PromiseUtils.wrapNoAction(this.getLocationDetailsViewInProgress(), true);
    } else {
      // story mode
      this.app.locationIndexView = this.app.locationIndex;
      LocationUtils.mergeStoryLocationProgress(this.story, this.app.storyLocations);

      let params2: IStoryListNavParams = {
        storyId: this.storyParams.storyId,
        dynamic: this.storyParams.dynamic,
        reload: false,
        localStories: this.storyParams.localStories,
        includeGlobal: true,
        category: this.storyParams.category,
        selectedCityId: this.storyParams.selectedCityId,
        categoryCode: this.storyParams.category ? this.storyParams.category.code : null,
        parentView: GmapPage,
        storyOverview: this.story,
        useLoadedStory: true,
        loadStory: false,
        fromMapOpened: true,
        lockPreview: true
      };

      let navParams: INavParams = {
        view: {
          fullScreen: true,
          transparent: false,
          large: true,
          addToStack: false,
          frame: false
        },
        params: params2
      };

      await this.onModalOpened();
      this.uiext.showCustomModal(null, StoryHomePage, navParams).then(() => {
        console.log("returned from modal to map");
        this.onModalClosed();
      }).catch((err: Error) => {
        console.error(err);
        this.onModalClosed();
      });
    }
  }

  /**
   * show reward popups
   * check level up
   * refresh/return
   */
  async onFinishedStory(showReward: boolean): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve) => {
      console.log("on finished story");
      if (showReward) {
        await this.mapGeneralUtils.showRewardResolve(GameStatsUtils.getGradedStatAdjusted(EStatCodes.storiesFinished, this.story.level, this.story.rewardXp, null, this.story != null ? this.story.xpScaleFactor : null, true), true, this.internalFlags.levelUpPopups, this.isWorldMap);
        console.log("reward resolved");
      }
      await this.uiext.dismissLoadingV2();
      // await this.gmapModals.checkNewAchievementsCoreResolveOnly();
      if (this.isWorldMap) {
        // stay on the map if world map mode
        this.onStoryEnded();
        resolve(false);
      } else {
        await PromiseUtils.wrapResolve(this.mapGeneralUtils.checkUserStatsChanged(this.internalFlags.levelUpPopups, this.isWorldMap), true);
        this.onStoryEnded();
        resolve(false);
      }
    });
    return promise;
  }

  async onNotFinishedStory(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve) => {
      console.log("on not finished story");
      if (this.isWorldMap) {
        // stay on the map if world map mode
        this.onStoryEnded();
        resolve(false);
      } else {
        await PromiseUtils.wrapResolve(this.mapGeneralUtils.checkUserStatsChanged(this.internalFlags.levelUpPopups, this.isWorldMap), true);
        this.onStoryEnded();
        resolve(false);
      }
    });
    return promise;
  }

  onStoryEnded() {
    this.internalFlags.returnToStoryline = true;
    this.app.canExitMap = true;
    // this.buttonOptions.exit.blink = true;
    this.buttonOptions.exitFab.blink = true;
    if (this.isWorldMap) {
      this.uiext.showAlertNoAction(Messages.msg.storyCompletedContinueWorldMap.after.msg, Messages.msg.storyCompletedContinueWorldMap.after.sub);
    } else {
      this.uiext.showAlertNoAction(Messages.msg.storyCompletedReturn.after.msg, Messages.msg.storyCompletedReturn.after.sub);
    }
    // let ret: boolean = await this.checkGoBackToStoryline(true, false);
    // if (ret) {
    //   await this.goBackToStoryline();
    // }
    // resolve(ret);
  }

  async returnToStoryline() {
    let ret: boolean = await this.checkGoBackToStoryline(true, false);
    if (ret) {
      await this.goBackToStoryline();
    }
    return ret;
  }

  /**
   * cleanup all resources used by current activity
   * including collected coins
   */
  cleanupPrevActivity() {
    this.locationApi.clearResultBuffer();
    this.app.msg = "";
    this.user.clickedScan = false;
    this.user.arMode = 0;
    let keys = Object.keys(this.activityStarted);
    keys.forEach((key) => {
      this.activityStarted[key] = false;
    });
    this.activityStarted.nav = false;
    this.app.collectedItemsCurrentActivity = 0;
    this.app.collectedItemsValueCurrentActivity = 0;
    this.app.rewardLpCurrentActivity = 0;
    this.app.canCompleteActivity = false;

    this.subscription.watchMove = ResourceManager.clearSub(this.subscription.watchMove);
    this.resetDisplayValues();
    this.modularViews.dismissLocationDetailsViewNoAction();
  }

  setOverlayStyle(large: boolean) {
    let ios: boolean = GeneralCache.checkPlatformOSWithPWA() === EOS.ios;
    if (!SettingsManagerService.settings.app.settings.onScreenHomeButtonFix.value) {
      ios = false;
    }
    let style = HudUtils.setOverlayStyle(large, ios);
    this.footerClass = style.footerClass;
    this.headerClass = style.headerClass;
    this.hudConfig.outerClass = style.outerClass;
    this.footerContentClass = style.footerContentClass;
  }

  checkVideoBefore(loc: ILocationContainer, show: boolean) {
    console.log("check video before");
    if (!loc && this.storySelected) {
      loc = this.app.storyLocations[this.app.locationIndex].loc;
    }
    if (!loc) {
      return false;
    }
    let videoUrl: string = null;
    let hasVideo: boolean = false;
    let descriptionParams = this.activityProvider.getCustomActivityParams(loc.merged.activity,
      loc, this.storySelected ? ECustomParamScope.story : ECustomParamScope.challenge, this.story);
    if (descriptionParams.videoGuideSpecs) {
      if (descriptionParams.videoGuideSpecs.before) {
        videoUrl = descriptionParams.videoGuideSpecs.before.url;
      }
    }
    hasVideo = videoUrl != null;
    if (hasVideo) {
      this.buttonOptions.playVideo.blink = true;
      this.internalFlags.hasVideoBefore = true;
    }
    if (show && hasVideo) {
      this.buttonOptions.playVideo.blink = false;
      PromiseUtils.wrapNoAction(this.youtube.openIframe(null, videoUrl, "Video Tutorial", false), true);
    }
    console.log("has video: ", hasVideo);
    return hasVideo;
  }

  /**
   * resume open world and prepare for next story
   */
  unloadStory() {
    console.log("unload story");
    this.storyId = null;
    this.storySelected = false;
    this.story = null;
    this.setOverlayStyle(false);
    this.user.canZoom = true;
    this.isPreloadStory = false;
    this.isDroneAllowed = true;
    this.isDroneOnly = false;
    this.itemScanner.setLocationBasedFiltering(true);
    // this.app.start = false;
    // reload show layers backup
    this.layers = DeepCopy.deepcopy(this.layersBak);
    this.itemScanner.refreshWorldMapMarkerLayerNoAction(EMarkerLayers.CRATES, this.itemScanner.getAttachedTreasurePlaceMarkers(), null);
    this.mapManager.showLayersBasedOnZoom(null);
    this.locationApi.clearCache();
    this.activityProvider.setCollectContext(ECheckpointCollectMode.manual, true);
    this.checkInteractiveModes(true);
    this.internalFlags.levelUpPopups = true;
    this.isARUnlocked = true;
    this.app.prevOffsetCenters = [];
    this.moveActivityProvider.setGameContext(EGameContext.worldMap);
  }

  resetGameFlagsScan() {
    this.user.canRequestDirections = false;
    this.user.canSkip = false;
    this.user.canScan = false;
    this.user.canTakePhoto = true;
    this.buttonOptions.scan.blink = false;
    this.app.collectedItemsCurrentActivity = 0;
    this.app.collectedItemsValueCurrentActivity = 0;
    this.app.rewardLpCurrentActivity = 0;
  }

  /**
   * lock position marker updating (moving the map with the user)
   */
  holdPositionMarker() {
    console.log("hold position marker");
    if (!this.internalFlags.positionMarkerLocked) {
      console.log("hold");
      this.internalFlags.positionMarkerLocked = true;
      this.internalFlags.updateUserMarkerGPSPrev = this.internalFlags.updateUserMarkerGPS;
      this.internalFlags.updateUserMarkerGPS = false;
    }
  }

  /**
   * reset update position marker to previous settings
   */
  releasePositionMarker() {
    console.log("release position marker");
    if (this.internalFlags.positionMarkerLocked) {
      console.log("release");
      this.internalFlags.positionMarkerLocked = false;
      this.internalFlags.updateUserMarkerGPS = this.internalFlags.updateUserMarkerGPSPrev;
    }
  }

  /**
   * recompute locations for preload story
   */
  async preloadStoryNew() {
    this.excludeLocationIdList = [];
    // can search new location even for saved locations
    for (let loc of this.app.storyLocations) {
      if (loc.flag !== ELocationFlag.FIXED) {
        loc.flag = ELocationFlag.RANDOM;
      }
    }
    this.mapEngineUtils.checkLocationScanDistance(this.currentLocation.location);
    await this.preloadStoryCore(true, true);
    this.storyManagerService.refreshStoryLocationMarkersNoAction();
  }

  /**
  * preload story
  * load story data / sync progress with group
  * @param reload reload story data from server
  * @param isSync skip loading modals, skip popups
  */
  preloadStorySyncNoAction(isSync: boolean, checkComplete: boolean) {
    this.preloadStory(isSync).then(() => {
      PromiseUtils.wrapNoAction(this.itemScanner.treasureScan(), true);
      if (checkComplete) {
        if (StoryUtils.checkAppLocationStoryFinished(this.app.storyLocations)) {
          this.dedicatedTimeouts.stateChange = setTimeout(() => {
            this.setState(EGmapStates.FINISHED_STORY);
          }, 1000);
        }
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  loadStorySyncNoAction(reload: boolean) {
    this.loadStory(reload).then(() => {
      PromiseUtils.wrapNoAction(this.itemScanner.treasureScan(), true);
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * preload story
   * load story data / sync progress with group
   * @param reload reload story data from server
   * @param isSync skip loading modals, skip popups
   */
  async preloadStory(isSync: boolean): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      console.log("preload story requested, sync: " + isSync);
      if (this.app.challengeInProgress) {
        // don't sync, schedule for later (after challenge completed)
        this.app.mapSyncRequested = true;
        resolve(false);
        return;
      }
      console.log("preload story requested");
      try {
        // handle reset group progress
        let groupLinkData: IEventStoryGroupLinkData = this.links.getLinkData();
        console.log("group link data: ", groupLinkData);
        let promiseHandleResetProgress: Promise<boolean> = new Promise((resolve) => {
          if (isSync) {
            resolve(true);
          } else {
            if (groupLinkData != null) {
              let mp: IMPGameSession = this.mpGameInterface.getGameContainer();
              if (mp && mp.currentGroup && mp.currentGroupRole === EGroupRole.leader) {
                if (StoryUtils.checkAppLocationStoryInProgress(this.app.storyLocations)) {
                  this.modularInteraction.clearProgressPromise(this.story, true).then(() => {
                    this.mpGameInterface.dispatchSyncStoryMapToMP();
                    resolve(true);
                  }).catch((err: Error) => {
                    console.error(err);
                    resolve(false);
                  });
                } else {
                  resolve(true);
                }
              } else {
                resolve(true);
              }
            } else {
              resolve(true);
            }
          }
        });

        await promiseHandleResetProgress;
        await this.loadStoryProgressOnly();
        await this.preloadStoryCore(!isSync, !isSync);
        resolve(true);
      } catch (err) {
        this.messageQueueHandler.prepare("Error loading story", true, EQueueMessageCode.warn);
        this.mapManager.setMapStyle(EMapStyles.leplace);
        this.analytics.dispatchError(err, "gmap");
        reject(err);
      }
    });
  }

  /**
   * preload story
   * show all locations at once
   * non-linear story mode
   * @param showLoading skip loading modals
   */
  async preloadStoryCore(showLoading: boolean, showOverview: boolean, onlyDonePreviewLinear: boolean = false) {
    if (!this.isPreloadStory && !onlyDonePreviewLinear) {
      console.warn("not preload story");
      return;
    }
    let locationIndex: number = 0;
    let useDefaultPhoto: boolean = SettingsManagerService.settings.app.settings.useDefaultPlacePhotos.value || this.platform.WEB;
    let getPhotoOptions: IGetPhotoOptions = {
      noPlaceholder: true,
      redirect: true,
      cacheDisk: true,
      useGeneric: useDefaultPhoto
    };

    if (this.platform.WEB) {
      getPhotoOptions.noPlaceholder = false;
      getPhotoOptions.redirect = false;
      getPhotoOptions.cacheDisk = false;
    }

    let markers: IPlaceMarkerContent[] = [];
    console.log("preloading story locations: ", this.app.storyLocations);

    if (showLoading && !onlyDonePreviewLinear) {
      await this.uiext.showLoadingV2Queue("Loading story locations..");
    }

    let loadingErrors: boolean = false;
    this.excludeLocationIdList = [];

    // exclude existing fixed / saved locations
    for (let sloc of this.app.storyLocations) {
      if (sloc.loc.merged.googleId != null) {
        this.excludeLocationIdList.push(sloc.loc.merged.googleId);
      }
    }

    for (let i = 0; i < this.app.storyLocations.length; i++) {
      try {
        let sloc: IAppLocation = this.app.storyLocations[i];
        sloc.index = i;
        let isDone: boolean = sloc.loc.merged.done === EStoryLocationDoneFlag.done;
        let prevOffsetCenter: ILatLng = null;

        console.log("check index (" + i + ") done: ", isDone);
        console.log("coords: ", sloc.location, sloc.loc.merged.lat, sloc.loc.merged.lng);

        if (i < this.app.prevOffsetCenters.length) {
          prevOffsetCenter = this.app.prevOffsetCenters[i];
        }

        if (onlyDonePreviewLinear && !isDone) {
          continue;
        }

        let result: IAppPlaceResult = await this.mapSearch(this.currentLocation.location, sloc, this.excludeLocationIdList, this.mapEngineFlags.returnLocationFromBuffer, null, false);
        this.excludeLocationIdList.push(result.googlePlaceSelected.place.googleId);

        if ((sloc.flag === ELocationFlag.FIXED) && result.fallback) {
          this.locationApi.handlePlaceErrorStat();
          continue;
        }

        sloc = LocationUtils.updateAppLocation(sloc, result.googlePlaceSelected, true, getPhotoOptions);

        LocationUtils.selectPlaceDispPhoto(sloc.loc, null, {
          hidden: useDefaultPhoto ? true : null
        });
        if (!isDone && (sloc.loc.merged.flag !== ELocationFlag.FIXED)) {
          // don't save location if already completed or fixed (overwrites progress)  
          await this.storyDataProvider.saveLocation(this.story, locationIndex, result.googlePlaceSelected.place, !this.platform.WEB);
        }
        // update place marker data details 
        let placeMarkerData: IPlaceMarkerContent = MarkerUtils.getPlaceMarkerDataFromPlaceResult(sloc, result.googlePlaceSelected, isDone);
        MarkerUtils.setMarkerDisplayOptions(placeMarkerData, EMarkerScope.place);
        console.log("place marker data: ", placeMarkerData);
        placeMarkerData.callback = this.getPlaceMarkerCallback(sloc);
        placeMarkerData.data.keepOffsetCenter = true;

        if (prevOffsetCenter && (!isNaN(prevOffsetCenter.lat) && !isNaN(prevOffsetCenter.lng))) {
          placeMarkerData.fakeLocation = prevOffsetCenter;
        }

        placeMarkerData.addLabel = "(" + (i + 1) + ")";
        let markersLoc: IPlaceMarkerContent[] = this.getShownMarkersForPlace(placeMarkerData, isDone);

        // check already completed story locations set as locked for session
        if (isDone) {
          if (SettingsManagerService.settings.app.settings.fadeLockedStoryLocations.value) {
            for (let mloc of markersLoc) {
              mloc.locked = true;
              MarkerUtils.setMarkerLockedMode(mloc, true);
            }
          }
          sloc.lockedForSession = true;
        }
        this.storyManagerService.checkStoryLocationCooldown(sloc);

        // all markers locked by default, before proximity check, prevent unauthorized access
        if (SettingsManagerService.settings.app.settings.fadeLockedStoryLocations.value) {
          for (let mloc of markersLoc) {
            // mloc.locked = true;
          }
        }
        sloc.placeMarker = markersLoc[0];

        if (isDone) {
          // make find marker visible, remove find circle and aux marker
          markersLoc[0].visible = true;
          markersLoc = [markersLoc[0]];
          this.storyManagerService.setMarkerAddLabel(sloc, (i + 1) + ECheckpointMarkerStatus.done, true);
          MarkerUtils.setMarkerSpecialModeChecked(sloc, sloc.placeMarker, true);
        }

        markers = markers.concat(markersLoc);
        locationIndex += 1;
      } catch (err) {
        console.error(err);
        loadingErrors = true;
      }
      await SleepUtils.sleep(250);
    }

    this.app.prevOffsetCenters = this.app.storyLocations.map(sloc => {
      if (sloc.placeMarker) {
        return sloc.placeMarker.fakeLocation;
      }
      return null;
    });

    if (showLoading && !onlyDonePreviewLinear) {
      await this.uiext.showLoadingV2Queue("Initializing map..");
      await SleepUtils.sleep(1000);
      await this.uiext.dismissLoadingV2();
    }

    if (loadingErrors) {
      let res: number = await this.uiext.showAlert(Messages.msg.storyPreloadError.after.msg, Messages.msg.storyPreloadError.after.sub, 2, null, false);
      if (res === EAlertButtonCodes.ok) {
        // force exit map
        await this.goBackRequest(true, true);
      }
    } else {
      // clear ALL place markers (not just the "last" one) 
      let clearMarkerList: string[] = [EMarkerLayers.PLACES];
      for (let e of clearMarkerList) {
        await this.markerHandler.disposeLayerResolve(e);
      }
      await this.showNewPlacesRetry(markers, [], true, false);
      if (showOverview && !onlyDonePreviewLinear) {
        await this.showStoryLocationsOverview();
      }
      console.log("preloaded markers: ", markers);
      if (!onlyDonePreviewLinear) {
        this.storyManagerService.loadStoryLocations(this.app.storyLocations);
        this.smartZoom.updateTransition(ESmartZoomTransitions.scanPreloadStory);
        this.navigatePreloadStory(false);
        this.start();
      }
    }
  }

  /**
   * zoom to fit story locations
   * then zoom back to user location
   */
  async showStoryLocationsOverview() {
    this.holdPositionMarker();
    await this.moveMapToFitStoryLocations();
    await SleepUtils.sleep(3000);
    if (!this.platform.WEB) {
      this.setFollowFlag(EFollowMode.MOVE_MAP);
    }
    await this.goToUser(null, false);
    this.releasePositionMarker();
  }

  isNavigateState() {
    return [EGmapStates.NAVIGATE, EGmapStates.NAVIGATE_PENDING].indexOf(this.app.state) !== -1;
  }

  /**
   * handle non-linear story navigation
   * add fallback for linear story navigation too
   */
  async navigatePreloadStory(isLinear: boolean) {
    this.subscription.navigatePreloadStory = ResourceManager.clearSub(this.subscription.navigatePreloadStory);
    console.log("subscribing to navigate observable");
    this.storyManagerService.storyLocationProximityCheck(this.currentLocation.location, true, false);
    // global popup lock
    let popupShown: boolean = false;
    let withPopup: boolean = false;
    // only request once per story location
    let requestChallengeExcludeIndex: number[] = [];
    await SleepUtils.sleep(100);
    // await this.storyManagerService.refreshStoryLocationMarkers();
    // await SleepUtils.sleep(100);
    this.subscription.navigatePreloadStory = this.virtualPositionService.watchVirtualPosition().subscribe((data: IVirtualLocation) => {
      try {
        if (this.virtualPositionService.checkNavContext(data, true)) {
          this.storyManagerService.storyLocationProximityCheck(data.coords, false, false);
          let sloc: IAppLocation = this.storyManagerService.storyLocationNearProximityCheck(data.coords);
          if (sloc) {
            console.log("location nearby: ", true);
            // activate skip button to act as play button, activate location indicator
            if (isLinear) {
              // linear stories only
              // only allow navigation to current location (for the moment)
              if ((this.app.locationIndex === sloc.index) && !this.activityStarted.ANYTEMP && this.isNavigateState()) {
                this.internalFlags.nearbyStoryLocationIndex = sloc.index;
              } else {
                this.internalFlags.nearbyStoryLocationIndex = null;
              }
            } else {
              // allow navigation to any location in nonlinear story
              this.internalFlags.nearbyStoryLocationIndex = sloc.index;
            }
            if (!this.activityStarted.ANYTEMP && !this.activityStarted.ANY) {
              this.droneSimulator.setLowSpeedZone(true);
              this.buttonOptions.startLoc.blink = true;
            }
            if (!isLinear) {
              // nonlinear stories only
              this.checkPreloadLocationIndex(sloc, false);
              // add circle around checkpoint radius
              let startRadius: number = ActivityUtils.getStartRadiusOrDefault(sloc.loc, AppConstants.gameConfig.itemNotifyDistance);
              let useOffsetRef: boolean = ActivityUtils.isHiddenLoc(sloc.loc);
              let showCircleDistance: boolean = !useOffsetRef;
              if (sloc.loc != null && showCircleDistance) {
                // show reached circle
                if (!sloc.virtualCircle) {
                  let cm: IPlaceMarkerContent = this.findActivityProvider.getCircleReachedMarker(sloc.location, startRadius);
                  sloc.virtualCircle = cm;
                  PromiseUtils.wrapNoAction(this.markerHandler.insertMultipleMarkers([cm], true), true);
                }
              }
            }
            if (withPopup) {
              // show popup
              if (requestChallengeExcludeIndex.indexOf(sloc.storyLocationId) !== -1) {
                // skip
              } else {
                // check show popup
                if (!popupShown && !this.activityStarted.ANYTEMP && !this.activityStarted.ANY) {
                  popupShown = true;
                  requestChallengeExcludeIndex.push(sloc.storyLocationId);
                  this.uiext.showAlert(Messages.msg.storyLocationAvailable.before.msg, Messages.msg.storyLocationAvailable.before.sub, 2, ["dismiss", "view"], true).then((res: number) => {
                    if (res === EAlertButtonCodes.ok) {
                      PromiseUtils.wrapNoAction(this.getLocationDetailsViewForIndex(this.storyManagerService.getCurrentStoryLocationIndex(sloc), false, false, true, false, null), true);
                    }
                    popupShown = false;
                  }).catch((err) => {
                    console.error(err);
                  });
                }
              }
            }
          } else {
            console.log("location nearby: ", false);
            this.internalFlags.nearbyStoryLocationIndex = null;
            this.droneSimulator.setLowSpeedZone(false);
            this.buttonOptions.startLoc.blink = false;

            if (!isLinear) {
              // remove circle around checkpoint radius
              for (let apploc of this.app.storyLocations) {
                if (apploc.virtualCircle != null) {
                  apploc.virtualCircle = null;
                  PromiseUtils.wrapNoAction(this.markerHandler.disposeLayerResolve(EMarkerLayers.MARKER_CIRCLES), true);
                }
              }
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    });
  }

  /**
   * the main app sequence for navigating through stories
   */
  stateMachine() {
    // let activityFinishedParams: IActivityFinished;
    let activityFailedParams: IActivityFailed;
    switch (this.app.state) {
      case EGmapStates.INIT:
        if (!this.app.start) {

        } else {
          this.runEntryAction(async () => {
            console.log(this.getStateName());

            if (this.isPreloadStory) {
              // triggered externally
              return;
            }

            await this.clearEnvMarkers();
            this.resetDisplayValues();
            this.excludeLocationIdList = [];
            if (this.isWorldMap && !this.storySelected) {
              // this.setState(EGmapStates.INIT);
              return;
            }

            try {
              await this.loadStory(false);
              this.app.skipNextAnimation = true;
              this.excludeLocationIdList = ActivityUtils.getExcludeListFromSavedLocations(this.app.storyLocations);
              this.internalFlags.proceedToTheNextLocationTTS = true;
              await this.uiext.showLoadingV2Queue("Loading story progress..");
              await this.preloadStoryCore(false, false, true);
              await SleepUtils.sleep(500);
              await this.uiext.dismissLoadingV2();
              if (!this.isPreloadStory) {
                await this.handleShowWarningCheck();
              }
              this.setState(EGmapStates.SEARCH);
            } catch (err) {
              console.error(err);
              this.analytics.dispatchError(err, "gmap");
              this.app.start = false;
              this.uiext.showAlert(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub), 1, null).then(() => {
                this.setState(EGmapStates.ERROR);
              }).catch((err: Error) => {
                console.error(err);
                this.analytics.dispatchError(err, "gmap");
                this.setState(EGmapStates.ERROR);
              });
            }
          });
        }
        break;

      case EGmapStates.SEARCH:
        if (!this.app.start) {
          this.setState(EGmapStates.INIT);
          break;
        }

        this.runEntryAction(async () => {
          this.resetGameFlagsScan();
          // use the data from the backend with location arrays to find the next location type
          let currentLocation: IAppLocation = this.app.storyLocations[this.app.locationIndex];
          // let locationData: Location = this.app.storyLocations[this.app.locationIndex];
          // locationData.locationIndex = this.app.locationIndex;
          this.smartZoom.resetState();
          this.smartZoom.resetTransitions();
          this.user.canZoom = false;
          this.checkInteractiveModes(false);
          this.storyDataProvider.getStoryLocationDetailsWithCache(this.story.id, currentLocation.loc.merged.id).then((locationDetails: ILocationItemsDef) => {
            // console.log(locationDetails);
            this.currentLocationItems = locationDetails;
          }).catch((err: Error) => {
            console.error(err);
          });

          let skipOverride: boolean = false;
          if (this.internalFlags.checkContinue) {
            this.internalFlags.checkContinue = false;
            let pc: ICheckPendingCheckpoints = this.storyManagerService.checkPendingCheckpoints();
            if (pc.firstPendingIndex !== pc.lastPendingIndex) {
              // skip to FIRST/LAST pending index           
              let res: number = await this.uiext.showAlert(Messages.msg.skipToFirstPending.after.msg, Messages.msg.skipToFirstPending.after.sub, 2, [">| FIRST", "|> LAST"], true);
              if (res === EAlertButtonCodes.ok) {
                // last
                if (this.app.locationIndex < pc.lastPendingIndex) {
                  skipOverride = true;
                }
              } else {
                // first
                if (this.app.locationIndex < pc.firstPendingIndex) {
                  skipOverride = true;
                }
              }
            }
          }

          if ((currentLocation.loc.merged.done === EStoryLocationDoneFlag.done) || skipOverride) {
            // skip location if already done, don't save again
            // skip location if pending (not started/failed/skipped)
            this.setState(EGmapStates.SET_NEXT_LOCATION);
          } else {
            // this.uiext.showToast(Settings.getLocationTypeName(currentLocation.generalType) + " place");
            this.user.canShowNext = false;
            if (!this.mapEngineFlags.returnLocationFromBuffer) {
              this.loading = true;
            }
            this.selectPlace(currentLocation, this.excludeLocationIdList, this.mapEngineFlags.returnLocationFromBuffer, this.internalFlags.bufferDirection).then((result: IAppPlaceResult) => {
              console.log("select place resolved");
              this.mapEngineFlags.returnLocationFromBuffer = false;
              this.user.canShowNext = true;
              this.uiext.dismissLoadingV2();
              if (((currentLocation.flag === ELocationFlag.FIXED) && result.fallback) || result.skip) {
                this.locationApi.handlePlaceErrorStat();
                // auto-skip on location error (place might no longer be valid)
                this.skipPlace(1, null);
              } else {
                // all good
                if (!result.samePlace) {
                  this.setState(EGmapStates.SHOW_LOCATION);
                } else {
                  // check distance to destination
                  let dest: ILatLng = new ILatLng(result.googlePlaceSelected.place.lat, result.googlePlaceSelected.place.lng);
                  if (GeometryUtils.getDistanceBetweenEarthCoordinates(this.currentLocation.location, dest, Number.MAX_VALUE) < AppConstants.gameConfig.itemEnableDistance) {
                    this.loading = false;
                    this.setState(EGmapStates.REACHED);
                  } else {
                    this.setState(EGmapStates.SHOW_LOCATION);
                  }
                }
              }
            }).catch((err: Error) => {
              console.error(err);
              this.analytics.dispatchError(err, "gmap");
              this.uiext.dismissLoadingV2();
              this.handleUnrecoverableErrorExitMap();
              this.setState(EGmapStates.ERROR);
            });
          }
        });
        break;
      case EGmapStates.SHOW_LOCATION:
        if (!this.app.start) {
          this.setState(EGmapStates.INIT);
          break;
        }
        this.runEntryAction(() => {
          // disable user location follow
          this.internalFlags.placeSkipped = false;
          this.internalFlags.postponeDirectionsRequest = false;
          this.internalFlags.hasVideoBefore = false;
          this.internalFlags.showPreviewNavEnabled = true;
          this.internalFlags.usingFallbackNav = false;
          this.internalFlags.manualChallengeStartRequested = false;
          this.internalFlags.showPreviewStartEnabled = true;
          this.storyDataProvider.clearStatusCache();
          this.modularViews.clearCurrentGmapDetailParams();
          if (!this.isPreloadStory) {
            this.internalFlags.nearbyStoryLocationIndex = null; // disable current location check on next location selected
          }
          this.flags.prevFollow = this.flags.follow;
          this.setFollowFlag(EFollowMode.NONE);
          this.holdPositionMarker();

          this.user.canSkipAnimation = true;
          let delay = (this.app.skipAnimation || this.app.skipNextAnimation) ? 1 : this.flags.sequenceDelay;
          this.app.skipNextAnimation = false;

          this.dedicatedTimeouts.stateChange = setTimeout(() => {
            this.showDestination().then(() => {
              this.dedicatedTimeouts.stateChange = setTimeout(() => {
                this.loading = false;
                this.setState(EGmapStates.GET_DIRECTIONS);
              }, delay);
            }).catch((err: Error) => {
              console.error(err);
              this.analytics.dispatchError(err, "gmap");
              this.setState(EGmapStates.ERROR);
            });
          }, 500);
        });
        break;

      case EGmapStates.GET_DIRECTIONS:
        if (!this.app.start) {
          this.setState(EGmapStates.INIT);
          break;
        }
        this.runEntryAction(() => {
          this.holdPositionMarker();
          // setup next location (viewPlaces)
          this.app.challengeInProgress = false;
          let apploc: IAppLocation = this.app.storyLocations[this.app.locationIndex];
          this.challengeEntry.setCurrentAppLocation(apploc);
          this.user.canSkipAnimation = true;
          let delay = this.app.skipAnimation ? 1 : 500;
          let visible = true;
          let isFind: boolean = false;
          if (ActivityUtils.isFindTypeActivity(apploc.loc) || ActivityUtils.isHiddenLoc(apploc.loc)) {
            visible = false;
            isFind = true;
            // this.activityStarted.find = true;
            // not now, when entering the circle
          }
          // check nav activity
          let activityNav: IActivity = apploc.loc.merged.activityNav;
          this.showDirectionsToNextLocation(visible).then(() => {
            if ((activityNav != null) && !this.internalFlags.postponeDirectionsRequest && !this.activityStarted.nav) {
              let params = this.activityProvider.getExploreActivityInitNav(null);
              params.activeInventoryItems = this.activeInventoryItems;
              params.currentLocation = this.virtualPositionService.getCurrentPosition();
              params.fixedCoins = activityNav.fixedCoins;
              params.syncData = {
                coins: [],
                fixedCoins: params.fixedCoins,
                waypoints: {
                  waypointArray: [{
                    waypoints: this.navigationHandler.getWaypointCoords()
                  }]
                }
              };
              this.cleanupPrevActivity();
              this.exploreUtils.setCoinSpecsForActivity(activityNav);
              this.activityProvider.setCollectContext(this.internalFlags.collectMode != null ? this.internalFlags.collectMode : ECheckpointCollectMode.manual, true);
              this.initExploreNavMain(params);
              this.activityStarted.nav = true;
            }
            this.dedicatedTimeouts.stateChange = setTimeout(() => {
              if (this.user.hasScanned) {
                this.user.hasScanned = false;
                if (isFind || !this.flags.waitForAuxMarkers) {
                  this.setState(EGmapStates.MOVE_TO_FIT);
                } else {
                  this.setState(EGmapStates.MOVE_TO_FIT_SCANNED);
                }
              } else {
                this.setState(EGmapStates.MOVE_TO_FIT);
              }
            }, delay);
          }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "gmap");
            this.setState(EGmapStates.NAVIGATE_PENDING);
          });
        });
        break;
      /**
       * move to fit all scanned locations
       */
      case EGmapStates.MOVE_TO_FIT_SCANNED:
        if (!this.app.start) {
          this.setState(EGmapStates.INIT);
          break;
        }
        this.runEntryAction(() => {
          this.user.canSkipAnimation = true;
          let delay = this.app.skipAnimation ? 1 : this.flags.sequenceDelay;
          this.moveMapToFitScannedPlaces().then(() => {
            this.dedicatedTimeouts.stateChange = setTimeout(() => {
              this.setState(EGmapStates.MOVE_TO_FIT);
            }, delay * 2);
          }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "gmap");
            // no error handling required
            this.setState(EGmapStates.MOVE_TO_FIT);
          });
        });
        break;

      case EGmapStates.MOVE_TO_FIT:
        if (!this.app.start) {
          this.setState(EGmapStates.INIT);
          break;
        }
        this.runEntryAction(() => {
          this.user.canSkipAnimation = true;
          let delay = this.app.skipAnimation ? 1 : this.flags.sequenceDelay;
          this.moveMapToFitWaypoints().then(() => {
            this.dedicatedTimeouts.stateChange = setTimeout(() => {
              this.setState(EGmapStates.MOVE_TO_USER_FOLLOW);
            }, delay);
          }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "gmap");
            // no error handling required
            this.setState(EGmapStates.MOVE_TO_USER_FOLLOW);
          });
        });
        break;

      case EGmapStates.MOVE_TO_USER_FOLLOW:
        if (!this.app.start) {
          this.setState(EGmapStates.INIT);
          break;
        }
        this.runEntryAction(() => {
          // reset user location follow
          this.flags.follow = this.flags.prevFollow;
          this.user.canSkipAnimation = false;
          this.app.skipAnimation = false;
          if (!this.platform.WEB) {
            this.setFollowFlag(EFollowMode.MOVE_MAP);
          }
          this.goToUser(null, false).then(() => {
            this.releasePositionMarker();
            this.setState(EGmapStates.NAVIGATE);
          }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "gmap");
            this.releasePositionMarker();
            // no error handling required
            this.setState(EGmapStates.NAVIGATE);
          });
        });
        break;

      case EGmapStates.NAVIGATE_PENDING:
        if (!this.app.start) {
          this.setState(EGmapStates.INIT);
          break;
        }
        this.runEntryAction(() => {
          this.internalFlags.usingFallbackNav = true;
          let appLocation: IAppLocation = this.app.storyLocations[this.app.locationIndex];
          let loc: ILocationContainer = appLocation.loc;
          if (loc.merged.flag !== ELocationFlag.FIXED) {
            this.user.canScan = true;
            this.buttonOptions.scan.blink = true;
          } else {
            this.user.canScan = false;
            this.buttonOptions.scan.blink = false;
          }
          this.user.canSkip = true;
          this.user.canRequestDirections = false;
          this.goToUser(null, false).then(() => {
            this.releasePositionMarker();
          }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "gmap");
            this.releasePositionMarker();
          });
          // will proceed once the user confirms destination reached (or skips location)
        });
        break;

      case EGmapStates.NAVIGATE:
        if (!this.app.start) {
          this.setState(EGmapStates.INIT);
          break;
        }
        this.runEntryAction(() => {
          this.user.canZoom = true;
          let appLocation: IAppLocation = this.app.storyLocations[this.app.locationIndex];
          let loc: ILocationContainer = appLocation.loc;
          let isFind: boolean = ActivityUtils.isFindTypeActivity(loc);

          this.releasePositionMarker();
          this.checkVideoBefore(null, false);
          let promise = new Promise((resolve) => {
            if (!this.isPreloadStory && this.internalFlags.showPreviewNavEnabled) {
              console.log("show activity tutorial (nav preview)");
              this.internalFlags.showPreviewNavEnabled = false;
              this.showActivityTutorialInContext(appLocation, EDroneMode.noDrone, { isNavPreview: true }, false).then(() => {
                resolve(true);
              });
              // resolve(true);
            } else {
              resolve(true);
            }
          });
          promise.then(() => {
            if (loc.merged.flag !== ELocationFlag.FIXED) {
              this.user.canScan = true;
              this.buttonOptions.scan.blink = true;
            } else {
              this.user.canScan = false;
              this.buttonOptions.scan.blink = false;
            }
            this.user.canSkip = true;
            this.user.canRequestDirections = !isFind;

            if (this.internalFlags.proceedToTheNextLocationTTS) {
              // don't play audio when loading another place (scan/tap), only the first time for the current story location
              this.internalFlags.proceedToTheNextLocationTTS = false;
              this.messageQueueHandler.prepare(Messages.toast.proceedToTheNextLocation, false, EQueueMessageCode.info);
              // this.uiext.showAlertNoAction(Messages.msg.walkToThePlace.before.msg, Messages.msg.walkToThePlace.before.sub);
              let messageTTS: string = Messages.tts.proceedToTheNextLocation;
              this.soundManager.vibrateContext(true);
              this.soundManager.ttsWrapper(messageTTS, true);
            }

            // navigation
            // navigation based activity entry point
            this.navigateToStoryLocationWrapper().then(async (result: IFindActivityResult) => {
              this.user.canRequestDirections = false;
              // check nav activity
              let activityNav: IActivity = loc.merged.activityNav;
              if (activityNav != null) {
                // check collected coins on the way
                let activityStats: IActivityStatsContainer = this.activityStatsProvider.getExploreStats();
                console.log("nav activity stats: ", activityStats);
                console.log("coins collected value: ", activityStats.stats.coinsCollectedValue);
                let collectedValue: number = activityStats.stats.coinsCollectedValue;
                let collectedCoins: number = activityStats.stats.coinsCollected;
                if (!collectedValue) {
                  collectedValue = collectedCoins * AppConstants.gameConfig.coinValue;
                }
                let stats: INavigationStats = {
                  distanceTravelled: 0,
                  collectedCoins: collectedCoins,
                  collectedValue: collectedValue,
                  gainedXp: GameStatsUtils.getGradedStatAdjusted(EStatCodes.challengeCoinsCollected, null, null, null, this.story != null ? this.story.xpScaleFactor : null, true).weight * collectedCoins,
                  xpScaleFactor: this.story != null ? this.story.xpScaleFactor : null,
                  collectedList: this.exploreUtils.getExploreStats().collectedCoinsList,
                  statsList: activityStats.statsList
                };
                await this.mapGeneralUtils.showCollectedLPValueResolve(stats, activityNav, true, !this.internalFlags.manualChallengeStart, this.internalFlags.levelUpPopups, this.isWorldMap);
                await this.uiext.showLoadingV2Queue("Saving progress..");
                await PromiseUtils.wrapResolve(this.userStatsProvider.registerLPCollected(this.story ? this.story.id : null, collectedValue), true);
                this.activityProvider.setActivityNavCollectType(null);
                await this.exitExploreActivityMain(true);
                this.cleanupPrevActivity();
                await this.uiext.dismissLoadingV2();
              }
              // console.log(result);
              switch (result.status) {
                case ENavigateReturnCodes.reached:
                  this.setState(EGmapStates.REACHED);
                  break;
                case ENavigateReturnCodes.findActivityExpired:
                  this.notifyActivityFailed();
                  activityFailedParams = {
                    infoHTML: "<p>Time's up</p><p>You may try again in story mode</p><p>Find the place before the time runs out</p>",
                    reasonCode: EStandardActivityFailedCode.timeExpired,
                    retryEnabled: !this.checkWorldMapFreeRoamingMode(),
                    shareMessage: null
                  };

                  let navParams: INavParams = {
                    view: {
                      fullScreen: true,
                      transparent: false,
                      large: true,
                      addToStack: true,
                      frame: true
                    },
                    params: activityFailedParams
                  };

                  let promiseAfterExpired: Promise<number>;
                  if (this.internalFlags.placeSkipped) {
                    promiseAfterExpired = Promise.resolve(EFinishedActionParams.default);
                  } else {
                    await this.onModalOpened();
                    promiseAfterExpired = this.uiext.showCustomModal(null, ActivityFailedViewComponent, navParams);
                  }

                  promiseAfterExpired.then(async (res: number) => {
                    this.onModalClosed();
                    switch (res) {
                      case EFinishedActionParams.retry:
                        this.cleanupPrevActivity();
                        let resRetry: number = await this.retryActivityCore();
                        if (resRetry === EFinishedActionParams.retry) {
                          loc.merged.photoValidated = false;
                          this.setState(EGmapStates.BEFORE_RETRY);
                        } else {
                          // canceled, exclude from next story locations
                          this.excludeLocationIdList.push(this.app.storyLocations[this.app.locationIndex].loc.merged.googleId);
                          this.setState(EGmapStates.FINISHED_LOCATION);
                        }
                        break;
                      case EFinishedActionParams.default:
                      default:
                        // skipped, exclude from next story locations
                        this.excludeLocationIdList.push(this.app.storyLocations[this.app.locationIndex].loc.merged.googleId);
                        this.setState(EGmapStates.SET_NEXT_LOCATION);
                        break;
                    }
                  }).catch((err: Error) => {
                    this.analytics.dispatchError(err, "gmap");
                    this.onModalClosed();
                    // exclude from next story locations
                    this.excludeLocationIdList.push(this.app.storyLocations[this.app.locationIndex].loc.merged.googleId);
                    this.setState(EGmapStates.FINISHED_LOCATION);
                  });
                  break;
                case ENavigateReturnCodes.recalculate:
                  // deviated from the path, recalculate
                  this.uiext.showAlert("Route recalculated", "", 1, null).then(() => {
                    this.setState(EGmapStates.GET_DIRECTIONS);
                  }).catch((err: Error) => {
                    console.error(err);
                    this.analytics.dispatchError(err, "gmap");
                    this.setState(EGmapStates.ERROR);
                  });
                  break;
              }
            }).catch((err: Error) => {
              console.error(err);
              this.analytics.dispatchError(err, "gmap");
              this.setState(EGmapStates.ERROR);
            });
          }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "gmap");
            this.setState(EGmapStates.ERROR);
          });
        });
        break;

      case EGmapStates.REACHED:
        if (!this.app.start) {
          this.setState(EGmapStates.INIT);
        }
        this.runEntryAction(async () => {
          // update done flag
          await this.clearNav();

          let apploc: IAppLocation = this.app.storyLocations[this.app.locationIndex];
          let loc: ILocationContainer = apploc.loc;

          // this.excludeLocationIdList.push(appPlaceResult.googlePlaceSelected.place.place_id);
          // exclude this location from future seaches during the current story
          this.excludeLocationIdList.push(loc.merged.googleId);

          // console.log("exclude location list updated: ", this.excludeLocationIdList);
          await this.itemScanner.refreshWorldMapMarkerLayerWrapperResolve(EMarkerLayers.CRATES, this.itemScanner.getAttachedTreasurePlaceMarkers(), null);
          // main activity entry point

          if (this.isPreloadStory) {
            await this.storyManagerService.updateActiveStoryLocationMarker(apploc, this.app.locationIndex, false);
          }

          this.checkStoryActivity(this.app.locationIndex).then(async (res: IActivityResultCore) => {
            // check if the user did the activity or skipped
            let status: number = res.status;
            console.log("check story activity resolved: " + status);
            this.modularViews.clearCurrentGmapDetailParams();
            // clear activity
            await this.quitChallenge(true);
            await this.closeGmapDetailResolve();
            loc.merged.doneStatus = status;
            switch (status) {
              case ECheckActivityResult.failed:
              case ECheckActivityResult.skipped:
                // failed/proceed to next location
                loc.merged.done = EStoryLocationDoneFlag.pending;
                await PromiseUtils.wrapResolve(this.storyDataProvider.updateStatus(this.story, this.app.locationIndex, status === ECheckActivityResult.failed ? EStoryLocationStatusFlag.failed : EStoryLocationStatusFlag.skipped), true);
                if (this.isPreloadStory) {
                  await this.storyManagerService.updateCompletedStoryLocationPreloaded(apploc, this.app.locationIndex, false);
                  await this.showStoryLocationsOverview();
                } else {
                  await PromiseUtils.wrapResolve(this.reloadLastStoryMarker(false, true, (status === ECheckActivityResult.skipped) ? ECheckpointMarkerStatus.skipped : ECheckpointMarkerStatus.failed), true);
                }
                this.dedicatedTimeouts.stateChange = setTimeout(() => {
                  this.setState(EGmapStates.SHOW_PLACE_ADS);
                }, this.flags.sequenceDelay);
                break;
              case ECheckActivityResult.done:
                // done/proceed to next location
                loc.merged.done = EStoryLocationDoneFlag.done;
                await PromiseUtils.wrapResolve(this.storyDataProvider.updateStatus(this.story, this.app.locationIndex, EStoryLocationStatusFlag.done), true);
                if (this.isPreloadStory) {
                  await this.storyManagerService.updateCompletedStoryLocationPreloaded(apploc, this.app.locationIndex, true);
                  await this.showStoryLocationsOverview();
                } else {
                  await PromiseUtils.wrapResolve(this.reloadLastStoryMarker(false, true, ECheckpointMarkerStatus.done), true);
                }

                this.dedicatedTimeouts.stateChange = setTimeout(() => {
                  this.setState(EGmapStates.SHOW_PLACE_ADS);
                }, this.flags.sequenceDelay);
                break;
              case ECheckActivityResult.retry:
                // retry/reload current location
                loc.merged.done = EStoryLocationDoneFlag.pending;
                await PromiseUtils.wrapResolve(this.storyDataProvider.updateStatus(this.story, this.app.locationIndex, EStoryLocationStatusFlag.retry), true);
                this.dedicatedTimeouts.stateChange = setTimeout(() => {
                  this.cleanupPrevActivity();
                  this.setState(this.isPreloadStory ? EGmapStates.REACHED : EGmapStates.BEFORE_RETRY);
                }, this.flags.sequenceDelay);
                break;
              default:
                // unknown/proceed to next location
                loc.merged.done = EStoryLocationDoneFlag.pending;
                if (this.isPreloadStory) {
                  await this.storyManagerService.updateCompletedStoryLocationPreloaded(apploc, this.app.locationIndex, false);
                  await this.showStoryLocationsOverview();
                }
                this.dedicatedTimeouts.stateChange = setTimeout(() => {
                  this.setState(EGmapStates.SHOW_PLACE_ADS);
                }, this.flags.sequenceDelay);
                break;
            }
          }).catch((err: Error) => {
            console.error(err);
            this.modularViews.clearCurrentGmapDetailParams();
            this.analytics.dispatchError(err, "gmap");
            this.setState(EGmapStates.ERROR);
          });
        });
        break;

      case EGmapStates.SHOW_PLACE_ADS:
        // restaurants nearby suggestions popup
        if (!this.app.start) {
          this.setState(EGmapStates.INIT);
        }
        this.loading = true;
        let loc: ILocationContainer = this.app.storyLocations[this.app.locationIndex].loc;
        this.runEntryAction(() => {
          this.modularViews.dismissLocationDetailsViewResolve().then(() => {
            let showNearbyPlacesPopup: boolean = loc.merged.flag !== ELocationFlag.FIXED;
            console.log("show nearby places popup: ", showNearbyPlacesPopup);
            if (showNearbyPlacesPopup) {
              this.showNearbyPlacesPopupResolve(false).then(() => {
                // go to next location
                this.loading = false;
                this.setState(EGmapStates.FINISHED_LOCATION);
              });
            } else {
              // go to next location
              this.loading = false;
              this.setState(EGmapStates.FINISHED_LOCATION);
            }
          });
        });
        break;

      case EGmapStates.BEFORE_RETRY:
        if (!this.app.start) {
          this.setState(EGmapStates.INIT);
        }
        this.runEntryAction(async () => {
          this.resetGameFlagsScan();
          await this.storyManagerService.updateStoryMarker(this.app.locationIndex, false, false, null);
          this.setState(EGmapStates.SHOW_LOCATION);
        });
        break;

      case EGmapStates.FINISHED_LOCATION:
        if (!this.app.start) {
          this.setState(EGmapStates.INIT);
        }
        this.runEntryAction(async () => {
          // console.log("activity finished, coins collected: " + this.app.coinsCollectedCurrentActivity);
          this.user.canZoom = true;
          // this.internalFlags.allowCollectingTreasures = true;
          await this.itemScanner.refreshWorldMapMarkerLayerWrapperResolve(EMarkerLayers.CRATES, this.itemScanner.getAttachedTreasurePlaceMarkers(), null);
          await this.exitActivityMain(true);
          // refresh current location marker        
          await this.toggleLastMarkerDisplayMode(this.app.locationIndex, true);
          this.internalFlags.proceedToTheNextLocationTTS = true;
          let appLocation: IAppLocation = this.app.storyLocations[this.app.locationIndex];
          let loc: ILocationContainer = appLocation.loc;
          let promiseDone: Promise<boolean>;

          // check for cooldown timer
          let reloadAlways: boolean = true;

          let handlePreloadStory = async () => {
            if (this.isPreloadStory) {
              if (this.app.mapSyncRequested || reloadAlways) {
                await PromiseUtils.wrapResolve(this.preloadStory(true), true);
              } else {
                // resync story locations (e.g. find circle)
                await this.loadStoryProgressOnly();
                await this.preloadStoryCore(false, true);
              }
            }
          };

          if (loc.merged.done === EStoryLocationDoneFlag.done) {
            // activity completed
            // set progress
            // register coins collected
            appLocation.dispDone = true;
            promiseDone = new Promise(async (resolve) => {
              await this.uiext.showLoadingV2Queue("Saving progress..");
              // update progress
              this.storyManagerService.syncPhotoUploadCache(loc.merged, this.storyDataProvider.getPhotoUploadCache());
              this.storyDataProvider.updateProgress(this.story, this.app.locationIndex, loc.merged.done).then(async () => {
                console.log("progress updated");
                this.internalFlags.storyProgressUpdated = true;
                await this.uiext.showLoadingV2Queue("Checking for updates..");
                // check achievements
                // possible achievements: x places visited
                this.mpGameInterface.dispatchSyncStoryMapToMP();
                this.mpGameInterface.dispatchSyncStoryProgressToMP();
                await handlePreloadStory();
                await this.uiext.dismissLoadingV2();
                // await this.gmapModals.checkNewAchievementsCoreResolveOnly();
                resolve(true);
              }).catch((err: Error) => {
                this.analytics.dispatchError(err, "gmap");
                console.error(err);
                resolve(false);
              });
            });
          } else {
            appLocation.dispDone = false;
            promiseDone = new Promise(async (resolve) => {
              await this.uiext.showLoadingV2Queue("Saving progress..");
              // update progress
              this.storyManagerService.syncPhotoUploadCache(loc.merged, this.storyDataProvider.getPhotoUploadCache());
              this.storyDataProvider.updateProgress(this.story, this.app.locationIndex, loc.merged.done).then(async () => {
                await this.uiext.showLoadingV2Queue("Checking for updates..");
                await handlePreloadStory();
                resolve(true);
              }).catch((err: Error) => {
                this.analytics.dispatchError(err, "gmap");
                console.error(err);
                resolve(false);
              });
            });
          }

          await promiseDone;
          await this.uiext.dismissLoadingV2();
          // reset coins collected for current activity
          // clear previous buffer with locations
          this.cleanupPrevActivity();
          // wait until the map is set to tracking mode
          this.setFollowTracking2D().then(() => {
            this.setState(EGmapStates.SET_NEXT_LOCATION);
          }).catch((err: Error) => {
            this.analytics.dispatchError(err, "gmap");
            this.setState(EGmapStates.ERROR);
          });
        });
        break;

      case EGmapStates.SET_NEXT_LOCATION:
        let appLocation: IAppLocation = this.app.storyLocations[this.app.locationIndex];
        appLocation.dispDone = appLocation.loc.merged.done === EStoryLocationDoneFlag.done;
        this.checkInteractiveModes(true);
        if (!this.isPreloadStory) {
          // sequential story
          this.app.locationIndex += 1;
          // handle skip to specific index
          if (this.internalFlags.preselectIndex != null) {
            this.app.locationIndex = this.internalFlags.preselectIndex;
            this.internalFlags.preselectIndex = null;
          }
          if (this.app.locationIndex >= this.app.storyLocations.length) {
            this.dedicatedTimeouts.stateChange = setTimeout(() => {
              this.setState(EGmapStates.FINISHED_STORY);
            }, 1000);
          } else {
            this.selectStoryLocationIndex(this.app.locationIndex);
            // go to next location
            // keep reference to the previous location
            this.app.storyLocations[this.app.locationIndex].prevLoc = this.app.storyLocations[this.app.locationIndex - 1].loc;
            this.setState(EGmapStates.SEARCH);
          }
        } else {
          // preload stories next locations triggered externally
          // check finished story
          if (StoryUtils.checkAppLocationStoryFinished(this.app.storyLocations)) {
            this.dedicatedTimeouts.stateChange = setTimeout(() => {
              this.setState(EGmapStates.FINISHED_STORY);
            }, 1000);
          }
        }
        break;

      case EGmapStates.FINISHED_STORY:
        if (!this.app.start) {
          this.setState(EGmapStates.INIT);
        }
        this.runEntryAction(async () => {
          this.app.finishedStory = true;
          let finished: boolean = StoryUtils.checkFinishedStoryComplete(this.story);
          let alreadyFinished: boolean = await this.handleStoryFinished(finished);
          let registered: boolean = false;
          let goBack: boolean = true;
          let showReward: boolean = true;
          if (finished) {
            try {
              if (!alreadyFinished) {
                let res: IRegisterStoryFinished = await this.userStatsProvider.registerStoryFinished(this.story.id, this.app.rewardLpStoryTotal);
                alreadyFinished = res.existing;
                registered = res.registered;
              }
              showReward = registered && !alreadyFinished;
            } catch (err) {
              console.error(err);
              showReward = false;
            }
            goBack = await this.onFinishedStory(showReward);
          } else {
            goBack = await this.onNotFinishedStory();
          }

          this.mpGameInterface.dispatchStoryFinishedToMP();
        });
        break;
      case EGmapStates.ERROR:
        this.runEntryAction(() => {
          this.app.start = false;
          this.user.canZoom = true;
          this.setState(EGmapStates.INIT);
          // this.uiext.showAlert(Messages.msg.error.after.msg, this.errorString, 1, null).then(() => {

          // }).catch((err: Error) => {
          //   this.analytics.dispatchError(err, "gmap");
          //   console.error(err);
          // });
        });
        break;
      default:
        break;
    }
  }
}
