<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss(null)" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">
  <div class="scrollbar-y theme-border-bottom padding-xs"
    [ngClass]="{'scroll-content-modal-1' : withButtons}">
    <img #myCrop [src]="myImage">
  </div>

  <div fxFlex>
  </div>

  <div [ngClass]="!vs.fullScreen ? 'stick-bottom' : 'stick-bottom-fullscreen'">
    <ion-grid class="full-w">
      <ion-row>
        <ion-col size="3">
          <ion-button itext-center class="action-button-fill button-color-primary button-font-size-m"
            (click)="rotate()">
            <!-- <span class="button-font-size-s">90</span> -->
            <icon-wrapper class="icon-wrapper" slot="icon-only" [icon]="appIcons.rotate" [custom]="true">
            </icon-wrapper>
          </ion-button>
        </ion-col>
        <ion-col size="3">
          <ion-button class="action-button-fill button-color-primary button-font-size-m" (click)="flipX()">
            <icon-wrapper class="icon-wrapper" slot="icon-only" [icon]="appIcons.flipX" [custom]="true">
            </icon-wrapper>
          </ion-button>
        </ion-col>
        <ion-col size="3">
          <ion-button class="action-button-fill button-color-primary button-font-size-m" (click)="flipY()">
            <icon-wrapper class="icon-wrapper" slot="icon-only" [icon]="appIcons.flipY" [custom]="true">
            </icon-wrapper>
          </ion-button>
        </ion-col>
        <ion-col size="3">
          <ion-button class="action-button-fill button-color-alternate button-font-size-m" (click)="ok()">
            <!-- <span class="button-font-size-s">Save</span> -->
            <icon-wrapper class="icon-wrapper" slot="icon-only" [icon]="appIcons.check" [custom]="true">
            </icon-wrapper>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</modal-container>