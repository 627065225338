import { ICustomParamForActivity } from "../core/custom-param";
import { IGameItem } from "../items/game-item";



/** keep track of data specs formats */
export interface ICustomParamSpecData {

}


export interface IActivityQuestSpecs extends ICustomParamSpecData {
    /** quest type should be found in quest params */
    t: number,
    q: string,
    clue: string,
    lat: number,
    lng: number,
    a: string | number,
    /** html string */
    opt?: string[],
    /** use dynamic engine */
    dynamic?: boolean,
    /** dynamic engine type, e.g. street name */
    type?: number,
    valid?: boolean,
    /** shuffle options */
    shuffle?: boolean,
    /** free/manual validation */
    free?: boolean
}

export interface IQuestSelectMultiOption {
    val: string,
    isChecked: boolean
}

export interface IActivityQuestResponse {
    q: string,
    a: string | number,
    sel?: IQuestSelectMultiOption[],
    dynamic?: boolean,
    valid?: boolean
}

export interface IActivityPhotoSpecs extends ICustomParamSpecData {
    ref: string,
    d: string,
    title: string
}

export interface IActivityVideoGuideSpecs extends ICustomParamSpecData {
    type: number;
    url: string;
    id: string;
}

export interface IActivityParamsView {
    title?: string;
    photoUrl?: string;
    customParams: ICustomParamForActivity[];
    customParamsNav: ICustomParamForActivity[];

    questData: IActivityQuestSpecs;
    multiQuestData?: IActivityQuestSpecs[];

    photoData?: IActivityPhotoSpecs;
    multiPhotoData?: IActivityPhotoSpecs[];

    mode: number;
    description?: string;
    shortDescription?: string;
    descriptionCode?: number;
    scope: number;
    tutorial?: string;
    videoGuideSpecs?: {
        before: IActivityVideoGuideSpecs;
        target: IActivityVideoGuideSpecs;
        after: IActivityVideoGuideSpecs;
    }
}

export interface IActivityParamDetails {
    description: string;
    items: IGameItem[];
    title: string;
    photoUrl: string;
}

export enum EActivityParamsViewModes {
    before = 0,
    after = 1,
    retry = 2
}

export interface IDynamicParamActivityParam {
    data: ICustomParamForActivity;
    showLabel: boolean;
}
