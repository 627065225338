import { IActivity } from './activity';
import { ILocationContainer } from '../places/backend-location';


export interface IProgressBarDisplay {   
    // slider value
    value: number;
    mode: number;
    animate: boolean;   
    blink?: boolean;
    highlight?: boolean;
    highClass?: string;
}

export interface IActivityProgressDisplay extends IProgressBarDisplay {
    name: string;
    // value
    raw: number;
    // slider value
    value: number;
    mode: number;
    animate: boolean;
    showText: boolean;
    dispValue: string;
    blink?: boolean;
    show: boolean;
    category: number;
    dispAux?: any;
    callback?: () => any;
    highlight?: boolean;
}

export enum EActivityProgressDisplayCategory {
    default = 0,
    extra = 1,
    all = 2
}

export enum EActivityProgressDisplay {
    textOnly = 0,
    slider = 1,
    soundButton = 2,
    interactiveChart = 3,
    inputButton = 4
}


export enum EActivityExitState {
    exit = 1,
    skip = 2
}


export interface IActivityProgressDisplayGroup {
    [key: string]: IActivityProgressDisplay;
    distance?: IActivityProgressDisplay;
    speed?: IActivityProgressDisplay;
    time?: IActivityProgressDisplay;
    reward?: IActivityProgressDisplay;
    navDistance?: IActivityProgressDisplay;
}

export interface IActivityValidateStatus {
    enable: boolean;
    done: boolean;
    failed: boolean;
    photoGridItemAdded: boolean;
    photoValidated: boolean;
    photoValidationFailed: boolean;
    questValidated: boolean;
    qrValidated: boolean;
    type: number;
    canSwitchMapView: boolean;
}

export enum EScanCodeMode {
    none = 0,
    required = 1,
    optional = 2
}

export interface IActivityProviderContainer {
    /**
     * full activity details
     */
    activity: IActivity;
    /**
     * full backend location data
     */
    blocation: ILocationContainer;
    /**
     * full description
     */
    description: string;
    /**
     * enable scan qr
     */
    scanEnable: boolean;
    /**
     * scan type: required/optional
     */
    scanType: number;
    /**
     * registered place
     */
    registered: boolean;
    /**
     * enable flags/buttons
     */
    enable: IActivityButtonsEnable;
    /**
     * unlock flags/buttons
     */
    unlock: IActivityButtonsEnable;
    /**
     * disabled buttons
     */
    disabled: IActivityButtonsEnable;
    /**
     * optionally enable flags/buttons
     */
    optional: IActivityButtonsEnable;
    /**
     * validate status flags
     */
    validate: IActivityValidateStatus;
    /**
     * validate progress disp
     */
    validateDisp: IActivityProgressDisplayGroup;
}

export interface IActivityButtonsDtreeFlags {    
    isStoryPreview: boolean,
    isInProgress: boolean,
    isBriefing: boolean,
    isReady: boolean,
    isPhotoChallenge: boolean,
    isPhotoTaken: boolean,
    isPhotoGrid: boolean,
    isPhotoGridFilled: boolean,
    isQRScan: boolean,
    isQRScanRequired: boolean,
    canReturnToMap: boolean,
    isQRValid: boolean,
    canRetry: boolean,
    isQuestValidated: boolean,
    isActivityComplete: boolean
}

export interface IActivityButtonsEnable {
    map: boolean;
    scan: boolean;
    photo: boolean;
    retry: boolean;
    return: boolean;
    check: boolean;
    skip: boolean;
    record: boolean;

    navigate: boolean;
    continue: boolean;
    start: boolean;
    finish: boolean;
}
