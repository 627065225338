<div class="padding-s" *ngIf="loaded">
  <safe-html-renderer [unsafeHtmlContent]="descriptionHtml"
    innerClass="info-text-m description-frame text-color-primary"></safe-html-renderer>
  <div class="text-center">
    <ion-button *ngIf="wikiUrl" slot="icon-only"
      class="as-text element-selector info-text-s text-color-primary padding-top-s" (click)="goToReference()">
      <icon-wrapper [icon]="appIcons.www" [custom]="true"></icon-wrapper>
      <!-- Wiki -->
    </ion-button>
  </div>
  <ion-row class="margin-top-s">
    <ion-col size="6">
      <div *ngIf="photoUrl" class="padding-s ">
        <responsive-img [withZoom]="false" [photoUrl]="photoUrl" class="border-radius">
        </responsive-img>
      </div>
    </ion-col>
    <ion-col size="6">
      <div *ngIf="photoUrlMarker" class="padding-s ">
        <responsive-img [withZoom]="false" [photoUrl]="photoUrlMarker" class="border-radius">
        </responsive-img>
      </div>
    </ion-col>
  </ion-row>
  <ion-row class="margin-top-s">
    <ion-col size="6" class="center-text">
      <span *ngIf="photoUrl" class="info-text-m bold text-color-primary center-text">Graphic</span>
    </ion-col>
    <ion-col size="6" class="center-text">
      <span *ngIf="photoUrlMarker" class="info-text-m bold text-color-primary center-text">Marker</span>
    </ion-col>
  </ion-row>
</div>
<!-- theme-border-top -->
<ion-row class="padding-s center-text" *ngIf="hasItems()">
  <ion-col>
    <span class="info-text-m text-color-primary">{{descriptionItems}}</span>
    <grid-item [data]="data.items" [interact]="true" [basicView]="true" emptyMessage="No medals available">
    </grid-item>
  </ion-col>
</ion-row>