import { Component, OnInit, Input, Output, OnDestroy } from '@angular/core';
import { ECustomParamScope, ICustomParamForActivity, ECustomParamScope2, ECustomParamMode } from 'src/app/classes/def/core/custom-param';
import { IActivityParamsView } from 'src/app/classes/def/nav-params/activity-details';
import { EventEmitter } from '@angular/core';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { AdsService } from 'src/app/services/general/apis/ads';
import { InventoryDataService } from 'src/app/services/data/inventory';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { BusinessFeaturesService } from 'src/app/services/app/utils/business';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { MessageUtils } from 'src/app/classes/utils/message-utils';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { NavParamsService } from 'src/app/services/app/nav-params';
import { AppConstants } from 'src/app/classes/app/constants';

@Component({
    selector: 'activity-details',
    templateUrl: './activity-details.component.html',
    styleUrls: ['./activity-details.component.scss'],
})

export class ActivityDetailsComponent implements OnInit, OnDestroy {
    theme: string = "theme-aubergine theme-aubergine-bg";
    descriptionDefault: string = "<p>Special items required for this activity</p>";
    description: string = "";
    descriptionItems: string = "Game items";
    descriptionNavItems: string = "Game items along the way";
    scope: number = ECustomParamScope.challenge;
    shortDescription: string = "";
    shareMessage: string = "";
    shareEnabledGlobal = AppConstants.enableSocialSharing;

    @Input()
    data: IActivityParamsView;

    @Output()
    select: EventEmitter<any> = new EventEmitter();

    @Input()
    isNavPreview: boolean;

    @Input()
    isPreview: boolean;

    @Input()
    gridOnly: boolean;

    activityParams: ICustomParamForActivity[];
    activityParamsNav: ICustomParamForActivity[];

    hasParamGrid: boolean = false;
    hasParamNavGrid: boolean = false;

    descriptionView: string = "";
    descriptionExpanded: boolean = false;
    canExpand: boolean = false;

    loading: boolean = false;

    constructor(
        public settingsProvider: SettingsManagerService,
        public nps: NavParamsService,
        public shareProvider: ShareService,
        public uiext: UiExtensionService,
        public adService: AdsService,
        public inventory: InventoryDataService,
        public analytics: AnalyticsService,
        public businessFeaturesProvider: BusinessFeaturesService,
        public resources: ResourcesCoreDataService
    ) {

    }

    ngOnInit() {
        let params: IActivityParamsView = this.data;
        if (params) {
            this.data = Object.assign({}, MessageUtils.getDefaultShareMsgParams(), params);
            this.activityParams = this.data.customParams ? this.data.customParams.filter(cpar => !cpar.hide) : null;
            this.activityParamsNav = this.data.customParamsNav ? this.data.customParamsNav.filter(cpar => !cpar.hide) : null;
            this.formatItemGridWrapper();

            if (this.data.descriptionCode) {
                this.loadDescription(this.data.descriptionCode);
            } else {
                if (this.data.description) {
                    this.description = this.data.description;
                    this.shortDescription = this.data.description;
                } else {
                    this.description = this.descriptionDefault;
                    this.shortDescription = this.descriptionDefault;
                }

                if (this.data.shortDescription) {
                    this.shortDescription = this.data.shortDescription;
                }

                if (this.data.tutorial) {
                    this.description += "<p></p><p>Tutorial description:</p>" + this.data.tutorial;
                    this.shortDescription += "<p></p><p>Tutorial description:</p>" + this.data.tutorial;
                }
            }

            console.log("activity details component");
            console.log("is preview: ", this.isPreview);
            console.log("is nav preview: ", this.isNavPreview);

            this.descriptionItems = MessageUtils.getActivityItemsDescription(this.data.scope, ECustomParamScope2.treasures, false);
            this.descriptionNavItems = MessageUtils.getActivityItemsDescription(this.data.scope, ECustomParamScope2.treasures, true);

            this.canExpand = this.expandDescription(true);
            this.settingsProvider.getSettingsLoaded(false).then((res) => {
                if (res) {
                    this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
                }
            }).catch((err: Error) => {
                console.error(err);
            });
        }
    }

    ngOnDestroy() {

    }

    checkPadding() {
        let usePadding: boolean = false;
        if (this.gridOnly) {
            if (this.hasParamGrid || this.hasParamNavGrid) {
                usePadding = true;
            }
        } else {
            usePadding = true;
        }
        return usePadding;
    }

    formatItemGridWrapper() {
        this.activityParams = this.formatItemGrid(this.activityParams);
        if (this.activityParams.length > 0) {
            this.hasParamGrid = true;
        }
        this.activityParamsNav = this.formatItemGrid(this.activityParamsNav);
        if (this.activityParamsNav.length > 0) {
            this.hasParamNavGrid = true;
        }
    }

    /**
     * format item grid 
     * with activity params
     */
    formatItemGrid(activityParams: ICustomParamForActivity[]) {
        if (activityParams) {
            // filter hidden items
            activityParams = activityParams.filter(ap => {
                let visible: boolean = true;
                if (ap == null) {
                    return false;
                }
                if (ap.mode != null) {
                    visible = ap.mode !== ECustomParamMode.hidden;
                }
                return visible;
            });
        }
        return activityParams;
    }

    openParamDetailsView(item: ICustomParamForActivity) {
        this.select.emit(item);
    }

    /**
    * load remote resource (description)
    * @param code 
    */
    loadDescription(code: number) {
        this.loading = true;
        this.resources.getInfoText(code).then((text: string) => {
            this.loading = false;
            this.description = text;
            this.expandDescription(true);
        }).catch(() => {
            this.loading = false;
            this.description = this.descriptionDefault;
            this.expandDescription(true);
        });
    }

    getTreasureIcon(cpar: ICustomParamForActivity) {
        if (cpar && cpar.treasureSpec) {
            return cpar.treasureSpec.photoUrl;
        }
        return null;
    }

    /**
     * Share progress then dismiss
     */
    shareAndContinue() {
        this.loading = true;
        this.shareProvider.share(this.shareMessage).then(() => {
            this.loading = false;
        }).catch(() => {
            this.loading = false;
        });
    }


    clearWatch() {

    }

    expandDescription(expand: boolean) {
        this.descriptionExpanded = !this.descriptionExpanded;
        let canExpand: boolean = false;

        if (expand != null) {
            this.descriptionExpanded = expand;
        }

        if (!this.descriptionExpanded) {
            // this.descriptionView = GeneralUtils.trimHtmlPar(this.description, EMessageTrim.descriptionPreview);
            this.descriptionView = this.shortDescription;
        } else {
            this.descriptionView = this.description;
        }

        canExpand = (this.description !== this.descriptionView);
        return canExpand;
    }
}
