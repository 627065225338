export enum EMarkerLayers {
    // single
    USER = "user",
    USER_CIRCLE = "user_circle",
    USER_NAV = "user_nav",
    
    DRONE = "drone",
    DRONE_CIRCLE = "drone_circle",
    MAIN_PATH = "main_path",
    ACTIVITY_PATH = "activity_path",
    ACTIVITY_PATH_TARGET = "activity_path_target",
    
    RECORD_PATH = "recording_path",
    RECORD_CRATES = "record_crates",

    // multi
    PLACES = "places",
    PLACES_AUX = "places_aux",
    WAYPOINTS = "waypoints",
    TESTS = "tests",
    COINS = "coins",
    CRATES = "crates",

    PLACE_FIXER = "place_fixer",
    
    MARKER_CIRCLES = "marker_circles",
    CIRCLE_AUX_MARKERS = "circle_markers",
    FIND_CIRCLES = "find_circles",
    
    OTHER_OBJECTS = "other_objects",
    OTHER_PLAYERS = "other_players",
    OTHER_PLAYERS_DRONE = "other_players_drone"
}
