import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { IBackendLocation, ILocationContainer } from 'src/app/classes/def/places/backend-location';
import { BusinessFeaturesService } from 'src/app/services/app/utils/business';
import { IShareMsgParams, MessageUtils, IShareMsg, EShareMsgFeatures, EMessageTrim, IShareMsgParamsActivity } from 'src/app/classes/utils/message-utils';
import { IGameItem, EItemCategoryCodes } from 'src/app/classes/def/items/game-item';
import { IStatDef } from 'src/app/classes/def/user/stats';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ModalController } from '@ionic/angular';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { InventoryDataService } from 'src/app/services/data/inventory';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { UserStatsDataService } from 'src/app/services/data/user-stats';
import { AchievementsDataService } from 'src/app/services/data/achievements';
import { ParamHandler } from 'src/app/classes/general/params';
import { IGameItemWithStats } from 'src/app/classes/def/core/challenge';
import { IInventoryNavParams } from 'src/app/classes/def/nav-params/inventory';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { IActivityFinishedModalOutput } from 'src/app/classes/def/nav-params/modal-finished-return';
import { EActivityCodes, ECheckActivityResult, EFinishedActionParams, IActivity, IActivityIcon, IActivityIcons } from 'src/app/classes/def/core/activity';
import { InventoryHomePage } from 'src/app/pages/inventory/inventory-home/inventory-home.page';
import { IBusinessFeaturesEnabled } from 'src/app/classes/def/business/business';
import { EAppIcons, EAppIconsStandard, IIconSpec } from 'src/app/classes/def/app/icons';
import { ILeplaceReg } from 'src/app/classes/def/places/google';
import { AdHandlerService, IAdHandlerContext } from 'src/app/services/general/apis/ads-handler';
import { IRatingView } from 'src/app/classes/def/views/rating';
import { IGenericResponse } from 'src/app/classes/def/requests/general';
import { Messages } from 'src/app/classes/def/app/messages';
import { MiscDataService } from 'src/app/services/data/misc';
import { ActivityService } from 'src/app/services/app/modules/activity';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { StringUtils } from 'src/app/services/app/utils/string-utils';
import { PromiseUtils } from 'src/app/services/utils/promise-utils';
import { ITextInputParams, ITextInputResult, TextInputViewComponent } from 'src/app/modals/generic/modals/text-input/text-input.component';
import { IGenericActivityStats } from 'src/app/classes/def/core/activity-stats';
import { ECustomParam, ECustomParamScope, ICustomParamForActivity } from 'src/app/classes/def/core/custom-param';
import { PhotosService } from 'src/app/services/media/photos';
import { DomSanitizer } from '@angular/platform-browser';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { AppConstants } from 'src/app/classes/app/constants';
import { ScrollUtils } from 'src/app/services/general/ui/scroll-utils';
import { IActivityQuestSpecs, IActivityQuestResponse } from 'src/app/classes/def/nav-params/activity-details';
import { ActivitiesDataService } from 'src/app/services/data/activities';
import { StoryDataService } from 'src/app/services/data/story';
import { QuestActivityService } from 'src/app/services/app/modules/activities/quest';
import { ActivityUtils } from 'src/app/classes/utils/activity-utils';
import { IStory } from 'src/app/classes/def/core/story';
import { EAudioGuideStatus } from 'src/app/components/app/components/audio-guide/audio-guide.component';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { EAudioGuide, ECheckpointNavMode } from 'src/app/classes/def/core/interactive-features';

@Component({
  selector: 'modal-activity-finished',
  templateUrl: './activity-finished.component.html',
  styleUrls: ['./activity-finished.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivityFinishedViewComponent implements OnInit, OnDestroy {
  theme: string = "theme-aubergine theme-aubergine-bg";
  title: string = "Challenge Completed";
  infoHTML: string = "";
  shareMessage: string = "";
  collectedLP: number = 0;
  shareEnabled: boolean = true;
  activityCode: number;
  storyName: string;
  locationName: string;
  platform: IPlatformFlags = {} as IPlatformFlags;
  blocation: ILocationContainer = null;
  standardRewardCap: number = 0;
  platformObs;

  enabledFlags: IBusinessFeaturesEnabled;

  params: IShareMsgParams;
  gameItems: IGameItem[];
  photoUrl: string = null;
  photoUrlLoaded: string = null;
  activityStats: IStatDef[] = null;
  stats: IGenericActivityStats = null;
  vs: IViewSpecs = ViewSpecs.getDefault();
  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;
  np: INavParams = null;
  placeFound: ILeplaceReg;
  adContext: IAdHandlerContext = null;
  ratingEnabled: boolean = true;
  rating: number = 0;
  videoUrl: string = null;
  storyMode: boolean = false;
  customDescription: string = null;
  descriptionPlain: string = null;
  inputFocused: boolean = false;
  shareEnabledGlobal = AppConstants.enableSocialSharing;

  review = {
    description: ""
  };

  reviewCopy = {
    description: ""
  };

  maxLength: number = EMessageTrim.userInput;

  photoFinish: boolean = false;
  questFinish: boolean = false;
  QRFinish: boolean = false;
  canContinue: boolean = true;

  photoUploadUrl: string = null;
  photoUploadUrlTemp: string = null;
  photoUploadUrlSanitized: string = null;

  questData: IActivityQuestSpecs = null;
  questResponse: IActivityQuestResponse = null;

  activityFinish: IActivity;
  customParams: ICustomParamForActivity[];

  validationInProgress: boolean = false;

  hasActivityFinish: boolean = false;
  dispActivityFinish: boolean = false;
  activityFinishDescription: string = "";
  activityFinishDescriptionDisp: string = "";

  icons: IActivityIcons = {
    activity: {
      icon: "",
      custom: false
    },
    activityFinish: {
      icon: "",
      custom: false
    },
    activityNav: {
      icon: "",
      custom: false
    }
  };

  activityFinishRewardXP: number = 0;
  doneAux: number = null;
  withAudioGuide: boolean = false;
  audioGuideAutoplay: boolean = false;
  autostart: boolean = false;

  timeouts = {
    ui: null
  };

  constructor(
    public modalCtrl: ModalController,
    public settingsProvider: SettingsManagerService,
    public shareProvider: ShareService,
    public uiext: UiExtensionService,
    public miscProvider: MiscDataService,
    public inventory: InventoryDataService,
    public analytics: AnalyticsService,
    public businessFeaturesProvider: BusinessFeaturesService,
    public userStats: UserStatsDataService,
    public achievements: AchievementsDataService,
    public adHandler: AdHandlerService,
    public activityProvider: ActivityService,
    public photoProvider: PhotosService,
    public activityData: ActivitiesDataService,
    public storyDataProvider: StoryDataService,
    public questActivityService: QuestActivityService,
    public sanitizer: DomSanitizer
  ) {
    this.adHandler.initContext();
    this.adContext = this.adHandler.getContext();
    this.adContext.shareContext = EShareMsgFeatures.activityFinished;
  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);
    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      this.params = Object.assign({}, MessageUtils.getDefaultShareMsgParams(), np.params);
      console.log(this.np);
      let activity: IShareMsgParamsActivity = this.params.activityStats;
      this.collectedLP = this.params.items.collected * this.params.items.value;
      if (this.params.items.collectedValue != null) {
        this.collectedLP = this.params.items.collectedValue;
      }
      this.storyName = this.params.story.name;
      this.storyMode = this.params.story != null;
      this.standardRewardCap = activity.standardRewardCap;
      this.activityCode = activity.code;
      this.locationName = this.params.place.name;
      this.blocation = this.params.place.place;
      this.photoUrl = activity.photoUrl;
      this.activityStats = activity.statsList;
      this.stats = activity.stats;
      this.placeFound = this.params.place.place.foundLocation;
      this.videoUrl = activity.videoUrl;

      this.adContext.watchEnabled = this.params.actionButtons.watchAd;
      this.shareEnabled = this.params.actionButtons.share;

      if (this.params.story.worldMap) {
        this.ratingEnabled = false;
      }

      if (this.params.story.story) {
        if (this.params.story.story.checkpointReview === 0) {
          this.ratingEnabled = false;
        }
        let story: IStory = this.params.story.story;
        let loc: IBackendLocation = this.blocation && this.blocation.merged ? this.blocation.merged : null;
        // use checkpoint specific options if available, use story options otherwise
        this.withAudioGuide = (loc != null && loc.audioGuide != null) ? loc.audioGuide !== 0 : story.audioGuide != null && story.audioGuide !== 0;
        this.audioGuideAutoplay = (loc != null && loc.audioGuide != null) ? loc.audioGuide === EAudioGuide.ttsAuto : story.audioGuide === EAudioGuide.ttsAuto;
        this.autostart = (loc != null && loc.navMode != null) ? loc.navMode === ECheckpointNavMode.auto : story.navMode === ECheckpointNavMode.auto;
      }

      this.dispActivityStats(activity);

      if (np.view) {
        this.vs = np.view;
      }

      console.log(np);
      this.checkUnlockedMedals();
      this.checkBusinessFeatures();

      let shareMsg: IShareMsg = MessageUtils.buildMessage(EShareMsgFeatures.activityFinished, this.params);
      let infoHTML: string = shareMsg.auxHTML;

      if (this.blocation && this.blocation.merged && this.blocation.merged.finishedDescription) {
        this.customDescription = this.blocation.merged.finishedDescription;
        this.descriptionPlain = StringUtils.textFromHTMLPlain(this.customDescription);
      }

      if (this.blocation && this.blocation.merged && this.blocation.merged.activityFinish) {
        this.activityFinish = this.blocation.merged.activityFinish;
      }
      if (this.params.activityFinish) {
        this.activityFinish = this.params.activityFinish;
      }
      if (this.activityFinish && this.activityFinish.customParams) {
        this.customParams = this.activityFinish.customParams;
      }

      this.checkFinishActivity();
      this.adHandler.updateContext(this.params, shareMsg.share, infoHTML, this.collectedLP, this.standardRewardCap);
    }

    if (this.autostart) {
      if (this.withAudioGuide && this.audioGuideAutoplay && this.customDescription != null && this.customDescription != "") {
        // defer autostart until after audio guide is finished
      } else {
        this.initAutostart();
      }
    }

    this.platformObs = this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
      if (loaded) {
        this.onPlatformLoaded();
      }
    }, (err: Error) => {
      console.error(err);
    });
  }

  updateAudioGuideStatus(event: number) {
    console.log("audio guide status update: ", event);
    if (event != null) {
      switch (event) {
        case EAudioGuideStatus.started:

          break;
        case EAudioGuideStatus.finished:
          if (this.autostart) {
            this.initAutostart();
          }
          break;
      }
    }
  }

  initAutostart() {
    this.uiext.showRewardPopupQueue("AutoPlay", "Proceeding to next checkpoint", null, false, 5000, false).then((start: boolean) => {
      console.log("autostart result: ", start);
      if (start) {
        this.timeouts.ui = setTimeout(() => {
          this.continue();
        }, 500);
      }
    });
  }

  dispActivityStats(activity: IShareMsgParamsActivity) {
    if (!(activity && activity.statsList && activity.statsList.length > 0)) {
      return;
    }

    this.activityStats = [...activity.statsList];

    if (this.stats && this.stats.droneUsed) {
      this.appendStat("drone used", 0, "yes");
    }

    if ((this.params.xp != null) && (this.params.xp > 0)) {
      let statName: string = "XP score";
      if (this.params.scaled) {
        statName += " (scaled)";
      }
      this.appendStat("XP score", this.params.xp, "" + this.params.xp);
    }

    let finalCoins: number = this.collectedLP + this.adContext.rewardLP;

    if (finalCoins > 0) {
      this.appendStat("LP collected", finalCoins, "" + finalCoins);
    }
  }

  appendStat(name: string, value: number, valueString: string) {
    this.activityStats.push({
      id: null,
      code: null,
      typeCode: null,
      delegateCode: null,
      singleName: null,
      name: name,
      value: value,
      valueString: valueString,
      statsLevelActivities: null,
      weight: null,
      level: null,
      key: null,
      scoreEntry: 0,
      show: 1,
      genericCode: null,
      gameContext: null,
      gameContextCode: null
    });
  }

  /**
  * view available sales for this place
  */
  viewSales() {
    this.businessFeaturesProvider.viewSalesBLocation(this.blocation).then(() => {
      this.enabledFlags = this.businessFeaturesProvider.getCurrentBusinessFeatures();
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  scanQR() {
    this.businessFeaturesProvider.scanQrRegisterClientBLocation(this.blocation).then(() => {
      this.enabledFlags = this.businessFeaturesProvider.getCurrentBusinessFeatures();
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  openBusinessFeatures() {
    this.businessFeaturesProvider.openAsModal(this.blocation, this.enabledFlags).then(() => {
      this.enabledFlags = this.businessFeaturesProvider.getCurrentBusinessFeatures();
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * check if business is registered
   * and enable qr code and sales buttons
   */
  checkBusinessFeatures() {
    this.enabledFlags = this.businessFeaturesProvider.loadBusinessFeatures(this.blocation, this.storyMode);
  }

  checkCustomParamsMainActivity() {
    return this.blocation && this.blocation.merged.activity && this.blocation.merged.activity.customParams && this.blocation.merged.activity.customParams.length > 0;
  }

  /**
   * check finish activity (e.g. photo, quest)
   */
  checkFinishActivity() {
    if (this.checkCustomParamsMainActivity()) {
      // compatibility
      let customParams = this.blocation.merged.activity.customParams;
      if (customParams && customParams.length > 0) {
        if (customParams.find(cpar => cpar.code === ECustomParam.photoFinish)) {
          this.photoFinish = true;
          this.canContinue = false;
        }
      }
    }

    if (this.activityFinish != null) {
      switch (this.activityFinish.code) {
        case EActivityCodes.photo:
          this.photoFinish = true;
          this.canContinue = false;
          break;
        case EActivityCodes.quest:
          this.questFinish = true;
          this.canContinue = true;
          break;
        case EActivityCodes.scanQR:
          this.QRFinish = true;
          this.canContinue = false;
          break;
      }
    }

    // load description
    if (this.activityFinish != null) {
      this.hasActivityFinish = true;
      this.getActivityIcon(this.activityFinish, this.icons.activityFinish);
      this.dispActivityFinish = this.activityFinish.code != null;
    }

    if (this.hasActivityFinish) {
      let activityFinishDescriptionPlainText: string = "(2nd) " + this.activityFinish.title + " - " + StringUtils.textFromHTML(this.activityFinish.description);
      if (this.blocation.merged.rewardXpAux) {
        activityFinishDescriptionPlainText += " (" + this.blocation.merged.rewardXpAux + " XP)";
        this.activityFinishRewardXP = this.blocation.merged.rewardXpAux;
      }
      this.activityFinishDescription = StringUtils.textToHTML(activityFinishDescriptionPlainText);
      this.activityFinishDescriptionDisp = this.activityFinishDescription;
    }

    // extract params, e.g. custom params, quest data
    this.extractParams();
  }

  extractParams() {
    if (!this.activityFinish) {
      return;
    }
    let descriptionParams = this.activityProvider.getCustomActivityParams(this.activityFinish,
      this.blocation, this.storyMode ? ECustomParamScope.story : ECustomParamScope.challenge, null);
    if (this.activityFinish.questData) {
      this.questData = this.activityFinish.questData;
    } else {
      this.questData = descriptionParams.questData;
    }
    console.log("extract finish params: ", descriptionParams);
  }


  questAction(event: number) {
    console.log("quest action (N/A)", event);
  }

  questFocus(focused: boolean) {
    console.log("quest focused: ", focused);
    // this.questFocused = state;
    this.inputFocused = focused;
    if (focused) {
      ScrollUtils.scrollActiveElementIntoVIew(false, null, null, 30);
    }
  }

  /**
   * submit response for quest activity
   * @param data 
   */
  submitResponse(data: IActivityQuestResponse) {
    this.questResponse = data;
    console.log("submit response: ", data);
  }

  checkQuestComplete() {
    return false;
  }

  /**
  * submit response for validations (quest activity)
  */
  async checkInput() {
    console.log("check input: ", this.questResponse);
    try {
      let validate: boolean = await this.activityData.validateQuestData(this.questData, this.questResponse);
      if (this.storyMode) {
        if (this.questData.free) {
          this.storyDataProvider.updateQuestFinishInputCache("" + this.questResponse.a);
        }
      }
      this.doneAux = validate ? ECheckActivityResult.done : ECheckActivityResult.failed;
      if (validate) {
        this.activityFinishDescriptionDisp = this.activityFinishDescription + " (done)";
      }
      await this.questActivityService.showValidateFeedbackBasic(this.questData, validate, true);
    } catch (err) {
      console.error(err);
      this.analytics.dispatchError(err, "gmap-detail");
    }
  }

  uploadPhotoFromCamera(_event) {
    // returns actual photo url from cloud storage
    this.validationInProgress = true;
    this.photoProvider.uploadPhotoFromCameraWizard((data) => {
      this.photoUploadUrlTemp = data;
      this.doneAux = ECheckActivityResult.done;
      this.activityFinishDescriptionDisp = this.activityFinishDescription + " (done)";
      return Promise.resolve(data);
    }, false, false).then(() => {
      this.photoUploadUrlSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(this.photoUploadUrlTemp) as any;
      this.canContinue = true;
      this.validationInProgress = false;
    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "editor-home");
      this.uiext.showAlertNoAction(Messages.msg.returned.after.msg, ErrorMessage.parse(err, Messages.msg.returned.after.sub));
      this.validationInProgress = false;
    });
  }


  /**
   * scan qr code for locations that require this feature
   * or validate custom story location qr code if specified
   */
  scanBarcodeOnClick() {
    let activity: IShareMsgParamsActivity = this.params.activityStats;
    if (activity != null) {
      this.validationInProgress = true;
      this.activityProvider.scanBarcodeSloc(activity.qrCodeTarget, activity.qrMode).then((valid: boolean) => {
        if (valid) {
          this.canContinue = true;
          this.doneAux = ECheckActivityResult.done;
          this.activityFinishDescriptionDisp = this.activityFinishDescription + " (done)";
        }
        this.validationInProgress = false;
      }).catch((_err: Error) => {
        this.validationInProgress = false;
        this.uiext.showAlert(Messages.msg.registerClientError.after.msg, Messages.msg.registerClientError.after.sub + "<p>You may try again</p>", 2, ["quit", "retry"], true).then((res: number) => {
          if (res === EAlertButtonCodes.ok) {

          } else {
            // continue
            this.canContinue = true;
          }
        }).catch((err: Error) => {
          console.error(err);
        });
      });
    }
  }

  /**
   * only works for custom params of a single category
   * it is however unlikely that there may be params for multiple categories within the same activity
   */
  checkUnlockedMedals() {
    if (this.checkCustomParamsMainActivity()) {
      let customParams = this.blocation.merged.activity.customParams;
      this.achievements.getProgressTowardsNewMedal(customParams).then((items: IGameItemWithStats[]) => {
        if (items && (items.length > 0)) {
          this.gameItems = items.map(item => item.item);
          console.log("game items achievements: ", this.gameItems);
          for (let i = 0; i < this.gameItems.length; i++) {
            // remove filter
            this.gameItems[i].newUnlockedAux = 1;
            this.gameItems[i].noBlink = true;
            // this.gameItems[i].name = StringUtils.trimNameMultilineHTML(this.gameItems[i].name, EMessageTrim.itemNameCardSmall);
          }
        }
      }).catch((err: Error) => {
        console.error(err);
        this.analytics.dispatchError(err, "activity-finished");
      });
    }
  }

  /**
   * check inventory for unlocked items
   */
  viewInventory() {
    let params: IInventoryNavParams = {
      closeOnItemUse: false,
      fixedCategory: false
    };
    GeneralCache.inventorySelectedCategory = EItemCategoryCodes.medals;
    this.uiext.showCustomModal(null, InventoryHomePage, {
      view: {
        fullScreen: true,
        transparent: false,
        large: true,
        addToStack: false,
        frame: false
      },
      params: params
    }).then(() => {
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  onPlatformLoaded() {
    this.platform = SettingsManagerService.settings.platformFlags;
  }

  dismiss(params: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  onRatingSet(rating: number): void {
    this.rating = rating;
  }

  /**
   * dismiss modal
   */
  async continue() {
    try {
      if (this.questData) {
        await this.checkInput();
      }
      let returnData: IActivityFinishedModalOutput = {
        status: null,
        finalCoins: this.collectedLP + this.adContext.rewardLP,
        photoUpload: this.photoUploadUrlTemp,
        doneAux: this.doneAux,
        rewardFinishXP: this.activityFinishRewardXP
      };

      let proceed: boolean = true;
      if (this.rating > 0) {
        proceed = await this.rateCheckpoint();
      }

      if (proceed) {
        if (this.adContext.rewardIssued) {
          returnData.status = EFinishedActionParams.reward;
          this.dismiss(returnData);
        } else {
          returnData.status = EFinishedActionParams.default;
          this.dismiss(returnData);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  isReviewEnabled() {
    return this.rating > 0;
  }

  reviewChange(update: boolean) {
    if (update) {
      this.textToHTML();
    } else {
      // popup window
      let params: ITextInputParams = {
        description: "<p></p>",
        title: "Edit review",
        text: this.reviewCopy.description,
        focused: true,
        allowEmpty: true
      };

      this.uiext.showCustomModal(null, TextInputViewComponent, {
        view: {
          fullScreen: false,
          transparent: false,
          large: false,
          addToStack: true,
          frame: false
        },
        params: params
      }).then((description: ITextInputResult) => {
        if (description) {
          console.log(description);
          this.reviewCopy.description = description.text;
          this.textToHTML();
        }
      });
    }
  }

  textToHTML() {
    this.review.description = StringUtils.textToHTML(this.reviewCopy.description);
  }

  textFromHTML() {
    this.reviewCopy.description = StringUtils.textFromHTML(this.review.description);
  }

  textAreaFocused(focused: boolean) {
    console.log("focus: ", focused);
    this.inputFocused = focused;
    if (focused) {
      ScrollUtils.scrollActiveElementIntoVIew(false, null, null, 30);
    }
  }

  /**
   * resolve: user rating, null if postponed (edit some more)
   * @returns 
   */
  checkRating() {
    return new Promise((resolve) => {
      let data: IRatingView = {
        rating: this.rating,
        review: this.review.description
      };

      if (!data.review) {
        this.uiext.showAlert(Messages.msg.submitWithoutReview.after.msg, Messages.msg.submitWithoutReview.after.sub, 2, null).then((res: number) => {
          switch (res) {
            case EAlertButtonCodes.ok:
              resolve(data);
              break;
            case EAlertButtonCodes.cancel:
            default:
              resolve(null);
              break;
          }
        }).catch((err: Error) => {
          console.error(err);
          resolve(null);
        });
      } else {
        resolve(data);
      }
    });
  }


  /**
   * submit story rating
   * resolve: proceed
   */
  rateCheckpoint() {
    let promise: Promise<boolean> = new Promise((resolve) => {
      console.log("rate checkpoint: ", this.params);
      if (!(this.params && this.params.story && this.params.place)) {
        resolve(false);
        return;
      }
      if (this.params.story.id == null) {
        resolve(false);
        return;
      }
      if (this.params.place.place.merged.id == null) {
        resolve(false);
        return;
      }
      this.checkRating().then((data: IRatingView) => {
        if (data != null) {
          this.miscProvider.rateStory(this.params.story.id, this.params.place.place.merged.id, data.rating, data.review, true).then((_response: IGenericResponse) => {
            PromiseUtils.wrapResolve(this.uiext.showRewardPopupQueue(Messages.msg.thanksForRating.after.msg, Messages.msg.thanksForRating.after.sub, null, false, 2000), true).then(() => {
              resolve(true);
            });
          }).catch((err: Error) => {
            this.analytics.dispatchError(err, "activity-finished");
            resolve(true); // internal error, proceed
          });
        } else {
          resolve(false); // user postponed rating
        }
      });
    });
    return promise;
  }



  /**
   * Share progress then dismiss
   */
  shareAndContinue() {
    this.adContext.loading = true;
    this.shareEnabled = false;
    this.shareProvider.share(this.adContext.shareMessage).then(() => {
      this.adContext.loading = false;
      this.shareEnabled = true;
    }).catch(() => {
      this.adContext.loading = false;
      this.shareEnabled = true;
    });
  }

  /**
   * play reward video then dismiss
   */
  playAndContinue() {
    this.adHandler.playAndContinue(() => this.dispActivityStats(this.params.activityStats));
  }

  getActivityIcon(activity: IActivity, activityIcon: IActivityIcon) {
    if (activity) {
      let icon: IIconSpec = {
        icon: "compass",
        custom: false
      };
      icon = ActivityUtils.getIcon(activity.name);
      if (!icon.icon) {
        if (activity.icon) {
          icon.icon = activity.icon;
        }
      }
      if (icon) {
        Object.assign(activityIcon, icon);
      }
    }
  }


  ngOnDestroy() {
    console.log("close modal");
    this.timeouts = ResourceManager.clearTimeoutObj(this.timeouts);
    if (this.platformObs) {
      this.platformObs.unsubscribe();
      this.platformObs = null;
    }
  }

  clearWatch() {

  }
}
