<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss(null)" [loading]="loading" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>


<!-- <ion-content class="custom-modal-style">

  <div [class]="wrapperClass" fxLayout="column" [ngClass]="vs.transparent?'view-frame-minimal':'view-frame-minimal theme-background'"> -->
<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">
  <div fxLayout="column">
    <div class="scrollbar-y scroll-content-modal-1">

      <safe-html-renderer [unsafeHtmlContent]="description"
        innerClass="info-text-m description-frame text-color-primary text-center padding-s"></safe-html-renderer>

      <div *ngIf="hasParamSlides && enableParamSlides" class="scrollbar-y item-slide-height theme-background">
        <div>
          <dynamic-slides [slideContent]="paramSlides" type="activityParam" [slidesPerView]="1">
          </dynamic-slides>
        </div>
      </div>

      <div *ngIf="!showPhotoCard && photoUrl" class="padding-s ">
        <responsive-img [photoUrl]="photoUrl" class="border-radius"></responsive-img>
      </div>

      <div *ngIf="showPhotoCard" class="padding-s padding-top-s">
        <nav-bar [slim]="true" [slidesPerView]="navBarSlidesPerView" [(selectedTabId)]="photoCardSelected"
          [navBarItems]="navBarPhotoCardItems" (select)="selectPhotoCardFromTab($event)">
        </nav-bar>
      </div>

      <div *ngIf="showPhotoCard" class="text-center">
        <responsive-img [photoUrl]="photoCardUrl" class="border-radius"></responsive-img>
      </div>

      <safe-html-renderer [unsafeHtmlContent]="descriptionContext"
        innerClass="info-text-m description-frame text-color-primary text-left padding-s"></safe-html-renderer>

    </div>
    <div fxFlex></div>
    <div [ngClass]="!vs.fullScreen ? 'stick-bottom' : 'stick-bottom-fullscreen'">
      <ion-grid class="full-w">
        <ion-row>
          <ion-col size="12" class="padding-xs">
            <ion-button *ngIf="uploadFromGallery" class="action-button-fill button-color-accent" [disabled]="!enable"
              (click)="ok()">
              <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIconsStandard.pickPhoto"></icon-wrapper>
              <span class="button-font-size-s">{{continueText}}</span>
            </ion-button>
            <ion-button *ngIf="!uploadFromGallery" class="action-button-fill button-color-accent" [disabled]="!enable"
              (click)="ok()">
              <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIconsStandard.takePhoto"></icon-wrapper>
              <span class="button-font-size-s">{{continueText}}</span>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</modal-container>