import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { EActivityProgressDisplay, IActivityProviderContainer, EActivityProgressDisplayCategory, IActivityProgressDisplay } from 'src/app/classes/def/core/activity-manager';

@Component({
  selector: 'activity-progress-data',
  templateUrl: './activity-progress-data.component.html',
  styleUrls: ['./activity-progress-data.component.scss'],
})
export class ActivityProgressDataComponent implements OnInit, OnDestroy {

  dispModes = EActivityProgressDisplay;

  @Input()
  activityContainer: IActivityProviderContainer;

  @Input()
  update: BehaviorSubject<boolean>;

  @Input()
  showExtra: boolean;

  @Input()
  flat: boolean;

  @Input()
  active: boolean;

  category = EActivityProgressDisplayCategory;

  constructor(
    public uiext: UiExtensionService
  ) {
  }

  ngOnInit() {
    if (this.activityContainer) {
      console.log("activity container: ", this.activityContainer);
    }
  }

  ngOnDestroy() {

  }

  onSelect(event: any) {
    console.log("event: ", event);
  }

  getCustomCss(e: IActivityProgressDisplay) {
    let css: string = "full-w progress-rounded ";
    if (!e.animate) {
      css += "progress-no-animation ";
    }

    return css;
  }

  getPbClass(e: IActivityProgressDisplay) {
    let css: string = "";
    if (e.highlight) {
      css += ((e.highClass != null) ? e.highClass : "progress-bar-accent");
    } else {
      css += "progress-bar-alternate";
    }
    return css;
  }

  getText(e: IActivityProgressDisplay) {
    let text: string = e.name;
    if (e.dispValue) {
      text += ": " + e.dispValue;
    }
    return text;
  }

}
