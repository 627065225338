
import { ResourcesCoreDataService } from '../../data/resources-core';
import { Injectable } from '@angular/core';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { IGenericResponse } from '../../../classes/def/requests/general';
import { GenericDataService } from '../data/generic';
import { IFirebaseShareLink } from 'src/app/classes/def/social/share';
import { WebviewUtilsService } from '../../app/utils/webview-utils';
import { Share, ShareResult } from 'capacitor-plugin-share'; // @capacitor/share


@Injectable({
    providedIn: 'root'
})
export class ShareService {
    constructor(
        public socialSharing: SocialSharing,
        public resourcesProvider: ResourcesCoreDataService,
        public generic: GenericDataService,
        public webviewUtils: WebviewUtilsService
    ) {
        console.log("share service created");
    }


    getShareLink(message: string) {
        return this.generic.genericPostStandard("/service/get-share-link", { message });
    }

    /**
     * social sharing
     * @param message 
     */
    share(message: string) {
        let promise: Promise<boolean> = new Promise(async (resolve, reject) => {
            if (!message) {
                message = "Treasure Hunting with Leplace Mobile App";
            }
            let subject = "Leplace Urban Experience";
            try {
                let response: IGenericResponse = await this.getShareLink(message);
                let firebaseShareLink: IFirebaseShareLink = response.data;
                let res: ShareResult = await Share.share({
                    title: subject,
                    text: message,
                    url: firebaseShareLink.shortLink,
                    dialogTitle: 'Share with buddies',
                });
                console.log("share with options result: ", res);
                //   await this.webviewUtils.resetViewframe(false, false);
                resolve(true);
            } catch (err) {
                reject(err);
            }
        });
        return promise;
    }

    /**
     * social sharing
     * @param message 
     */
    sharePhoto(message: string, photo: string) {
        let promise = new Promise(async(resolve, reject) => {
            if (!message) {
                message = "#leplace";
            }
            // let subject = "#leplace";
            try {                
                let res: ShareResult = await Share.share({
                    text: message,
                    url: photo
                });
                console.log("share with options result: ", res);
                //   await this.webviewUtils.resetViewframe(false, false);
                resolve(true);
            } catch (err) {
                reject(err);
            }
        });
        return promise;
    }

}
