
import { ILatLng } from 'src/app/classes/def/map/coords';
import { IPlatformFlags } from "../../classes/def/app/platform";
import { MarkerHandlerService } from "./markers";
import { Injectable } from "@angular/core";
import { SettingsManagerService } from "../general/settings-manager";
import { LocationMonitorService } from "./location-monitor";
import { EMarkerLayers } from "../../classes/def/map/marker-layers";
import { AppConstants } from "../../classes/app/constants";
import { IAppLocation } from 'src/app/classes/def/places/app-location';
import { IPlaceMarkerContent } from 'src/app/classes/def/map/map-data';
import { PromiseUtils } from '../utils/promise-utils';
import { MarkerUtils } from "./marker-utils";
import { DeepCopy } from "src/app/classes/general/deep-copy";
import { LocationDispUtils } from "../location/location-disp-utils";
import { IPhotoResultResponse } from "src/app/classes/def/media/processing";
import { IBackendLocation } from "src/app/classes/def/places/backend-location";
import { ActivityUtils } from "src/app/classes/utils/activity-utils";
import { StoryUtils } from "src/app/classes/utils/story-utils";
import { IGmapApp } from "src/app/classes/def/map/gmap";
import { EStoryLocationDoneFlag } from "src/app/classes/def/nav-params/story";


interface ICheckLocationCooldown {
    locked: boolean,
    lockedChanged: boolean
}

export interface ICheckPendingCheckpoints {
    firstPendingIndex: number;
    lastPendingIndex: number;
}

@Injectable({
    providedIn: 'root'
})
export class StoryManagerService {
    platform: IPlatformFlags = {} as IPlatformFlags;
    storyLocations: IAppLocation[] = [];
    subscription = {
        navigate: null
    };
    lastKnownLocation: ILatLng;
    lockedInLocationId: number = null;

    constructor(
        public markerHandler: MarkerHandlerService,
        public settings: SettingsManagerService,
        public locationMonitor: LocationMonitorService
    ) {
        console.log("story manager service created");
    }

    loadStoryLocations(slocs: IAppLocation[]) {
        this.storyLocations = slocs;
    }

    unloadStoryLocations() {
        this.storyLocations = [];
    }

    /**
     * get current story location index
     * @param appLocation 
     */
    getCurrentStoryLocationIndex(appLocation: IAppLocation) {
        let activeIndex: number = -1;
        for (let i = 0; i < this.storyLocations.length; i++) {
            let sloc = this.storyLocations[i];
            if (sloc.storyLocationId === appLocation.storyLocationId) {
                activeIndex = i;
                break;
            }
        }
        return activeIndex;
    }


    /**
     * check story location cooldown lockedForSession
     * @param sloc 
     */
    checkStoryLocationCooldown(sloc: IAppLocation): ICheckLocationCooldown {
        let res: ICheckLocationCooldown = {
            locked: false,
            lockedChanged: false
        };
        let lock: boolean = false;
        let message: string = null;
        // let timeCrt: number = new Date().getTime();
        // console.log("checking time left for: ", sloc);
        // if (sloc.loc.merged.timestamp != null) {
        //     // check timestamp last attempt
        //     let timeLast: number = new Date(sloc.loc.merged.timestamp).getTime();
        //     let timediff: number = timeCrt - timeLast;
        //     if (timediff < 0) {
        //         timediff = 0;
        //     }
        //     let timeLeft: number = AppConstants.gameConfig.challengeCooldown * 1000 - timediff;
        //     if (timeLeft < 0) {
        //         timeLeft = 0;
        //     }
        //     console.log("checking time left: ", TimeUtils.formatTimeFromSeconds(timeLeft / 1000, true));
        //     console.log(timeLeft, timediff);
        //     if (timeLeft > 0) {
        //         lock = true;
        //         message = Messages.msg.storyLocationLockedCooldown.after.sub;
        //         let timeLeftString: string = TimeUtils.formatTimeFromSeconds(timeLeft / 1000, true);
        //         message += timeLeftString;
        //     }
        //     // don't skip cooldown for failed challenges (because it should use EG to retry, otherwise ok)
        //     // if (!sloc.loc.merged.done) {
        //     //     lock = false;
        //     // }
        // }
        if (lock) {
            if (!sloc.lockedForSession) {
                res.lockedChanged = true;
            }
            sloc.lockedForSession = true;
            sloc.lockedForSessionNoAccess = true;
            sloc.lockedMessage = message;
        } else {
            if (!sloc.lockedForSessionComplete) {
                // unlock for session only if not complete
                if (sloc.lockedForSession) {
                    res.lockedChanged = true;
                }
                sloc.lockedForSession = false;
            }
            // unlock on-demand access on cooldown complete
            sloc.lockedForSessionNoAccess = false;
            sloc.lockedMessage = null;
        }
        res.locked = lock;
        return res;
    }

    /**
      * set locked story location after completed
      * @param sloc 
      */
    async updateCompletedStoryLocationPreloaded(sloc: IAppLocation, slocIndex: number, completed: boolean) {
        let activeIndex: number = slocIndex != null ? slocIndex : this.getCurrentStoryLocationIndex(sloc);
        if (activeIndex === -1) {
            console.error("update completed story location not found");
            return;
        }
        let slocActive: IAppLocation = this.storyLocations[activeIndex];
        let fade: boolean = SettingsManagerService.settings.app.settings.fadeLockedStoryLocations.value;
        // lock completed marker

        if (completed) {
            if (fade) {
                slocActive.placeMarker.requiresRefresh = !slocActive.placeMarker.locked;
                slocActive.placeMarker.locked = true;
                MarkerUtils.setMarkerLockedMode(sloc.placeMarker, true);
            }
            // slocActive.lockedForSession = true;
            // slocActive.lockedForSessionComplete = true;
        }

        // unlock other (unlockable) markers
        if (fade) {
            for (let i = 0; i < this.storyLocations.length; i++) {
                if (i !== activeIndex) {
                    let sloc: IAppLocation = this.storyLocations[i];
                    if (!sloc.lockedForSession) {
                        if (sloc.placeMarker.locked && sloc.inRange) {
                            sloc.placeMarker.requiresRefresh = sloc.placeMarker.locked;
                            sloc.placeMarker.locked = false;
                            MarkerUtils.setMarkerLockedMode(sloc.placeMarker, false);
                        }
                    }
                }
            }
        }

        // prevent acidental unlock if not in range
        this.storyLocationProximityCheck(this.lastKnownLocation, true, false);
        await this.refreshStoryLocationMarkers();
    }

    /**
     * set locked (other) story locations before starting challenge
     * @param sloc 
     * @param slocIndex 
     */
    async updateActiveStoryLocationMarker(sloc: IAppLocation, slocIndex: number, applyFilter: boolean) {
        // set other markers locked
        let activeIndex: number = slocIndex != null ? slocIndex : this.getCurrentStoryLocationIndex(sloc);
        if (activeIndex === -1) {
            console.error("update active story location not found");
            return;
        }
        let fade: boolean = SettingsManagerService.settings.app.settings.fadeLockedStoryLocations.value;
        let slocActive: IAppLocation = this.storyLocations[activeIndex];
        if (fade) {
            // unlock active marker
            if (!slocActive.lockedForSession && slocActive.inRange) {
                slocActive.placeMarker.requiresRefresh = slocActive.placeMarker.locked;
                slocActive.placeMarker.locked = false;
                MarkerUtils.setMarkerLockedMode(slocActive.placeMarker, false);
            }
        }

        if (fade && applyFilter) {
            for (let i = 0; i < this.storyLocations.length; i++) {
                // lock all other markers
                if (i !== activeIndex) {
                    let sloc: IAppLocation = this.storyLocations[i];
                    if (!sloc.placeMarker.locked) {
                        slocActive.placeMarker.requiresRefresh = !slocActive.placeMarker.locked;
                        slocActive.placeMarker.locked = true;
                        MarkerUtils.setMarkerLockedMode(slocActive.placeMarker, true);
                    }
                }
            }
        }

        // prevent acidental unlock if not in range
        this.storyLocationProximityCheck(this.lastKnownLocation, true, false);
        await this.refreshStoryLocationMarkers();
    }

    async refreshStoryLocationMarkers() {
        let markers: IPlaceMarkerContent[] = [];
        // we don't want pass by reference here as we need to detect changes
        // markers = DeepCopy.deepcopy(markers);
        for (let sloc of this.storyLocations) {
            if (sloc.lockedForSession) {
                // sloc.placeMarker.addLabel = " (done)";
            }
            markers.push(sloc.placeMarker);
        }
        console.log("refresh story location markers: ", markers);
        console.log(this.storyLocations);
        await this.markerHandler.syncMarkerArrayResolve(EMarkerLayers.PLACES, markers);
    }

    async clearStoryLocationMarkers() {
        await this.markerHandler.syncMarkerArrayResolve(EMarkerLayers.PLACES, []);
    }

    async updateStoryMarker(slocIndex: number, lock: boolean, done: boolean, addLabel: string) {
        let sloc: IAppLocation = this.storyLocations[slocIndex];
        console.log("lock story marker: ", sloc);
        console.log("done: ", done);
        if (!(sloc && sloc.placeMarker)) {
            console.warn("lock story marker undefined");
            return;
        }
        MarkerUtils.setMarkerDone(sloc.placeMarker, done);
        MarkerUtils.syncMarkerData(sloc.placeMarker, sloc);
        MarkerUtils.setMarkerSpecialModeChecked(sloc, sloc.placeMarker, done);
        LocationDispUtils.checkHiddenLocationOverrides(sloc, sloc.placeMarker, !done);

        // if (done) {          
        //     LocationUtils.checkHiddenLocationOverrides(sloc, sloc.placeMarker.data, false);
        // } else {
        //     // sloc.placeMarker.locked = lock;
        //     LocationUtils.checkHiddenLocationOverrides(sloc, sloc.placeMarker.data, true);
        // }
        sloc.placeMarker.locked = lock;
        MarkerUtils.setMarkerLockedMode(sloc.placeMarker, lock);
        sloc.placeMarker.requiresRefresh = true;

        this.setMarkerAddLabel(sloc, addLabel, false);
        console.log("update story marker: ", DeepCopy.deepcopy(sloc.placeMarker));
        console.log("base sloc: ", sloc);
        return this.markerHandler.updateArrayMarkerCore(EMarkerLayers.PLACES, sloc.placeMarker);
    }

    /**
     * sync photo upload preview via photo challenge result
     * @param bloc 
     * @param prr 
     * @returns 
     */
    syncPhotoUploadResponse(bloc: IBackendLocation, prr: IPhotoResultResponse) {
        if (!prr) {
            return;
        }
        bloc.uploadedPhotoUrl = prr.uploadedPhotoUrl != null ? prr.uploadedPhotoUrl : prr.processedImageData;
    }

    /**
     * sync photo upload preview via cache (e.g., photo finish)
     * @param bloc 
     * @param photoUrlCache 
     * @returns 
     */
    syncPhotoUploadCache(bloc: IBackendLocation, photoUrlCache: string) {
        if (!photoUrlCache) {
            return;
        }
        bloc.uploadedPhotoFinishUrl = photoUrlCache;
    }

    setMarkerAddLabel(sloc: IAppLocation, addLabel: string, final: boolean) {
        if (!(sloc && sloc.placeMarker)) {
            return;
        }
        if (addLabel) {
            console.log("add label: ", addLabel);
            sloc.placeMarker.tempLabel = sloc.placeMarker.addLabel;
            sloc.placeMarker.addLabel = "(" + addLabel + ")";
            if (final) {
                sloc.placeMarker.tempLabel = sloc.placeMarker.addLabel;
            }
            // sloc.placeMarker.addLabel2 = " (" + addLabel + ")";
        } else {
            // sloc.placeMarker.addLabel2 = null;
            sloc.placeMarker.addLabel = sloc.placeMarker.tempLabel;
        }
    }

    refreshStoryLocationMarkersNoAction() {
        PromiseUtils.wrapNoAction(this.refreshStoryLocationMarkers(), true);
    }

    clearStoryLocationMarkersNoAction() {
        PromiseUtils.wrapNoAction(this.clearStoryLocationMarkers(), true);
    }

    checkStoryLocationsProgressUpdated() {
        console.log("check story locations progress updated: ", this.storyLocations);
        if (!this.storyLocations) {
            return;
        }
        for (let sloc of this.storyLocations) {
            if (sloc && sloc.placeMarker) {
                let checkCd: ICheckLocationCooldown = this.checkStoryLocationCooldown(sloc);
                if (sloc.requiresReload || checkCd.lockedChanged) {
                    console.log("requires reload detected");
                    sloc.requiresReload = false;
                    sloc.placeMarker.requiresRefresh = true;
                }
            }
        }

        this.refreshStoryLocationMarkersNoAction();
    }

    /**
     * check unlock story locations based on proximity
     * return available nearby story location
     * @param currentLocation 
     * @param init use strict threshold to check actual condition / no histeresis
     */
    storyLocationProximityCheck(currentLocation: ILatLng, init: boolean, applyFilter: boolean): IAppLocation {
        let unlockDistance: number = AppConstants.gameConfig.itemEnableDistance;
        let nearbyStoryLocationInRange: IAppLocation = null;
        if (!currentLocation) {
            return;
        }
        this.lastKnownLocation = currentLocation;
        let fade: boolean = SettingsManagerService.settings.app.settings.fadeLockedStoryLocations.value && applyFilter;
        for (let sloc of this.storyLocations) {
            if (sloc && sloc.placeMarker) {
                let dist: number = StoryUtils.getDistanceToStoryLocationProximity(currentLocation, sloc);
                let unlockDistanceSpec: number = ActivityUtils.getStartRadiusOrDefault(sloc.loc, unlockDistance);

                // console.log(dist, AppConstants.gameConfig.reachedDistance);
                if (dist <= unlockDistanceSpec) {
                    sloc.unlocked = true;
                    sloc.inRange = true;
                    if (!sloc.lockedForSession) {
                        nearbyStoryLocationInRange = sloc;
                        if (fade) {
                            if (sloc.placeMarker.locked) {
                                sloc.placeMarker.requiresRefresh = sloc.placeMarker.locked;
                                sloc.placeMarker.locked = false;
                                MarkerUtils.setMarkerLockedMode(sloc.placeMarker, false);
                                // refresh marker
                                this.refreshStoryLocationMarkersNoAction();
                            }
                        }
                    }
                } else if ((dist > unlockDistanceSpec * StoryUtils.lockInDistanceFactor) || (init && (dist > unlockDistanceSpec))) {
                    // with histeresis/threshold
                    sloc.unlocked = false;
                    sloc.inRange = false;
                    if (fade) {
                        if (!sloc.placeMarker.locked) {
                            sloc.placeMarker.requiresRefresh = !sloc.placeMarker.locked;
                            sloc.placeMarker.locked = true;
                            MarkerUtils.setMarkerLockedMode(sloc.placeMarker, true);
                            // refresh marker
                            this.refreshStoryLocationMarkersNoAction();
                        }
                    }
                }
            } else {
                // console.log("no place marker for story location: ", sloc);
            }
        }
        return nearbyStoryLocationInRange;
    }


    /**
     * return the nearest location
     * @param currentLocation 
     */
    storyLocationNearProximityCheck(currentLocation: ILatLng): IAppLocation {
        let unlockDistance: number = AppConstants.gameConfig.itemNotifyDistance;
        let nearbyStoryLocationInRange: IAppLocation = null;
        let minDist: number = null;
        // check all story locations
        // select the nearest one
        for (let sloc of this.storyLocations) {
            if (sloc && sloc.placeMarker) {

                let dist: number = StoryUtils.getDistanceToStoryLocationProximity(currentLocation, sloc);
                let unlockDistanceSpec: number = ActivityUtils.getStartRadiusOrDefault(sloc.loc, unlockDistance);

                if (dist <= unlockDistanceSpec) {
                    if (!sloc.lockedForSession) {
                        if ((dist < minDist) || minDist == null) {
                            minDist = dist;
                            nearbyStoryLocationInRange = sloc;
                            this.lockedInLocationId = sloc.storyLocationId;
                        }
                    }
                }
            }
        }

        // check threshold for previously locked-in location
        if (nearbyStoryLocationInRange == null) {
            if (this.lockedInLocationId != null) {
                let sloc: IAppLocation = this.storyLocations.find(sl => sl.storyLocationId === this.lockedInLocationId);
                if (sloc != null) {
                    let dist: number = StoryUtils.getDistanceToStoryLocationProximity(currentLocation, sloc);
                    let unlockDistanceSpec: number = ActivityUtils.getStartRadiusOrDefault(sloc.loc, unlockDistance);
                    // too far away => release lock
                    if (dist > unlockDistanceSpec * StoryUtils.lockInDistanceFactor) {
                        this.lockedInLocationId = null;
                        sloc = null;
                    }
                }
                nearbyStoryLocationInRange = sloc;
            }
        }
        return nearbyStoryLocationInRange;
    }

    clearFlags() {
        this.lockedInLocationId = null;
    }

    checkPendingCheckpoints(): ICheckPendingCheckpoints {
        let pc: ICheckPendingCheckpoints = {
            firstPendingIndex: -1, // Index of the first pending checkpoint
            lastPendingIndex: -1 // Index of the first pending checkpoint after which all are pending
        };
        if (!this.storyLocations) {
            return pc;
        }

        console.log("check pending checkpoints status array: ", this.storyLocations.map(sloc => sloc.loc.merged.done === EStoryLocationDoneFlag.done));

        // Find the first pending checkpoint
        for (let i = 0; i < this.storyLocations.length; i++) {
            let loc = this.storyLocations[i].loc.merged;
            if (loc.done !== EStoryLocationDoneFlag.done) {
                pc.firstPendingIndex = i;
                break; // Exit the loop once the first pending checkpoint is found
            }
        }

        // If there is no pending checkpoint, no need to proceed further
        if (pc.firstPendingIndex === -1) {
            return pc;
        }

        // Iterate backwards to find the first pending checkpoint after which all checkpoints are pending
        for (let i = this.storyLocations.length - 1; i >= 0; i--) {
            let loc = this.storyLocations[i].loc.merged;
            if (loc.done === EStoryLocationDoneFlag.done) {
                // Found a true checkpoint, so the previous pending (if any) is the lastFalseBeforeAllFalse
                pc.lastPendingIndex = ((i + 1) < this.storyLocations.length) ? (i + 1) : -1;
                break;
            } else if (i === 0) {
                // If we've reached the start, then all following the firstFalseIndex are pending
                pc.lastPendingIndex = pc.firstPendingIndex;
            }
        }

        console.log("check pending checkpoints: ", pc);
        return pc;
    }

    handleSkipOptions(app: IGmapApp, direction: number) {
        let loop: boolean = false;
        if (!direction) {
            direction = 1;
            loop = true;
        }
        switch (direction) {
            case 1:
                if (app.locationIndex >= app.storyLocations.length) {
                    app.locationIndex = app.storyLocations.length - 1;
                }
                // disable highlight current location in progress
                if (app.storyLocations[app.locationIndex].loc.merged.done !== EStoryLocationDoneFlag.done) {
                    app.storyLocations[app.locationIndex].dispDone = false;
                }
                // find next location not done
                app.locationIndex += 1;
                // reset index
                if (app.locationIndex >= app.storyLocations.length) {
                    app.locationIndex = 0;
                }
                while (app.storyLocations[app.locationIndex].loc.merged.done === EStoryLocationDoneFlag.done) {
                    app.locationIndex += 1;
                    // if no location found, reset index
                    if (app.locationIndex >= app.storyLocations.length) {
                        app.locationIndex = 0;
                        break;
                    }
                }
                // reset index
                if (app.locationIndex >= app.storyLocations.length) {
                    if (loop) {
                        app.locationIndex = 0;
                    } else {
                        app.locationIndex = app.storyLocations.length - 1;
                    }
                }
                break;
            case -1:
                if (app.locationIndex < 0) {
                    app.locationIndex = 0;
                }
                // disable highlight current location in progress
                if (app.storyLocations[app.locationIndex].loc.merged.done !== EStoryLocationDoneFlag.done) {
                    app.storyLocations[app.locationIndex].dispDone = false;
                }
                // find next location not done
                app.locationIndex -= 1;
                // reset index
                if (app.locationIndex < 0) {
                    app.locationIndex = 0;
                }
                while (app.storyLocations[app.locationIndex].loc.merged.done === EStoryLocationDoneFlag.done) {
                    app.locationIndex -= 1;
                    // if no location found, reset index
                    if (app.locationIndex < 0) {
                        app.locationIndex = 0;
                        break;
                    }
                }
                // reset index
                if (app.locationIndex < 0) {
                    if (loop) {
                        app.locationIndex = app.storyLocations.length - 1;
                    } else {
                        app.locationIndex = 0;
                    }
                }
                break;
        }
    }
}


