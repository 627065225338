<div [ngClass]="checkPadding() ? 'padding-s' : ''">
  <div class="padding-bottom-s" *ngIf="!gridOnly">
    <safe-html-renderer [unsafeHtmlContent]="descriptionView"
      innerClass="info-text-m description-frame text-color-primary"></safe-html-renderer>

    <!-- <expander *ngIf="canExpand" [expanded]="descriptionExpanded" [large]="false" (expand)="expandDescription(null)">
    </expander> -->
    <!-- <ion-button *ngIf="canExpand" class="action-button-xs button-color-alternate" (click)="expandDescription(null)">
      <icon-wrapper *ngIf="!descriptionExpanded" slot="icon-only" icon="add-circle"></icon-wrapper>
      <icon-wrapper *ngIf="descriptionExpanded" slot="icon-only" icon="remove-circle"></icon-wrapper>
    </ion-button> -->
  </div>
  <!-- theme-border-top -->
  <div *ngIf="hasParamGrid && (!isNavPreview || isPreview)" class="center-text">
    <span class="info-text-m text-color-primary">{{descriptionItems}}</span>
    <grid-activity-param [data]="activityParams" [interact]="false" [showLabel]="true"
      (action)="openParamDetailsView($event)" emptyMessage=""></grid-activity-param>
  </div>
  <div *ngIf="hasParamNavGrid && (isNavPreview || isPreview)" class="center-text padding-top-s">
    <span class="info-text-m text-color-primary">{{descriptionNavItems}}</span>
    <grid-activity-param [data]="activityParamsNav" [interact]="false" [showLabel]="true"
      (action)="openParamDetailsView($event)" emptyMessage=""></grid-activity-param>
  </div>
  <div *ngIf="shareMessage && !gridOnly && !isNavPreview && !isPreview && shareEnabledGlobal" class="padding-top-s"
    fxLayout="column">
    <span class="info-text-m bold text-color-primary">Share progress</span>
    <span class="info-text-m text-color-primary">{{shareMessage}}</span>
  </div>
</div>