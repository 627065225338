import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ICustomParamForActivity, ECustomParamScope2 } from 'src/app/classes/def/core/custom-param';
import { IGameItem } from 'src/app/classes/def/items/game-item';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { Util } from 'src/app/classes/general/util';
import { MessageUtils } from 'src/app/classes/utils/message-utils';
import { NavParamsService } from 'src/app/services/app/nav-params';
import { EAppIcons } from 'src/app/classes/def/app/icons';

@Component({
  selector: 'activity-param-details',
  templateUrl: './activity-param-details.component.html',
  styleUrls: ['./activity-param-details.component.scss'],
})
export class ActivityParamDetailsComponent implements OnInit, OnDestroy {

  @Input()
  data: ICustomParamForActivity;

  descriptionHtml: string = "";
  items: IGameItem[] = null;
  loading: boolean = false;
  photoUrl: string = null;
  photoUrlMarker: string = null;
  descriptionItems: string = "";
  wikiUrl: string = null;

  appIcons = EAppIcons;

  loaded: boolean = false;

  constructor(
    public nps: NavParamsService,
    public resources: ResourcesCoreDataService,
    public analytics: AnalyticsService
  ) {

  }

  formatItems() {
    if (this.items) {
      for (let i = 0; i < this.items.length; i++) {
        let item: IGameItem = this.items[i];
        item.validCount = 1;
      }
    }
  }

  goToReference() {
    if (this.wikiUrl) {
      Util.openURL(this.wikiUrl);
    }
  }

  hasItems() {
    return this.data.items && this.data.items.length > 0;
  }

  ngOnInit() {
    console.log(this.data);
    if (this.data) {
      let description: string = this.data.description;
      this.wikiUrl = this.data.wikiUrl;

      if (this.data.items && this.data.items.length > 0) {
        let itemSample: IGameItem = this.data.items[0];
        if (!description) {
          // fetch the description from one of the associated items
          description = itemSample.description;
        }

        if (!this.wikiUrl) {
          this.wikiUrl = itemSample.wikiUrl;
        }
      }

      if (description) {
        this.descriptionHtml = description;
      }

      this.descriptionItems = MessageUtils.getActivityItemsDescription(this.data.scope, ECustomParamScope2.items, false);
      this.photoUrl = this.data.photoUrl;

      // may not be available e.g. opening from AR view has no info about the map marker
      this.photoUrlMarker = this.data.photoUrlMarker;
      if (!this.photoUrlMarker && this.data.treasureSpec != null) {
        this.photoUrlMarker = this.data.treasureSpec.photoUrl;
      }

      this.formatItems();
      this.loaded = true;
    }
  }

  ngOnDestroy() {

  }
}
