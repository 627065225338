
import { Injectable } from '@angular/core';
import { UiExtensionService } from '../../../general/ui/ui-extension';
import { AnalyticsService } from '../../../general/apis/analytics';
import { MiscDataService } from '../../../data/misc';
import { IGenericResponse, ECheckLoginResponse } from '../../../../classes/def/requests/general';
import { NewsfeedDataService } from '../../../data/newsfeed';
import { Messages } from '../../../../classes/def/app/messages';
import { EAlertButtonCodes } from '../../../../classes/def/app/ui';
import { StoryDataService } from '../../../data/story';
import { IMasterLockResponse, IStory, EStoryUnlockedCode } from '../../../../classes/def/core/story';
import { IStoryBuyNavParams } from '../../../../classes/def/nav-params/story';
import { IItemAction } from '../../../../classes/def/items/action';
import { EItemActions } from '../../../../classes/def/items/game-item';
import { ResourcesCoreDataService } from '../../../data/resources-core';
import { SettingsManagerService } from '../../../general/settings-manager';
import { IPlatformFlags } from '../../../../classes/def/app/platform';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Market } from '@ionic-native/market/ngx';
import { INewsfeedInbox } from '../../../../classes/def/newsfeed/newsfeed';
import { ErrorMessage } from '../../../../classes/general/error-message';
import { UserStatsDataService } from '../../../data/user-stats';
import { UserDataService } from '../../../data/user';
import { AchievementsDataService } from '../../../data/achievements';
import { GmapModalsService } from './gmap-modals';
import { AdsService } from '../../../general/apis/ads';
import { AppConstants } from '../../../../classes/app/constants';
import { StoryBuyOptionsViewComponent } from 'src/app/modals/app/modals/story-buy-options/story-buy-options.component';
import { IGetReferralCode, ICheckReferralCode } from 'src/app/classes/utils/popup-features';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { IEventStoryGroupLinkData } from 'src/app/classes/def/core/links';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';
import { IAPUtils } from 'src/app/services/apis/iap-utils';
import { IAppVersionDB } from 'src/app/classes/def/app/app-version';
import { Util } from 'src/app/classes/general/util';
import { LinksDataService } from 'src/app/services/data/links';
import { IListSelectorItem, IListSelectorParams, IListSelectorCategory, IGridSelectorParams } from 'src/app/classes/def/dynamic/list';
import { IGenericEntry } from 'src/app/classes/def/app/entry';
import { ListSelectorViewComponent } from 'src/app/modals/generic/modals/list-selector/list-selector.component';
import { GridSelectorViewComponent } from 'src/app/modals/generic/modals/grid-selector/grid-selector.component';

import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { ITutorial, ETutorialEntries } from 'src/app/classes/def/app/tutorials';
import { AppFlags } from 'src/app/classes/def/app/storage-flags';
import { StorageOpsService } from 'src/app/services/general/data/storage-ops';
import { LocationManagerService } from 'src/app/services/map/location-manager';
import { IDBModelCountry } from 'src/app/classes/def/world/world';
import { TutorialsService } from './tutorials';
import { EDescriptionViewStyle, IDescriptionFrameParams } from 'src/app/modals/generic/modals/description-frame/description-frame.component';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { INavParams } from 'src/app/classes/def/nav-params/general';
import { IAmountNavParams } from 'src/app/classes/def/nav-params/inventory';
import { AmountInputViewComponent } from 'src/app/modals/generic/modals/amount-input/amount-input.component';
import { IAPCoreService } from 'src/app/services/apis/iap-rc';
import { IDescriptionFrameResult } from 'src/app/modals/generic/modals/description-frame-extra/description-frame-extra.component';
import { ILatLng } from 'src/app/classes/def/map/coords';

export interface IPopupSkipResult {
    skip: boolean;
    comment: string;
}

@Injectable({
    providedIn: 'root'
})
export class PopupFeaturesService {

    platform: IPlatformFlags = {} as IPlatformFlags;
    isRewardPopupActive: boolean = false;

    constructor(
        public uiext: UiExtensionService,
        public uiextStandard: UiExtensionStandardService,
        public analytics: AnalyticsService,
        public misc: MiscDataService,
        public newsfeed: NewsfeedDataService,
        public dataProvider: StoryDataService,
        public resources: ResourcesCoreDataService,
        public settingsProvider: SettingsManagerService,
        public appVersion: AppVersion,
        public market: Market,
        public userStats: UserStatsDataService,
        public userData: UserDataService,
        public achievements: AchievementsDataService,
        public gmapModals: GmapModalsService,
        public iapService: IAPCoreService,
        public adService: AdsService,
        public links: LinksDataService,
        public storageOps: StorageOpsService,
        public locationManager: LocationManagerService,
        public tutorials: TutorialsService
    ) {
        console.log("popup features service created");
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    /**
     * request referral code
     * that will return an LP bonus if another user signs up with the code
     */
    getReferralCode() {
        this.misc.requestReferralCode().then((resp: IGenericResponse) => {
            let rd: IGetReferralCode = resp.data;

            //  <input class="text-box info-text-lg bold" type="text" value=" ` + rd.code + `" readonly>
            let description: string = `
                <p>Your referral code is:</p>
                <p class="margin-top-s"> 
                   <span class="text-center info-text-lg bold">` + rd.code + `</span>
                </p>
                <p class="margin-top-s">You will receive a reward when your friends sign up with this code</p>`;
            this.uiextStandard.showStandardModal(null, EModalTypes.description, "Referral program", {
                view: {
                    fullScreen: false,
                    transparent: false,
                    large: false,
                    addToStack: true,
                    frame: false
                },
                params: { description: description }
            }).then(() => {
                // this.copyToClipboard(rd.code);
            }).catch((err: Error) => {
                console.error(err);
            });

            // this.uiext.showAlertNoAction("Referral program", 
            // `<ion-item class="transparent">
            // <ion-label stacked class="text-color">Your referral code is:</ion-label>
            // <ion-input [(ngModel)]="` + rd.code + `"class="text-color">
            // </ion-input>
            // </ion-item>
            // <p>You will receive a reward when your friends sign up with this code</p>` );
        }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "popup-features");
        });
    }

    copyToClipboard(text: string) {
        const el = document.createElement('textarea');
        el.value = text;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    /**
     * check if another user used the referral code
     * (and you won the prize)
     */
    checkReferralCodeValidatedCore(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            this.misc.checkReferralCodeValidated().then((rd: ICheckReferralCode) => {
                if (rd.validated) {
                    this.uiext.showAlert(Messages.msg.referralCodeValidated.after.msg, rd.message, 1, null).then(() => {
                        resolve(true);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                } else {
                    resolve(false);
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * check inbox (latest unseen news in the newsfeed)
     */
    checkInbox() {
        this.checkInboxCore(true).then(() => {

        }).catch(() => {

        });
    }


    /**
     * watch video ad
     * show loading
     * check if auto ads enabled
     * @param checkGlobalAdsEnabled check if auto ads enabled
     */
    watchAdResolve(checkGlobalAdsEnabled: boolean) {
        let promise = new Promise((resolve) => {
            let adFn: () => Promise<boolean> = () => {
                return new Promise((resolve) => {
                    this.adService.showRewardVideo(true, false).then((reward: boolean) => {
                        resolve(reward);
                    }).catch((err: Error) => {
                        console.error(err);
                        resolve(false);
                    });
                });
            };

            let promiseAd: Promise<boolean>;
            if (checkGlobalAdsEnabled) {
                // check autoAds enabled globally
                let autoAds: boolean = false;
                if (GeneralCache.os === EOS.ios) {
                    autoAds = AppConstants.gameConfig.autoAdsIOS === 1;
                } else {
                    autoAds = AppConstants.gameConfig.autoAds === 1;
                }
                if (autoAds) {
                    // also check user flag if user has opted to remove ads via iap
                    if (this.userData.checkAdsDisabledFlag()) {
                        promiseAd = Promise.resolve(false);
                    } else {
                        promiseAd = adFn();
                    }
                } else {
                    promiseAd = Promise.resolve(false);
                }
            } else {
                promiseAd = adFn();
            }

            promiseAd.then((data) => {
                resolve(data);
            });
        });
        return promise;
    }

    checkInboxCore(popup: boolean) {
        let promise = new Promise((resolve, reject) => {
            this.newsfeed.checkGlobalInbox().then((rd: INewsfeedInbox) => {
                if (rd.count > 0) {
                    if (popup) {
                        this.uiext.showAlert(Messages.msg.newInbox.after.msg, rd.message, 1, null).then((res: number) => {
                            switch (res) {
                                case EAlertButtonCodes.ok:
                                    resolve(true);
                                    break;
                                default:
                                    resolve(false);
                                    break;
                            }
                        }).catch((err: Error) => {
                            reject(err);
                        });
                    } else {
                        resolve(rd.count);
                    }
                } else {
                    resolve(0);
                }
            }).catch((err: Error) => {
                console.error(err);
                this.analytics.dispatchError(err, "popup-features");
                reject(err);
            });
        });
        return promise;
    }

    /**
     * load iap details for stories
     * @param reload 
     */
    loadIapDetails(stories: IStory[], reload: boolean, showLoading: boolean) {
        return new Promise((resolve, reject) => {
            // check products loading required
            let promiseCheckLoading: Promise<boolean> = new Promise(async (resolve) => {
                let check = IAPUtils.checkItemDataMulti(stories, reload, false);
                if (!showLoading) {
                    resolve(false);
                } else {
                    if (check.checkItems.length > 0) {
                        await this.uiext.showLoadingV2Queue("Loading products..");
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }
            });

            promiseCheckLoading.then((loading: boolean) => {
                this.iapService.retrieveProductList(stories, reload, false, false).then(async () => {
                    console.log("iap products loaded: ", stories);
                    if (loading) {
                        await this.uiext.dismissLoadingV2();
                    }
                    resolve(true);
                }).catch(async (err: Error) => {
                    console.error(err);
                    this.analytics.dispatchError(err, "popup-features");
                    if (loading) {
                        await this.uiext.dismissLoadingV2();
                    }
                    reject(err);
                });
            });
        });
    }

    /**
     * open story
     * check if the story is locked 
     * check if the story has to be unlocked with coins and proceed to unlock with coins
     * either way show the story description and photo, so that the user knows what he will get
     * the buy option may be disabled (e.g. when the user level is too low)
     */
    openStoryUnlock(story: IStory, masterLockData: IMasterLockResponse, fromStoryList: boolean, coords: ILatLng, prefillToken: string) {
        let promise = new Promise(async (resolve, reject) => {
            let resp: IMasterLockResponse = {
                unlocked: false,
                message: "Locked",
                code: EStoryUnlockedCode.lockedLevel,
                aux: {
                    reload: false,
                    alert: true
                }
            };

            if (!story) {
                reject(new Error("undefined story"));
                return;
            }

            if (story.itemIapCode != null && !story.itemIap) {
                reject(new Error("Missing IAP details"));
                return;
            }

            if (story.itemIap) {
                if (!story.itemIap.iapLoaded) {
                    if (fromStoryList) {
                        // wait until the IAP details are loaded via story list and try again
                        reject(new Error("IAP data loading in progress"));
                        return;
                    } else {
                        // load iap details (accurate pricing, etc)
                        // will actually preload all products (if not previously loaded)
                        // use case for map opened / tester mode
                        try {
                            await this.loadIapDetails([story], false, true);
                        } catch (err) {
                            reject(err);
                            return;
                        }
                    }
                }
            }

            let checkUnlockedPromise: Promise<IMasterLockResponse>;

            let links: IEventStoryGroupLinkData = this.links.getLinkData();

            // show story details with option to buy (if the user level is high enough)
            let processStoryBuyOptions = () => {
                let params: IStoryBuyNavParams = {
                    storyExt: story,
                    masterLockData: masterLockData,
                    links: links,
                    prefillToken: prefillToken
                };
                this.uiext.showCustomModal(null, StoryBuyOptionsViewComponent, {
                    view: {
                        fullScreen: false,
                        transparent: false,
                        large: true,
                        addToStack: true,
                        frame: false
                    },
                    params: params
                }).then((res: IItemAction) => {
                    console.log("story buy options resolved: ", res);
                    if (res) {
                        switch (res.code) {
                            case EItemActions.buy:
                                resp.unlocked = true;
                                resp.message = "Unlocked";
                                resp.aux.reload = true;
                                resolve(resp);
                                break;
                            default:
                                // do not show another popup
                                resp.aux.alert = false;
                                resolve(resp);
                                break;
                        }
                    } else {
                        // do not show another popup
                        resp.aux.alert = false;
                        resolve(resp);
                    }
                }).catch((err: Error) => {
                    reject(err);
                });
            };

            // check the master lock
            // if the story is blank, then it's also a sign that the content is locked (e.g. premium content not owned by the user)
            if (!story.locked && (story.locations != null)) {
                resp.unlocked = true;
                resp.message = "Unlocked";
                checkUnlockedPromise = Promise.resolve(resp);
            } else {
                if (!masterLockData) {
                    checkUnlockedPromise = new Promise((resolve) => {
                        this.dataProvider.checkStoryUnlocked(story.id, coords).then((data: IMasterLockResponse) => {
                            if (data) {
                                resp.unlocked = data.unlocked;
                                resp.message = data.message;
                                resp.code = data.code;
                            }
                            // local resolve
                            resolve(resp);
                        }).catch(() => {
                            // error occured, treat as locked
                            resp.unlocked = false;
                            resp.message = "This story is locked at the moment";
                            // local resolve
                            resolve(resp);
                        });
                    });
                } else {
                    resp.unlocked = masterLockData.unlocked;
                    resp.message = masterLockData.message;
                    resp.code = masterLockData.code;
                    checkUnlockedPromise = Promise.resolve(resp);
                }
            }

            // actually begin the unlock sequence
            checkUnlockedPromise.then((resp1: IMasterLockResponse) => {
                console.log("master lock data: ", resp1);
                if (resp1.unlocked) {
                    resp.unlocked = true;
                    resp.message = "Unlocked";
                    resolve(resp);
                } else {
                    processStoryBuyOptions();
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * check service available
     * resolve only
     * @param serviceCode 
     */
    checkServiceAvailable(serviceCode: number): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            this.resources.checkAvailableService(serviceCode).then((available: boolean) => {
                if (!available) {
                    this.uiext.showAlert(Messages.msg.serviceNotAvailable.after.msg, Messages.msg.serviceNotAvailable.after.sub, 1, null, true).then(() => {
                        resolve(false);
                    }).catch((err: Error) => {
                        console.error(err);
                        resolve(false);
                    });
                } else {
                    resolve(true);
                }
            }).catch(() => {
                this.uiext.showAlert(Messages.msg.serviceNotAvailable.after.msg, Messages.msg.serviceNotAvailable.after.sub, 1, null, true).then(() => {
                    resolve(false);
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(false);
                });
            });
        });
        return promise;
    }

    /**
     * update app
     */
    userPromptUpdateApp(appVersion: IAppVersionDB, mandatory: boolean) {
        let promise = new Promise((resolve, reject) => {
            if (!this.platform.WEB) {
                this.uiext.showAlert("We have important updates", "Please update the app from the store", 2, null).then((res: number) => {
                    if (res === EAlertButtonCodes.ok) {
                        this.goToAppPageCore(appVersion).then(() => {
                            resolve(true);
                        }).catch((err: Error) => {
                            reject(err);
                        });
                    } else {
                        if (mandatory) {
                            this.uiext.showAlert("Update required",
                                "<p>Your app version is too old</p><p>Some features might not work as expected</p>", 2, ["ignore", "update"]).then((res: number) => {
                                    if (res === EAlertButtonCodes.ok) {
                                        this.goToAppPageCore(appVersion).then(() => {
                                            resolve(true);
                                        }).catch((err: Error) => {
                                            reject(err);
                                        });
                                    } else {
                                        resolve(false);
                                    }
                                }).catch((err: Error) => {
                                    reject(err);
                                });
                        } else {
                            resolve(false);
                        }
                    }
                }).catch((err: Error) => {
                    console.error(err);
                    reject(err);
                });
            } else {
                this.uiext.showAlert("We have important updates", "Please update the app from the store", 2, null).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    reject(err);
                });
            }
        });
        return promise;
    }

    /**
     * check if rating is available for the user country (published on the local app store)
     * @returns 
     */
    userPromptRateAppCheckAvailableResolve() {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            let checkAvailable: boolean = false;
            try {
                let coords: ILatLng = await this.locationManager.getCurrentLocationWrapper(true, true, true);
                let country: IDBModelCountry = await this.userData.checkRatingAvailable(coords.lat, coords.lng);
                if (GeneralCache.os === EOS.android && country.enableStoreAndroid === 1) {
                    checkAvailable = true;
                }
                if (GeneralCache.os === EOS.ios && country.enableStoreIos === 1) {
                    checkAvailable = true;
                }
            } catch (err) {
                console.error(err);
            }
            resolve(checkAvailable);
        });
        return promise;
    }

    /**
     * request app rating
     * update local flags
     * @returns 
     */
    userPromptRateApp(): Promise<number> {
        let promise: Promise<number> = new Promise(async (resolve, reject) => {
            try {
                // allow rating for mobile context, excluding testflight distribution
                if (!this.platform.WEB && GeneralCache.isPublicDistribution) {
                    let actions: IPopoverActions = {
                        ok: {
                            name: "Rate 5 stars",
                            code: 0,
                            enabled: true
                        },
                        later: {
                            name: "Remind me later",
                            code: 1,
                            enabled: true
                        },
                        no: {
                            name: "No, thanks",
                            code: 2,
                            enabled: true
                        }
                    };

                    let tutorial: ITutorial = await this.resources.getInfoData(ETutorialEntries.rating, true);
                    let input: number = await this.uiext.showCustomAlert(tutorial.title, tutorial.description, actions);

                    switch (input) {
                        case 0:
                            await this.goToAppPageCore(null);
                            AppFlags.generalFlags.appRate.value = false; // stop asking for rating
                            break;
                        case 1:
                            AppFlags.generalFlags.appRate.value = true; // ask for rating
                            AppFlags.generalFlags.openCounter.value = 0; // later
                            break;
                        case 2:
                        default:
                            AppFlags.generalFlags.appRate.value = false; // stop asking for rating
                            break;
                    }

                    this.storageOps.setStorageFlagNoAction(AppFlags.generalFlags.openCounter); // update open counter
                    this.storageOps.setStorageFlagNoAction(AppFlags.generalFlags.appRate); // update flags with user input

                    resolve(input);
                } else {
                    resolve(null);
                }
            } catch (err) {
                reject(err);
            }
        });
        return promise;
    }


    /**
     * open the app page for update/rating
     */
    goToAppPageCore(appVersion: IAppVersionDB) {
        let promise = new Promise((resolve, reject) => {
            if (!appVersion) {
                appVersion = GeneralCache.appDetails;
            }
            if (!appVersion) {
                this.appVersion.getPackageName().then((packageName: string) => {
                    this.market.open(packageName).then(() => {
                        resolve(true);
                    }).catch((err: Error) => {
                        console.error(err);
                        reject(err);
                    });
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                if (GeneralCache.os === EOS.ios || GeneralCache.os === EOS.android) {
                    let packageName: string = appVersion.package;
                    console.log("opening app page: " + packageName);
                    this.market.open(packageName).then(() => {
                        resolve(true);
                    }).catch((err: Error) => {
                        console.error(err);
                        reject(err);
                    });
                } else {
                    if (appVersion.store) {
                        Util.openURLExt(appVersion.store);
                    }
                    resolve(true);
                }
            }
        });
        return promise;
    }

    checkLoginCode(code: number, message: string) {
        let emailValidationPrompt: boolean = false;
        switch (code) {
            case ECheckLoginResponse.emailNotValidated:
                if (emailValidationPrompt) {
                    // show option to resend validation email
                    this.uiext.showAlert(Messages.msg.info.after.msg, ErrorMessage.parse(message), 2, ["ok", "resend"]).then((res: number) => {
                        switch (res) {
                            case EAlertButtonCodes.ok:
                                // request resend email
                                this.misc.requestNewValidationEmail(null).then((_response: IGenericResponse) => {
                                    this.uiext.showAlertNoAction(Messages.msg.requestNewValidationEmail.after.msg, Messages.msg.requestNewValidationEmail.after.sub);
                                }).catch((err: Error) => {
                                    this.analytics.dispatchError(err, "popup-features");
                                    this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
                                });
                                break;
                            case EAlertButtonCodes.cancel:

                                break;
                            default:
                                break;
                        }
                    });
                }
                break;
            default:
                this.uiext.showAlertNoAction(Messages.msg.info.after.msg, ErrorMessage.parse(message));
                break;
        }
    }

    openCustomListSelector(title: string, items: IListSelectorItem[]): Promise<IGenericEntry> {
        return new Promise<IGenericEntry>((resolve, reject) => {
            console.log("open custom selector");
            let params: IListSelectorParams = {
                title: title,
                componentType: "cardItem",
                listItems: items,
                direct: true,
                customSelect: true,
                spaceNoBorder: true
            };
            this.uiext.showCustomModal(null, ListSelectorViewComponent, {
                view: {
                    fullScreen: false,
                    transparent: AppConstants.transparentMenus,
                    large: true,
                    addToStack: true,
                    frame: false
                },
                params: params
            }).then((res: IListSelectorItem) => {
                console.log(res);
                if (res) {
                    let ge: IGenericEntry = res.item;
                    resolve(ge);
                } else {
                    resolve(null);
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
    }

    openCustomGridSelector(title: string, items: IListSelectorCategory[], _small: boolean): Promise<IGenericEntry> {
        return new Promise<IGenericEntry>((resolve, reject) => {
            console.log("open custom selector");
            let params: IGridSelectorParams = {
                title: title,
                componentType: "cardItem",
                listItems: items,
                direct: true,
                customSelect: true
            };
            this.uiext.showCustomModal(null, GridSelectorViewComponent, {
                view: {
                    fullScreen: false,
                    transparent: AppConstants.transparentMenus,
                    large: true,
                    addToStack: true,
                    frame: false
                },
                params: params
            }).then((res: IListSelectorItem) => {
                console.log(res);
                if (res) {
                    let ge: IGenericEntry = res.item;
                    resolve(ge);
                } else {
                    resolve(null);
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
    }

    /**
     * show skip modal warning w/ graphics
     * @param isPreloadStory 
     * @returns 
     */
    showSkipModal(isPreloadStory: boolean) {
        return new Promise<IPopupSkipResult>((resolve) => {
            let params: IDescriptionFrameParams = {
                title: null, // use loaded
                description: null,
                mode: EDescriptionViewStyle.withCustomButtonArray,
                photoUrl: null,
                loaderCode: isPreloadStory ? ETutorialEntries.skipNonlinear : ETutorialEntries.skipLinear,
                buttons: [{
                    // name: "Dismiss",
                    name: null,
                    icon: EAppIconsStandard.reject,
                    class: "action-button-fill button-color-warn",
                    enabled: true,
                    size: 6,
                    callback: null,
                    highlight: false,
                    disabled: false,
                    code: EAlertButtonCodes.cancel
                }, {
                    // name: "Proceed",
                    name: null,
                    icon: EAppIconsStandard.skipForward,
                    class: "action-button-fill button-color-accent",
                    enabled: true,
                    size: 6,
                    callback: null,
                    highlight: false,
                    disabled: false,
                    code: EAlertButtonCodes.ok
                }],
                withInput: true,
                inputLabel: "Let us know your feedback on this checkpoint"
            };
            this.tutorials.showTutorialResolve(null, null, null, params, false).then((res: IDescriptionFrameResult) => {
                let skipResult: IPopupSkipResult = {
                    skip: false,
                    comment: ""
                };
                if (res != null) {
                    skipResult.skip = res.code === EAlertButtonCodes.ok;
                    skipResult.comment = res.inputContent;
                }
                resolve(skipResult);
            });
        });
    }

    openNumericInputSelector(title: string, min: number, max: number, val: number) {
        return new Promise((resolve) => {
            let params: IAmountNavParams = {
                title: title,
                description: null,
                min: min,
                max: max,
                current: val
            };

            let navParams: INavParams = {
                view: {
                    fullScreen: false,
                    transparent: false,
                    large: false,
                    addToStack: false,
                    frame: false
                },
                params: params
            };

            this.uiext.showCustomModal(null, AmountInputViewComponent, navParams).then((val: number) => {
                resolve(val);
            }).catch((err: Error) => {
                console.error(err);
                resolve(null);
            });
        });
    }
}
