import { EOS } from "../def/app/app";
import { ESkillCategory } from "../def/user/skills";
import { EItemCategoryCodes } from "../def/items/game-item";
import { ENewsfeedCategory } from "../def/newsfeed/newsfeed";
import { IAppResources } from '../def/app/resources';
import { IAppVersionDB } from '../def/app/app-version';
import { IDiagnosticStatus } from "src/app/services/general/app-diagnostic.utils";

export class GeneralCache {

    public static resourceCache: IAppResources;

    public static userId: number = 0;
    public static isOnline: boolean = false;
    public static appLoaded: boolean = false;
    public static iapLoaded: boolean = false;

    public static versionString: string = "1.0.0";

    /**
     * the version code is used for http requests
     * enable features based on the defined availability in the current app version
     * IOS/Android different format, should be handled by server using also OS spec
     */
    public static versionCode: number = null;
    public static codePushLabel: string = null;

    // keep selected tabs e.g. inventory, newsfeed, metrics
    public static inventorySelectedCategory: number = EItemCategoryCodes.items;
    public static newsfeedSelectedCategory: number = ENewsfeedCategory.global;
    public static metricsSelectedCategory: number = ESkillCategory.general;

    public static homePageActiveMode: number = null;

    /**
     * app paused/active
     */
    public static paused: boolean = false;
    public static pausedTimestamp: number = null;

    /**
     * check app resumed in home page (then reset flag)
     */
    public static appResumedFlag: boolean = false;

    // show warning the first time map is opened, each time the app is opened
    public static warningShown: boolean = false;

    /**
     * db user: tester = {0, 1}
     */
    public static canBeTester: boolean = false;
    public static agreementPending: boolean = false;

    public static os: number = EOS.android;
    public static isIOSWebClient: boolean = false;

    public static isWeb: boolean = false;
    public static isDev: boolean = false;

    public static shiftView: boolean = false;

    public static codePushReload: boolean = false;

    public static appDetails: IAppVersionDB = null;

    public static storylineMapOpened: boolean = false;
    public static externalLogin: boolean = false;
    public static adShown: boolean = false;
    public static justLoggedIn: boolean = false;
    public static tboot: number = 0;

    public static locationChecked: boolean = false;

    /** use WebGL API (vector maps, dynamic maps) */
    public static useWebGL: boolean = false;
    /** use WebGL API on mobile too */
    public static useWebGLMobile: boolean = false;

    public static enablePopupInputMode: boolean = false;

    public static isPublicDistribution: boolean = true;
    public static isPWA: boolean = false;

    public static enforceBackgroundLocationPermissionRequestOnAndroid: boolean = false;

    public static firstOpen = {
        home: false
    };

    public static appFlags: IGeneralAppFlags = {
        locationPermissionsGranted: false,
        locationPermissionsAlwaysGranted: false,
        notificationPermissionsGranted: false,
        bgModeEnabled: false,
        bgModeActiveState: false,
        appPaused: false,
        locationFallbackActive: false,
        os: null,
        isWeb: false,
        isPWA: false,
        telemetry: false,
        diagnosticStatus: null
    };

    /**
     * return os (mobile context)
     * handle web context - check os via browser
     */
    public static checkPlatformOS() {
        if (GeneralCache.isWeb) {
            if (GeneralCache.isIOSWebClient) {
                return EOS.ios;
            } else {
                return EOS.android;
            }
        } else {
            return GeneralCache.os;
        }
    }

    /**
     * return os (mobile context)
     * handle web context - check os via browser
     * handle pwa context - use generic os if not pwa
     */
    public static checkPlatformOSWithPWA() {
        if (GeneralCache.isWeb && !GeneralCache.isPWA) {
            return GeneralCache.os;
        }
        return GeneralCache.checkPlatformOS();
    }

    constructor() {
    }
}

export interface IGeneralAppFlags {
    locationPermissionsGranted: boolean;
    locationPermissionsAlwaysGranted: boolean;
    notificationPermissionsGranted: boolean;
    bgModeActiveState: boolean;
    bgModeEnabled: boolean;
    appPaused: boolean;
    locationFallbackActive: boolean;
    os: number;
    isWeb: boolean;
    isPWA: boolean;
    telemetry: boolean;
    diagnosticStatus: IDiagnosticStatus
}
