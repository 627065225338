import { Component, OnInit, OnDestroy } from '@angular/core';
import { IButtonOptions, EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { ParamHandler } from 'src/app/classes/general/params';
import { ITutorial } from 'src/app/classes/def/app/tutorials';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { EAudioGuide } from 'src/app/classes/def/core/interactive-features';
import { StringUtils } from 'src/app/services/app/utils/string-utils';
import { EDescriptionViewStyle, IDescriptionFrameParams } from '../description-frame/description-frame.component';


export interface IDescriptionFrameResult {
  code: number;
  inputContent?: string;
}

@Component({
  selector: 'modal-description-frame-extra',
  templateUrl: './description-frame-extra.component.html',
  styleUrls: ['./description-frame-extra.component.scss'],
})
export class DescriptionFrameExtraViewComponent implements OnInit, OnDestroy {
  description: string = "";
  descriptionFooter: string = "";
  descriptionHeader: string = "";

  withInput: boolean = false;
  inputLabel: string = "";
  inputContent: string = "";

  title: string = "";
  heading: string = "";
  selectAll: boolean = false;
  mode: number = EDescriptionViewStyle.plain;
  modes = EDescriptionViewStyle;

  wrapperClass: string = "";
  innerClass: string = "";
  buttonBar: string = "";

  loaderCode: number = null;
  videoLoaderCode: number = null;

  loading: boolean = false;

  photoUrl: string = null;
  videoUrl: string = null;
  vs: IViewSpecs = ViewSpecs.getDefault();

  params: IDescriptionFrameParams = null;
  noButtons: boolean = true;

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  np: INavParams = null;

  customButton: IButtonOptions = {
    name: null,
    code: null,
    icon: EAppIcons.film,
    customIcon: true,
    class: null,
    size: null,
    disabled: false,
    enabled: true,
    highlight: null,
    callback: null
  };

  buttons: IButtonOptions[] = null;
  fromCache: boolean = true;
  extPhoto: boolean = false;
  cooldown: number = null;
  autoClose: boolean = false;

  withAudioGuide: boolean = false;
  audioGuideAutoplay: boolean = false;
  descriptionPlain: string = null;

  constructor(
    public modalCtrl: ModalController,
    public resources: ResourcesCoreDataService,
    public analytics: AnalyticsService
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IDescriptionFrameParams = np.params;
      this.params = params;
      if (np.view) {
        this.vs = np.view;
      }

      console.log(np);

      if (params.heading) {
        this.heading = params.heading;
      }

      if (params.description) {
        this.description = params.description;
        this.descriptionPlain = StringUtils.textFromHTMLPlain(this.description);
      }

      if (params.descriptionHeader) {
        this.descriptionHeader = params.descriptionHeader;
      }

      if (params.descriptionFooter) {
        this.descriptionFooter = params.descriptionFooter;
      }

      if (params.customButton) {
        this.customButton = params.customButton;
      }

      if (params.buttons) {
        this.buttons = params.buttons;
      }

      if (params.cooldown != null) {
        this.cooldown = params.cooldown;
        this.autoClose = true;
      }

      this.withInput = params.withInput;
      this.inputLabel = params.inputLabel;

      this.withAudioGuide = params.audioGuide != null && params.audioGuide !== 0;
      this.audioGuideAutoplay = params.audioGuide != null && params.audioGuide === EAudioGuide.ttsAuto;

      this.loaderCode = params.loaderCode;
      this.videoLoaderCode = params.videoLoaderCode;
      // this.descriptionLines = this.formatText(this.description);
      this.title = params.title;
      this.mode = params.mode;
      this.photoUrl = params.photoUrl;
      this.videoUrl = params.videoUrl;

      if (params.fromCache != null) {
        this.fromCache = params.fromCache;
      }
    }

    this.getClass(this.mode);

    if (this.loaderCode != null) {
      this.load(this.loaderCode, this.fromCache);
    }
    if (this.videoLoaderCode != null) {
      this.loadVideo(this.videoLoaderCode, this.fromCache);
    }
    // console.log(this.wrapperClass);
    // console.log(this.innerClass);
  }

  ngOnDestroy() {

  }

  /**
   * load remote resource (description)
   * @param code 
   */
  loadVideo(code: number, fromCache: boolean) {
    this.resources.getInfoData(code, fromCache).then((resp: ITutorial) => {
      this.videoUrl = resp.photoUrl;
    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "description-frame");
    });
  }


  /**
   * load remote resource (description)
   * @param code 
   */
  load(code: number, fromCache: boolean) {
    this.loading = true;
    this.resources.getInfoData(code, fromCache).then((resp: ITutorial) => {
      this.loading = false;
      if (resp.description) {
        this.description = resp.description;
      }
      if (resp.title) {
        this.title = resp.title;
      }
      this.photoUrl = resp.photoUrl;
    }).catch((err: Error) => {
      this.loading = false;
      this.description = "Description not found";
      this.analytics.dispatchError(err, "description-frame");
    });
  }

  getClass(mode: number) {
    this.noButtons = false;
    switch (mode) {
      case EDescriptionViewStyle.withOk:
      case EDescriptionViewStyle.withCustomButton:
        // this.wrapperClass = "theme-border-top";
        this.wrapperClass = "";
        this.innerClass = "padding-s scrollbar-y scroll-content-modal-1-border-flush";
        // this.buttonBar = "theme-border-top";
        this.buttonBar = "";
        break;
      case EDescriptionViewStyle.withOkDismiss:
        // this.wrapperClass = "theme-border-top";
        this.wrapperClass = "";
        this.innerClass = "padding-s scrollbar-y scroll-content-modal-1-border-flush";
        // this.buttonBar = "theme-border-top";
        this.buttonBar = "";
        break;
      case EDescriptionViewStyle.plain:
      default:
        // this.wrapperClass = "theme-border-top";
        this.wrapperClass = "";
        this.innerClass = "padding-s";
        this.buttonBar = "";
        this.noButtons = true;
        break;
    }
  }

  dismiss(value: IDescriptionFrameResult) {
    if (value == null) {
      value = {
        code: EAlertButtonCodes.cancel
      };
    }
    value.inputContent = this.inputContent;
    setTimeout(() => {
      this.modalCtrl.dismiss(value).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  ok() {
    if (this.params.customButtonFn) {
      // special action
      this.params.customButtonFn();
    } else {
      // dismiss with ok
      this.dismiss({
        code: EAlertButtonCodes.ok
      });
    }
  }

  cancel() {
    this.dismiss({
      code: EAlertButtonCodes.cancel
    });
  }

  customButtonTap(button: IButtonOptions) {
    if (button.callback) {
      button.callback();
    } else {
      this.dismiss({
        code: button.code
      });
    }
  }
}
