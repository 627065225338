import { Component, OnInit, OnDestroy } from '@angular/core';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { ParamHandler } from 'src/app/classes/general/params';
import { Messages } from 'src/app/classes/def/app/messages';
import { IBase64Ext } from 'src/app/classes/def/media/photo';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { ImageUtilsService } from 'src/app/services/media/image-utils';

export interface IPhotoFrameNavParams {
  description?: string;
  title: string;
  photoUrl: string;
  auxPhotoUrls?: string[];
  sharePhoto: boolean;
  isDataUrl: boolean;
  /**
   * add the functionality to change the photo (e.g. profile picture)
   */
  uploadPhoto: boolean;
  expanded?: boolean;
  /**
   * the callback on select photo
   */
  uploadPhotoFn?: () => any;
}

@Component({
  selector: 'modal-photo-frame',
  templateUrl: './photo-frame.component.html',
  styleUrls: ['./photo-frame.component.scss'],
})
export class PhotoFrameViewComponent implements OnInit, OnDestroy {
  description: string = "";
  descriptionLines: string[];
  title: string = "";
  /**
   * file/data URI
   */
  photoUrl: string = null;
  selectAll: boolean = false;

  zoom: boolean = false;
  isDataUrl: boolean = false;
  vs: IViewSpecs = ViewSpecs.getDefault();

  enable = {
    share: false,
    upload: false
  };

  appIconsStandard = EAppIconsStandard;

  withButtons: boolean = false;
  expanded: boolean = false;

  params: IPhotoFrameNavParams = null;

  np: INavParams = null;

  constructor(
    public modalCtrl: ModalController,
    public shareProvider: ShareService,
    public imgUtils: ImageUtilsService,
    public uiext: UiExtensionService
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IPhotoFrameNavParams = np.params;
      this.params = params;

      if (np.view) {
        this.vs = np.view;
      }

      console.log(np);

      this.description = params.description;

      this.title = params.title;
      this.photoUrl = params.photoUrl;
      this.enable.share = params.sharePhoto;
      this.isDataUrl = params.isDataUrl;
      this.enable.upload = params.uploadPhoto;
      this.expanded = params.expanded;

      let keys: string[] = Object.keys(this.enable);

      for (let i = 0; i < keys.length; i++) {
        let key: string = keys[i];
        if (this.enable[key]) {
          this.withButtons = true;
        }
      }
    }
  }

  ngOnDestroy() {

  }

  dismiss() {
    setTimeout(() => {
      this.modalCtrl.dismiss(null).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  sharePhoto() {
    this.shareProvider.sharePhoto(this.title, this.photoUrl).then(() => {

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  uploadPhoto() {
    if (this.params.uploadPhotoFn != null) {
      this.params.uploadPhotoFn();
      this.dismiss();
    }
  }

  savePhoto() {
    if (this.isDataUrl) {

      let title: string = this.title;

      if (title != null) {
        title = title.replace(/\s/g, '').toLowerCase();
      } else {
        title = "image";
      }

      let filename: string = this.title.replace(/\s/g, '').toLowerCase() + "_" + new Date().getTime().toString() + ".jpg";
      let b64data: IBase64Ext = this.imgUtils.formatBase64PhotoContent(this.photoUrl);

      this.imgUtils.saveBase64ImageToFile(filename, b64data.content, true, b64data.contentType).then(() => {
        this.uiext.showAlert(Messages.msg.success.after.msg, "Saved to gallery: " + filename, 1, ["ok"]).then(() => {
          this.dismiss();
        }).catch((err: Error) => {
          console.error(err);
        });
      }).catch((err: Error) => {
        console.error(err);
      });
    } else {
      this.uiext.showAlertNoAction(Messages.msg.error.after.msg, "Media format error");
    }
  }

  zoomIn() {
    this.zoom = true;
  }

  zoomOut() {
    this.zoom = false;
  }
}
