

import { Injectable } from '@angular/core';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS, EQueues } from 'src/app/classes/def/app/app';
import { TimeUtils } from 'src/app/classes/general/time';
import { GenericQueueService } from '../generic-queue';
import { SettingsManagerService } from '../settings-manager';
import { ResourcesCoreDataService } from '../../data/resources-core';
import { ITutorial } from 'src/app/classes/def/app/tutorials';
import { BackgroundModeWatchService } from './background-mode-watch';
import { SleepUtils } from '../../utils/sleep-utils';
import { BackgroundModeService } from './background-mode';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { SoundUtils } from './sound-utils';
import { BehaviorSubject } from 'rxjs';
import { WaitUtils } from '../../utils/wait-utils';
import { ApiDef } from 'src/app/classes/app/api';
import { IDBModelLanguage } from 'src/app/classes/def/core/story';
import { Messages } from 'src/app/classes/def/app/messages';
import { TextToSpeech, TTSOptions } from '@capacitor-community/text-to-speech';
import { PromiseUtils } from '../../utils/promise-utils';

var synth = window.speechSynthesis;

interface ITTSPlatformSetup {
    ios: ITTSPlatformOption;
    android: ITTSPlatformOption;
    web: ITTSPlatformOption;
}
interface ITTSPlatformOption {
    enable: boolean;
    app: number;
    content: number;
}

interface ITTSContextOptions {
    app: ITTSContextOption,
    content: ITTSContextOption
}

interface ITTSContextOption {
    native: TTSOptions,
    browser: SpeechSynthesisVoice,
    cloud: ITTSVoice
}

enum ETTSMode {
    native = 1,
    browser = 2,
    cloud = 3
}

export interface ITTSVoice {
    name: string;
    uri?: string;
    local?: boolean;
    lang?: string;
    voice?: string;
}

@Injectable({
    providedIn: 'root'
})
export class TextToSpeechService {

    lastTimeSpeechOutput: string = "";
    enabled: boolean = true;
    tutorialsPlayed: number[] = [];


    voiceOptions: ITTSContextOptions = {
        app: {
            native: null,
            browser: null,
            cloud: null
        },
        content: {
            native: null,
            browser: null,
            cloud: null
        }
    };

    observable = {
        locked: null,
        ttsReady: null,
        audioEvent: null
    };

    subscription = {
        locked: null,
        ttsReady: null
    };

    locked: boolean = false;

    wakeLock: boolean = true;
    ttsReady: boolean = true;

    platformSetup: ITTSPlatformSetup = {
        ios: {
            enable: true,
            app: ETTSMode.native, // local voice is good
            content: ETTSMode.cloud
        },
        android: {
            enable: true,
            app: ETTSMode.native, // local voice is so and so
            content: ETTSMode.cloud
        },
        web: {
            enable: true,
            app: ETTSMode.cloud, // local voice can be terrible
            content: ETTSMode.cloud
        }
    }

    first: boolean = true;
    speechTimeout = null;
    soundPlaying: boolean = false;
    soundPreloaded: boolean = false;

    speechInProgress: boolean = false;

    private audioElement: HTMLAudioElement;
    private lastPlayedText: string = '';

    keepAwakeTimeout: number = 10000;
    keepAwakeTs: number = null;

    constructor(
        public genericQueue: GenericQueueService,
        public resources: ResourcesCoreDataService,
        public backgroundModeWatch: BackgroundModeWatchService,
        public settingsProvider: SettingsManagerService,
        public bgmService: BackgroundModeService,
        public nativeAudio: NativeAudio
    ) {
        console.log("tts service created");
        this.observable = ResourceManager.initBsubObj(this.observable);
        this.audioElement = new Audio();
        // this.audioElement
        this.initAudioHandlers();
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.watchBackgroundMode();
                this.initDefaultTTSOptions();
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    initAudioHandlers() {
        this.audioElement.addEventListener('play', () => { this.observable.audioEvent.next(true); this.observable.audioEvent.next(null); });
        this.audioElement.addEventListener('ended', () => { this.observable.audioEvent.next(false); this.observable.audioEvent.next(null); });
        this.audioElement.addEventListener('pause', () => { this.observable.audioEvent.next(false); this.observable.audioEvent.next(null); });
    }

    getAudioEventObservable(): BehaviorSubject<boolean> {
        return this.observable.audioEvent;
    }

    synthesizeAndPlay(content: string, volume: number, isContent: boolean) {
        return new Promise(async (resolve) => {
            console.log("synthesize tts: ", content);
            let po = this.getPlatformOption();
            let vo = isContent ? this.voiceOptions.content : this.voiceOptions.app;
            await this.speakCloud(content, volume, null, vo.cloud);
            resolve(true);
        });
    }

    playAudio(volume: number) {
        this.audioElement.volume = volume != null ? volume : 1;
        this.audioElement.play().then(() => {
            console.log("audio element play resolved"); // on loaded
        }).catch((err: Error) => {
            console.error(err);
        });
    }

    stopAudio() {
        this.audioElement.pause();
        this.audioElement.currentTime = 0; // Reset audio to the start
        this.observable.audioEvent.next(false); this.observable.audioEvent.next(null);
    }

    /**
     * wait until tts is unlocked (some time after entering bg mode)
     */
    waitUnlocked() {
        let promise = new Promise((resolve) => {
            if (!this.locked) {
                resolve(true);
                return;
            }
            console.log("wait unlocked");
            this.subscription.locked = this.observable.locked.subscribe((locked: boolean) => {
                if (locked === false) {
                    this.subscription.locked = ResourceManager.clearSub(this.subscription.locked);
                    resolve(true);
                }
            });
        });
        return promise;
    }

    /**
     * wait until tts is ready (not playing sound)
     */
    waitTTSReady() {
        let promise = new Promise((resolve) => {
            if (this.ttsReady) {
                resolve(true);
                return;
            }
            this.subscription.ttsReady = this.observable.ttsReady.subscribe((ready: boolean) => {
                if (ready) {
                    this.subscription.ttsReady = ResourceManager.clearSub(this.subscription.ttsReady);
                    resolve(true);
                }
            });
        });
        return promise;
    }

    watchBackgroundMode() {
        this.backgroundModeWatch.getBackgroundWatch().subscribe((paused: boolean) => {
            if (paused != null) {
                if (paused) {
                    this.locked = true;
                    this.observable.locked.next(true);
                    setTimeout(() => {
                        this.locked = false;
                        this.observable.locked.next(false);
                    }, 3000);
                } else {
                    this.locked = false;
                    this.observable.locked.next(false);
                }
            }
        }, (err: Error) => {
            console.error(err);
        });

        this.backgroundModeWatch.getBackgroundWatchTimer().subscribe((ok: boolean) => {
            if (ok) {
                if (this.wakeLock) {
                    let ts: number = new Date().getTime();
                    if (this.keepAwakeTs == null || ((ts - this.keepAwakeTs) >= this.keepAwakeTimeout)) {
                        this.keepAwakeTs = ts;
                        this.keepAwake();
                    }
                }
            }
        });
    }

    /**
     * trick to keep the app alive in the background
     * because using audio seems to break the background mode plugin
     * functionality is replaced by this
     */
    keepAwake() {
        if (this.genericQueue.checkSize(EQueues.tts) === 0) {
            if (GeneralCache.os === EOS.ios) {
                // iOS no longer tricked by empty sound, suspends app in background
                // this.textToSpeechQueue(null, true);
                this.textToSpeechQueue("The app is running in background mode", false, 0.01, null, null, false);
                // this.textToSpeechQueue("                                     ", false);
            } else {
                // this.textToSpeechQueue(" ", false, 0.01, null, null, false, true, false);

                // this.textToSpeechQueue("The app is running in background mode", false, 0.01, null, null, false, true, false);
                this.textToSpeechQueue(Messages.tts.bgModeDefault, false, 0.01, null, null, false);

                // this.textToSpeechQueue(" ", false, 1, null, null, false, true, false);
            }
        }
    }

    disableWakeLock(waitReady: boolean): Promise<boolean> {
        return new Promise((resolve) => {
            // disable tts playing in the bg
            this.wakeLock = false;
            if (waitReady) {
                // wait until the current tts has completed
                this.waitTTSReady().then(async () => {
                    if (this.bgmService.isBgModeEnabled()) {
                        await SleepUtils.sleep(100);
                    }
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }

    resumeWakeLock() {
        this.wakeLock = true;
    }

    playSoundHack(id: string) {
        return new Promise<boolean>(async (resolve) => {
            try {
                if (this.soundPlaying) {
                    resolve(false);
                    return;
                }
                this.soundPlaying = true;

                // let audio: HTMLAudioElement = new Audio();

                // audio.preload = "auto";
                // audio.autoplay = false;

                // audio.src = SoundUtils.soundBank[id].path;

                // // audio.onload = () => {
                // //     audio.play().then(() => {
                // //         console.log("audio started");
                // //     }).catch((err) => {
                // //         console.error(err);
                // //     });
                // // };

                // audio.onerror = (err) => {
                //     console.error(err);
                //     this.soundPlaying = false;
                //     resolve(false);
                // };
                // audio.onended = () => {
                //     setTimeout(() => {
                //         this.soundPlaying = false;
                //         resolve(true);
                //     }, 100);
                // };

                // audio.play().then(() => {
                //     console.log("audio started");
                // }).catch((err) => {
                //     console.error(err);
                //     this.soundPlaying = false;
                //     resolve(false);
                // });

                if (!this.soundPreloaded) {
                    // await this.nativeAudio.preloadComplex(id, SoundUtils.soundBank[id].path, 1, 1, 0);
                    await this.nativeAudio.preloadSimple(id, SoundUtils.soundBank[id].path);
                }
                this.soundPreloaded = true;
                console.log("play audio");
                this.nativeAudio.play(id, () => {
                    console.log("play audio completed");
                    this.soundPlaying = false;
                    resolve(true);
                }).then(() => {
                    // resolves after starting audio?
                    console.log("play audio started");
                }).catch((err) => {
                    console.error(err);
                    this.soundPlaying = false;
                    resolve(false);
                });
            } catch (err) {
                console.error(err);
                this.soundPlaying = false;
                resolve(false);
            }
        });
    }


    startStopTTSQueue(text: string, soundHack: boolean, volume: number, onComplete: () => any, timeout: number) {
        if (this.speechInProgress) {
            this.stop(false);
        } else {
            this.textToSpeechQueue(text, soundHack, volume, onComplete, timeout, false);
        }
    }


    /**
     * use cloud TTS
     * https://cloud.google.com/text-to-speech/
     * @param text 
     * @param lang 
     * @param voice 
     * @returns 
     */
    private synthesizeSpeechCloud(text: string, lang: string, voice: string) {
        return new Promise(async (resolve, reject) => {
            const apiKey = ApiDef.ttsApiKey;
            const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;
            console.log("tts synthesize speech cloud");

            const request = {
                input: { text: text },
                voice: { languageCode: lang != null ? lang : 'en-US', name: voice != null ? voice : 'en-US-Standard-J' },
                audioConfig: {
                    audioEncoding: 'MP3',
                    effectsProfileId: [
                        "handset-class-device"
                    ],
                    pitch: 0,
                    speakingRate: 1
                }
            };

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(request),
                });
                if (!response.ok) {
                    reject(new Error('Network error'));
                    return;
                }
                const data = await response.json();
                resolve(data.audioContent); // This is the base64-encoded audio content
            } catch (err) {
                console.error('Error calling the Text-to-Speech API:', err);
                reject(err);
            }
        });
    }

    /**
     * text to speech
     * add to queue
     * @param text 
     */
    textToSpeechQueue(text: string, soundHack: boolean, volume: number, onComplete: () => any, timeout: number, isContent: boolean) {
        if (!this.checkEnabled() && !soundHack) {
            if (onComplete) {
                onComplete();
            }
            return;
        }

        if (text === null && !soundHack) {
            if (onComplete) {
                onComplete();
            }
            return;
        }

        this.observable.ttsReady.next(false);
        this.ttsReady = false;

        console.log("tts request queue: " + text);

        this.genericQueue.enqueue(() => {
            if (soundHack) {
                let soundId: string = SoundUtils.soundBank.coin.id;
                console.log("play sound hack soundId: " + soundId);
                return this.playSoundHack(soundId);
            } else {
                return this.textToSpeechCoreResolve(text, volume, timeout, isContent);
            }
        }, () => {
            this.ttsReady = true;
            this.observable.ttsReady.next(true);
            if (onComplete) {
                onComplete();
            }
        }, null, EQueues.tts, null, timeout != null ? timeout : 20000);
    }

    async textToSpeechNoAction(text: string, volume: number, onComplete: () => any, timeout: number) {
        if (!this.checkEnabled()) {
            console.warn("tts not enabled");
            return;
        }
        try {
            await this.textToSpeechCoreResolve(text, volume, timeout, false);
            if (onComplete) {
                onComplete();
            }
        } catch (err) {
            console.error(err);
            if (onComplete) {
                onComplete();
            }
        }
    }

    private checkEnabled(): boolean {
        if (!this.enabled) {
            return false;
        }

        if (!SettingsManagerService.settings.app.settings.tts.value) {
            return false;
        }

        return this.getPlatformOption().enable;
    }

    getVoicesNative(): Promise<ITTSVoice[]> {
        return new Promise(async (resolve, reject) => {
            try {
                let res = await TextToSpeech.getSupportedVoices(); // this fails in bg mode, prevent deadlock
                let voices: ITTSVoice[] = res.voices.map((voice) => {
                    let v: ITTSVoice = {
                        name: voice.name,
                        uri: voice.voiceURI,
                        local: voice.localService,
                        lang: voice.lang
                    };
                    return v;
                });
                resolve(voices);
            } catch (err) {
                reject(err);
            }
        });
    }

    getVoicesBrowser(langCode: string): Promise<ITTSVoice[]> {
        return new Promise(async (resolve, reject) => {
            try {
                let voices: SpeechSynthesisVoice[] = synth.getVoices();
                let voicesList: ITTSVoice[] = [];
                for (let voice1 of voices) {
                    if (voice1.lang === langCode) {
                        let v: ITTSVoice = {
                            name: voice1.name,
                            uri: voice1.voiceURI,
                            local: voice1.localService,
                            lang: voice1.lang
                        }
                        voicesList.push(v);

                    }
                }
                resolve(voicesList);
            } catch (err) {
                reject(err);
            }
        });
    }

    getVoicesCloud(langCode: string): Promise<ITTSVoice[]> {
        return new Promise(async (resolve, reject) => {
            const apiKey = ApiDef.ttsApiKey;
            const url = `https://texttospeech.googleapis.com/v1/voices?key=${apiKey}&languageCode=${langCode}`;
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (!response.ok) {
                    reject(new Error('Network error'));
                    return;
                }
                const data = await response.json();
                resolve(data);
            } catch (err) {
                console.error('Error calling the Text-to-Speech API:', err);
                reject(err);
            }
        });
    }



    getPlatformOption(): ITTSPlatformOption {
        switch (GeneralCache.os) {
            case EOS.ios:
                return this.platformSetup.ios;
            case EOS.android:
                return this.platformSetup.android;
            case EOS.browser:
            default:
                return this.platformSetup.web;
        }
    }


    private getTTSVoiceOptionsNative(lang: ITTSVoice): TTSOptions {
        let options: TTSOptions = {
            text: null,
            lang: lang.lang,
            // locale: "en-GB",
            // locale: "fr_FR",
            rate: 1.00,
            volume: 1
        };
        return options;
    }

    private getTTSVoiceOptionsBrowser(lang: ITTSVoice): SpeechSynthesisVoice {
        if (synth == null) {
            console.warn("synth not available");
            return;
        }
        let voices: SpeechSynthesisVoice[] = synth.getVoices();
        let voice: SpeechSynthesisVoice;
        for (let voice1 of voices) {
            if (voice1.localService && voice1.lang === lang.lang) {
                voice = voice1;
                break;
            }
        }
        return voice;
    }

    private getTTSVoiceOptionsCloud(lang: ITTSVoice) {
        return lang;
    }

    getTTSVoiceOptionsApp(langCode: string) {
        return new Promise(async (resolve) => {
            let po: ITTSPlatformOption = this.getPlatformOption();
            let ttsVoices: ITTSVoice[] = await this.getTTSVoiceOptionsCore(po.app, langCode);
            resolve(ttsVoices);
        });
    }

    getTTSVoiceOptionsContent(langCode: string) {
        return new Promise(async (resolve) => {
            let po: ITTSPlatformOption = this.getPlatformOption();
            let ttsVoices: ITTSVoice[] = await this.getTTSVoiceOptionsCore(po.content, langCode);
            resolve(ttsVoices);
        });
    }

    private getTTSVoiceOptionsCore(context: number, langCode: string) {
        return new Promise<ITTSVoice[]>(async (resolve) => {
            let ttsVoices: ITTSVoice[] = [];
            try {
                switch (context) {
                    case ETTSMode.native:
                        ttsVoices = await this.getVoicesNative();
                        break;
                    case ETTSMode.browser:
                        ttsVoices = await this.getVoicesBrowser(langCode);
                        break;
                    case ETTSMode.cloud:
                        ttsVoices = await this.getVoicesCloud(langCode);
                        break;
                }
            } catch (err) {
                console.error(err);
            }
            resolve(ttsVoices);
        });
    }

    /**
     * set TTS voice and language for app messages
     * @param lang 
     */
    setTTSVoiceOptionsApp(lang: ITTSVoice) {
        let po: ITTSPlatformOption = this.getPlatformOption();
        switch (po.app) {
            case ETTSMode.native:
                this.voiceOptions.app.native = this.getTTSVoiceOptionsNative(lang);
                break;
            case ETTSMode.browser:
                this.voiceOptions.app.browser = this.getTTSVoiceOptionsBrowser(lang);
                break;
            case ETTSMode.cloud:
                this.voiceOptions.app.cloud = this.getTTSVoiceOptionsCloud(lang);
                break;
        }
    }

    /**
     * set TTS voice and language for content
     * @param lang 
     */
    setTTSVoiceOptionsContent(langModel: IDBModelLanguage, langOverride: string) {
        let po: ITTSPlatformOption = this.getPlatformOption();
        let lang: ITTSVoice = {
            name: langOverride != null ? langOverride : langModel != null ? langModel.langCode : "en-US",
            voice: langModel != null ? langModel.ttsVoice : null
        };
        switch (po.content) {
            case ETTSMode.native:
                this.voiceOptions.content.native = this.getTTSVoiceOptionsNative(lang);
                break;
            case ETTSMode.browser:
                this.voiceOptions.content.browser = this.getTTSVoiceOptionsBrowser(lang);
                break;
            case ETTSMode.cloud:
                this.voiceOptions.content.cloud = this.getTTSVoiceOptionsCloud(lang);
                break;
        }
    }

    initDefaultTTSOptions() {
        let defaultVoice: ITTSVoice = {
            name: "en-US"
        };
        // context setup
        this.setTTSVoiceOptionsApp(defaultVoice);
        this.setTTSVoiceOptionsContent(null, defaultVoice.name);
    }

    speakNative(text: string, volume: number, _timeout: number, opts: TTSOptions) {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            let options: TTSOptions = {
                text: text,
                lang: opts ? opts.lang : "en-US",
                // locale: "en-GB",
                // locale: "fr_FR",
                rate: 1.00,
                volume: volume
            };

            console.log("tts speak native: ", options);

            try {
                await TextToSpeech.getSupportedVoices(); // this fails in bg mode, prevent deadlock
                this.speechInProgress = true;
                await TextToSpeech.speak(options);
                console.log("speech complete");
                ResourceManager.clearTimeout(this.speechTimeout);
                this.speechInProgress = false;
                resolve(true);
            } catch (err) {
                console.log("speech error");
                console.error(err);
                ResourceManager.clearTimeout(this.speechTimeout);
                this.speechInProgress = false;
                resolve(false);
            }
        });
        return promise;
    }

    speakBrowser(text: string, volume: number, timeout: number, opts: SpeechSynthesisVoice) {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            // if (this.first) {
            //     this.first = false;
            //     console.log("first speech");
            //     let btn = document.getElementById('speechButtonHidden');
            //     console.log(btn);
            //     btn.click();
            // }

            console.log("tts speak browser: ", opts);

            if (synth == null) {
                console.warn("synth not available");
                resolve(false);
                return;
            }

            if (synth.speaking) {
                synth.cancel();
            }

            let btn = document.getElementById('speechButtonHidden');
            console.log(btn);

            btn.onclick = () => {
                console.log("virtual button clicked");
                // https://mdn.github.io/web-speech-api/speak-easy-synthesis/

                let voice: SpeechSynthesisVoice = opts;
                var spk = new SpeechSynthesisUtterance(text);
                spk.onstart = () => {
                    console.log("speech started");
                    this.speechInProgress = true;
                };
                spk.onend = () => {
                    console.log("speech ended");
                    ResourceManager.clearTimeout(this.speechTimeout);
                    this.speechInProgress = false;
                    resolve(true);
                };
                spk.onerror = (event) => {
                    console.error(event);
                    ResourceManager.clearTimeout(this.speechTimeout);
                    this.speechInProgress = false;
                    resolve(false);
                };
                spk.voice = voice;

                spk.pitch = 1;
                spk.rate = 1;
                spk.volume = volume;

                synth.onvoiceschanged = () => {
                    console.log("voice changed");
                };

                synth.speak(spk);

                ResourceManager.clearTimeout(this.speechTimeout);
                this.speechTimeout = setTimeout(() => {
                    console.log("speech timed out");
                    this.speechInProgress = false;
                    resolve(true);
                }, timeout != null ? timeout : 10000);
            };

            btn.click();
        });
        return promise;
    }

    speakCloud(text: string, volume: number, _timeout: number, opts: ITTSVoice) {
        return new Promise(async (resolve) => {
            console.log("tts speak cloud: ", opts);

            if (text === this.lastPlayedText && this.audioElement.src) {
                this.speechInProgress = true;
                this.playAudio(volume);
                await WaitUtils.waitFlagResolve(null, this.observable.audioEvent, [false], 120000);
                this.speechInProgress = false;
                resolve(true);
                return;
            }
            this.lastPlayedText = text;
            const audioContentBase64 = await this.synthesizeSpeechCloud(text, opts.lang, opts.voice);
            if (audioContentBase64) {
                this.audioElement.src = `data:audio/mp3;base64,${audioContentBase64}`;
                this.speechInProgress = true;
                this.playAudio(volume);
            }
            await WaitUtils.waitFlagResolve(null, this.observable.audioEvent, [false], 120000);
            this.speechInProgress = false;
            resolve(true);
        });
    }

    textToSpeechCoreResolve(text: string, volume: number, timeout: number, isContent: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            if (!this.checkEnabled()) {
                console.warn("tts not enabled");
                resolve(false);
                return;
            }

            console.log("tts request core: " + text);

            if (volume == null) {
                volume = 0.7;
            }

            /**
             * ["
             * ku,pt_BR,ta,ja_JP,sk_SK,tr_TR,ru_RU,ko_KR,hi_IN,fil_PH,
             * fi_FI,ca,hr,es_ES,sk,ro_RO,zh_TW,sw,el_GR,fr_BE,en_GB,
             * nl_NL,la,et_EE,pt_PT,fr_FR,bs,km_KH,vi_VN,en_AU,pl_PL,
             * da_DK,sq,cy,en_US,bn_IN,si_LK,in_ID,yue_HK,uk_UA,bn_BD,
             * sr,en_IN,cs_CZ,it_IT,ne_NP,de_DE,es_US,zh_CN,th_TH,sv_SE,nb_NO,hu_HU" ]
             */

            this.waitUnlocked().then(async () => {
                let po = this.getPlatformOption();
                let co = isContent ? po.content : po.app;
                let vo = isContent ? this.voiceOptions.content : this.voiceOptions.app;
                if (volume < 0.1) {
                    co = GeneralCache.os !== EOS.browser ? ETTSMode.native : ETTSMode.browser; // filler sound hack
                }
                console.log("tts start context: " + co);
                switch (co) {
                    case ETTSMode.native:
                        await this.speakNative(text, volume, timeout, vo.native);
                        break;
                    case ETTSMode.browser:
                        await this.speakBrowser(text, volume, timeout, vo.browser);
                        break;
                    case ETTSMode.cloud:
                        await this.speakCloud(text, volume, timeout, vo.cloud);
                        break;
                }
                resolve(true);
            });
        });
        return promise;
    }

    stopContext(context: number) {
        console.log("tts stop context: " + context);
        switch (context) {
            case ETTSMode.native:
                PromiseUtils.wrapNoAction(TextToSpeech.stop(), true);
                break;
            case ETTSMode.browser:
                synth.cancel();
                break;
            case ETTSMode.cloud:
                this.stopAudio();
                break;
        }
    }

    stop(cloud: boolean) {
        if (!this.checkEnabled()) {
            console.warn("tts not enabled");
            return;
        }
        let po = this.getPlatformOption();
        this.stopContext(po.content);
        this.stopContext(po.app);
        if (cloud) {
            this.stopAudio();
        }
    }


    /**
     * speak time left if time left matches increment
     * e.g. each 30 seconds
     * adaptive speech (don't include full detail)
     * @param timeLeft 
     * @param increment 
     * @param keepTrackOfChanges trigger speech only if time speech is different
     */
    speakTimeLeft(timeLeft: number, keepTrackOfChanges: boolean, useQueue: boolean) {
        if (!this.checkEnabled()) {
            console.warn("tts not enabled");
            return;
        }
        let message: string = this.getTimeLeftMessageWithContext(timeLeft, keepTrackOfChanges, useQueue);
        if (message) {
            this.textToSpeechWrapper(message, useQueue, null, null, null);
        }
    }

    /**
     * get time left message
     * returns null if time should not be spoken yet
     * @param timeLeft 
     * @param totalTime
     * @param keepTrackOfChanges 
     * @param useQueue 
     */
    getTimeLeftMessageWithContext(timeLeft: number, keepTrackOfChanges: boolean, useQueue: boolean): string {
        let message: string = "";
        const fiveMinutes = 5 * 60;
        const twoMinutes = 2 * 60;
        const oneMinute = 1 * 60;

        if (timeLeft > 0) {
            if (timeLeft >= fiveMinutes) {
                // Speak every 5 minutes
                if (timeLeft % fiveMinutes == 0) {
                    let ttsdata: string[] = TimeUtils.formatTimeSpeechFromSeconds(timeLeft, true);
                    console.log(ttsdata[0]);
                    if (keepTrackOfChanges) {
                        if (ttsdata[0] !== this.lastTimeSpeechOutput) {
                            this.lastTimeSpeechOutput = ttsdata[0];
                            message = ttsdata[0] + " left", useQueue;
                        }
                    } else {
                        message = ttsdata[0] + " left", useQueue;
                    }
                } else {
                    message = null;
                }
            } else {
                // Speak at 2 minutes and 1 minute left
                if (timeLeft == twoMinutes || timeLeft == oneMinute) {
                    let ttsdata: string[] = TimeUtils.formatTimeSpeechFromSeconds(timeLeft, true);
                    console.log(ttsdata[0]);
                    if (keepTrackOfChanges) {
                        if (ttsdata[0] !== this.lastTimeSpeechOutput) {
                            this.lastTimeSpeechOutput = ttsdata[0];
                            message = ttsdata[0] + " left", useQueue;
                        }
                    } else {
                        message = ttsdata[0] + " left", useQueue;
                    }
                } else {
                    message = null;
                }
            }
        } else {
            message = null;
        }
        return message;
    }


    private textToSpeechWrapper(text: string, useQueue: boolean, volume: number, onComplete: () => any, timeout: number) {
        if (!this.checkEnabled()) {
            console.warn("tts not enabled");
            return;
        }
        if (!useQueue) {
            this.textToSpeechNoAction(text, volume, onComplete, timeout);
        } else {
            this.textToSpeechQueue(text, false, volume, onComplete, timeout, false);
        }
    }

    checkAlreadyPlayed(code: number): boolean {
        if (this.tutorialsPlayed.indexOf(code) !== -1) {
            return true;
        }
        return false;
    }

    /**
     * speak using loader code (tutorial from DB)
     * option to read the tutorial only once (for each tutorial code)
     * @param code 
     * @param text if specified, loader code is only virtual, and content is overridden
     * @param useQueue 
     * @param onlyOnce 
     */
    speakUsingLoaderCode(code: number, text: string, useQueue: boolean, onlyOnce: boolean, volume: number, onComplete: () => any, timeout: number) {
        if (!this.checkEnabled()) {
            console.warn("tts not enabled");
            return;
        }
        if (onlyOnce) {
            if (this.checkAlreadyPlayed(code)) {
                return;
            }
        }
        let promiseText: Promise<string> = new Promise((resolve) => {
            if (text != null) {
                resolve(text);
            } else {
                this.resources.getInfoData(code, true).then((resp: ITutorial) => {
                    if (resp && resp.description) {
                        resolve(resp.description);
                    } else {
                        resolve(null);
                    }
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(null);
                });
            }
        });
        promiseText.then((text: string) => {
            if (text != null) {
                this.tutorialsPlayed.push(code);
                this.textToSpeechWrapper(text, useQueue, volume, onComplete, timeout);
            }
        });
    }
}
