

export class ApiDef {
    // public static mainServerURL = "http://localhost:8081";
    public static mainServerURL = "https://leplace.leplace-api.com";

    // leplace world
    public static googleMapsApiKeyJS: string = "AIzaSyCYGqQuoIgEIN5g3c2HxG2KGU1GTg28k34";
    // leplace world
    public static googleWebClientId: string = "806950131803-u35esea64did6ab7vh8fblldhoicklj1.apps.googleusercontent.com";

    // from onesignal, for leplace world
    public static oneSignalAppId: string = "96149543-bbd3-45ba-9c9a-1725cbf05dce";
    // from firebase, for leplace world
    public static pushSenderId: string = "806950131803";

    // IAP
    public static billingKey: string = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAiTmLDpUHQ0Z0QnfFyrF720ZuKRa6S85Agj7sEHjEA1DD0dEZvYDRMUZjBqf9FZ5cz5ddrRQENVtNKNqsW0upCda8e+yJU++fMoSCI1NrJHpGGkNH4noZzYtr3a39FKbFM2Skrao7jakVKvUl7Q9K2Tk/rkrHSBsc+7A9N+HkwiZe/anVbBBF5Lqb1GdrD5eFzlBQWrhAfu0O/GCuOEGPK6E+0ebYPaod91dYQFOOVaOWNKySdi0Q54OLXmB/7Z+Ec5zb9uHMzaQrfv24xfmLNIsiHG3E1lE8dv8MNZ88gYD5yxHZT8fEbiU5bXRetOos1owj9P/mcD1buf0SIIIghwIDAQAB";
    // public static revenueCatApiKey: string = "sk_RQUxQiiSCLAivUVPDjtuitSdAErLs";
    public static revenueCatApiKey: string = "goog_wGaYYSQjXXitjitOqfLIxnbtIMO";
    public static revenueCatApiKeyIOS: string = "appl_wnrHfYRQmKzPAMivyLcfNcIUFNq";

    // zoom
    public static zoomApiKey: string = "Tp5ZpSxhRIeDnQuZzTsuKQ";
    public static zoomApiSecret: string = "KqboVX5NVZG78h0FBTpNYPmkIYt0C4HWGCQc";

    // deprecated
    public static hereMapsAppId: string = "Nhml32bk8NRCayekb4Sg";
    public static hereMapsAppCode: string = "jMT2tRQNToBlxJHqFyuk0w";

    public static facebookAppId: string = "151466342186131";

    // leplace world
    public static codePushDebugKey: string = "IA3yk3CIHMlxNwYYDWGqniLuaQ0n8yax8UI0M";
    public static codePushReleaseKey: string = "xK6lSTXyGIOPchaMPW83LOvOI8NBAHACq-uhA";
    public static codePushDebugKeyIOS: string = "PHDbqf6b1XycP0oKjNz47gOnBzS-CEh9e_y5g";
    public static codePushReleaseKeyIOS: string = "XtUwq9nGtXvEXVynGeth0hvCjivWzFPSaUhyK";

    // tts
    public static ttsApiKey: string = "AIzaSyAN8qKDjGl1q8U_n8NK8METTC_B79kUkk8";


    /**
     * only valid for AdMob network
     * for inmobi, add test devices via console: https://developers.google.com/admob/android/mediation/inmobi
     */
    public static admobTestDevices: string[] = [];
    public static admobTestDevicesIOS: string[] = [];

    public static apiKeysLoaded: boolean = false;

    static loadRemoteConfig(apiKeys: IApiKey[]) {
        for (let apik of apiKeys) {
            switch (apik.apiSpecCode) {
                case EApiSpec.googleWebClientId:
                    ApiDef.googleWebClientId = apik.value;
                    break;
                case EApiSpec.oneSignalAppId:
                    ApiDef.oneSignalAppId = apik.value;
                    break;
                case EApiSpec.firebasePushSenderId:
                    ApiDef.pushSenderId = apik.value;
                    break;
                case EApiSpec.googleMapsApiKeyJS:
                    ApiDef.googleMapsApiKeyJS = apik.value;
                    break;
                case EApiSpec.facebookAppId:
                    ApiDef.facebookAppId = apik.value;
                    break;
                case EApiSpec.admobTestDevices:
                    ApiDef.admobTestDevices.push(apik.value);
                    break;
                case EApiSpec.admobTestDevicesIOS:
                    ApiDef.admobTestDevicesIOS.push(apik.value);
                    break;
                case EApiSpec.ttsApiKey:
                    ApiDef.ttsApiKey = apik.value;
                    break;
                default:
                    break;
            }
        }
    }
}


export enum EApiSpec {
    // googleplus login verification
    googleWebClientId = 1,
    // subscribe to push notifications
    oneSignalAppId = 2,
    // push notification sender
    firebasePushSenderId = 3,
    // google maps js key
    googleMapsApiKeyJS = 4,
    // admob test devices
    admobTestDevices = 6,
    admobTestDevicesIOS = 7,
    facebookAppId = 8,
    ttsApiKey = 9
}

export enum EConnectLinks {
    // support = "https://connect.leplace.online/#/support"
    support = "https://connect.leplace.online/support"
}

export interface IApiKey {
    apiSpecCode: number,
    value: string
}
