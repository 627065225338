import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class ActivityStatsTrackerService {

    droneUsed: boolean = false;

    constructor(

    ) {
        console.log("activity stats tracker service created");
    }

    checkDroneUsed() {
        return this.droneUsed;
    }

    registerDroneUsed() {
        console.log("stats tracker register drone used");
        this.droneUsed = true;
    }

    startNewSession() {
        console.log("stats tracker start new session");
        this.droneUsed = false;
    }
}
